import React from 'react';

interface HowToPlayModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isModalFadingOut: boolean;
  handleAnimationEnd: () => void;
}

const HowToPlayModal: React.FC<HowToPlayModalProps> = ({
  isOpen,
  setIsOpen,
  isModalFadingOut,
  handleAnimationEnd,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div
        className={`modal-how-to ${isModalFadingOut ? 'fade-out' : ''}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <span
          onClick={() => setIsOpen(false)}
          style={{ color: 'white', fontSize: '25px', marginLeft: '600px' }}
        >
          &times;
        </span>
        <div className="modal-content-2">
          <div className="display-image">
            <img src={'/img/Help.svg'} />
          </div>
          <p
            className="how-to-header"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            {' '}
            How do I play?
          </p>
          <p
            className="how-to-desc"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            If you’re new to fantasy sports, here’s a quick rundown on how to
            get started.
          </p>
          <p
            className="how-to-step "
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Step 1: Choose a sport
          </p>
          <img
            className="how-to-img"
            src={'/img/Choose-sport.png'}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <p
            className="how-to-step"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Step 2: Choose a competition
          </p>
          <p
            className="how-to-desc"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Competitions determine the matches played and players available for
            that league.
          </p>
          <img
            className="how-to-img"
            src={'/img/choose-competition.png'}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <p
            className="how-to-step"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Step 3: Create a team
          </p>
          <p
            className="how-to-desc"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            This is where you choose which players to have on your team. They
            each have a monetary value that increases or decreases based on
            their performance. This determines the overall value of your team
            and your ranking within a league.
          </p>
          <img
            className="how-to-img"
            src={'/img/create-team.png'}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <p
            className="how-to-step"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Step 4: Choose a league
          </p>
          <p className="how-to-desc">
            Leagues are where you play your team and compete against other
            users. Some leagues require a fee to participate. Please check the
            league information to see if this applies. To see what users are in
            a league, simply click on the league name.
          </p>
          <img className="how-to-img" src={'/img/choose-league.png'} />
          <p
            className="how-to-desc"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            **Note: A user who has previously won an Athstat Paid league will
            have a trophy icon next to their name so you can easily identify
            experienced players.
          </p>
          <img
            className="how-to-img"
            src={'/img/user-winner.png'}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <div
            className="how-to-modal-buttons"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <button className="cancel-button" onClick={() => setIsOpen(false)}>
              {' '}
              Cancel{' '}
            </button>
            <button className="got-it-button" onClick={() => setIsOpen(false)}>
              {' '}
              Got It
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayModal;
