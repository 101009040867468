import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from '@mui/material';
import axios from 'axios';
import AccessDenied from '../../AccessDenied';
import Message from '../../Message/Message';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const SportsDashboard: React.FC = () => {
  const { isAuthenticated, user } = useAuth();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const navigate = useNavigate();
  const [access, setAccess] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, []);

  const handleRefreshSports = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/sports/refresh`,
        {},
        {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      )
      .then((result) => {
        setMessage(result.data);
        setIsError(false);
        setShowMessage(true);
      })
      .catch(() => {
        setMessage('Error Updating Sports');
        setIsError(true);
        setShowMessage(true);
      });
  };

  if (!access) {
    return <AccessDenied message={'Admin Access Denied'} />;
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>Sports Dashboard</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col size="6" lg="6" md="3">
              <Button
                className="Theme-Btn Table-Btn"
                onClick={() => {
                  handleRefreshSports();
                }}
              >
                Refresh
              </Button>
            </Col>
            {showMessage && (
              <Col size="6" lg="6" md="3">
                <Message
                  message={message}
                  setShowMessage={setShowMessage}
                  error={isError}
                  noMargin={true}
                />
              </Col>
            )}
          </Row>
        </section>
      </React.Fragment>
    );
  }
};

export default SportsDashboard;
