import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IMember, IOrganization, ITeam } from '../../../../d';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';
import AccessDenied from '../../AccessDenied';
import Message from '../../Message/Message';
import ReceiptSubscriptionsTable from './ReceiptSubscriptionsTable';

const Subscriptions: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const package_name = searchParams.get('package_name');
  const team_id = searchParams.get('team_id');
  const state = searchParams.get('state');
  const [teamState, setTeamState] = useState<ITeam | undefined>();
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [loadingData, setLoadingData] = useState(false);
  const [userRoleLevel, setUserRoleLevel] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);

  const packageName = (
    cPackageName: string | string[]
  ): { name: string | string[]; price: string } => {
    switch (cPackageName) {
      case 'pitchside_package':
        return { name: 'Pitchside', price: '$0/mo' };
      case 'data_sharing_package':
        return { name: 'Data Hub', price: '$10/mo' };
      case 'analysis_package':
        return { name: 'Analytics', price: '$49/mo' };
      case 'bundle_package':
        return { name: 'Bundle (Data Hub & Analytics)', price: '$55/mo' };
      default:
        return { name: 'Athstat Package', price: '$0/mo' };
    }
  };

  useEffect(() => {
    if (isAuthenticated && token) {
      if (team_id) {
        setLoadingData(true);
        const getTeam = async () => {
          const fetchedTeam: ITeam = await axios
            .get(`${process.env.REACT_APP_API_URL}/teams/team/${team_id}`, {
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            })
            .then((team) => {
              return team.data;
            })
            .catch(() => {
              setErrorMessage('Error retrieving team');
            });
          if (fetchedTeam) {
            setTeamState(fetchedTeam);
            const fetchedOrg: IOrganization = await axios
              .get(
                `${process.env.REACT_APP_API_URL}/organizations/organization/${fetchedTeam.organization_id}`,
                {
                  headers: {
                    Authorization: user ? `Bearer ${token}` : undefined,
                  },
                }
              )
              .then((org) => {
                return org.data;
              })
              .catch(() => {
                setErrorMessage('Error verifying access ');
              });
            if (fetchedOrg) {
              const orgAdmin: IMember | undefined = fetchedOrg.members.find(
                (member: IMember) => member.user_id === userId
              );
              if (orgAdmin && orgAdmin.roleLevel === 500) {
                setUserRoleLevel(orgAdmin.roleLevel);
              }
            }
          }
        };

        if (state && state === 'succeeded') {
          setMessage(
            `Thank you for purchasing a Monthly subscription of ${
              package_name
                ? packageName(package_name).name
                : 'an Athstat Package!'
            }! Please note that subscription updates may take a few minutes to appear. Please refresh your page if the correct payment status is not showing.`
          );
          setIsError(false);
          setShowMessage(true);
        } else {
          setMessage(
            `Subscription Update Failed. Please reach out to Athstat Support and reference ID: ${
              id ? id : 'Not Available'
            } `
          );
          setIsError(true);
          setShowMessage(true);
        }

        const timerFunc = setTimeout(() => {
          getTeam();
          setLoadingData(false);
        }, 1000);

        return () => clearTimeout(timerFunc);
      }
    }
  }, []);

  if (loadingData) {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>Loading...</h2>
              </div>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  } else if (!team_id || !teamState) {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>Team Subscriptions</h2>
              </div>
            </Col>
          </Row>
          {showMessage && state && id && (
            <Row end={true}>
              <Col size="12" md="6" lg="6" className={'adminDashboardMessage'}>
                <Message
                  message={
                    'Subscription Update Failed. Please reach out to Athstat Support'
                  }
                  setShowMessage={setShowMessage}
                  error={true}
                  noMargin={true}
                />
              </Col>
            </Row>
          )}
        </section>
      </React.Fragment>
    );
  } else if (userRoleLevel !== 500) {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <div className="SectionTitle _with-btn">
            <AccessDenied message={errorMessage} />
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>
                  {teamState
                    ? `${teamState.name}'s Subscriptions`
                    : 'Team Subscriptions'}
                </h2>
              </div>
            </Col>
          </Row>
          {showMessage && state && id && (
            <Col size="12" md="12" lg="12">
              <Message
                message={message}
                setShowMessage={setShowMessage}
                error={isError}
                noMargin={true}
              />
            </Col>
          )}
          <Row>
            <Col size="12">
              <br />

              {/*Subscription Table*/}
              {!loadingData && teamState && (
                <ReceiptSubscriptionsTable teamState={teamState} />
              )}
              {loadingData ||
                (!teamState && (
                  <div style={{ color: 'white' }}>No Team Loaded</div>
                ))}
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
};
export default Subscriptions;
