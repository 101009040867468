/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateLeagueModal: React.FC<Props> = ({ setIsModalOpen }) => {
  const [leagueName, setLeagueName] = useState('');
  const { user } = useAuth();
  const accessToken = user?.access_token;

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [officialLeagueId, setOfficialLeagueId] = useState<string | null>(null);
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const leagueTypeOptions = ['private', 'public'];
  const [selectedLeagueType, setSelectedLeagueType] = useState('');
  const [entryCode, setEntryCode] = useState<string>('');
  const [teams, setTeams] = useState<Array<IFantasyClubTeam>>([]);
  const [selectedTeam, setSelectedTeam] = useState<IFantasyClubTeam | null>(
    null
  );
  const [isLeagueCreatedModalOpen, setIsLeagueCreatedModalOpen] =
    useState(false);

  const [options] = useState<IOption[]>([
    {
      index: 1,
      title: 'Private',
      value: 'Private',
    },
    {
      index: 2,
      title: 'Public',
      value: 'Public',
    },
  ]);
  const [selectedOption, setSelectedOption] = useState<string>('Public');
  const [isCreateLeagueModalOpen, setIsCreateLeagueModalOpen] = useState(true);

  const handleCloseCreateLeagueModal = () => {
    setIsCreateLeagueModalOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setIsLeagueCreatedModalOpen(false);
  };

  interface IOption {
    index: number;
    title: string;
    value: string;
  }

  interface IFantasyClubTeam {
    id: number | null;
    name: string;
    club_id: number | null;
    official_league_id: string | null;
    created_date: Date | null;
    team_value: number | null;
    balance: number | null;
    round_score: number | null;
    transfers_remaining: number | null;
    transfer_penalties: number | null;
  }

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fantasy-teams/fantasy-teams-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // eslint-disable-next-line no-console
        console.log('Teams response:', response.data);
        if (response.data) {
          setTeams(response.data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching teams:', error);
        // Handle error feedback to the user if needed
      }
    };

    fetchTeams();
  }, [accessToken]);

  const handleCreateLeague = async () => {
    try {
      if (!leagueName || !selectedTeam) {
        // eslint-disable-next-line no-console
        console.error('Please fill in all required fields');
        return;
      }
      setIsLoading(true);

      const requestData = {
        league: {
          title: leagueName,
          type: 'u',
          official_league_id: selectedTeam?.official_league_id,
          is_private: selectedOption === 'Public' ? true : false,
          is_open: true,
        },
        team: {
          name: selectedTeam?.name || '',
          official_league_id: selectedTeam?.official_league_id || '',
          id: selectedTeam?.id,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fantasy-leagues/fantasy-user-leagues`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const createdLeague = response.data;

      if (createdLeague) {
        setEntryCode(createdLeague.entry_code);
        setIsLeagueCreatedModalOpen(true);
        setIsCreateLeagueModalOpen(false);
      }

      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating league:', error);
      setIsLoading(false);
      // Handle error feedback to the user if needed
    }
  };

  return (
    <div className="match-modal">
      <div className="modal-name-close">
        <div className="modal-img-div">
          <img className="create-team-modal-img" src="/icons/CreateTeam.svg" />
        </div>
        <h1 className="modal-close-x" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#E6E6E6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </h1>
      </div>
      {isLoading ? (
        <>
          <h1 className="modal-name">Creating league...</h1>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <div className="loading-spinner">
              <img
                src="/img/loading2.gif"
                alt="Loading"
                style={{ height: '100px', borderRadius: '50px' }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {isCreateLeagueModalOpen && (
            <>
              <h1 className="modal-name">Create League</h1>
              <h2 className="modal-description">
                Create a new league and compete with other players.
              </h2>
              <div className="new-team-input">
                <h1 className="modal-name-2">League Name</h1>
                <input
                  type="text"
                  className="modal-input"
                  style={{ color: 'white', backgroundColor: '#17181c' }}
                  id="title"
                  placeholder="League Name"
                  value={leagueName}
                  onChange={(e) => setLeagueName(e.target.value)}
                />
              </div>

              <div className="new-team-input">
                <h1 className="modal-name-2">League Type</h1>
                <select
                  className="modal-input"
                  style={{ color: 'white', backgroundColor: '#17181c' }}
                  id="title"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {leagueTypeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Team Selection Dropdown */}
              <div className="new-team-input">
                <h1 className="modal-name-2">Select a Team</h1>
                <select
                  className="modal-input"
                  style={{ color: 'white', backgroundColor: '#17181c' }}
                  value={selectedTeam ? String(selectedTeam.id) : ''}
                  onChange={(e) => {
                    const selectedTeamId: string = e.target.value;
                    const team = teams.find(
                      (t) => t.id === Number(selectedTeamId)
                    );
                    setSelectedTeam(team ?? null); // Use the nullish coalescing operator here
                  }}
                >
                  <option value="" disabled>
                    Select a Team
                  </option>
                  {teams.map((team) => (
                    <option key={team.id} value={String(team.id)}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="modal-buttons">
                <button className="modal-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button className="modal-create" onClick={handleCreateLeague}>
                  Create League
                </button>
              </div>
            </>
          )}

          {/* Success Modal */}
          {isLeagueCreatedModalOpen && (
            <>
              <h1 className="modal-name">League Created</h1>
              <h2 className="modal-description">
                Players will now be able to join and add their teams. Use the
                code below to quickly share with others.
              </h2>
              <div className="new-team-input">
                <h1 className="modal-name-2">Share code </h1>
                <input
                  type="text"
                  className="modal-input"
                  style={{ color: 'white', backgroundColor: '#17181c' }}
                  id="title"
                  placeholder=""
                  value={entryCode}
                  onChange={(e) => setLeagueName(e.target.value)}
                />
              </div>
              <h2 className="modal-description">
                You can find this code later in the “Information” tab of your
                league page.
              </h2>

              <div className="modal-buttons">
                <button className="modal-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button className="modal-create" onClick={handleClose}>
                  Close
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreateLeagueModal;
