import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import AccessDenied from '../AccessDenied';
import axios from 'axios';
import { IUpdatePaywall } from '../../../d';
import Message from '../Message/Message';
import { useAuth } from 'react-oidc-context';

const AdminDashBoard: React.FC = () => {
  const { isAuthenticated, user, isLoading } = useAuth();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const [access, setAccess] = useState<boolean>(false);
  const [paywallActive, setPaywallActive] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      const checkPaywallStatus = async () => {
        const fetchedPaywallStatus = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/paywall/check`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedPaywallStatus.data) {
          setPaywallActive(fetchedPaywallStatus.data);
        }
      };

      checkPaywallStatus();
    }
  }, []);

  const handlePaywallManagement = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatePaywall: IUpdatePaywall = {
      paywall_active: event.target.checked,
      updated_by: userId ? userId : 'User ID Unavailable',
      last_updated: new Date().toLocaleString(),
    };

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/admin/paywall/update`,
        updatePaywall,
        {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          setPaywallActive(res.data.paywall_active);
        }
      })
      .catch(() => {
        setIsError(true);
        setMessage('Error Updating Paywall');
        setShowMessage(true);
      });
  };
  if (isLoading) {
    return <AccessDenied message={'Loading...'} />;
  } else if (!isLoading && !access) {
    return <AccessDenied message={'Admin Access Denied'} />;
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row className="">
            <Col size="12">
              <div className="PageTitle">
                <h2>Super Admin Dashboard</h2>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitches"
                    checked={paywallActive}
                    onChange={(e) => {
                      handlePaywallManagement(e);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitches"
                  >
                    Paywall Activated
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          {showMessage && (
            <Row end={true}>
              <Col size="12" md="6" lg="6" className={'adminDashboardMessage'}>
                <Message
                  message={message}
                  setShowMessage={setShowMessage}
                  error={isError}
                  noMargin={true}
                />
              </Col>
            </Row>
          )}
          <div className="Tab-container Dashboard-container Admin-Dashboard-Container">
            <Row className="justify-content-center">
              <Col size="12" sm="12" md="6" lg="4">
                <Link
                  to="/auth/admin/entities"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="home-dashboard-info home-link">
                    <div className="icon-name">
                      <div className="home-dashboard-icon-box">
                        <img
                          className="home-vector"
                          src="../icons/share.svg"
                          alt={'Entity Dashboard'}
                        />
                      </div>
                      <h2>Entity Dashboard</h2>
                    </div>
                    <p>Manage Organizations, Leagues, and Teams.</p>
                  </div>
                </Link>
              </Col>

              <Col size="12" sm="12" md="6" lg="4">
                <Link
                  to="/auth/admin/sports"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="home-dashboard-info home-link">
                    <div className="icon-name">
                      <div className="home-dashboard-icon-box">
                        <img
                          className="home-vector"
                          src="../icons/CreateSport.svg"
                          alt={'Sports Dashboard'}
                        />
                      </div>
                      <h2>Sports Dashboard</h2>
                    </div>
                    <p>Manage Sports.</p>
                  </div>
                </Link>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col size="12" sm="12" md="6" lg="4">
                <Link
                  to="/auth/admin/playerSummaries"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="home-dashboard-info home-link">
                    <div className="icon-name">
                      <div className="home-dashboard-icon-box">
                        <img
                          className="home-vector"
                          src="../icons/CreateSport.svg"
                          alt={'Player Card'}
                        />
                      </div>
                      <h2>Player Summaries Dashboard</h2>
                    </div>
                    <p>Create and Manage Player Summaries.</p>
                  </div>
                </Link>
              </Col>

              <Col size="12" sm="12" md="6" lg="4">
                <Link to="/auth/admin/logs" style={{ textDecoration: 'none' }}>
                  <div className="home-dashboard-info home-link">
                    <div className="icon-name">
                      <div className="home-dashboard-icon-box">
                        <img
                          className="home-vector"
                          src="../icons/DataAnalysis.svg"
                          alt={'Logs Dashboard'}
                        />
                      </div>
                      <h2>Logs Dashboard</h2>
                    </div>
                    <p>Manage Logs.</p>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
};
export default AdminDashBoard;
