export const parseDate = (dateString: string): string => {
  const date = new Date(dateString);
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${date.getDate()} ${date.toLocaleString('default', {
    month: 'short',
  })} ${date.getFullYear()} ${date.getHours()}:${minutes} (GMT+1)`;
};

export const getDisplayDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toDateString();
};
