import {
  getFantasyLeagueById,
  getFantasyLeagueTeamsByLeagueId,
} from 'components/Games/games.services';

import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IFantasyLeagueTeam, ILeagueJoinedTable } from '../../../../d';

export const Leaderboard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const leagueId = searchParams.get('leagueId');
  const { user, isAuthenticated } = useAuth();
  const token = user?.access_token;
  const [league, setLeague] = React.useState<ILeagueJoinedTable | null>(null);
  const [teams, setTeams] = React.useState<IFantasyLeagueTeam[]>([]);
  const [showModal, setShowModal] = useState(false);

  const navigator = useNavigate();

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleShowLeagueCode = () => {
    setShowModal(true);
  };
  useEffect(() => {
    const fetchLeaderboard = async () => {
      if (isAuthenticated && token && leagueId) {
        const response = await getFantasyLeagueById({
          id: leagueId,
          token: token ? token : '',
        });
        setLeague(response);
      }
    };

    const fetchLeagueTeams = async () => {
      if (token && leagueId) {
        const response = await getFantasyLeagueTeamsByLeagueId({
          id: leagueId,
          token: token ? token : '',
        });
        setTeams(response);
      }
    };
    fetchLeaderboard();
    fetchLeagueTeams();
  }, []);

  return (
    <React.Fragment>
      <div className="leaderboard-styling">
        <div
          className="back-breadcrumbs2"
          style={{
            marginTop: '100px',
            marginLeft: '125px',
            marginBottom: '-90px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => navigator(-1)}
            style={{ background: 'none', border: 'none' }}
          >
            <img
              className="nft-vector"
              src="/icons/back-nft-2.svg"
              alt={'Portfolio'}
            />
          </Button>
        </div>
        <Row
          className="dashboard-PageTitle-row"
          style={{
            marginTop: '100px',
            marginLeft: '140px',
            marginBottom: '-70px',
          }}
        >
          <Col size="12">
            <div className="PageTitle" style={{ marginTop: '20px' }}>
              <h2>{league?.title}</h2>
              <div className="league-menu">
                <div className="menu-icon" onClick={toggleMenu}>
                  ⋮
                </div>
                {menuVisible && (
                  <div className="menu-options" style={{ color: 'white' }}>
                    <div className="menu-option" onClick={handleShowLeagueCode}>
                      View League Code
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="container HeaderDown" style={{ marginLeft: '200px' }}>
          <table className="table table-history" style={{ width: '60vw' }}>
            <thead>
              <tr className="table-history-header">
                <th scope="col">Pos</th>
                <th scope="col">Team Name</th>
                <th scope="col">Entry Bal.</th>
                <th scope="col">Score</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, index) => {
                return (
                  <tr key={team.id}>
                    <td>{index + 1}</td>
                    <td>{team.team_name}</td>
                    <td>{team.entry_balance?.toFixed(2)}</td>
                    <td>{team.score?.toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        centered
        size="sm"
        animation
        show={showModal}
        onHide={() => setShowModal(!showModal)}
      >
        <Modal.Header closeButton style={{ backgroundColor: '#17181c' }}>
          <Modal.Title>League</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            style={{
              backgroundColor: '#17181c',
              borderRadius: 8,
              padding: 16,
            }}
          >
            <span style={{ color: 'white' }}>{league?.entry_code}</span>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
