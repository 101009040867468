import { createContext } from 'react';
import { ICoach, IGame, IReferee, IRoster, ISubstitution } from '../../d';

interface IGameContext {
  game: IGame | null;
  setGame?: (game: IGame) => void;
}
export const refereeObject: IReferee = {
  name: '',
  type: '',
  comment: '',
};

export const substituteObject: ISubstitution = {
  playerInName: '',
  playerInNumber: '',
  reason: '',
  time: '',
  playerOutName: '',
  playerOutNumber: '',
};

export const rosterObject: IRoster = {
  user_id: '',
  roster_id: 0,
  name: '',
  number: '',
  position: '',
  isCaptain: false,
  isSubstitute: false,
  isFrontRow: false,
};

export const coachObject: ICoach = {
  name: '',
  position: '',
  comment: '',
};

export const sampleGame: IGame = {
  name: 'Sample Game',
  date: new Date('10/01/2021'),
  time: '00:00',
  location: 'Home',
  timezone: 'pst',
  extraInfo: ' ',
  venue: ' ',
  isKnockout: false,
  roster: [rosterObject],
  awayRoster: [rosterObject],
  coaches: [coachObject],
  referees: [refereeObject],
  substitutions: [substituteObject],
  dataTabs: [],
  data: [],
  teamScore: 0,
  oppositionScore: 0,
  artifacts: [],
  logs: [],
  league_id: '1',
  team_id: '1',
  season_id: '1',
  organization_id: '1',
  team_admin_id: '1',
  sport_id: '1',
  leagueManaged: false,
  awayTeam_id: '',
  hidden: false,
};

export const sampleGameContext: IGameContext = {
  game: sampleGame,
};

export const GameContext = createContext<IGameContext>(sampleGameContext);
