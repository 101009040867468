import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { IFantasyAthlete, IFantasyTeamAthlete } from '../../../d';

import { postFantasyTeam, updateFantasyTeamAthletes } from './games.services';

type Slot = {
  player: IFantasyAthlete | null;
  position?: string;
};

type ActionResponse = {
  type: 'success' | 'error';
  message: string;
};

interface IReturn {
  slots: Slot[];
  addPlayers: (players: IFantasyAthlete[]) => void;
  addPlayerToIndex: (
    slotIndex: number,
    player: IFantasyAthlete
  ) => ActionResponse;
  removeByIndex: (index: number) => ActionResponse;
  getPlayerByIndex: (slotIndex: number) => IFantasyAthlete | null;
  balance: number;
  setBalance: React.Dispatch<React.SetStateAction<number>>;
  saveTeam: (teamId: number | undefined) => Promise<ActionResponse>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useFantasyTeam = (): IReturn => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [originalSlots, setOriginalSlots] = useState<Slot[]>();
  const [slots, setSlots] = useState<Slot[]>([
    { player: null },
    { player: null },
    { player: null },
    { player: null },
    { player: null },
  ]);
  const [balance, setBalance] = useState<number>(0);
  let prevSlots = slots;

  const addPlayers = (players: IFantasyAthlete[]) => {
    const newSlots = [...slots];
    players.forEach((player, index) => {
      newSlots[index].player = player;
    });
    // Create a deep copy of the newSlots array to store as originalSlots
    const newOriginalSlots = JSON.parse(JSON.stringify(newSlots));
    setOriginalSlots(newOriginalSlots);
    setSlots(newSlots);
  };

  /**
   * Replace player in slot with new player if any and update balance
   * @param slotIndex
   * @returns
   */
  const addPlayerToIndex = (
    slotIndex: number,
    player: IFantasyAthlete
  ): ActionResponse => {
    if (slotIndex >= 1 && slotIndex <= 5) {
      const newSlots = [...slots];
      const oldPlayer = newSlots[slotIndex - 1].player;
      const updatedBalance = balance + (oldPlayer?.price || 0) - player.price;
      newSlots[slotIndex - 1].player = player;
      setSlots(newSlots);
      setBalance(updatedBalance);
      return { type: 'success', message: 'Player added to slot.' };
    } else {
      return { type: 'error', message: `Invalid slot index. ${slotIndex}` };
    }
  };

  /**
   * Remove player from slot and update balance
   * @param slotIndex : index of slot to get player from
   */
  const removeByIndex = (index: number): ActionResponse => {
    const newSlots = [...slots];
    if (index >= 1 && index <= 5) {
      const oldPlayer = newSlots[index - 1].player;
      if (oldPlayer) {
        setBalance(balance + oldPlayer?.price);
      }
      newSlots[index - 1].player = null;
      setSlots(newSlots);
      prevSlots = slots;
      return { type: 'success', message: 'Player removed from slot.' };
    } else {
      return { type: 'error', message: 'Invalid slot index.' + index };
    }
  };

  //get player in slot return null if no player at slot index
  const getPlayerByIndex = (slotIndex: number): IFantasyAthlete | null => {
    if (slotIndex >= 1 && slotIndex <= 5) {
      return slots[slotIndex - 1].player;
    } else {
      return null;
    }
  };

  const saveTeam = async (
    teamid: number | undefined
  ): Promise<ActionResponse> => {
    try {
      // Check that all the slots have players
      if (slots.filter((slot) => slot.player).length < 5) {
        throw new Error('Team must have 5 players.');
      }

      // Check duplicate players
      const playerIds = slots.map((slot) => slot.player?.tracking_id);
      if (new Set(playerIds).size !== playerIds.length) {
        throw new Error('Team cannot have duplicate players.');
      }

      // Check that the team has changed
      if (originalSlots) {
        const originalPlayerIds = originalSlots.map(
          (slot) => slot.player?.tracking_id
        );
        if (JSON.stringify(originalPlayerIds) === JSON.stringify(playerIds)) {
          throw new Error('No changes made to team.');
        }
      }

      if (user?.access_token && teamid) {
        //Check if new team with 0 slots and POST
        if (originalSlots && originalSlots[0].player === null) {
          const athletes: [number, string, number, string, string, number][] =
            slots.map((slot, index) => {
              const player: IFantasyAthlete | null = slot.player;
              return [
                teamid,
                player?.tracking_id || '',
                player?.price || 0,
                new Date().toISOString(),
                player?.position || '',
                index + 1,
              ];
            });

          await postFantasyTeam({
            athletes: athletes,
            token: user.access_token,
          });
        } else {
          const athletes: IFantasyTeamAthlete[] = slots.map((slot, index) => {
            const player: IFantasyAthlete | null = slot.player;
            return {
              team_id: teamid,
              athlete_id: player?.tracking_id || '',
              purchase_date: new Date().toISOString(),
              purchase_price: player?.price || 0,
              position: player?.position || '',
              slot: index + 1,
            };
          });

          await updateFantasyTeamAthletes({
            athletes: athletes,
            token: user.access_token,
          });
        }

        return { type: 'success', message: 'Team saved successfully.' };
      } else {
        throw new Error('Error saving team.');
      }
    } catch (error) {
      return {
        type: 'error',
        message: 'Sorry, something went wrong.',
      };
    }
  };

  useEffect(() => {
    if (slots !== prevSlots) {
      return;
    }
  }, [slots]);

  return {
    slots,
    addPlayers,
    addPlayerToIndex,
    removeByIndex,
    getPlayerByIndex,
    balance,
    setBalance,
    saveTeam,
    loading,
    setLoading,
  };
};

export default useFantasyTeam;
