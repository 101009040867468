import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { useAuth } from 'react-oidc-context';
import {
  deleteFantasyTeamById,
  getFantasyTeamByUserId,
  updateFantasyTeam,
} from 'components/Games/games.services';
import { IFantasyTeam, IUpdateUser } from '../../../../d';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditTeam: React.FunctionComponent = () => {
  const [showRenameTeamModal, setShowRenameTeamModal] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [teamName, setTeamName] = useState('');
  const { user, isAuthenticated } = useAuth();
  const navigator = useNavigate();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [team, setTeam] = useState<IFantasyTeam | null>();

  useEffect(() => {
    const fetchFantasyTeam = async () => {
      try {
        if (token && isAuthenticated) {
          await getFantasyTeamByUserId({
            id: kcUser?.sub ? kcUser.sub : '',
            token: token ? token : '',
          }).then((res) => {
            setTeam(res);
          });
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchFantasyTeam();
  }, [token]);

  const onRenameTeam = async (teamName: string) => {
    try {
      if (isAuthenticated && token && team && teamName) {
        team.team_name = teamName;
        const response = await updateFantasyTeam({
          team: team,
          token: token,
        });
        if (response) {
          alert('Team name updated');
        } else {
          alert('Error updating team name');
        }
        setShowRenameTeamModal(false);
      }
    } catch (error) {
      alert('Error updating team name');
      setShowRenameTeamModal(false);
    }
  };

  const onPressDeleteTeam = () => {
    if (isAuthenticated && team && token) {
      deleteFantasyTeamById({
        id: team.id ? team.id : -1,
        token: token,
      }).then((response) => {
        if (response === 'ok') {
          alert('Team deleted');
          handleDeleteCallback(false);
        } else {
          alert('Error deleting team');
        }
        setShowDeleteTeamModal(false);
      });
    } else {
      alert('Error deleting team');
      setShowDeleteTeamModal(false);
    }
  };

  const updateOnboard = async (accepted: boolean) => {
    const update: IUpdateUser = {
      kc_id: userId,
      gamesOnboardingComplete: accepted,
      status: 'new',
    };
    await axios.put(`${process.env.REACT_APP_API_URL}/users/`, update, {
      headers: {
        Authorization: user ? `Bearer ${token}` : undefined,
      },
    });
  };

  const handleDeleteCallback = (accepted: boolean) => {
    updateOnboard(accepted);
  };

  return (
    <React.Fragment>
      <div
        className="back-breadcrumbs2"
        style={{
          marginTop: '100px',
          marginLeft: '125px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => navigator(-1)}
          style={{ background: 'none', border: 'none' }}
        >
          <img
            className="nft-vector"
            src="/icons/back-nft-2.svg"
            alt={'Portfolio'}
          />
        </Button>
      </div>
      <div className="container HeaderDown d-flex justify-content-center">
        <div
          className="graph-container"
          style={{ marginTop: '-100px', marginLeft: '-40px', padding: '20px' }}
        >
          <h3 className="text-center my-3" style={{ color: 'white' }}>
            {`Edit Your Team: ${team?.team_name ? team?.team_name : ''}`}
          </h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '50px',

              width: '70%',
              margin: '0 auto',
              marginTop: '100px',
            }}
            className="edit-team-group"
          >
            <Button
              variant="primary"
              className="fantasy-button edit-team-group"
              onClick={() => {
                setShowRenameTeamModal(true);
              }}
            >
              Edit Team Name
            </Button>
            <Button
              variant="primary"
              className="fantasy-button "
              onClick={() => {
                setShowDeleteTeamModal(true);
              }}
            >
              Delete Team
            </Button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <Modal
          show={showRenameTeamModal}
          onHide={() => setShowRenameTeamModal(false)}
        >
          <Modal.Header className="edit-team-modal">
            <Modal.Title className="edit-team-modal team-modal-header">
              Rename Team
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="edit-team-modal">
            <p style={{ color: 'white' }}>
              Are you sure you want to rename your team?
            </p>
            <input
              type="text"
              className="form-control theme-input"
              id="teamName"
              placeholder="Enter New Team Name"
              onChange={(e) => setTeamName(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer className="edit-team-modal">
            <Button
              variant="secondary"
              onClick={() => setShowRenameTeamModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="fantasy-button"
              onClick={() => {
                onRenameTeam(teamName);
                setShowRenameTeamModal(false);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeleteTeamModal}
          onHide={() => setShowDeleteTeamModal(false)}
        >
          <Modal.Header className="edit-team-modal">
            <Modal.Title className="edit-team-modal team-modal-header">
              Delete Team
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="edit-team-modal">
            <p style={{ color: 'white' }}>
              Are you sure you want to delete your team?
            </p>
          </Modal.Body>
          <Modal.Footer className="edit-team-modal">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteTeamModal(false)}
            >
              Cancel
            </Button>
            <Button className="fantasy-button" onClick={onPressDeleteTeam}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default EditTeam;
