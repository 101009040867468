import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import EulaText from './EulaText';

const StaticEula: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div className="PageTitle">
              <h2>End User License Agreement</h2>
            </div>
          </Col>
        </Row>
        {/*<iframe*/}
        {/*    src={`https://s3.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/Athstat+Quickstart+Guide.pdf`}*/}
        {/*    width="100%"*/}
        {/*    height="750vh"*/}
        {/*/>*/}
        <EulaText />
      </section>
    </React.Fragment>
  );
};
export default StaticEula;
