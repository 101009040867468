import React, { ReactElement } from 'react';
import Header from '../Header/Header';

import styles from '../../styles/Home.module.css';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router';

const PublicLayout = (): ReactElement => {
  return (
    <div className={styles.container}>
      <head>
        <title>{'Athstat'}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="application-name" content="Athstat Web App" />
        <meta
          name="description"
          content="Athstat Sports Analytics Application"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
      </head>
      <div id="MainApp" className={'MainApp'}>
        <Header competitionId={''} club_id={''} />

        {/*<Login />*/}

        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default PublicLayout;
