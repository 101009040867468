import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import {
  IFantasyAthlete,
  IFantasyLeagueTeam,
  IFantasyTeam,
  IUpdateUser,
} from '../../../d';

import {
  createUserLeague,
  getFantasyLeaguesByTeamId,
  getFantasyTeamAthletes,
  getFantasyTeamByUserId,
  getTransfersStatus,
} from './games.services';

import PlayerCard from '../Components/PlayerCard';
import GamesOnboard from './GamesOnboarding';

import axios from 'axios';

type status = 'success' | 'error' | 'none';

export const GamesDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [, setLoading] = useState<boolean>();
  const { user, isAuthenticated } = useAuth();
  const navigator = useNavigate();
  const token = user?.access_token;
  const [leagues, setLeagues] = React.useState<IFantasyLeagueTeam[]>([]);
  const [players, setPlayers] = React.useState<IFantasyAthlete[]>([]);
  const [team, setTeam] = React.useState<IFantasyTeam | null>(null);
  const [CLStatus, setCLStatus] = useState<status>('none');
  const [showCreateLeagueModal, setShowCreateLeagueModal] =
    useState<boolean>(false);
  const [value, setValue] = useState('Public');
  const [title, settitle] = useState('');
  const [completedOnboarding, setCompletedOnboarding] = useState(false);
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const [showModal, setShowModal] = useState(false);
  const [newLeagueCode, setNewLeagueCode] = useState<string>('');
  const [transferStatus, setTransferStatus] = useState<boolean>(false);

  const fetchLeaderboards = async (id: number) => {
    try {
      if (token) {
        const response = await getFantasyLeaguesByTeamId({
          id: id ? id.toString() : ' -1',
          token: user.access_token,
        });
        setLeagues(response);
      }
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    const fetchTransferStatus = async () => {
      try {
        if (isAuthenticated && token) {
          const response = await getTransfersStatus({ token });
          setTransferStatus(response);
        } else {
          setTransferStatus(false);
        }
      } catch (error) {
        //console.error('Error fetching transfer status:', error);
        setTransferStatus(false);
      }
    };

    fetchTransferStatus();
  }, [token]);

  useEffect(() => {
    const fetchTeamPlayers = async (id: number) => {
      try {
        getFantasyTeamAthletes({
          id: id ? id : -1,
          token: user?.access_token ? user?.access_token : '',
        })
          .then((res) => {
            setPlayers(res);
          })
          .catch(() => {
            setLoading(false);
            alert('Something went wrong');
          });
      } catch (error) {
        return [];
      }
    };

    const fetchFantasyTeam = async () => {
      try {
        setLoading(true);
        await getFantasyTeamByUserId({
          id: kcUser?.sub ? kcUser.sub : '',
          token: token ? token : '',
        })
          .then((res) => {
            setTeam(res);
            fetchTeamPlayers(res?.id ?? -1);
            fetchLeaderboards(res?.id ?? -1);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFantasyTeam();
  }, [token]);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      setLoading(true);
      try {
        if (userId) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${userId}`,
            {
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            }
          );
          const onboardingStatus = response.data.gamesOnboardingComplete;
          setCompletedOnboarding(onboardingStatus);
          setLoading(false);
        }
      } catch (error) {
        // console.error('Error fetching onboarding status:', error);
      }
    };

    fetchOnboardingStatus();
  }, [userId, token]);

  const createLeague = async () => {
    if (title === '') {
      alert('Please enter a league title');
      return;
    }

    if (title.length < 5 || title.length > 20) {
      alert('League title must be between 5 and 20 characters');
      return;
    }
    if (!team) {
      alert('Please create a team before creating a league');
      return;
    }

    try {
      setLoading(true);
      if (team) {
        const response = await createUserLeague({
          team: team,
          league: {
            title: title,
            league_type: 'User',
            entry_fee_tickets: 0,
            is_private: value === 'Private',
            prize_pool_amount: 0,
          },
          token: token ? token : '',
        });
        if (response != null) {
          setNewLeagueCode(response);
          setCLStatus('success');
          settitle('');
          fetchLeaderboards(team.id ?? -1);
        } else {
          // alert('Something went wrong' + response);
          setCLStatus('error');
          settitle('');
        }
      }
    } catch (error) {
      alert(` ${error} Something went wrong`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
  };

  const onPlayerSelect = (selectedPlayer: IFantasyAthlete) => {
    navigator(
      `/auth/games-dash/fantasy/profile?playerId=${selectedPlayer.tracking_id}`
    );
  };

  const onLeaderboardRowSelect = (leagueId: number | undefined) => {
    if (!leagueId) {
      return;
    }
    navigator(`/auth/games-dash/fantasy/leaderboard?leagueId=${leagueId}`);
  };

  const updateOnboard = async (accepted: boolean) => {
    const update: IUpdateUser = {
      kc_id: userId,
      status: 'new',
      gamesOnboardingComplete: accepted,
    };
    await axios.put(`${process.env.REACT_APP_API_URL}/users/`, update, {
      headers: {
        Authorization: user ? `Bearer ${token}` : undefined,
      },
    });
    setCompletedOnboarding(accepted);
  };

  const handleOnboardCallBack = (accepted: boolean) => {
    setCompletedOnboarding(accepted);
    updateOnboard(accepted);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setNewLeagueCode('');
  };

  return (
    <React.Fragment>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          marginBottom: '100px',
        }}
        className="fantasy-container-styling"
      >
        {!completedOnboarding ? (
          <GamesOnboard
            onboardingCallback={handleOnboardCallBack}
            setOnboardingStatus={setCompletedOnboarding}
          />
        ) : (
          <div>
            <div
              className="back-breadcrumbs2"
              style={{
                marginTop: '100px',
                marginLeft: '125px',
                marginBottom: '-90px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => navigator('/auth/games-dash')}
                style={{ background: 'none', border: 'none' }}
              >
                <img
                  className="nft-vector"
                  src="/icons/back-nft-2.svg"
                  alt={'Portfolio'}
                />
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '100px',
                marginLeft: '100px',
              }}
            >
              <section style={{ width: '70vw' }}>
                <div className=" card  mb-2 mx-2 my-2 p-1 rounded games-card2 fantasy-landing">
                  <div className="card-body outerContainer">
                    <h3 className="games-title">
                      {team?.team_name || 'Fantasy Team'}
                    </h3>

                    <div className="score-container d-flex">
                      <div className="card p-1 rounded flex-grow-1 balance-value-box">
                        <div className="card-body info-container">
                          <h3 className="balance-value-text">Team Value</h3>
                        </div>
                        <h4 className="font-weight-bold mb-0 text-secondary info-container">
                          ${(team?.team_value || 0).toFixed(2)}
                        </h4>
                      </div>
                      <div className="card p-1 rounded flex-grow-1 balance-value-box">
                        <div className="card-body info-container">
                          <h3 className="balance-value-text">Balance</h3>
                        </div>
                        <h4 className="font-weight-bold mb-0 text-secondary info-container">
                          ${(team?.balance || 0).toFixed(2)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" card  mb-2 mx-2 my-2 p-1 rounded games-card2 fantasy-landing">
                  <div
                    className="outerContainer"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    {players.map((player, index) => (
                      <PlayerCard
                        player={player}
                        key={index}
                        onPress={() => onPlayerSelect(player)}
                        showPrice={false}
                        highlight={false}
                      />
                    ))}
                  </div>
                </div>
                <div className=" card  mb-2 mx-2 my-2 p-1 rounded games-card2">
                  <h2
                    className="font-weight-bold mb-0 my-1 info-container fantasy-text"
                    onClick={toggleModal}
                  >
                    Menu{' '}
                    <i
                      className="fa fa-info-circle menu-circle"
                      style={{
                        cursor: 'pointer',

                        fontSize: '20px',
                        marginBottom: '5px',
                        color: '#049d2d',
                      }}
                      onClick={toggleModal}
                    ></i>
                  </h2>
                  <Modal show={showModal} onHide={toggleModal}>
                    <Modal.Header
                      closeButton
                      style={{
                        backgroundColor: '#049d2d',
                        borderBottom: '1px solid #17181c',
                      }}
                    >
                      <Modal.Title
                        style={{ fontFamily: 'DM Sans', color: '#17181c' }}
                      >
                        When do teams close?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#049d2d' }}>
                      <p style={{ fontFamily: 'DM Sans', color: 'white' }}>
                        The picking of teams and team transfers close 90 minutes
                        before the first game of the day begins. They will open
                        back up 3 hours after kick-off of the last game of the
                        day.{' '}
                      </p>
                    </Modal.Body>
                    <Modal.Footer
                      style={{ backgroundColor: '#049d2d', borderTop: 'none' }}
                    >
                      <Button
                        variant="secondary"
                        onClick={toggleModal}
                        style={{ backgroundColor: '#17181c' }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <div className="card-body outerContainer2">
                    <div className="fantasy-button-group">
                      <Button
                        className="fantasy-button"
                        onClick={() => navigate('pickteam')}
                        disabled={!transferStatus}
                      >
                        Pick Team
                      </Button>
                      <Button
                        className="fantasy-button"
                        onClick={() => navigate('history')}
                      >
                        Team History
                      </Button>

                      <Button
                        className="fantasy-button"
                        onClick={() => navigate('editTeam')}
                      >
                        Edit Team
                      </Button>
                      <Button
                        className="fantasy-button"
                        onClick={() => navigate('search')}
                      >
                        Search Players
                      </Button>
                      <Button
                        className="fantasy-button"
                        onClick={() => navigate('matches')}
                      >
                        Matches
                      </Button>
                    </div>
                  </div>
                </div>

                <div className=" card  mb-2 mx-2 my-2 p-1 rounded d-flex games-card2">
                  <h2 className="font-weight-bold mb-0 my-1 info-container fantasy-text">
                    Leagues
                  </h2>
                  <div className="card-body outerContainer2">
                    <div className="fantasy-button-group">
                      <Button
                        type="button"
                        className="fantasy-button"
                        data-toggle="modal"
                        data-target="#createLeague"
                        variant="primary"
                        onClick={() => setShowCreateLeagueModal(true)}
                      >
                        Create League
                      </Button>
                      <Link to={'joinfantasyleague'}>
                        <Button className="fantasy-button">Join League</Button>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="leagues-container2"
                    style={{
                      maxHeight: '150px',
                      overflowY: 'scroll',
                    }}
                  >
                    <h1 className="font-weight-bold mb-0 my-1 info-container fantasy-text">
                      My Leagues
                    </h1>
                    {leagues.length === 0 ? (
                      <p
                        className="font-weight-bold mb-0 my-1 info-container fantasy-text-4"
                        style={{ padding: '20px' }}
                      >
                        You&#39;re nearly there! Create or join a league to show
                        your skills!
                      </p>
                    ) : (
                      leagues.map((league) => (
                        <div
                          key={league.id}
                          className="league-card2"
                          style={{
                            color: 'white',
                            fontFamily: 'DM Sans',
                            textAlign: 'center',
                            borderTop: '1px solid lightgrey',
                            marginTop: '10px',
                          }}
                          onClick={() =>
                            onLeaderboardRowSelect(league.league_id)
                          }
                        >
                          <Col>
                            <h4>{league.title}</h4>
                          </Col>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <Modal
                  show={showCreateLeagueModal}
                  onHide={() => {
                    setCLStatus('none');
                    setShowCreateLeagueModal(false);
                  }}
                  onDismiss={() => {
                    setCLStatus('none');
                    setShowCreateLeagueModal(false);
                  }}
                  centered
                >
                  <Modal.Header closeButton className="cl-modal-header">
                    <Modal.Title>Create League</Modal.Title>
                  </Modal.Header>
                  {CLStatus === 'none' ? (
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="title">League Title</label>
                        <input
                          type="text"
                          className="form-control text-input create-input"
                          id="title"
                          style={{ color: 'black', backgroundColor: 'white' }}
                          placeholder="Enter league title"
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="leagueType">League Type</label>
                        <select
                          className="form-control"
                          id="leagueType"
                          onChange={handleChange}
                        >
                          <option>Public</option>
                          <option>Private</option>
                        </select>
                      </div>
                    </Modal.Body>
                  ) : CLStatus === 'success' ? (
                    <Modal.Body>
                      <div className="form-group">
                        <h5 style={{ color: 'white' }}>
                          League created successfully
                        </h5>
                        <Row
                          style={{
                            backgroundColor: '#17181c',
                            borderRadius: 8,
                            padding: 16,
                          }}
                        >
                          <span style={{ color: 'white' }}>
                            {newLeagueCode}
                          </span>
                        </Row>
                      </div>
                    </Modal.Body>
                  ) : (
                    <Modal.Body>
                      <div className="form-group">
                        <h3>Something went wrong</h3>
                      </div>
                    </Modal.Body>
                  )}
                  <Modal.Footer className="cl-modal-header">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCLStatus('none');
                        setShowCreateLeagueModal(false);
                      }}
                      className="modal-close-button"
                    >
                      Close
                    </Button>
                    {CLStatus === 'none' && (
                      <Button
                        variant="primary"
                        onClick={createLeague}
                        className="fantasy-button"
                      >
                        Create
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
              </section>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default GamesDashboard;
