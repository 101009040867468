// LeagueDisplay.tsx
import React, { useEffect, useState } from 'react';
import SportsSelection from 'components/SportsSelection/SportsSelection';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export interface League {
  id?: number;
  type: string;
  official_league_id: string;
  title: string;
  created_date?: string;
  entry_code?: string;
  entry_fee?: number;
  is_private: boolean;
  reward_type: string;
  reward_description: string;
  end_round: number;
  is_open: boolean;
  join_deadline?: string | null;
  disclaimer: string;
  has_ended: boolean;
}

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}

interface SelectedSport {
  id: string;
  name: string;
  games_supported: boolean;
}

const LeagueDisplay: React.FC = () => {
  const [leagues, setLeagues] = useState<League[]>([]);
  const [athleagues, setAthLeagues] = useState<League[]>([]);
  const [userleagues, setUserLeagues] = useState<League[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setShowUpcoming] = useState(true);
  const [currentPage] = useState(1);
  const [activeItem, setActiveItem] = useState<number>(1);
  const [sortCriteria, setSortCriteria] = useState<string | null>(null);
  const matchesPerPage = 5;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, setIsSearchUsed] = useState(false);
  const [searchValue] = useState<string>('');
  const [selectedTabs, setSelectedTabs] = useState('a');
  // const [selectedLeagueId, setSelectedLeagueId] = useState<number | null>(null);
  const [, setSelectedTab] = useState<'Athstat' | 'User' | 'My'>('Athstat');
  const [, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { user } = useAuth();
  const token = user?.access_token;
  const [selectedSeason, setSelectedSeason] =
    useState<SelectedCompetition | null>(null);

  const [selectedSport, setSelectedSport] = useState<SelectedSport | null>(
    null
  );

  // ...

  useEffect(() => {
    const fetchLeagues = async () => {
      setIsLoading(true);
      if (selectedSeason && selectedTabs === 'a') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DATAHUB_URL}/fantasy-leagues/fantasy-athstat-leagues/${selectedSeason?.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAthLeagues(response.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      } else if (selectedSeason && selectedTabs === 'u') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DATAHUB_URL}/fantasy-leagues/fantasy-user-leagues/${selectedSeason?.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setUserLeagues(response.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      } else if (selectedSeason && selectedTabs === 'My') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DATAHUB_URL}/fantasy-leagues/fantasy-user-leagues/${user?.profile.sub}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log(response);
          // const filteredLeagues = response.data.filter(
          //   (league: League) => league.type === selectedTabs
          // );
          setLeagues(response.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchLeagues();
  }, [selectedSeason, selectedTabs]);

  // Function to sort leagues by different criteria
  const sortLeagues = (criteria: string) => {
    const sortedLeagues = [...leagues];

    if (criteria === 'alphabetical') {
      sortedLeagues.sort((a, b) => a.title.localeCompare(b.title));
    }
    // else if (criteria === 'lowToHighAvgNumbers') {
    //   sortedLeagues.sort((a, b) =>
    //     a.player_limit && b.player_limit ? a.player_limit - b.player_limit : 0
    //   );
    // } else if (criteria === 'duration') {
    //   sortedLeagues.sort((a, b) => a.duration.localeCompare(b.duration));
    // } else if (criteria === 'entryFeeTickets') {
    //   sortedLeagues.sort((a, b) =>
    //     a.entry_fee_tickets && b.entry_fee_tickets
    //       ? a.entry_fee_tickets - b.entry_fee_tickets
    //       : 0
    //   );
    // } else if (criteria === 'prizePoolAmount') {
    //   sortedLeagues.sort((a, b) =>
    //     a.prize_pool_amount && b.prize_pool_amount
    //       ? a.prize_pool_amount - b.prize_pool_amount
    //       : 0
    //   );
    // }

    setSortCriteria(criteria);
    setLeagues(sortedLeagues);
  };

  // Function to switch to upcoming matches
  const switchToUpcoming = () => {
    setSelectedTabs('a');
    sortLeagues(sortCriteria || 'alphabetical');
    setShowUpcoming(true);
    setActiveItem(1);
  };

  const switchToPrevious = () => {
    setSelectedTabs('u');
    sortLeagues(sortCriteria || 'alphabetical');
    setShowUpcoming(false);
    setActiveItem(2);
  };

  const switchToMyLeagues = () => {
    setSelectedTab('My');
    sortLeagues(sortCriteria || 'alphabetical');
    setShowUpcoming(false);
    setActiveItem(3);
  };
  const handleSearchBar = async (search: string | null) => {
    setIsSearchUsed(true);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATAHUB_URL}/api/v1/fantasy-leagues`
      );
      let filteredLeagues;
      if (search) {
        filteredLeagues = response.data.filter(
          (league: League) =>
            league.title.toLowerCase().includes(search.toLowerCase()) &&
            league.type === selectedTabs
        );
      } else {
        filteredLeagues = response.data.filter(
          (league: League) => league.type === selectedTabs
        );
      }
      setLeagues(filteredLeagues);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Paginate matches based on the current page
  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentLeagues = leagues.slice(indexOfFirstMatch, indexOfLastMatch);

  // Logic to render page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(leagues.length / matchesPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleRowClick = (leagueId: string) => {
    // navigate(`/auth/games-dash/fantasy/leagueinfo${leagueId}`);

    navigate({
      pathname: `/auth/games-dash/fantasy/leagueinfo`,
      search: `?id=${leagueId}`,
    });
  };

  const handleSeasonSelect = (season: SelectedCompetition): void => {
    setSelectedSeason(season);
  };
  const handleSportSelect = (sport: SelectedSport): void => {
    setSelectedSport(sport);
  };

  return (
    <section className="games-home-page">
      <div>
        <SportsSelection
          onCompetitionSelect={handleSeasonSelect}
          selectedCompetition={selectedSeason}
          onSportSelect={handleSportSelect}
          selectedSport={selectedSport}
        />
      </div>
      <div className="matches-container">
        <h1 className="match-container-title" style={{ marginTop: '32px' }}>
          Leagues
        </h1>
        <div>
          <button
            className={`match-tabs ${activeItem === 1 ? 'selected-tab' : ''}`}
            onClick={switchToUpcoming}
          >
            Athstat Leagues
          </button>
          <button
            className={`match-tabs ${activeItem === 2 ? 'selected-tab' : ''}`}
            onClick={switchToPrevious}
          >
            User Leagues
          </button>
          <button
            className={`match-tabs ${activeItem === 3 ? 'selected-tab' : ''}`}
            onClick={switchToMyLeagues}
          >
            My Leagues
          </button>
        </div>
        <div className="tab-filtering">
          <div className="player-search-sort">
            <Autocomplete
              style={{
                width: '350px',
                height: '15px',
                color: '#fff',
                background: '#212121',
                marginBottom: '40px',
                marginTop: '20px',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              freeSolo
              options={leagues.map((league) => league.title || '')}
              value={searchValue}
              onChange={(_event, selectedValue) =>
                handleSearchBar(selectedValue)
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <div
                  ref={params.InputProps.ref}
                  className="autocomplete-container"
                >
                  <input
                    {...params.inputProps}
                    placeholder={'Search Leagues'}
                    type="text"
                    className=" player-input search-text player-auto"
                    id="free-solo-demo"
                  />
                </div>
              )}
            />
            <div className="dropdown">
              <button
                className="player-dropdown"
                type="button"
                id="sortDropdown"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <h1 className="sort-text">Sort By</h1>
                <img src={'/icons/games-down-arrow.svg'} alt="Sort By" />
              </button>
              {isDropdownOpen && (
                <div
                  className="dropdown-menu show"
                  aria-labelledby="sortDropdown"
                >
                  <div
                    className="dropdown-item"
                    onClick={() => sortLeagues('alphabetical')}
                  >
                    Alphabetical
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortLeagues('lowToHighAvgNumbers')}
                  >
                    Avg Numbers (Low to High)
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortLeagues('duration')}
                  >
                    Duration
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortLeagues('entryFeeTickets')}
                  >
                    Entry Fee Tickets
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortLeagues('prizePoolAmount')}
                  >
                    Prize Pool Amount
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Display the current matches on the selected page */}
        {activeItem === 1 && (
          <div className="league-table-div">
            <table className="league-table" style={{ width: '1280px' }}>
              <thead>
                <tr className="league-table-head">
                  <th className="league-table-head-name">League Name</th>
                  <th className="league-table-head-name">Prize Pool</th>
                  <th className="league-table-head-name">Entry Fee</th>
                </tr>
              </thead>
              <tbody>
                {athleagues.map((league) => (
                  <tr
                    className="league-row"
                    key={league.id}
                    onClick={() => {
                      if (league.id !== undefined) {
                        handleRowClick(league.id.toString());
                      }
                    }}
                  >
                    <td className="league-name-row">{league.title}</td>
                    <td
                      className="league-numbers"
                      // style={{ marginLeft: '-10px' }}
                    >
                      {league.reward_description}
                    </td>
                    <td
                      className="league-total-value"
                      // style={{ marginLeft: '-100px' }}
                    >
                      <img
                        src="/icons/athcoin.png"
                        style={{ height: '18px' }}
                      />
                      <h1 className="leaguetotalValue">${league.entry_fee}</h1>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeItem === 2 && (
          <div className="league-table-div">
            <table className="league-table" style={{ width: '1280px' }}>
              <thead>
                <tr className="league-table-head">
                  <th className="league-table-head-name">League Name</th>
                  <th className="league-table-head-name">Prize Pool</th>
                  <th className="league-table-head-name">Entry Fee</th>
                </tr>
              </thead>
              <tbody>
                {userleagues.map((league) => (
                  <tr
                    className="league-row"
                    key={league.id}
                    onClick={() => {
                      if (league.id !== undefined) {
                        handleRowClick(league.id.toString());
                      }
                    }}
                  >
                    <td className="league-name-row">{league.title}</td>
                    <td
                      className="league-numbers"
                      // style={{ marginLeft: '-10px' }}
                    >
                      {league.reward_description}
                    </td>
                    <td
                      className="league-total-value"
                      // style={{ marginLeft: '-100px' }}
                    >
                      <img
                        src="/icons/athcoin.png"
                        style={{ height: '18px' }}
                      />
                      <h1 className="leaguetotalValue">${league.entry_fee}</h1>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeItem === 3 && (
          <div className="league-table-div">
            <table className="league-table" style={{ width: '1280px' }}>
              <thead>
                <tr className="league-table-head">
                  <th className="league-table-head-name">League Name</th>
                  <th className="league-table-head-name">Prize Pool</th>
                  <th className="league-table-head-name">Entry Fee</th>
                </tr>
              </thead>
              <tbody>
                {currentLeagues.map((league) => (
                  <tr
                    className="league-row"
                    key={league.id}
                    onClick={() => {
                      if (league.id !== undefined) {
                        handleRowClick(league.id.toString());
                      }
                    }}
                  >
                    <td className="league-name-row">{league.title}</td>
                    <td
                      className="league-numbers"
                      // style={{ marginLeft: '-10px' }}
                    >
                      {league.reward_description}
                    </td>
                    <td
                      className="league-total-value"
                      // style={{ marginLeft: '-100px' }}
                    >
                      <img
                        src="/icons/athcoin.png"
                        style={{ height: '18px' }}
                      />
                      <h1 className="leaguetotalValue">${league.entry_fee}</h1>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};
export default LeagueDisplay;
