import React from 'react';
// import { Row, Col, Tabs, Tab } from 'react-bootstrap';
// import { Button } from '@mui/material';
// import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useAuth } from 'react-oidc-context';
// import axios from 'axios';
// import { IAthletePriceChange, IFantasyAthlete } from '../../../../d';

export const ViewLeague: React.FC = () => {
  // const [league, setLeague] = useState<IFantasyAthlete>();

  // const { user, isAuthenticated } = useAuth();
  // const token = user?.access_token;
  // const navigator = useNavigate();
  // const [searchParams] = useSearchParams();
  // const leagueId = searchParams.get('leagueId');
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <React.Fragment>
      <section>
        <h1>Coming soon</h1>
      </section>
    </React.Fragment>
  );
};
export default ViewLeague;
