import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@mui/material';
import PaymentTable from './PaymentTable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import AccessDenied from '../AccessDenied';

const PaymentDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [access, setAccess] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, []);

  if (!access) {
    return <AccessDenied message={'Admin Access Denied'} />;
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            {' '}
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>Payments/Billing</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <PaymentTable />
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
};

export default PaymentDashboard;
