import React, { useContext, useEffect, useState } from 'react';

// import { useNavigate } from 'react-router-dom';
import { FantasyGamesContext } from 'context/FantasyGamesContext';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import SportsSelection from 'components/SportsSelection/SportsSelection';
import BannerCarousel from 'components/BannerCarousel/BannerCarousel';
import PlayerLeaderboard from 'components/PlayerLeaderboard/PlayerLeaderboard';
import MatchList from 'components/Matches/UpcomingMatches';
import PlayerDisplay from 'components/Players/PlayerDisplay';

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}

interface SelectedSport {
  id: string;
  name: string;
  games_supported: boolean;
}

export const GamesDash: React.FC = () => {
  // const navigator = useNavigate();
  const { fetchFantasyTeam, loading } = useContext(FantasyGamesContext);
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [, setAccess] = useState<boolean>(false);
  const [selectedCompetition, setSelectedCompetition] =
    useState<SelectedCompetition | null>(null);
  const [selectedSport, setSelectedSport] = useState<SelectedSport | null>(
    null
  );

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );

        if (fetchedAccess.data) {
          // eslint-disable-next-line no-console
          console.log(`Success: User ${userId} is an admin.`);
          setAccess(fetchedAccess.data);
        }
      };
      checkAdmin();
    }
  }, []);

  useEffect(() => {
    if (kcUser && fetchFantasyTeam && token) {
      fetchFantasyTeam(kcUser.sub, token);
    }
  }, []);

  const handleCompetitionSelect = (competition: SelectedCompetition): void => {
    setSelectedCompetition(competition);
  };

  const handleSportSelect = (sport: SelectedSport): void => {
    setSelectedSport(sport);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <section className="games-home-page">
        <div>
          <SportsSelection
            onCompetitionSelect={handleCompetitionSelect}
            selectedCompetition={selectedCompetition}
            onSportSelect={handleSportSelect}
            selectedSport={selectedSport}
          />
        </div>
        <div>
          <div className="carousel-leaderboard">
            <BannerCarousel />
            <PlayerLeaderboard />
          </div>
        </div>
        <PlayerDisplay selectedCompetition={selectedCompetition} />
        <MatchList selectedCompetition={selectedCompetition} />
      </section>
    </React.Fragment>
  );
};
export default GamesDash;
