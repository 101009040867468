// MatchDetailsModal.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface MatchDetails {
  homeTeamImage: string;
  homeTeamName: string;
  homeTeamScore: number;
  awayTeamScore: number;
  awayTeamImage: string;
  awayTeamName: string;
  location: string;
  targetTime: string;
}

interface MatchDetailsModalProps {
  matchDetails: MatchDetails;
  onClose: () => void;
}

const MatchDetailsModal: React.FC<MatchDetailsModalProps> = ({
  matchDetails,
  onClose,
}) => {
  const navigate = useNavigate();
  const calculateTimeLeft = () => {
    const now = new Date();
    const target = new Date(matchDetails.targetTime);

    if (now > target) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        hasExpired: true,
      };
    }

    const difference = target.getTime() - now.getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);

    return {
      days,
      hours,
      minutes,
      hasExpired: false,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [matchDetails.targetTime]);

  //   const formatTime = (value: number, unit: string) => {
  //     return `${value} ${unit}${value !== 1 ? 's' : ''}`;
  //   };
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  const kickoffDate = new Date(matchDetails.targetTime);
  const dateOptions = {
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const,
  };
  const formattedDate = kickoffDate.toLocaleDateString(undefined, dateOptions);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewTeamClick = () => {
    navigate('auth/games-dash/fantasy/myteams');
  };
  return (
    <div className="match-modal">
      <div className="modal-name-close">
        <h1 className="modal-name">Match Information</h1>
        <h1
          onClick={(event) => handleClose(event)}
          style={{ color: 'white', fontSize: '20px', cursor: 'pointer' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#E6E6E6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </h1>
      </div>
      <div className="game-details">
        <div className="location-timing">
          <span className="modal-location">{matchDetails.location}</span>
          {timeLeft.hasExpired ? (
            <>
              <div className="modal-expired-display">
                <img src="/icons/match.date.png" className="match-calendar" />
                <p className="expired-date">{formattedDate}</p>
              </div>
            </>
          ) : (
            <p>{formattedDate}</p>
          )}
        </div>
        <div className="modal-team-info">
          <div className="modal-team-left">
            <span className="modal-team-name">{matchDetails.homeTeamName}</span>
          </div>
          <h1 className="modal-score">{matchDetails.homeTeamScore}</h1>
          <span className="modal-vs">VS</span>
          <h1 className="modal-score">{matchDetails.awayTeamScore}</h1>

          <div className="modal-team-right">
            <span className="modal-team-name">{matchDetails.awayTeamName}</span>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            return null;
          }}
          className="modal-view-team"
        >
          View My Team
        </button>
      </div>
    </div>
  );
};
export default MatchDetailsModal;
