/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const EulaText = (): JSX.Element => {
  return (
    <div className="EULABody">
      <h2>Effective: 6/24/2021</h2>
      <ol id="List Home" type="1">
        <li style={{ fontSize: '20px' }}>
          Scope and Operation of this Agreement
        </li>
        <ul id="1">
          <li>
            These Terms and Conditions shall apply to Customer’s use of the
            Athstat application’s services, packaged professional services, all
            linked pages, content, products or offline components (“Service” or
            “Services”). These Terms and Conditions (the “Agreement”) represent
            the parties’ entire understanding regarding the Services. Customers
            not willing to be bound by these Terms and Conditions should not
            purchase a Subscription or use the Services. Athstat is a wholly
            owned entity of NU Borders LLC, who own the legal rights to all
            aspects of the Athstat Product and are herein referred to as the
            legal entity owning the product.
          </li>
        </ul>
        <br />
        <li>Purchases and Subscriptions</li>
        <ul id="2">
          <li>
            Customer may purchase subscription access (a “Subscription”) to the
            Services for a fee (the “Fee”) for the period of time (the “Access
            Period”) elected from the available options (e.g., monthly or
            annually etc.). The Fee is exclusive of any applicable federal,
            state, telecommunications, excise or other taxes or duties,
            including VAT and GST (collectively “Applicable Taxes”) unless
            otherwise specified.
          </li>

          <li>
            Subscriptions will automatically renew until cancelled. NU Borders
            will automatically renew or commence the Subscription for a new
            Access Period by charging the associated Fee plus any Applicable
            Taxes to the payment method provided unless the Subscription is
            cancelled as provided.
          </li>

          <li>
            If Customer opts-out of automatic renewals, the Subscription shall
            expire and access to the Services will cease at the end of the
            specified Access Period. In order to continue to access the Services
            after the Access Period has expired or terminated, Customer will
            need to purchase a new Subscription or submit a data access request
            as described in 7.3.
          </li>

          <li>
            Customer may cancel the Subscription at any time by contacting
            info@athstat.com or by cancelling directly from the application in
            the User Setting page. Cancellation will cease auto-renewal of
            Subscription, but the Fee is nonrefundable except where required by
            law.
          </li>

          <li>
            NU Borders shall transfer Customer billing information to payment
            processor(s) as described in the Privacy Policy. Customer is
            required to maintain accurate and up-to-date payment information. NU
            Borders assumes no responsibility or liability if the Subscription
            fails to renew or otherwise expires because of outdated or incorrect
            payment information.
          </li>
        </ul>
        <br />
        <li>Right to Use the Services</li>
        <ul id="3">
          <li>
            3.1. To be eligible to access and use the Services, Customer must be
            at least 18 years of age, or the age of legal majority in the
            Customer’s jurisdiction, and capable of entering into a binding
            agreement; User must be affiliated with the team for which they are
            requesting am account. NU Borders will determine user affiliation
            using multiple methods depending on team size and evidence provided.
          </li>

          <li>
            {`3.2. During the Access Period. NU Borders grants to Customer a
            nontransferable, nonexclusive, nonsublicenseable worldwide right to
            permit those individuals authorized by Customer or on Customer's
            behalf, and who are Customer's employees, agents or contractors
            ("Users"), to access and use the Services subject to the terms of
            the Agreement.`}
          </li>

          <li>
            3.3. The Services are provided by NU Borders from a data center
            facility to which Users have remote access via the Internet.
            Specific usage rights (“Usage Rights”) are described below, and
            Customer shall at all times ensure that its use does not exceed or
            otherwise violate its Usage Rights.
          </li>

          <li>
            3.4. Customer shall be solely responsible for obtaining and
            maintaining appropriate equipment and devices needed to connect to,
            access or otherwise use the Services, including, without limitation,
            computers, computer operating system and web browser (collectively,
            "Equipment"). Customer shall ensure that Equipment complies with any
            configurations and specifications set forth herein.
          </li>

          <li>3.5. Usage and System Requirements</li>
          <ul id="3.5">
            <li>
              3.5.1. Customer will be appraised of system requirements to run
              the application upon contacting the Athstat team working under NU
              Borders.
            </li>
          </ul>
        </ul>
        <br />
        <li>Usage Restrictions and Representations</li>
        <ul id="4">
          <li>4.1. Customer shall not, directly or indirectly: </li>
          <ul id="4.1">
            <li>
              4.1.1. reverse engineer, decompile, disassemble or otherwise
              attempt to discover the source code, object code or underlying
              structure, ideas or algorithms of the Services or any software,
              documentation or data related to or provided with the Services;{' '}
            </li>
            <li>
              4.1.2. modify, translate, or create derivative works based on the
              Services or Software; or copy (except for archival purposes),
              rent, lease, distribute, pledge, assign, or otherwise transfer or
              encumber rights to the Services or Software;{' '}
            </li>
            <li>
              4.1.3. use or access the Services to build or support, and/or
              assist a third party in building or supporting, products or
              services competitive to NU Borders; or{' '}
            </li>
            <li>
              4.1.4. remove any proprietary notices or labels from the Services
              or Software. Customer shall use the Services and software only for
              its own internal business operations, and not for the operation of
              a service bureau or timesharing service.
            </li>
          </ul>

          <li>
            4.2. Customer shall not knowingly or willfully use the Services in
            any manner that could damage, disable, overburden, impair or
            otherwise interfere with NU Borders's provision of the Services.
            Customer shall be responsible for maintaining the security of the
            Equipment and Customer's account access passwords. Customer and NU
            Borders agree to make every reasonable effort to prevent
            unauthorized third parties from accessing the Services. Customer
            shall be liable for all acts and omissions of its Users.
          </li>

          <li>
            4.3. Customer represents and warrants that Customer will use the
            Services only in compliance with the requirements set forth herein
            (as may be amended from time to time upon written notice to
            Customer), and all applicable and laws and regulations, including
            those related to spamming, privacy, data protection, intellectual
            property, consumer and child protection, pornography, obscenity or
            defamation.
          </li>

          <li>
            4.4. NU Borders may immediately suspend Customer's password,
            account, and access to the Services if{' '}
          </li>
          <ul id="4.4">
            <li>
              4.4.1. Customer fails to make payment due within ten business days
              after NU Borders has provided Customer with notice of such
              failure; or{' '}
            </li>
            <li>
              4.4.2. Customer violates Section 3, 4, or 9 of these Terms and
              Conditions. Any suspension by NU Borders of the Services under the
              preceding sentence shall not relieve Customer of its payment
              obligations under the Agreement.
            </li>
          </ul>

          <li>4.5. Troubleshooting</li>
          <ul id="4.5">
            <li>
              4.5.1. Customer must first work through the applicable Support
              Troubleshooting Guides provided via the Services prior to
              submitting any Support Request to NU Borders. If Customer is
              unable to resolve any actual or suspected issue having worked
              through the Support Troubleshooting Guide, it must submit a
              reasonably detailed Support Request to NU Borders via the “Submit
              a Request” feature.
            </li>
            <li>
              4.5.2. Customer must provide, at NU Border’s request, suitably
              qualified and informed Personnel to provide assistance and
              information to NU Borders, including with respect to knowledge or
              control of matters that may assist NU Borders in performing its
              obligations under this Agreement.
            </li>
            <li>
              4.5.3. NU Borders will determine the nature, potential severity
              and impact of the issue giving rise to a Support Request. NU
              Borders will during the Hours of Service investigate the problem
              and respond to Customer, where reasonably practicable, within one
              Business Day from the time of the first consultation with Customer
              and will, as and when necessary, provide Customer with the
              following information by email response:{' '}
            </li>
            <ul id="4.5.3">
              <li>4.5.3.1. an outline of the problem;</li>
              <li>4.5.3.2. the proposed corrective action; and </li>
              <li>
                4.5.3.3. to the extent possible, the proposed timeframe for
                performing such corrective action.
              </li>
            </ul>
          </ul>
        </ul>
        <br />
        <li>Ownership</li>
        <ul id="5">
          <li>
            5.1. NU Borders owns or has rights to all intellectual property
            rights in and to the Services (including all derivatives or
            improvements thereof). All suggestions, enhancements requests,
            feedback, recommendations or other input provided by Customer or any
            other party relating to the Services or Software shall be owned by
            NU Borders, and Customer hereby does and shall make all assignments
            and take all reasonable acts necessary to accomplish the foregoing
            ownership. Any rights not expressly granted herein are reserved by
            NU Borders.
          </li>

          <li>
            5.2. Customer owns any data, information or material originated by
            Customer that Customer submits, collects or provides in the course
            of using the Services, ("Customer Data"). NU Borders has no
            ownership rights in or to Customer Data. Customer shall be solely
            responsible for the accuracy, quality, content and legality of
            Customer Data, the means by which Customer Data is acquired and the
            transfer of Customer Data outside of the NU Borders Services.
            Customer Data shall be deemed to be Customer Confidential
            Information pursuant to Section 9 below.
          </li>

          <li>5.3. Statistical Information</li>
          <ul id="5.3">
            <li>
              5.3.1. Notwithstanding anything else in the Agreement or
              otherwise, NU Borders may monitor Customer’s use of the Services
              and use Customer Data in an aggregate and anonymous manner to
              compile statistical and performance information related to the
              provision and operation of the Services (for example application
              response time, uptime and downtime). NU Borders will at no point
              make the data publicly available.
            </li>
          </ul>

          <li>5.4. Privacy and Data Protection</li>
          <ul id="5.4">
            <li>5.4.1. Customer will ensure that: </li>
            <ul id="5.4.1">
              <li>
                5.4.1.1. Customer is entitled to transfer all relevant personal
                data to NU Borders so that NU Borders may lawfully use, process
                and transfer the personal data on Customer’s behalf and in
                accordance with this Agreement; and{' '}
              </li>
              <li>
                5.4.1.2. the relevant third parties and data subjects have been
                informed of, and have given their consent to, such use,
                processing, and transfer as required by all applicable data
                protection laws. Customer shall be knowledgeable about and at
                all times compliant with all privacy and data protection laws
                applicable to its location and operations, such as, the European
                Union General Data Protection Regulation and member state
                implementations thereof.
              </li>
            </ul>
          </ul>
        </ul>
        <br />
        <li>Billing and Payment</li>
        <ul id="6">
          <li>
            6.1. Customer shall pay the Fee set forth at the time of purchase.
            All fees are non-cancelable and nonrefundable, except as expressly
            specified herein. The Fee is exclusive of taxes, levies, or duties
            imposed by taxing authorities, and Customer shall be responsible for
            payment of all such taxes, levies, or duties (excluding taxes based
            on NU Borders's income). Customer shall pay all fees in U.S. Dollars
            or in such other currency as agreed to in writing by the parties.
          </li>

          <li>
            6.2. If at any time NU Borders determines that Customer is exceeding
            the Usage Rights or if customer decides they would like to upgrade
            service, NU Borders shall notify Customer and Customer shall apply
            charges for appropriate service levels. If Customer fails to do so
            within 30 days of receipt of NU Borders’s notice, NU Borders
            reserves the right to charge and Customer agrees to pay NU Borders's
            then-current usage fees for such overage.
          </li>
        </ul>
        <br />
        <li>Term and Termination</li>
        <ul id="7">
          <li>
            7.1. The Agreement shall commence as of the date of purchase and,
            unless earlier terminated, shall remain in effect through the Access
            Period. All sections of the Agreement which by their nature should
            survive termination will survive, including without limitation,
            accrued rights to payment, use restrictions and indemnity
            obligations, confidentiality obligations, warranty disclaimers, and
            limitations of liability.
          </li>

          <li>
            7.2. In the event of a material breach by either party, the
            non-breaching party shall have the right to cancel the Subscription
            for cause if such breach has not been cured within 30 days of
            written notice from the non-breaching party specifying the breach in
            detail. If NU Borders terminates the Subscription for Customer's
            material breach, all Fees shall be retained and no refund shall be
            provided for any unexpired portion of the Access Period.
          </li>

          <li>
            7.3. Upon any termination or expiration of the Subscription,
            Customer's right to access and use the Services covered by the
            Subscription shall terminate. Notwithstanding the foregoing, at
            Customer's request if received within 30 days of termination of the
            Subscription, NU Borders will permit Customer to access the Services
            solely to the extent necessary for Customer to retrieve a file of
            Customer Data then in NU Borders's possession. Customer acknowledges
            and agrees that NU Borders has no obligation to retain Customer Data
            and that NU Borders may irretrievably delete and destroy Customer
            Data after 30 days following the termination of the Subscription.
            Customer may request deletion of data by written request to
            info@athstat.com following termination of the Subscription.
          </li>
        </ul>
        <br />
        <li>Representations, Disclaimer of Warranties, Indemnities</li>
        <ul id="8">
          <li>
            8.1. Each party represents and warrants to the other party that it
            has the power and authority to enter into the Agreement. NU Borders
            warrants to Customer that provide the Services in a manner
            consistent with the Agreement. Customer must notify NU Borders of
            any warranty deficiencies within 30 days from performance of the
            relevant Services in order to receive warranty remedies.
          </li>

          <li>
            8.2. For breach of the express warranty set forth herein, Customer's
            exclusive remedy shall be the re-performance of the deficient
            Services. If NU Borders cannot re-perform such deficient Services as
            warranted, Customer shall be entitled to recover a pro-rata portion
            of the fees paid to NU Borders for such deficient Services, and such
            refund shall be NU Borders's sole remedy and entire liability.
          </li>

          <li>
            8.3. The Services may be temporarily unavailable for scheduled
            maintenance or for unscheduled emergency maintenance, or because of
            other causes beyond NU Borders's reasonable control. NU Borders
            shall use reasonable efforts to provide advance notice in writing or
            by e-mail of any scheduled unavailability of the Services.
          </li>

          <li>
            8.4. NU Borders shall defend, indemnify and hold Customer harmless
            against any loss, damage or costs (including reasonable attorneys'
            fees) incurred in connection with claims, demands, suits, or
            proceedings ("Claims") made or brought against Customer by a third
            party alleging that the use of the Service as contemplated hereunder
            infringes the intellectual property rights of a third party;
            provided, that Customer
          </li>
          <ul id="8.4">
            <li>
              8.4.1. promptly gives written notice of the Claim to NU Borders;{' '}
            </li>
            <li>
              8.4.2. gives NU Borders sole control of the defense and settlement
              of the Claim (provided that NU Borders may not settle or defend
              any Claim unless it unconditionally releases Customer of all
              liability); and{' '}
            </li>
            <li>
              8.4.3. provides to NU Borders, at NU Borders's cost, all
              reasonable assistance.{' '}
            </li>

            <li>
              Customer shall defend, indemnify and hold NU Borders harmless
              against any loss, damage or costs (including reasonable attorneys'
              fees) incurred in connection with Claims made or brought against
              NU Borders by a third party alleging that Customer Data, or
              Customer's use of the Services in violation of the Agreement,
              infringes the intellectual property rights of, or has otherwise
              harmed, a third party or violates any law or regulation; provided,
              that NU Borders{' '}
            </li>
            <li>
              8.4.4. promptly gives written notice of the Claim to Customer;{' '}
            </li>
            <li>
              8.4.5. gives Customer sole control of the defense and settlement
              of the Claim (provided that Customer may not settle or defend any
              Claim unless it unconditionally releases NU Borders of all
              liability); and{' '}
            </li>
            <li>
              8.4.6. provides to Customer, at Customer's cost, all reasonable
              assistance.
            </li>
          </ul>

          <li>
            8.5. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH HEREIN, NU BORDERS
            AND ITS THIRD PARTY PROVIDERS HEREBY DISCLAIM ALL EXPRESS OR IMPLIED
            WARRANTIES WITH REGARD TO THE SERVICES, INCLUDING BUT NOT LIMITED TO
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE,TITLE, NON-INFRINGEMENT AND QUALITY. NU BORDERS AND ITS
            THIRD PARTY PROVIDERS MAKE NO REPRESENTATIONS OR WARRANTIES
            REGARDING THE RELIABILITY, AVAILABILITY, TIMELINESS, SUITABILITY,
            ACCURACY OR COMPLETENESS OF THE SERVICES OR THE RESULTS CUSTOMER MAY
            OBTAIN BY USING THE SERVICES. WITHOUT LIMITING THE GENERALITY OF THE
            FOREGOING, NU BORDERS AND ITS THIRD PARTY PROVIDERS DO NOT REPRESENT
            OR WARRANT THAT{' '}
          </li>
          <ul id="8.5">
            <li>
              8.5.1. THE OPERATION OR USE OF THE SERVICES WILL BE TIMELY,
              UNINTERRUPTED OR ERROR-FREE; OR
            </li>
            <li>
              8.5.2. THE QUALITY OF THE, SERVICES WILL MEET CUSTOMER'S
              REQUIREMENTS. CUSTOMER ACKNOWLEDGES THAT NEITHER NU BORDERS NOR
              ITS THIRD PARTY PROVIDERS CONTROLS THE TRANSFER OF DATA OVER
              COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT THE
              SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
              INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. NU BORDERS
              IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER
              DAMAGE RESULTING FROM SUCH PROBLEMS. EXCEPT WHERE EXPRESSLY
              PROVIDED OTHERWISE BY NU BORDERS, THE SERVICES ARE PROVIDED TO
              CUSTOMER ON AN "AS IS" BASIS.
            </li>
          </ul>
        </ul>
        <br />
        <li>Limitation of Liability</li>
        <p>
          EXCEPT FOR LIABILITY ARISING UNDER A BREACH OF ANY INTELLECTUAL
          PROPERTY RIGHT OF A PARTY, PAYMENT OBLIGATIONS OF CUSTOMER, THE
          INDEMNIFICATION OBLIGATIONS SET FORTH HEREIN, OR A PARTY’S GROSS
          NEGLIGENCE OR WILLFUL MISCONDUCT, NEITHER PARTY SHALL BE LIABLE UNDER
          ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY:{' '}
        </p>
        <ul id="9">
          <li>
            9.1. FOR ERROR OR INTERRUPTION OF USE, INACCURACY OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICE OR TECHNOLOGY OR LOSS OF
            BUSINESS;
          </li>

          <li>
            9.2. FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES;
          </li>

          <li>
            9.3. FOR ANY MATTER BEYOND IT'S REASONABLE CONTROL, EVEN IF SUCH
            PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE; OR
          </li>

          <li>
            9.4. FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL
            OTHER CLAIMS, EXCEED THE LESSER OF $500,000 OR THE FEES PAID BY
            CUSTOMER IN THE PRECEEDING 12 MONTHS.
          </li>
        </ul>
        <br />
        <li>Confidential Information</li>
        <p>
          Each party (the "Receiving Party") understands that the other party
          (the "Disclosing Party") has disclosed or may disclose information
          relating to the Disclosing Party's business (hereinafter referred to
          as "Confidential Information" of the Disclosing Party). Such
          information includes, without limitation, Customer Data, information
          related to Customer's login identifiers and credentials for Accounts.
          The Receiving Party agrees:{' '}
        </p>

        <ul id="10">
          <li>
            10.1. to take reasonable precautions to protect such Confidential
            Information; and{' '}
          </li>

          <li>
            10.2. not to use (except as expressly permitted in Section 4.3) or
            divulge to any third person any such Confidential Information. The
            Disclosing Party agrees that the foregoing shall not apply with
            respect to Confidential Information that the Receiving Party can
            document{' '}
          </li>
          <ul id="10.2">
            <li>
              10.2.1. is or becomes generally available to the public; or{' '}
            </li>
            <li>
              10.2.2. was in its possession or known by it prior to receipt from
              the Disclosing Party; or
            </li>
            <li>10.2.3. was rightfully disclosed to it by a third party; or</li>
            <li>
              10.2.4. was independently developed without use of any
              Confidential Information of the Disclosing Party; or{' '}
            </li>
            <li>
              10.2.5. is required by law. Customer Data may be destroyed as set
              forth in Section 7.3, and, upon Customer’s request, NU Borders
              shall certify to such destruction in writing.
            </li>
          </ul>
        </ul>
        <br />
        <li>Notices</li>
        <p>
          NU Borders may give notice applicable to NU Borders's general Services
          customer base by means of a general notice on the Services portal, and
          notices specific to Customer by electronic mail to Customer's e-mail
          address on record in NU Borders's account information or by written
          communication sent by first class mail or pre-paid post to Customer's
          address on record in NU Borders's account information. If Customer has
          a dispute with NU Borders, wishes to provide a notice under the
          Agreement, or becomes subject to insolvency or other similar legal
          proceedings, Customer shall promptly send written notice to NU Borders
          at 1037 Pleasant St., Bridgewater MA 02324.
        </p>
        <br />
        <li>Force Majeure</li>
        <p>
          Neither party shall be responsible for failure or delay of performance
          if caused by: an act of war, hostility, or sabotage; act of God;
          electrical, internet, or telecommunication outage that is not caused
          by the obligated party; government restrictions (including the denial
          or cancellation of any export or other license); or other event
          outside the reasonable control of the obligated party. Each party will
          use reasonable efforts to mitigate the effect of a force majeure
          event. If such event continues for more than 20 days, either party may
          cancel unperformed Services upon written notice. This section does not
          excuse either party of its obligations to take reasonable steps to
          follow its normal disaster recovery procedures or Customer's
          obligation to pay for the Services provided.
        </p>
        <li>General provisions</li>
        <ul id="13">
          <li>
            13.1. Any legal claims, proceedings or litigation arising out of or
            in connection with the Services will be brought solely in the
            Federal or State courts of the State of Virginia, and each Party
            hereto consents to the jurisdiction and venue of such courts in any
            suit, action or proceeding concerning this Agreement. Any action,
            Claim, or dispute related to the Agreement will be governed by
            Virginia law, excluding its conflicts of law provisions, and
            controlling U.S. federal law. The Uniform Computer Information
            Transactions Act will not apply to the Agreement. In any action or
            proceeding to enforce rights under the Agreement, the prevailing
            party will be entitled to recover costs and attorneys' fees. The
            failure of either party to enforce any right or provision in the
            Agreement shall not constitute a waiver of such right or provision
            unless acknowledged and agreed to by such party in writing. Except
            for actions for nonpayment or breach of either party’s proprietary
            rights, no action, regardless of form, arising out of or relating to
            the Agreement may be brought by either party more than two years
            after the cause of action has accrued.
          </li>

          <li>
            13.2. This Agreement represent the parties' entire understanding
            relating to the Services, and supersedes any prior or
            contemporaneous, conflicting or additional communications. Accepting
            these terms and completing the account creation process, shall be
            sufficient to bind the parties to the Agreement. If any provision of
            the Agreement is held by a court of competent jurisdiction to be
            invalid or unenforceable, then such provision(s) shall be construed
            to reflect the intentions of the invalid or unenforceable
            provision(s), with all other provisions remaining in full force and
            effect.
          </li>

          <li>
            13.3. No joint venture, partnership, employment, or agency
            relationship exists between NU Borders and Customer as a result of
            the Agreement or use of the Services. Neither party may assign the
            Agreement without the prior written approval of the other, such
            approval not to be unreasonably withheld or delayed, provided that
            such approval shall not be required in connection with a merger or
            acquisition of all or substantially all of the assets of the
            assigning company. Any purported assignment in violation of this
            Section shall be void.
          </li>
          <br />
        </ul>
      </ol>
    </div>
  );
};

export default EulaText;
