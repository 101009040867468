import React, { useContext, useEffect, useState } from 'react';

// import { useNavigate } from 'react-router-dom';
import { FantasyGamesContext } from 'context/FantasyGamesContext';
import { useAuth } from 'react-oidc-context';
// import axios from 'axios';
import SportsSelection from 'components/SportsSelection/SportsSelection';
// import BannerCarousel from 'components/BannerCarousel/BannerCarousel';
// import PlayerLeaderboard from 'components/PlayerLeaderboard/PlayerLeaderboard';
// import MatchList from 'components/Matches/UpcomingMatches';
import PlayerDisplay from 'components/Players/PlayerDisplay';

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}
interface SelectedSport {
  id: string;
  name: string;
  games_supported: boolean;
}

export const CreateTeam: React.FC = () => {
  // const navigate = useNavigate();
  const { fetchFantasyTeam, loading } = useContext(FantasyGamesContext);
  const { user } = useAuth();
  const kcUser = user?.profile;
  // const userId = kcUser?.sub;
  const token = user?.access_token;
  const [, setSelectedSeason] = useState<SelectedCompetition | null>(null);

  const [selectedSport, setSelectedSport] = useState<SelectedSport | null>(
    null
  );
  // const [setAccess] = useState<boolean>(false);

  // useEffect(() => {
  //   if (isAuthenticated && token) {
  //     const checkAdmin = async () => {
  //       const fetchedAccess = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/admin/check-access`,
  //         {
  //           params: { user_id: userId },
  //           headers: {
  //             Authorization: user ? `Bearer ${token}` : undefined,
  //           },
  //         }
  //       );
  //       if (fetchedAccess.data) {
  //         setAccess(fetchedAccess.data);
  //       }
  //     };

  //     checkAdmin();
  //   }
  // }, []);

  useEffect(() => {
    if (kcUser && fetchFantasyTeam && token) {
      fetchFantasyTeam(kcUser.sub, token);
    }
  }, []);

  // const scrollToTop = () => {
  //   window.scrollTo(0, 0);
  // };

  const handleSeasonSelect = (season: SelectedCompetition): void => {
    setSelectedSeason(season);
  };

  const handleSportSelect = (sport: SelectedSport): void => {
    setSelectedSport(sport);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <section className="games-home-page">
        <div>
          <SportsSelection
            onCompetitionSelect={handleSeasonSelect}
            selectedCompetition={null}
            onSportSelect={handleSportSelect}
            selectedSport={selectedSport}
          />
        </div>
        <PlayerDisplay selectedCompetition={null} />
      </section>
    </React.Fragment>
  );
};
export default CreateTeam;
