/* eslint-disable @typescript-eslint/ban-ts-comment */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

interface Player {
  tracking_id: number;
  player_name: string;
  position_class: string;
  price: string;
  image_url: string;
  power_rank_rating: string;
  sex: string;
  age: number;
  country: string;
  isChecked?: boolean;
  selectedPlayers: Player[];
  team_id: string;
  athlete_id: string;
}

interface IFantasyTeamAthlete {
  id?: string;
  team_id: string;
  athlete_id: string;
  purchase_price: number;
  purchase_date: Date;
  is_starting: boolean;
  slot: number;
  score: number;
}

interface PlayerDisplayModalProps {
  onClose: () => void;
  club_id: string;
  official_league_id: string;
  totalValue: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  team_athletes: Player[];
}
const CreateTeamModal: React.FC<PlayerDisplayModalProps> = ({
  official_league_id,
  totalValue,
  setIsModalOpen,
  team_athletes,
}) => {
  //const [isModalOpen, setIsModalOpen] = useState(true);
  const [teamName, setTeamName] = useState('');
  const { user } = useAuth();
  const accessToken = user?.access_token;
  const [isTeamCreated, setIsTeamCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [officialLeagueId, setOfficialLeagueId] = useState<string | null>(null);
  const [teamValue, setTeamValue] = useState<number | undefined>(undefined);
  const [allowedBacks, setAllowedBack] = useState<number>(0);
  const [allowedForwards, setAllowedForwards] = useState<number>(0);
  const [startingBacks, setStartingBack] = useState<number>(0);
  const [startingForwards, setStartingForwards] = useState<number>(0);
  //const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleChooseLeague = () => {
    handleClose();
    navigate('/auth/games-dash/fantasy/leagues');
  };

  useEffect(() => {
    // This code will run whenever club_id or official_league_id changes
    setOfficialLeagueId(official_league_id);
    setTeamValue(totalValue);
    //console.log('Team athletes: ', team_athletes);
  }, [official_league_id, totalValue]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/fantasy-league-config/${
          official_league_id ?? ''
        }`
      )
      .then((response) => {
        const data = response.data;
        setAllowedBack(data.allowed_positions.back);
        setAllowedForwards(data.allowed_positions.forward);
        setStartingBack(data.starting_positions.back);
        setStartingForwards(data.starting_positions.forward);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
      });
  }, [official_league_id]);

  const handleCreateTeam = async () => {
    ///teamValue =
    //Get club_id
    if (!officialLeagueId) {
      alert('No competition selected');
      return;
    }
    if (teamValue === 0) {
      alert('Please select players');
      return;
    }

    let backCount = 0;
    let forwardCount = 0;

    team_athletes.forEach((athlete) => {
      if (athlete.position_class === 'back') {
        backCount++;
      } else if (athlete.position_class === 'forward') {
        forwardCount++;
      }
    });

    if (backCount > allowedBacks) {
      alert(`No more than ${allowedBacks} backs`);
      return;
    }
    if (backCount < allowedBacks) {
      alert(`You need ${allowedBacks} backs`);
      return;
    }
    if (forwardCount > allowedForwards) {
      alert(`No more than ${allowedForwards} forwards`);
      return;
    }
    if (forwardCount < allowedForwards) {
      alert(`You need ${allowedForwards} forwards`);
      return;
    }

    setIsLoading(true);
    //Get user's club_id to create the team
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/fantasy-teams/fantasy-clubs/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data[0];
        return data;
      })
      .then((data) => {
        //Create the team
        const club_id = data.id;

        const firstBacks = team_athletes
          .filter((players: Player) => players.position_class === 'back')
          .slice(0, startingBacks);

        const firstBacksI = team_athletes
          .filter((players: Player) => players.position_class === 'back')
          .slice(startingBacks, allowedBacks);

        const firstBackI: IFantasyTeamAthlete[] = firstBacks.map(
          (athlete, index) => ({
            team_id: athlete.team_id,
            athlete_id: athlete.tracking_id.toString(),
            purchase_price: Number(athlete.price),
            purchase_date: new Date(),
            is_starting: true,
            slot: index,
            score: 0, // Convert 'score' to string
          })
        );

        const firstBackII: IFantasyTeamAthlete[] = firstBacksI.map(
          (athlete, index) => ({
            team_id: athlete.team_id,
            athlete_id: athlete.tracking_id.toString(),
            purchase_price: Number(athlete.price),
            purchase_date: new Date(),
            is_starting: false,
            slot: startingBacks + index,
            score: 0, // Convert 'score' to string
          })
        );

        const firstForwards = team_athletes
          .filter((players: Player) => players.position_class === 'forward')
          .slice(0, startingForwards);

        const firstForwardsI = team_athletes
          .filter((players: Player) => players.position_class === 'forward')
          .slice(startingForwards, allowedForwards);

        const firstForwardI: IFantasyTeamAthlete[] = firstForwards.map(
          (athlete, index) => ({
            team_id: athlete.team_id,
            athlete_id: athlete.tracking_id.toString(),
            purchase_price: Number(athlete.price),
            purchase_date: new Date(),
            is_starting: true,
            slot: allowedBacks + index,
            score: 0, // Convert 'score' to string
          })
        );

        const firstForwardII: IFantasyTeamAthlete[] = firstForwardsI.map(
          (athlete, index) => ({
            team_id: athlete.team_id,
            athlete_id: athlete.tracking_id.toString(),
            purchase_price: Number(athlete.price),
            purchase_date: new Date(),
            is_starting: false,
            slot: allowedBacks + startingForwards + index,
            score: 0, // Convert 'score' to string
          })
        );

        const fantasyTeamAthletes = [
          ...firstBackI,
          ...firstBackII,
          ...firstForwardI,
          ...firstForwardII,
        ];

        try {
          const params = {
            team: fantasyTeamAthletes,
            name: teamName,
            leagueId: officialLeagueId,
            clubId: club_id,
          };
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/fantasy-athletes/fantasy-team-athletes`, ///fantasy-athletes/fantasy-team-athletes
              params,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                setIsLoading(false);
                setIsTeamCreated(true);
                //console.log('Team created: ', response.data);
              } else {
                // Handle non-successful response
                // eslint-disable-next-line no-console
                console.error('Request failed with status', response.status);
                setIsLoading(false);
              }
            })
            .catch((error) => {
              // Handle network error
              // eslint-disable-next-line no-console
              console.error('Network error', error);
              setIsLoading(false);
            });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error creating team: ', error);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  };

  return (
    <div className="match-modal">
      <div className="modal-name-close">
        <div className="modal-img-div">
          <img className="create-team-modal-img" src="/icons/CreateTeam.svg" />
        </div>
        <h1 className="modal-close-x" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#E6E6E6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </h1>
      </div>
      {isLoading ? (
        <>
          <h1 className="modal-name">Creating team...</h1>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <div className="loading-spinner">
              <img
                src="/img/loading2.gif"
                alt="Loading"
                style={{ height: '100px', borderRadius: '50px' }}
              />
            </div>
          </div>
        </>
      ) : isTeamCreated ? (
        <>
          <h1 className="modal-name">Team Created</h1>
          <h2 className="modal-description">
            Take your new team and play them in a league to start winning
            prizes.
          </h2>
          <div className="modal-buttons">
            <button className="modal-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create" onClick={handleChooseLeague}>
              Choose League
            </button>
          </div>
        </>
      ) : (
        <>
          <h1 className="modal-name">Create Team</h1>
          <h2 className="modal-description">
            Create a new team and play them in leagues against other players.
          </h2>
          <div className="new-team-input">
            <h1 className="modal-name-2">Team Name</h1>
            <input
              type="text"
              className="modal-input"
              style={{ color: 'white', backgroundColor: '#17181c' }}
              id="title"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button className="modal-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create" onClick={handleCreateTeam}>
              Create Team
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateTeamModal;
