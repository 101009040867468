import React, { useEffect, useState } from 'react';
import PriceChangeChart from './PriceChangeChart';
import axios from 'axios';

interface Player {
  tracking_id: number;
  player_name: string;
  position_class: string;
  price: string;
  image_url: string;
  power_rank_rating: string;
  sex: string;
  age: number;
  country: string;
  isChecked?: boolean;
  selectedPlayers: Player[];
  team_id: string;
  athlete_id: string; // or some other identifier
}
export interface PlayerItem {
  player_name: string;
  price: number;
  position_class: string;
  image_url: string;
  id?: number;
  team_id: number;
  athlete_id: string;
  purchase_price: number;
  purchase_date: string;
  is_starting: boolean;
  slot: number;
  score: number;
}

interface Ranking {
  athlete_id: string;
  game_id: string;
  opposition_name: string;
  team_name: string;
  updated_power_ranking: number;
}
interface PlayerCardProps {
  tracking_id: number;
  player_name: string;
  position_class: string;
  position?: string;
  team_name?: string;
  price: string;
  image_url: string;
  power_rank_rating: string;
  onPlayerSelect: (isSelected: boolean) => void;
  onAddToTeam: (player: Player) => void;
  disabled: boolean;
  sex: string;
  age: number;
  country: string;
  team_id: string;
  athlete_id: string;
  handleIsCheckedToggle: (player: Player) => void;
  selectedPlayers: Player[];
  isChecked: boolean;
  onAddPlayerToSub: (player: PlayerItem) => void;
  onAddPlayerToBench: (player: PlayerItem) => void;
}

const PlayerCardItem: React.FC<PlayerCardProps> = ({
  tracking_id,
  player_name,
  position_class,
  position,
  team_name,
  price,
  image_url,
  power_rank_rating,
  //   onAddToTeam,
  disabled,
  sex,
  age,
  country,
  team_id,
  athlete_id,
  handleIsCheckedToggle,
  isChecked,
  onAddPlayerToSub,
  onAddPlayerToBench,
}) => {
  const [isCheckedd, setIsCheckedd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Player Information');

  const handleCheckboxChange = () => {
    const player: Player = {
      tracking_id: tracking_id,
      player_name: player_name,
      position_class: position_class.toLowerCase(),
      price: price,
      image_url: image_url,
      power_rank_rating: power_rank_rating,
      sex: sex,
      age: age,
      country: country,
      selectedPlayers: [],
      team_id: team_id,
      athlete_id: athlete_id,
    };
    const empPlayer: PlayerItem = {
      player_name: player_name,
      price: 0,
      position_class: position_class.toLowerCase(),
      image_url: image_url,
      id: 24,
      team_id: 1,
      athlete_id: tracking_id.toString(),
      purchase_price: 0,
      purchase_date: '',
      is_starting: true,
      slot: 24,
      score: 0,
    };
    // console.log(empPlayer);
    handleIsCheckedToggle(player);
    onAddPlayerToSub(empPlayer);
    onAddPlayerToBench(empPlayer);
  };

  useEffect(() => {
    setIsCheckedd(isChecked);
  }, [isChecked]);

  const handleStatsImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [athletesPowerRankings, setAthletesPowerRankings] = useState([]);
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/athletes-power-rankings/${tracking_id}`
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        // eslint-disable-next-line no-console
        console.log('Athlete power rankings: ', data);
        setAthletesPowerRankings(data);

        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  }, [tracking_id]);

  const cardClassName = `player-card${isCheckedd ? ' checked' : ''}${
    disabled ? ' disabled' : ''
  }`;
  const imageBackgroundColor = isCheckedd
    ? 'rgba(38, 182, 112, 0.15)'
    : '#323232';

  return (
    <div className={cardClassName}>
      <div className="stat-check-photo">
        <div className="stat-check" style={{ position: 'relative' }}>
          <img
            className="stat-img"
            src="/icons/stats.svg"
            style={{ height: '35px' }}
            alt="stats"
            onClick={handleStatsImageClick}
          />
          <input
            type="checkbox"
            className="player-card-checkbox"
            checked={isCheckedd}
            onChange={handleCheckboxChange}
            id="athlete"
            disabled={disabled}
            style={{
              appearance: 'none',
              WebkitAppearance: 'none',
              border: `1px solid ${isCheckedd ? '#26B670' : '#434343'}`,
              width: '20px',
              height: '20px',
              borderRadius: '3px',
              outline: 'none',
              margin: '0',
              cursor: 'pointer',
              position: 'relative',
            }}
          />

          {isCheckedd && (
            <span
              className="check-mark"
              aria-hidden="true"
              onClick={handleCheckboxChange}
              style={{
                position: 'absolute',
                top: '30%',
                right: '-1%',
                transform: 'translate(-50%, -50%)',
                color: '#FFF',
                cursor: 'pointer',
              }}
            >
              &#10003;
            </span>
          )}
        </div>
        <img
          src={image_url}
          onError={(e) => {
            if (e.target instanceof HTMLImageElement) {
              e.target.src = '/icons/default-player-img.png';
            }
          }}
          alt="avatar"
          className="player-card-avatar"
          style={{
            backgroundColor: imageBackgroundColor,
            cursor: 'pointer',
          }}
          onClick={handleCheckboxChange}
        />
      </div>

      {isModalOpen && (
        <div
          className="custom-modal"
          style={{
            marginTop: '30px',
            height: '90vh',
            //overflowY: 'auto',
          }}
        >
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <div
              style={{
                display: 'flex',
                marginTop: '60px',
                marginLeft: '-20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Added width to ensure it takes the full width
              }}
            >
              <div>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Player Information' ? 'selected-tab' : ''
                  }`}
                  onClick={() => setSelectedTab('Player Information')}
                >
                  Player Information
                </button>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Power Rankings' ? 'selected-tab' : ''
                  }`}
                  onClick={() => setSelectedTab('Power Rankings')}
                  //disabled={isPowerRankingsDisabled}
                >
                  Power Rankings
                </button>
              </div>
              <div
                onClick={closeModal}
                style={{ marginRight: '-20px', cursor: 'pointer' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#E6E6E6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="modal-player-button">
              <h1 className="modal-player-header">Player</h1>
              {/* <button className="add-player" onClick={handleAddToTeamClick}>
                Select Player
              </button> */}
            </div>
            <img
              src={image_url}
              onError={(e) => {
                if (e.target instanceof HTMLImageElement) {
                  e.target.src = '/icons/default-player-img.png';
                }
              }}
              className="stats-card--user-img"
            />
            <div style={{ width: '100%', height: '400px', overflowY: 'auto' }}>
              {selectedTab === 'Player Information' && (
                <>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Name</h1>
                    <h1 className="detail-value">{player_name || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Position</h1>
                    <h1 className="detail-value">{position_class || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Country</h1>
                    <h1 className="detail-value">{country || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Age</h1>
                    <h1 className="detail-value">{age || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Sex</h1>
                    <h1 className="detail-value">{sex || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Power Ranking</h1>
                    <h1 className="detail-value">{power_rank_rating || '-'}</h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Current Value</h1>
                    <h1 className="detail-value">
                      {parseFloat(price).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }) || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row-graph">
                    <h1 className="detail-desc">Value History</h1>
                    {tracking_id && (
                      <PriceChangeChart trackingId={tracking_id} />
                    )}
                  </div>
                </>
              )}
              {selectedTab === 'Power Rankings' && (
                <div style={{ height: 500 }}>
                  {athletesPowerRankings.map((ranking: Ranking, index) => (
                    <div className="player-detail-row-rankings" key={index}>
                      <h1 className="detail-desc">
                        {ranking.team_name || '-'} vs{' '}
                        {ranking.opposition_name || '-'}
                      </h1>
                      <h1 className="detail-value">
                        {ranking.updated_power_ranking || '-'}
                      </h1>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="player-name-position">
        <h1 className="player-name" style={{ marginTop: '2px' }}>
          {player_name}
        </h1>
        <h1 className="player-position" style={{ marginTop: '-10px' }}>
          {position_class || '-'}
        </h1>
        <p className="player-position" style={{ marginTop: '-10px' }}>
          {position?.toUpperCase() || '-'}
        </p>
        <p
          className="player-position"
          style={{ marginTop: '-18px', marginBottom: -5 }}
        >
          {team_name?.toUpperCase() || '-'}
        </p>
      </div>
      <div
        className="power-rank-value"
        style={{ backgroundColor: imageBackgroundColor }}
      >
        <div className="player-card-value">
          <h1 className="value-desc">Power Ranking</h1>
          <h1 className="value-amount"> {power_rank_rating}</h1>
        </div>
        <svg
          width="1"
          height="48"
          viewBox="0 0 1 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.5 1V47" stroke="#535353" strokeLinecap="round" />
        </svg>
        <div className="player-card-value">
          <h1 className="value-desc">Dollar Amount</h1>
          <h1 className="value-amount">
            {parseFloat(price).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }) || '-'}
          </h1>
        </div>
      </div>

      <style>
        {`
          /* Styles for the modal */
          .custom-modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000; /* Adjust the z-index value as needed */
          }

          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 999; /* Ensure the overlay is behind the modal content */
          }

          .modal-content {
            background: #fff;
            padding: 20px;
            border-radius: 5px;
            z-index: 1001; /* Adjust the z-index value to be above the overlay */
            border-radius: 4px;
            background: var(--gray-900, #111);
            display: flex;
            width: 720px;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px; 
        
          }
        
          
      
          .modal-close {
            cursor: pointer;
            color: #fff;
            font-size: 20px;
          }
      
          .modal-close:hover {
            color: #ff0000;
          }
        `}
      </style>
    </div>
  );
};

export default PlayerCardItem;
