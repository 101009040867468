/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
//import { User } from '../../../d';

const VerifyUser: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const kcUser = auth.user?.profile;
  //const token = auth.user?.access_token;
  const userId = kcUser?.sub;
  //   const email = kcUser?.email ? kcUser?.email : 'example@example.com';
  //   const given_name = kcUser?.given_name;
  //   const family_name = kcUser?.family_name;

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (process.env.REACT_APP_ENV === 'development') {
        navigate('/auth');
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            //LOGIC FOR GEOFENCE UPDATE HERE if blocked location navigate ('/location-block)
            // eslint-disable-next-line no-console
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            const runGeo = async () => {
              if (userId) {
                const allowedLocation = await postLocation(
                  latitude,
                  longitude,
                  userId
                );
                if (allowedLocation === 'ENTER') {
                  //getUser(userId);
                  navigate('/auth');
                  // eslint-disable-next-line no-console
                } else if (allowedLocation === 'EXIT') {
                  navigate('/location-blocked');
                  return;
                } else {
                  navigate('/location-disclaimer');
                }
                // if (result.message === 'Success') {
                //   setTimeout(async () => {
                //     const res = await fetchGeolocation(userId);
                //     if (res) {
                //       if (res === 'ENTER') {
                //         //getUser(userId);
                //         navigate('/auth');
                //         // eslint-disable-next-line no-console
                //         console.log('Allowed region');
                //       } else if (res === 'EXIT') {
                //         navigate('/location-blocked');
                //         return;
                //       } else {
                //         navigate('/location-disclaimer');
                //       }
                //     } else {
                //       // eslint-disable-next-line no-console
                //       console.log('Failed to get the location status');
                //     }
                //   }, 1500);
                // }
              }
            };
            if (process.env.REACT_APP_GEOLOCATION_DEV === 'development') {
              navigate('/auth');
            } else {
              runGeo();
            }
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.error('Unable to retrieve your location:', error.message);
            navigate('/location-disclaimer');
          }
        );
      } else {
        // eslint-disable-next-line no-console
        console.log('Geolocation is not supported by this browser.');
        navigate('/location-disclaimer');
      }
    }
    // const getUser = async (userIds: string) => {
    //   try {
    //     const fetchedUser = await axios.get(
    //       `${process.env.REACT_APP_API_URL}/users/${userIds}`,
    //       {
    //         headers: {
    //           Authorization: token ? `Bearer ${token}` : undefined,
    //         },
    //       }
    //     );

    //     if (fetchedUser.data) {
    //       // eslint-disable-next-line no-console
    //       console.log(`Success: User ${fetchedUser.data.state} fetched.`);
    //       navigate('/auth');
    //       //   switch (fetchedUser.data.state) {
    //       //     case 'new':
    //       //     case null:
    //       //     case undefined:
    //       //       navigate('/signup-games');
    //       //       break;
    //       //     case 'pending':
    //       //       navigate('/pending');
    //       //       break;
    //       //     case 'banned':
    //       //       navigate('/banned');
    //       //       break;
    //       //     case 'verified':
    //       //       navigate('/auth');
    //       //       break;
    //       //     default:
    //       //       break;
    //       //   }
    //     } else {
    //       if (userIds) {
    //         const newUser: User = {
    //           kc_id: userIds,
    //           email: email,
    //           firstName: given_name,
    //           lastName: family_name,
    //           kcSynced: true,
    //           hidden: false,
    //           middleInitial: '',
    //           age: '',
    //           sex: '',
    //           height: '',
    //           weight: '',
    //           nftCustomerAgreement: false,
    //           gamesOnboardingComplete: false,
    //           state: 'verified', //change back to new when implemeting Idology
    //           verified: '',
    //           athcoin_balance: 0,
    //         };
    //         const createdUser = await axios.post(
    //           `${process.env.REACT_APP_API_URL}/users/`,
    //           newUser,
    //           {
    //             headers: {
    //               Authorization: token ? `Bearer ${token}` : undefined,
    //             },
    //           }
    //         );
    //         if (createdUser) {
    //           navigate('/signup-games');
    //         }
    //       }
    //     }
    //   } catch (err) {
    //     // eslint-disable-next-line no-console
    //     console.log(err);
    //   }
    // };
  }, [auth]);

  // const fetchGeolocation = async (userIds: string): Promise<string> => {
  //   let response = 'none';
  //   try {
  //     await axios
  //       .get(
  //         `https://qa-games-app.athstat-next.com/api/v1/geolocation/${userIds}`
  //       )
  //       .then((r: AxiosResponse) => {
  //         response = r.data.event_type;
  //       })
  //       .catch(() => {
  //         // eslint-disable-next-line no-console
  //         console.log('Error fetching location');
  //       });
  //     return response;
  //   } catch (e) {
  //     if (e instanceof Error)
  //       // eslint-disable-next-line no-console
  //       console.log('Unable to fetch location for user');
  //     return 'none';
  //   }
  // };

  const postLocation = async (
    latitude: number,
    longitude: number,
    userIds: string
  ): Promise<string> => {
    try {
      const data = JSON.stringify({
        id: userIds,
        location: {
          latitude: latitude,
          longitude: longitude,
        },
      });
      const response: AxiosResponse = await axios.post(
        `${process.env.REACT_APP_GEOLOCATION_URL}/api/v1/geolocation/checkLocation`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data.data.event_type;
    } catch (e) {
      if (e instanceof Error)
        // eslint-disable-next-line no-console
        console.log('Error posting location');
      return 'none';
    }
  };

  return (
    <div className="sign-up">
      <div className="sign-up-header">
        <img
          src="/icons/Athstat-header.svg"
          alt="Athstat Logo"
          className="athstat-header-logo"
        />
      </div>
      <div className="centered-content">
        <div
          className="sign-up-box"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 className="verification-header" style={{ marginLeft: '10px' }}>
            Loading...
          </h1>
          <br />
          <h1
            className="verification-p"
            style={{ width: '330px', marginTop: '-20px', textAlign: 'center' }}
          >
            Please wait while we verify your location.
          </h1>
          <div className="loading-spinner">
            <img
              src="/img/loading2.gif"
              alt="Loading"
              style={{ height: '100px', borderRadius: '50px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUser;
