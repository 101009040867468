import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { IFantasyAthlete, IFantasyTeam, IFantasyTransfer } from '../../../../d';
import {
  getAthleteById,
  getFantasyTeamByUserId,
  getTransfersByTeamId,
} from '../../Games/games.services';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getDisplayDate } from 'components/utils/timeParser';

export const TeamHistory: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const accessToken = user?.access_token;
  const [transferHistory, setTransferHistory] = React.useState<
    IFantasyTransfer[]
  >([]);
  const [players, setPlayers] = React.useState<(IFantasyAthlete | null)[]>([]);
  const [, setLoading] = React.useState<boolean>(false);
  const token = user?.access_token;
  const navigator = useNavigate();
  const [team, setTeam] = React.useState<IFantasyTeam | null>();

  useEffect(() => {
    const fetchFantasyTeam = async () => {
      try {
        if (isAuthenticated && accessToken) {
          setLoading(true);
          await getFantasyTeamByUserId({
            id: kcUser?.sub ? kcUser.sub : '',
            token: accessToken,
          })
            .then((res) => {
              setTeam(res);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchFantasyTeam();
  }, [accessToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (isAuthenticated && token) {
          const response = await getTransfersByTeamId({
            id: team?.id ? team.id : -1,
            token: user.access_token,
          });
          setTransferHistory(response);

          const athleteIds = [];
          const athletesAdded = new Set();

          for (const transfer of response) {
            if (!athletesAdded.has(transfer.athlete_in_id)) {
              athleteIds.push(transfer.athlete_in_id);
              athletesAdded.add(transfer.athlete_in_id);
            }
            if (!athletesAdded.has(transfer.athlete_out_id)) {
              athleteIds.push(transfer.athlete_out_id);
              athletesAdded.add(transfer.athlete_out_id);
            }
          }

          const fetchPlayersData = async (athleteId: string) => {
            try {
              const playerResponse = await getAthleteById({
                id: athleteId,
                token: token ? token : '',
              });

              setPlayers((prevPlayers) =>
                prevPlayers.some((player) => player?.tracking_id === athleteId)
                  ? prevPlayers
                  : [...prevPlayers, playerResponse]
              );
            } catch (error) {
              // Handle error
            }
          };
          athleteIds.forEach(fetchPlayersData);
        }
        setLoading(false);
      } catch (error) {
        // Handle error
        setLoading(false);
      }
    };
    fetchData();
  }, [token, team]);

  return (
    <React.Fragment>
      <div className="leaderboard-styling2">
        <div
          className="back-breadcrumbs2"
          style={{
            marginTop: '100px',
            marginLeft: '100px',
          }}
        >
          <Button className="backButtonNFT" onClick={() => navigator(-1)}>
            <img
              className="nft-vector"
              src="/icons/back-nft-2.svg"
              alt={'Portfolio'}
            />
            <h1 className="back-word2">Back</h1>
          </Button>
          <Button
            className="backButtonNFT"
            onClick={() => navigator(`/auth/games-dash/fantasy/search`)}
            title="Search Players"
            style={{ marginTop: '2px' }}
          >
            <img
              className="nft-vector"
              src="/icons/search.svg"
              alt={'Portfolio'}
            />
          </Button>
        </div>
        <Row
          className="dashboard-PageTitle-row history-title"
          style={{
            marginTop: '30px',
            marginLeft: '140px',
            marginBottom: '-70px',
          }}
        >
          <Col size="12">
            <div className="PageTitle">
              <h2>Team History</h2>
            </div>
          </Col>
        </Row>
        <div className="container HeaderDown" style={{ marginLeft: '200px' }}>
          <table className="table table-history" style={{ width: '60vw' }}>
            <thead>
              <tr className="table-history-header">
                <th scope="col">Date</th>
                <th scope="col">Player In</th>
                <th scope="col">Player Out</th>
                <th scope="col">In Price</th>
                <th scope="col">Out Price</th>
              </tr>
            </thead>
            <tbody>
              {transferHistory.map((transfer) => {
                const playerIn = players.find(
                  (player) => player?.tracking_id === transfer.athlete_in_id
                );
                const playerOut = players.find(
                  (player) => player?.tracking_id === transfer.athlete_out_id
                );
                return (
                  <tr key={transfer.id}>
                    <td>{getDisplayDate(transfer.time ?? '')}</td>
                    <td>{playerIn?.player_name}</td>
                    <td>{playerOut?.player_name}</td>
                    <td>{transfer.athlete_in_price.toFixed(2)}</td>
                    <td>{transfer.athlete_out_price.toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};
