import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import JoinLeagueModal from './JoinLeagueModal';

export interface League {
  id?: number;
  type: string;
  official_league_id: string;
  title: string;
  created_date?: string;
  entry_code?: string;
  entry_fee?: number;
  is_private: boolean;
  reward_type: string;
  reward_description: string;
  end_round: number;
  is_open: boolean;
  join_deadline?: string | null;
  disclaimer: string;
  has_ended: boolean;
}

export interface team {
  id?: number;
  league_id: number;
  team_id: number;
  position?: number | null;
  position_change: number;
  overall_score: number;
  is_admin: boolean;
  join_date: string;
  name: string;
  round_score: number;
}

const LeagueDetails: React.FC = () => {
  const { user } = useAuth();
  const token = user?.access_token;
  // const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [league, setLeague] = useState<League | null>(null);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [, setCurrentPage] = useState(1);
  const [teams, setTeams] = useState<team[]>([]);
  const [, setIsLoading] = useState(true);
  const [leagueName, setLeagueName] = useState('');
  const [leagueType, setLeagueType] = useState('');
  const [leaguecode, setLeagueCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [officialLeagueId, setOfficialLeagueId] = useState<string | null>(null);

  useEffect(() => {
    // replace this with your actual API call
    fetch(`/api/leagues/${id}`)
      .then((response) => response.json())
      .then((data) => setLeague(data))
      .catch((error) =>
        // eslint-disable-next-line no-console
        console.error('Error:', error)
      );
  }, [id]);

  useEffect(() => {
    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATAHUB_URL}/fantasy-leagues/participating-teams/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeams(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchLeague = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATAHUB_URL}/fantasy-leagues/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLeague(response.data);
        setLeagueName(response.data.title);
        setLeagueType(response.data.type);
        setLeagueCode(response.data.entry_code);
        setOfficialLeagueId(response.data.official_league_id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeague();

    fetchTeams();
  }, [id]);

  const switchToUpcoming = () => {
    setCurrentPage(1);
    setShowUpcoming(true);
  };

  const switchToPrevious = () => {
    setCurrentPage(1);
    setShowUpcoming(false);
  };

  const handleNewTeamClick = () => {
    setIsModalOpen(true);
  };

  return (
    <section className="games-home-page">
      <div>
        <div className="team-dropdown-container">
          <button className="team-dropdown-toggle" onClick={handleNewTeamClick}>
            {'Join League +'}
          </button>
        </div>
      </div>
      <div className="matches-container">
        <h1 className="match-container-title" style={{ marginTop: '32px' }}>
          {leagueName}
        </h1>
        <div>
          <button
            className={`match-tabs ${showUpcoming ? 'selected-tab' : ''}`}
            onClick={switchToUpcoming}
          >
            Rankings
          </button>
          <button
            className={`match-tabs ${!showUpcoming ? 'selected-tab' : ''}`}
            onClick={switchToPrevious}
          >
            Information
          </button>
        </div>
        {/* Display the current matches on the selected page */}
        {showUpcoming && (
          <div className="league-table-div">
            <table className="league-table" style={{ width: '1280px' }}>
              <thead>
                <tr className="league-table-head">
                  <th className="league-table-head-name">Rank</th>
                  <th className="league-table-head-name">Team Name</th>

                  <th className="league-table-head-name">Points</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team) => (
                  <tr
                    className="league-row"
                    key={team.id}
                    onClick={() => {
                      if (team.id !== undefined) {
                        // handleRowClick(league.id.toString());
                      }
                    }}
                  >
                    <td className="league-name-row">{team.position}</td>
                    <td className="league-name-row">{team.name}</td>
                    <td className="league-numbers">{team.overall_score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!showUpcoming && (
          <div className="leagueInfo ">
            <div className="leagueInforLeft">
              <div className="leagueInforLeft-div">
                <div className="leagueInforLeft-div-left"> League Name</div>
                <div className="leagueInforLeft-div-right">{leagueName}</div>
              </div>
              <div className="leagueInforLeft-div">
                <div className="leagueInforLeft-div-left"> Type</div>
                <div className="leagueInforLeft-div-right">{leagueType}</div>
              </div>
              <div className="leagueInforLeft-div">
                <div className="leagueInforLeft-div-left"> Team</div>
                <div className="leagueInforLeft-div-right">Le DOC</div>
              </div>
            </div>
            <div className="leagueInfoRight">
              <div className="leagueInfoRight-code"> Code </div>
              <div className="leagueInfoRight-div"> {leaguecode} </div>
            </div>
          </div>
          // <div>
          //   <h1>{leagueName}</h1>
          //   <p>{leagueType}</p>
          //   <p>{leaguecode}</p>
          // </div>
        )}
      </div>
      {isModalOpen && (
        <JoinLeagueModal
          onClose={() => setIsModalOpen(false)}
          code={leaguecode}
          official_league_id={officialLeagueId}
          league={league}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </section>
  );
};

export default LeagueDetails;
