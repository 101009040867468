// src/Sidebar.js
import React from 'react';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  openModal: () => void;
}
const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  openModal,
}) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
        <button className="close-btn" onClick={toggleSidebar}>
          &times;
        </button>
      </div>
      <ul>
        <li onClick={toggleSidebar}>
          <a href="#">Home</a>
        </li>
        <li onClick={openModal}>
          <a href="#">Leagues</a>
        </li>
        <li onClick={openModal}>
          <a href="#">Teams</a>
        </li>
        <li onClick={openModal}>
          <a href="#">Resources</a>
        </li>
      </ul>
      <div className="auth-button-collections">
        <div className="sidebar-footer">
          <div className="sidebar-footer-left">
            <ul>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#">Help</a>
              </li>
              <li>
                <a href="#">Responsible Gaming</a>
              </li>
            </ul>
          </div>
          <div className="sidebar-footer-right">
            <ul>
              <li>
                <a href="#">Terms </a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-footer-buttons">
          <button className="sign-up-buttons" onClick={openModal}>
            <div className="sign-up-button-text"> Sign Up</div>
          </button>
          <button className="login-buttons" onClick={openModal}>
            <div className="login-button-text"> Login </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
