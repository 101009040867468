import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  matchRoutes,
} from 'react-router-dom';
import routes from './routes/routes';
import CssBaseline from '@mui/material/CssBaseline';
import './styles/Demski.index.css';
import './styles/Demski.MobileResponsive.css';
import './styles/Demski.TabletResponsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/js/all';
import { IGame, ILeague, IOrganization, ITeam } from '../d';
import { OrganizationContext } from './context/OrganizationContext';
import { LeagueContext, sampleLeague } from './context/LeagueContext';
import { sampleTeam, TeamContext } from './context/TeamContext';
import { sampleGame, GameContext } from './context/GameContext';
import ErrorBoundary from './components/ErrorFallBack';
import { ThemeProvider } from '@mui/styles';
import { theme } from './styles/theme';
import { FantasyContextProvider } from 'context/FantasyGamesContext';
import { LocationProvider } from 'context/LocationContext';

export const useCurrentPath = (): string => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);
  const route = matchedRoutes
    ? matchedRoutes.find((m) => m.pathname === location.pathname)
    : null;
  if (route) {
    return route.pathname;
  } else {
    return '';
  }
};
const router = createBrowserRouter(routes);
//
// const keycloakCfg = {
//   realm: process.env.REACT_APP_AUTH_KEYCLOAK_REALM || '',
//   url: process.env.REACT_APP_AUTH_KEYCLOAK_URL || '',
//   clientId: process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID || '',
// };
//
// export const keycloak = new Keycloak(keycloakCfg);
export default function App(): React.JSX.Element {
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<IOrganization>();
  const [organizations, setOrganizations] = React.useState<
    Array<IOrganization>
  >([]);
  // const silentCheckUrl =
  //   typeof window !== 'undefined'
  //     ? window.location.origin
  //     : process.env.REACT_APP_URL;
  const [updatedItem, setUpdatedItem] = React.useState<unknown>();
  const [league, setLeague] = React.useState<ILeague>(sampleLeague);
  const [team, setTeam] = React.useState<ITeam>(sampleTeam);
  const [game, setGame] = React.useState<IGame>(sampleGame);

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        //LOGIC FOR GEOFENCE
        // eslint-disable-next-line no-console
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error('Unable to retrieve your location:', error.message);
      }
    );
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <OrganizationContext.Provider
            value={{
              selectedOrganization,
              setSelectedOrganization,
              organizations,
              setOrganizations,
              updatedItem,
              setUpdatedItem,
            }}
          >
            <FantasyContextProvider>
              <LeagueContext.Provider value={{ league, setLeague }}>
                <TeamContext.Provider value={{ team, setTeam }}>
                  <LocationProvider>
                    <GameContext.Provider value={{ game, setGame }}>
                      <CssBaseline />
                      <RouterProvider
                        router={router}
                        fallbackElement={<p>Loading...</p>}
                      />
                    </GameContext.Provider>
                  </LocationProvider>
                </TeamContext.Provider>
              </LeagueContext.Provider>
            </FantasyContextProvider>
          </OrganizationContext.Provider>{' '}
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

// function Root(): React.ReactElement {
//   return routes;
// }
