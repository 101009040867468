import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { AthstatLeague, IFantasyTeam, IUserLeague } from '../../../../d';
import {
  getAthstatLeagues,
  getFantasyLeagueById,
  getFantasyTeamByUserId,
  getUserLeagues,
  joinLeagueByEnteredCode,
  joinUserLeague,
} from '../../Games/games.services';
import { LeagueRow } from './LeagueRow';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';

export const JoinLeague: React.FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [team, setTeam] = useState<IFantasyTeam | null>(null);
  const [, setLoading] = useState<boolean>(false);
  const [AthstatLeagues, setAthstatLeagues] = useState<AthstatLeague[]>([]);
  const [userLeagues, setUserLeagues] = useState<IUserLeague[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLeague, setSelectedLeague] = useState<
    AthstatLeague | IUserLeague | null
  >(null);
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const accessToken = user?.access_token;
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enteredCode, setEnteredCode] = useState<string>('');

  useEffect(() => {
    const fetchLeagues = async () => {
      setLoading(true);
      try {
        if (isAuthenticated && accessToken) {
          const athLeagues = await getAthstatLeagues({
            token: accessToken,
          });
          setAthstatLeagues(athLeagues);
          const userLeagues = await getUserLeagues({
            token: accessToken,
          });
          setUserLeagues(userLeagues);
        }
      } catch (error) {
        // TODO: handle error
      }
      setLoading(false);
    };
    fetchLeagues();
  }, [accessToken]);

  useEffect(() => {
    const fetchFantasyTeam = async () => {
      try {
        setLoading(true);
        await getFantasyTeamByUserId({
          id: kcUser?.sub ? kcUser.sub : '',
          token: accessToken ? accessToken : '',
        })
          .then((res) => {
            setTeam(res);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };
    fetchFantasyTeam();
  }, []);

  const joinFantasyLeague = async () => {
    if (!selectedLeague) {
      alert('Error joining league');
      return;
    }

    try {
      setIsLoading(true);
      if (accessToken && selectedLeague.id && user) {
        const leagueData = await getFantasyLeagueById({
          id: selectedLeague.id.toString(),
          token: accessToken,
        });
        if (!leagueData) {
          alert('Error getting league data');
          setLoading(false);
          return null;
        }

        try {
          if (team) {
            const req2 = await joinUserLeague({
              team: {
                league_id: selectedLeague.id,
                team_id: team.id ? team.id : -1,
                entry_paid: selectedLeague?.entry_fee_tickets || 0,
                entry_balance: (team?.team_value || 0) + (team?.balance || 0),
              },
              token: accessToken ? accessToken : '',
            });
            setIsLoading(false);
            setLoading(false);
            if (req2) {
              alert('Successfully joined league');
              setIsLoading(false);
              navigator(`/auth/games-dash/fantasy`);
              return;
            }
          } else {
            alert('Error joining league, please try again later');
            setIsLoading(false);
          }
        } catch (error) {
          alert('Error joining league');
          setIsLoading(false);
          setLoading(false);
          return null;
        }
      }
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      alert('Error joining league');
      setLoading(false);
      setIsLoading(true);
    }
  };
  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const joinLeagueByCode = async () => {
    if (!enteredCode) {
      alert('Please enter a code');
      return;
    }

    try {
      setLoading(true);
      if (accessToken && team) {
        await joinLeagueByEnteredCode({
          team: team,
          code: enteredCode,
          token: accessToken ? accessToken : '',
        })
          .then((res) => {
            if (res === 'ok') alert('Successfully joined league');
            else alert(res);
          })
          .catch((error) => {
            alert(error);
          });
      }
      setLoading(false);
    } catch (error) {
      alert('Error joining league');
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className="back-breadcrumbs2"
        style={{
          marginTop: '100px',
          marginLeft: '100px',
        }}
      >
        <Button className="backButtonNFT" onClick={() => navigator(-1)}>
          <img
            className="nft-vector"
            src="/icons/back-nft-2.svg"
            alt={'Portfolio'}
          />
          <h1 className="back-word2">Back</h1>
        </Button>
        <Button
          className="backButtonNFT"
          onClick={() => navigator(`/auth/games-dash/fantasy/search`)}
          title="Search Players"
          style={{ marginTop: '2px' }}
        >
          <img
            className="nft-vector"
            src="/icons/search.svg"
            alt={'Portfolio'}
          />
        </Button>
      </div>
      <div className="join-league-styling">
        <Col
          className="dashboard-PageTitle-row join-league-title"
          style={{
            marginTop: '30px',
            marginLeft: '140px',
            marginBottom: '-70px',
          }}
        >
          <Col size="12">
            <div className="PageTitle">
              <h2>Join a League</h2>
            </div>
          </Col>
          <div className="PageTitle" style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Enter League Code"
              className="form-control league-code-entry"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              value={enteredCode}
              onChange={(e) => setEnteredCode(e.target.value)}
              style={{
                backgroundColor: '#17181c',
                borderColor: 'grey',
                color: 'white',
                width: '90%',
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              onClick={() => joinLeagueByCode()}
              style={{
                width: '10%',
                borderColor: 'grey',
                marginLeft: '10px',
              }}
            >
              Join
            </button>
          </div>
        </Col>

        <div
          className={'container HeaderDown league-buttons'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '30px',
          }}
        >
          <ul className="league-tab-container" style={{ marginTop: 10 }}>
            <li className="league-tab">
              <a
                className={`nav-link ${
                  activeTab === 0 ? 'active' : ''
                } custom-nav-link`}
                href="#"
                onClick={() => handleTabClick(0)}
              >
                <span className="nav-button-text">Athstat Leagues</span>
              </a>
            </li>
            <li className="league-tab">
              <a
                className={`nav-link ${
                  activeTab === 1 ? 'active' : ''
                } custom-nav-link`}
                href="#"
                onClick={() => handleTabClick(1)}
              >
                <span className="nav-button-text">User Leagues</span>
              </a>
            </li>
          </ul>

          <input type="text" placeholder="Search" />
          <div
            className={'leagues-container'}
            style={{ maxHeight: '400px', overflowY: 'scroll' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isLoading && (
                <div className="loading-spinner">
                  <img
                    src="/img/loading2.gif"
                    alt="Loading"
                    style={{ height: '100px', borderRadius: '50px' }}
                  />
                </div>
              )}
            </div>
            <div className="league-display">
              {activeTab === 0 &&
                AthstatLeagues.map((league) => (
                  <LeagueRow
                    league={league}
                    key={league.id}
                    onClick={() => {
                      setSelectedLeague(league);
                      setShowModal(true);
                    }}
                  />
                ))}
              {activeTab === 1 &&
                userLeagues.map((league) => (
                  <LeagueRow
                    league={league}
                    key={league.id}
                    onClick={() => {
                      setSelectedLeague(league);
                      setShowModal(true);
                    }}
                  />
                ))}
            </div>
          </div>
          <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: showModal ? 'block' : 'none' }}
          >
            <div
              className="modal-dialog"
              role="document"
              style={{ marginTop: '150px' }}
            >
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: '#17181c', borderBottom: 'none' }}
                ></div>
                <div
                  className="modal-body"
                  style={{ backgroundColor: '#17181c' }}
                >
                  <h1
                    style={{
                      color: 'white',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  >
                    Join League?
                  </h1>
                </div>
                <div
                  className="modal-footer"
                  style={{ backgroundColor: '#17181c', borderTop: 'none' }}
                >
                  <button
                    type="button"
                    className="btn btn-primary fantasy-button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary fantasy-button"
                    onClick={() => {
                      joinFantasyLeague();
                      setShowModal(false);
                    }}
                  >
                    Join
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JoinLeague;
