import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import axios from 'axios';

import { createAthstatLeague } from '../Games/games.services';

import { AthstatLeague } from '../../../d';
import { Link } from 'react-router-dom';
type status = 'success' | 'error' | 'none';

export const AdminGamesDashboard: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;

  const [, setAccess] = useState<boolean>(false);
  const [CLStatus, setCLStatus] = useState<status>('none');
  const [showCreateLeagueModal, setShowCreateLeagueModal] =
    useState<boolean>(false);
  const [rewardType, setRewardType] = useState<string>('Cash');

  const [, setLoading] = useState<boolean>();
  const [leagueData, setLeagueData] = useState<AthstatLeague>({
    title: '',
    league_type: 'Athstat',
    user_limit: 0, //no limit for users who can join a league at the moment
    prize_desc: '',
    reward_type: '',
    entry_fee_tickets: 0,
    duration: 0,
    end_date: new Date(),
    is_open: false,
    team_budget: 0, // Add the missing property 'team_budget'
  });

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, []);

  const createLeague = async () => {
    if (leagueData.title.trim() === '') {
      alert('Please enter a league title');
      return;
    }

    if (leagueData.title.length < 5 || leagueData.title.length > 20) {
      alert('League title must be between 5 and 20 characters');
      return;
    }

    try {
      setLoading(true);

      if (token) {
        const response = await createAthstatLeague({
          league: leagueData,
          token: token || '',
        });

        if (response != null) {
          setCLStatus('success');
          setLeagueData({
            title: '',
            league_type: 'Athstat',
            user_limit: 0,
            prize_desc: '',
            reward_type: '',
            entry_fee_tickets: 0,
            duration: 0,
            end_date: new Date(),
            is_open: false,
            team_budget: 0, // Add the missing property 'team_budget'
          });
          // console.log('closing modal');
          setShowCreateLeagueModal(false);
          alert('League created successfully');
        } else {
          setCLStatus('error');
          alert('Failed to create new league, please try again');
        }
      }
    } catch (error) {
      alert(`Something went wrong: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setValue(event.target.value);
  // };

  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <div className="Tab-container Dashboard-container">
          <Row className="">
            <Col size="12">
              <div className="NFTPageTitle">
                <div className="NFTPageIntro">
                  <h3 style={{ fontFamily: 'DM Sans', fontSize: '20px' }}>
                    {' '}
                    Manage Games from this dashboard.
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col size="12" md="4" lg="4">
              <div
                className="home-dashboard-info home-link mb-md-0"
                onClick={() => {
                  setShowCreateLeagueModal(true);
                }}
              >
                <div className="icon-name">
                  <div className="home-dashboard-icon-box">
                    <img
                      className="home-vector"
                      src="/icons/playercards-vector.svg"
                      alt={'Player Cards (Coming Soon)'}
                    />
                  </div>
                  <h2>Create Athstat League </h2>
                </div>
                <p>Create a new athstat league for upcoming competitions.</p>
              </div>
              <div>
                <Modal
                  show={showCreateLeagueModal}
                  onHide={() => {
                    setCLStatus('none');
                    setShowCreateLeagueModal(false);
                  }}
                  centered
                >
                  <Modal.Header closeButton className="cl-modal-header">
                    <Modal.Title>Create League</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <div className="form-group">
                        <label htmlFor="title">League Title</label>
                        <input
                          type="text"
                          className="form-control"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          id="title"
                          placeholder="Enter league title"
                          value={leagueData.title}
                          onChange={(e) =>
                            setLeagueData({
                              ...leagueData,
                              title: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="league_type">League Type</label>
                        <select
                          className="form-control"
                          id="league_type"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          value={'Athstat'}
                        >
                          <option value="Athstat">Athstat</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="user_limit">User Limit</label>
                        <input
                          type="number"
                          className="form-control"
                          id="user_limit"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          placeholder="Enter user limit"
                          value={
                            leagueData.user_limit === 0
                              ? ''
                              : leagueData.user_limit
                          }
                          onChange={(e) =>
                            setLeagueData({
                              ...leagueData,
                              user_limit:
                                e.target.value !== '' ? +e.target.value : 0,
                            })
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="prize_desc">Prize Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="prize_desc"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          placeholder="Enter prize description"
                          value={leagueData.prize_desc}
                          onChange={(e) =>
                            setLeagueData({
                              ...leagueData,
                              prize_desc: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="reward_type">Reward Type</label>
                        <select
                          className="form-control"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          id="reward_type"
                          value={rewardType}
                          onChange={(e) => {
                            setRewardType(e.target.value);
                            setLeagueData({
                              ...leagueData,
                              reward_type: e.target.value,
                            });
                          }}
                        >
                          <option value="Cash">Cash</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="entry_fee_tickets">
                          Entry Fee Tickets
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="entry_fee_tickets"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          placeholder="Enter entry fee tickets (Leave blank if 0)"
                          value={
                            leagueData.entry_fee_tickets === 0
                              ? ''
                              : leagueData.entry_fee_tickets
                          }
                          onChange={(e) =>
                            setLeagueData({
                              ...leagueData,
                              entry_fee_tickets:
                                e.target.value !== '' ? +e.target.value : 0,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="end_date">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          style={{ color: 'white', backgroundColor: '#17181c' }}
                          value={
                            leagueData.end_date?.toISOString().split('T')[0]
                          }
                          onChange={(e) =>
                            setLeagueData({
                              ...leagueData,
                              end_date: new Date(e.target.value),
                            })
                          }
                        />
                      </div>
                    </form>
                  </Modal.Body>
                  <Modal.Footer className="cl-modal-header">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCLStatus('none');
                        setShowCreateLeagueModal(false);
                      }}
                    >
                      Close
                    </Button>
                    {CLStatus === 'none' && (
                      <Button variant="primary" onClick={createLeague}>
                        Create
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
            <Col size="12" md="4" lg="4">
              <Link
                to="/auth/games-dash/fantasy/admin/leagues"
                style={{ textDecoration: 'none' }}
              >
                <div className="home-dashboard-info home-link mb-md-0">
                  <div className="icon-name">
                    <div className="home-dashboard-icon-box">
                      <img
                        className="home-vector"
                        src="/icons/playercards-vector.svg"
                        alt={'Player Cards (Coming Soon)'}
                      />
                    </div>
                    <h2>See All Leagues </h2>
                  </div>
                  <p>See all user and Athstat Leagues.</p>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};
export default AdminGamesDashboard;
