/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import axios from 'axios';
import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Keycloak from 'keycloak-js';
// import UsaStates from 'usa-states';

interface DecodedToken {
  sub: string;
  aud: string;
  exp: number;
  iat: number;
}

const SignUpWizard: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_TOKEN_URL;
  const clientId = process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_KEYCLOAK_DEV_CLIENT_SECRET;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobMonths, setDobMonths] = useState('January');
  const [dobYear, setDobYear] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [ssnLast4, setSsnLast4] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [clubName, setClubName] = useState('');
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const auth = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const [ageBlock, setAgeBlock] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMonthChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthNumber = monthNames.indexOf(event.target.value as string) + 1;
    if (monthNumber) {
      setDobMonths(event.target.value as string);
      setDobMonth(
        monthNumber.toString() // < 10 ? `0${monthNumber}` : monthNumber.toString()
      );
    }
  };

  const handleSubmit = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_DATAHUB_URL}/unauth/create-games-user/`,
          {
            user: {
              username: email,
              enabled: true,
              createdTimestamp: Date.now(),
              emailVerified: false,
              firstName: firstName,
              lastName: lastName,
              email: email,
              credentials: [
                {
                  type: 'password',
                  value: password,
                  temporary: false,
                },
              ],
              attributes: {
                terms_and_conditions: [Math.random()],
              },
            },
            dbUser: {
              email: email,
              firstName: firstName,
              lastName: lastName,
              kcSynced: false,
              hidden: false,
              middleInitial: '',
              age: '0',
              sex: '',
              height: '0',
              weight: '0',
              nftCustomerAgreement: false,
              gamesOnboardingComplete: false,
              address: address,
              usState: state,
              city: city,
              zip: zip,
            },
            clubName: 'Trappist', // Set the club name directly
          },
          {
            headers: {
              'Content-Type': 'application/json', // Use JSON content type
            },
          }
        )
        .then(async (response) => {
          console.log('User created successfully:', response.data);
          auth.signinRedirect();
        });
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleSubmitAge = async () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns a zero-based value (where zero indicates the first month)
    const currentDay = new Date().getDate();

    let age = currentYear - parseInt(dobYear);
    if (
      currentMonth < parseInt(dobMonth) ||
      (currentMonth === parseInt(dobMonth) && currentDay < parseInt(dobDay))
    ) {
      age--;
    }

    console.log('Their age: ', age);

    if (age >= 18) {
      setStep(step + 1);
    } else {
      setAgeBlock(true);
      // Block the user
      //alert('You must be at least 18 years old to sign up.');
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
    // if (step === 6) {
    //   // Assuming step 7 is the final step
    //   if (password !== confirmPassword) {
    //     console.error('Passwords do not match.');
    //     return;
    //   }
    //   if (!keycloakUrl || !clientId || !clientSecret) {
    //     console.error('Required environment variables are not defined.');
    //     return;
    //   }
    //   // Define the payload
    //   const payload = new URLSearchParams();
    //   payload.append('client_id', clientId);
    //   payload.append('client_secret', clientSecret);
    //   payload.append('grant_type', 'client_credentials');
    //   // Obtain an access token
    //   axios({
    //     method: 'post',
    //     url: keycloakUrl,
    //     data: payload,
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   })
    //     .then((response) => {
    //       const token = response.data.access_token;
    //       console.log('Access token:', token);
    //       const decodedToken = jwt_decode(token) as DecodedToken;
    //       console.log('Decoded token:', decodedToken);
    //       const newUser = {
    //         username: email,
    //         email: email,
    //         firstName: firstName,
    //         lastName: lastName,
    //         enabled: true,
    //         credentials: [
    //           {
    //             type: 'password',
    //             value: password,
    //             temporary: false,
    //           },
    //         ],
    //       };
    //       axios({
    //         method: 'post',
    //         url: `https://keycloak-sandbox.athstat-next.com/auth/admin/realms/athstat-games/users`,
    //         data: newUser,
    //         headers: {
    //           'Content-Type': 'application/json',
    //           Authorization: `Bearer ${token}`,
    //         },
    //       })
    //         .then(async (response) => {
    //           console.log('User created successfully:', response.data);
    //           console.log('User ID:', response.data.id);
    //         })
    //         .catch((error) => {
    //           console.error('Error creating user:', error);
    //         });
    //       // Check if a user with the email already exists
    //       //   axios({
    //       //     method: 'get',
    //       //     url: `https://keycloak-sandbox.athstat-next.com/auth/admin/realms/athstat-games/users`,
    //       //     params: {
    //       //       email: email,
    //       //     },
    //       //     headers: {
    //       //       Authorization: `Bearer ${token}`,
    //       //     },
    //       //   })
    //       //     .then((response) => {
    //       //       if (response.data && response.data.length > 0) {
    //       //         console.error('A user with this email already exists.');
    //       //         return;
    //       //       }
    //       //       const newUser = {
    //       //         username: email,
    //       //         email: email,
    //       //         firstName: firstName,
    //       //         lastName: lastName,
    //       //         enabled: true,
    //       //         credentials: [
    //       //           {
    //       //             type: 'password',
    //       //             value: password,
    //       //             temporary: false,
    //       //           },
    //       //         ],
    //       //       };
    //       //       axios({
    //       //         method: 'post',
    //       //         url: `https://keycloak-sandbox.athstat-next.com/auth/admin/realms/athstat-games/users`,
    //       //         data: newUser,
    //       //         headers: {
    //       //           'Content-Type': 'application/json',
    //       //           Authorization: `Bearer ${token}`,
    //       //         },
    //       //       })
    //       //         .then(async (response) => {
    //       //           console.log('User created successfully:', response.data);
    //       //           console.log('User ID:', response.data.id);
    //       //           //const userId = response.data.id;
    //       //           //   const idologyData = {
    //       //           //     firstName: firstName,
    //       //           //     lastName: lastName,
    //       //           //     address: address,
    //       //           //     zip: zip,
    //       //           //     state: state,
    //       //           //     city: city,
    //       //           //     dobMonth: dobMonth,
    //       //           //     dobYear: dobYear,
    //       //           //     dobDay: dobDay,
    //       //           //     ssnLast4: ssnLast4,
    //       //           //   };
    //       //           //   try {
    //       //           //     const response = await axios({
    //       //           //       method: 'post',
    //       //           //       url: `${process.env.REACT_APP_DATAHUB_URL}/idology/${userId}`,
    //       //           //       data: idologyData,
    //       //           //       headers: {
    //       //           //         'Content-Type': 'application/json',
    //       //           //       },
    //       //           //     });
    //       //           //     console.log(
    //       //           //       'Idology data sent successfully:',
    //       //           //       response.data
    //       //           //     );
    //       //           //     setIsLoading(false);
    //       //           //     if (
    //       //           //       response.data.response['summary-result'].message ===
    //       //           //       'PASS'
    //       //           //     ) {
    //       //           //       setMessage('Verified');
    //       //           //       // Update user's verified state to 'verified'
    //       //           //       await axios({
    //       //           //         method: 'put',
    //       //           //         url: `${process.env.REACT_APP_DATAHUB_URL}/users/${userId}`,
    //       //           //         data: { verified: 'verified' },
    //       //           //         headers: {
    //       //           //           'Content-Type': 'application/json',
    //       //           //         },
    //       //           //       });
    //       //           //     } else {
    //       //           //       setMessage('UH OH');
    //       //           //       // Update user's verified state to 'pending'
    //       //           //       await axios({
    //       //           //         method: 'put',
    //       //           //         url: `${process.env.REACT_APP_DATAHUB_URL}/users/${userId}`,
    //       //           //         data: { verified: 'pending' },
    //       //           //         headers: {
    //       //           //           'Content-Type': 'application/json',
    //       //           //         },
    //       //           //       });
    //       //           //     }
    //       //           //   } catch (error) {
    //       //           //     console.error('Error sending Idology data:', error);
    //       //           //     setIsLoading(false);
    //       //           //     setMessage('UH OH');
    //       //           //   }
    //       //         })
    //       //         .catch((error) => {
    //       //           console.error('Error creating user:', error);
    //       //         });
    //       //     })
    //       //     .catch((error) => {
    //       //       console.error('Error checking if user exists:', error);
    //       //     });
    //     })
    //     .catch((error) => {
    //       console.error('Error obtaining access token:', error);
    //     });
    // } else {
    //setStep(step + 1);
    //}
  };

  return (
    <div className="sign-up">
      <div className="sign-up-header">
        <img
          src="/icons/Athstat-header.svg"
          alt="Athstat Logo"
          className="athstat-header-logo"
        />
      </div>
      {isLoading ? (
        <div className="centered-content">
          <div className="sign-up-box">
            <div>Validating...</div>
          </div>
        </div>
      ) : message ? (
        <div className="centered-content">
          <div className="sign-up-box">
            <div>{message}</div>
          </div>
        </div>
      ) : (
        <>
          {step === 1 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1 className="verification-header">Enter your email</h1>
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  className="verification-long-input"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => navigate('/')}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1 className="verification-header">Enter your name</h1>
                <input
                  type="text"
                  name="firstName"
                  style={{ marginTop: '-10px' }}
                  value={firstName}
                  placeholder="First Name"
                  className="verification-long-input"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />{' '}
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  style={{ marginTop: '-20px' }}
                  placeholder="Last Name"
                  className="verification-long-input"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />{' '}
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {ageBlock ? (
                  <>
                    <h1 className="age-block-header ">Not Allowed</h1>
                    <h1
                      className="verification-p"
                      style={{ textAlign: 'center', marginTop: -10 }}
                    >
                      You must be 18 years old or older to sign up
                    </h1>
                    <div className="verification-buttons">
                      <button
                        className="verification-back"
                        onClick={() => navigate('/')}
                      >
                        Back to homepage
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1
                      className="verification-header"
                      style={{ marginLeft: '-35px' }}
                    >
                      Enter your date of birth
                    </h1>

                    <select
                      name="dobMonth"
                      value={dobMonths}
                      style={{ marginTop: '10px', marginLeft: '-20px' }}
                      className="long-verification-select"
                      onChange={(e) => {
                        handleMonthChange(e);
                      }}
                      required
                    >
                      {[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ].map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '100%',
                        marginBottom: '30px',
                        marginLeft: '-20px',
                      }}
                    >
                      <select
                        name="dobDay"
                        className="short-verification-select"
                        value={dobDay}
                        onChange={(e) => setDobDay(e.target.value)}
                        required
                      >
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <option
                              key={day}
                              value={day < 10 ? `0${day}` : day}
                            >
                              {day < 10 ? `0${day}` : day}
                            </option>
                          )
                        )}
                      </select>
                      <select
                        name="dobYear"
                        value={dobYear}
                        className="short-verification-select-2"
                        onChange={(e) => setDobYear(e.target.value)}
                        required
                      >
                        {Array.from({ length: 125 }, (_, i) => 2024 - i).map(
                          (year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className="verification-buttons">
                      <button
                        className="verification-back"
                        onClick={() => setStep(step - 1)}
                      >
                        Back
                      </button>
                      <button
                        className="verification-next"
                        onClick={handleSubmitAge}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-75px' }}
                >
                  Enter your address
                </h1>
                <input
                  type="text"
                  name="address"
                  placeholder="Street Address"
                  value={address}
                  style={{ marginTop: '15px' }}
                  className="verification-long-input"
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />{' '}
                <input
                  type="text"
                  name="city"
                  className="verification-long-input"
                  placeholder="City"
                  style={{ marginTop: '-40px' }}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <div className="short-input-group">
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    className="verification-short-input"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    maxLength={2}
                    required
                  />
                  <input
                    type="text"
                    name="zip"
                    placeholder="Zip"
                    className="verification-short-input"
                    maxLength={5}
                    onChange={(e) => setZip(e.target.value)}
                    required
                  />{' '}
                </div>
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {step === 5 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-20px' }}
                >
                  Enter last four of your SSN
                </h1>
                <div className="ssn-div">
                  <img
                    data-tip="State law requires us to confirm user identity through the use of certain identification factors."
                    src="/icons/information.png"
                    alt="Help icon"
                    style={{ marginLeft: '5px', width: '20px', height: '20px' }}
                  />
                  <ReactTooltip place="top" type="dark" effect="float" />
                  <h1 className="ssn-description"> Why do you need this?</h1>
                </div>

                <input
                  type="text"
                  name="ssnLast4"
                  value={ssnLast4}
                  className="verification-long-input"
                  placeholder="Last 4 SSN"
                  onChange={(e) => setSsnLast4(e.target.value)}
                  required
                />

                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )} */}
          {step === 5 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-100px' }}
                >
                  {' '}
                  Create your password
                </h1>

                <input
                  type="password"
                  name="password"
                  className="verification-long-input"
                  placeholder="Password"
                  style={{ marginTop: '20px' }}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                <input
                  type="password"
                  name="confirmPassword"
                  className="verification-long-input"
                  placeholder="Confirm Password"
                  style={{ marginTop: '-40px' }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    marginTop: '-30px',
                    marginLeft: '40px',
                    alignSelf: 'flex-start',
                  }}
                  className="password-display"
                >
                  <img
                    src={
                      password.length < 8
                        ? '/icons/no-match.svg'
                        : '/icons/pw-match.svg'
                    }
                    alt="icon"
                    className="password-icon"
                    style={{ marginRight: '5px', marginLeft: '5px' }}
                  />
                  <div className="password-desc">
                    Password must be at least 8 characters long
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    marginTop: '-20px',
                    marginLeft: '40px',
                    alignSelf: 'flex-start',
                  }}
                  className="password-display"
                >
                  <img
                    src={
                      password &&
                      confirmPassword &&
                      password === confirmPassword
                        ? '/icons/pw-match.svg'
                        : '/icons/no-match.svg'
                    }
                    alt="icon"
                    className="password-icon"
                    style={{ marginRight: '5px', marginLeft: '5px' }}
                  />
                  <div className="password-desc">Passwords must match</div>
                </div>

                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 6 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-20px' }}
                >
                  Enter Club Name
                </h1>
                <input
                  type="text"
                  name="ssnLast4"
                  value={clubName}
                  className="verification-long-input"
                  placeholder="Enter Club name"
                  onChange={(e) => setClubName(e.target.value)}
                  required
                />

                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 7 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-100px' }}
                >
                  {' '}
                  Terms & Conditions
                </h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '310px',
                    marginTop: '10px',
                  }}
                >
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    style={{ marginTop: '5px' }}
                    onChange={handleInputChange}
                  />
                  <h1
                    className="verification-terms"
                    style={{ marginLeft: '10px' }}
                  >
                    By checking this box, you agree to ATHSTAT’s{' '}
                    <Link className="terms-links" to="/games-terms">
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link className="terms-links" to="/privacy-policy">
                      Privacy Policy
                    </Link>{' '}
                    , and also confirm that you are of legal age to participate
                    in ATHSTAT’s games. Read more about ATHSTAT’s responsible
                    gaming policy{' '}
                    <Link className="terms-links" to="/responsible-gaming">
                      here
                    </Link>{' '}
                  </h1>
                </div>
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button className="verification-next" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignUpWizard;
