/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import SportsSelection from 'components/SportsSelection/SportsSelection';
import PlayerDisplay from 'components/Players/PlayerDisplay';
// import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
export interface Team {
  id?: number;
  name: string;
  club_id: number;
  official_league_id: string;
  created_date: string;
  team_value: number;
  balance: number;
  round_score: number;
  transfers_remaining: number;
  transfer_penalties: number;
}

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}

interface SelectedSport {
  id: string;
  name: string;
  games_supported: boolean;
}

const TeamDisplay: React.FC = () => {
  // const [showUpcoming, setShowUpcoming] = useState(true);
  const [currentPage] = useState(1);
  // const [setSortCriteria] = useState<string | null>(null);
  const matchesPerPage = 10;
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isSearchUsed, setIsSearchUsed] = useState(false);
  // const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const [teams, setTeams] = useState<Team[]>([]);
  const [, setIsLoading] = useState(false);
  const [perfoVale] = useState('positive');
  const { user } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [selectedCompetition, setSelectedCompetition] =
    useState<SelectedCompetition | null>(null);

  const [selectedSport, setSelectedSport] = useState<SelectedSport | null>(
    null
  );

  // // Function to sort teams by different criteria
  // const sortTeams = (criteria: string) => {
  //   const sortedTeams = [...teams];

  //   if (criteria === 'alphabetical') {
  //     sortedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
  //   } else if (criteria === 'lowToHighAvgNumbers') {
  //     sortedTeams.sort((a, b) => a.numberPlayers - b.numberPlayers);
  //   } else if (criteria === 'lowToHighAvgBalance') {
  //     sortedTeams.sort(
  //       (a, b) =>
  //         parseFloat(a.totalValue.replace(',', '')) -
  //         parseFloat(b.totalValue.replace(',', ''))
  //     );
  //   } else if (criteria === 'highToLowAvgBalance') {
  //     sortedTeams.sort(
  //       (a, b) =>
  //         parseFloat(b.totalValue.replace(',', '')) -
  //         parseFloat(a.totalValue.replace(',', ''))
  //     );
  //   }

  //   setSortCriteria(criteria);
  //   setTeams(sortedTeams);
  // };

  // // Function to switch to upcoming matches
  // const switchToUpcoming = () => {
  //   setShowUpcoming(true);
  //   setCurrentPage(1);
  //   sortTeams(sortCriteria || 'alphabetical'); // Apply the current sorting criteria
  // };

  // // Function to switch to previous matches
  // const switchToPrevious = () => {
  //   setShowUpcoming(false);
  //   setCurrentPage(1);
  //   sortTeams(sortCriteria || 'alphabetical'); // Apply the current sorting criteria
  // };

  // const handleSearchBar = (search: string | null) => {
  //   setIsSearchUsed(true);
  //   if (search) {
  //     const filteredTeams = dummyTeams.filter((team) =>
  //       team.teamName.toLowerCase().includes(search.toLowerCase())
  //     );
  //     setTeams(filteredTeams);
  //   } else {
  //     setTeams(dummyTeams);
  //   }
  // };
  useEffect(() => {
    // This code will run whenever club_id or official_league_id changes

    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATAHUB_URL}/fantasy-teams/${userId}/${selectedCompetition?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // const filteredTeams = response.data.filter(
        //   (team: Team) => team.official_league_id === official_league_id
        // );
        setTeams(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [selectedCompetition]);

  // Paginate matches based on the current page
  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentTeams = teams.slice(indexOfFirstMatch, indexOfLastMatch);

  // Logic to render page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(teams.length / matchesPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleCompetitionSelect = (competition: SelectedCompetition): void => {
    setSelectedCompetition(competition);
  };

  const handleSportSelect = (sport: SelectedSport): void => {
    setSelectedSport(sport);
  };

  const handleRowClick = (teamId: string) => {
    // navigate(`/auth/games-dash/fantasy/leagueinfo${leagueId}`);

    navigate({
      pathname: `/auth/games-dash/fantasy/manageteam`,
      search: `?id=${teamId}`,
    });
  };
  return (
    <section className="games-home-page">
      <div>
        <SportsSelection
          onCompetitionSelect={handleCompetitionSelect}
          selectedCompetition={selectedCompetition}
          onSportSelect={handleSportSelect}
          selectedSport={selectedSport}
        />
      </div>
      <div className="matches-container">
        <h1
          className="match-container-title"
          style={{ marginBottom: '20px', marginTop: '20px' }}
        >
          Manage Teams
        </h1>
        {/* <div className="tab-filtering">
          <div className="player-search-sort">
            <Autocomplete
              style={{
                width: '433px',
                height: '15px',
                color: '#fff',
                background: '#212121',
                marginBottom: '40px',
                marginTop: '20px',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              freeSolo
              options={dummyTeams.map((team) => team.teamName || '')}
              value={searchValue}
              onChange={(_event, selectedValue) =>
                handleSearchBar(selectedValue)
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <div
                  ref={params.InputProps.ref}
                  className="autocomplete-container"
                >
                  <input
                    {...params.inputProps}
                    placeholder={'Search Teams'}
                    type="text"
                    className=" player-input search-text player-auto"
                    id="free-solo-demo"
                  />
                </div>
              )}
            />
            <div className="dropdown">
              <button
                className="player-dropdown"
                type="button"
                id="sortDropdown"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <h1 className="sort-text">Sort By</h1>
                <img src={'/icons/games-down-arrow.svg'} alt="Sort By" />
              </button>
              {isDropdownOpen && (
                <div
                  className="dropdown-menu show"
                  aria-labelledby="sortDropdown"
                >
                  <div
                    className="dropdown-item"
                    onClick={() => sortTeams('alphabetical')}
                  >
                    Alphabetical
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortTeams('lowToHighAvgNumbers')}
                  >
                    Avg Numbers (Low to High)
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortTeams('lowToHighAvgBalance')}
                  >
                    Avg Balance (Low to High)
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => sortTeams('highToLowAvgBalance')}
                  >
                    Avg Balance (High to Low)
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}

        {/* Display the current matches on the selected page */}
        <div className="teams-table-div ">
          <table className="teams-table" style={{ width: '1000px' }}>
            <thead>
              <tr className="team-table-head">
                <th className="team-table-head-name">Team Name</th>
                <th className="team-table-head-name">Number of Players</th>
                <th className="team-table-head-name">Total Value</th>
                <th className="team-table-head-name">Performance</th>
              </tr>
            </thead>
            <tbody className="teams-body">
              {teams.map((team) => (
                <tr
                  className="team-row"
                  key={team.id}
                  onClick={() => {
                    if (team.id !== undefined) {
                      handleRowClick(team.id.toString());
                    }
                  }}
                >
                  <td className="team-name-row" style={{ width: '500px' }}>
                    {team.name}
                  </td>
                  <td className="team-numbers" style={{ width: '100px' }}>
                    {'12'}
                  </td>
                  <td
                    className="team-total-value"
                    style={{ marginLeft: '50px' }}
                  >
                    <img src="/icons/value.svg" />
                    <h1 className="totalValue">{team.team_value}</h1>
                  </td>
                  <td
                    style={{ marginLeft: '90px' }}
                    className="team-perfomance"
                  >
                    {perfoVale === 'positive' ? (
                      <img src="/icons/positive.svg" />
                    ) : (
                      <img src="/icons/negative.svg" />
                    )}
                  </td>
                  {/* <td>
                    <img
                      style={{ marginLeft: '40px', marginRight: '-100px' }}
                      src="/icons/table-arrow.svg"
                    />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination
        <div className="pagination-teams">
          <button
            className="pagination-button-group"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src="/icons/arrow-left.svg" alt="Previous" />
            <h1 className="pagination-text">Previous</h1>
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className={`pagination-button ${
                currentPage === number ? 'active' : ''
              }`}
            >
              <h1 className="pagination-text">{number}</h1>
            </button>
          ))}
          <button
            className="pagination-button-group"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(teams.length / matchesPerPage)}
          >
            <h1 className="pagination-text">Next</h1>
            <img src="/icons/arrow-right.svg" alt="Next" />
          </button>
        </div> */}
      </div>
      <PlayerDisplay selectedCompetition={selectedCompetition} />
    </section>
  );
};

export default TeamDisplay;
