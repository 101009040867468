/* eslint-disable no-console */
import axios from 'axios';
import {
  AthstatLeague,
  IFantasyAthlete,
  IFantasyLeagueTeam,
  IFantasyTeam,
  IFantasyTeamAthlete,
  IFantasyTransfer,
  ILeagueJoinedTable,
  IPGGame,
  ISportActions,
  IUserLeague,
} from '../../../d';

export interface IResponse<T> {
  data?: T;
  type: string;
  message?: string;
}

// export const createFantasyTransfer = async ({
//   transfer,
//   token,
// }: {
//   transfer: IFantasyTransfer;
//   token: string;
// }): Promise<IFantasyAthlete> => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_DATAHUB_URL}/fantasy-teams/transfers`,
//       transfer,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (response.status === 200) {
//       return response.data;
//     } else {
//       throw new Error('Error updating team');
//     }
//   } catch (error) {
//     throw new Error('Error updating team');
//   }
// };

export const createTeamAthlete = async ({
  athlete,
  token,
}: {
  athlete: IFantasyTeamAthlete;
  token: string;
}): Promise<IFantasyAthlete> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fantasy-athletes/athletes`,
      athlete,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error updating team!');
    }
  } catch (error) {
    throw new Error('Error updating team');
  }
};

export const postFantasyTeam = async ({
  athletes,
  token,
}: {
  athletes: [number, string, number, string, string, number][];
  token: string;
}): Promise<IFantasyTeamAthlete[]> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fantasy-athletes/athletes`,
      athletes,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status < 400) {
      return response.data;
    } else {
      throw new Error('Error updating team');
    }
  } catch (error) {
    throw new Error('Error updating team');
  }
};

export const updateFantasyTeamAthletes = async ({
  athletes,
  token,
}: {
  athletes: IFantasyTeamAthlete[];
  token: string;
}): Promise<IFantasyAthlete> => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/fantasy-athletes/athletes`,
      athletes,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error updating team');
    }
  } catch (error) {
    throw new Error('Error updating team');
  }
};

export const getTransfersByTeamId = async ({
  id,
  token,
}: {
  id: number;
  token: string;
}): Promise<IFantasyTransfer[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-teams/transfers/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error updating team');
    }
  } catch (error) {
    throw new Error('Error updating team');
  }
};

export const getAthleteById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<IFantasyAthlete | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/athletes/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data[0];
  } catch (error) {
    return null;
  }
};

export const createAthstatLeague = async ({
  league,
  token,
}: {
  league: AthstatLeague;
  token: string;
}): Promise<AthstatLeague | null> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/athstat`,
      {
        league,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getAthstatLeagues = async ({
  token,
}: {
  token: string;
}): Promise<AthstatLeague[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/fantasy-athstat-leagues/configs`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getUserLeagues = async ({
  token,
}: {
  token: string;
}): Promise<IUserLeague[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/user`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getFantasyLeagueById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<ILeagueJoinedTable | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data[0];
  } catch (error) {
    return null;
  }
};

export const joinUserLeague = async ({
  team,
  token,
}: {
  team: IFantasyLeagueTeam;
  token: string;
}): Promise<IResponse<IFantasyLeagueTeam | null>> => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/user/join-user-league`,
      team,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return {
        type: 'success',
        message: 'Successfully joined league',
        data: response.data,
      };
    } else {
      return {
        type: 'error',
        message: 'Error joining league',
        data: null,
      };
    }
  } catch (error) {
    return {
      type: 'error',
      message: 'error joining league',
      data: null,
    };
  }
};

export const createUserLeague = async ({
  team,
  league,
  token,
}: {
  team: IFantasyTeam;
  league: IUserLeague;
  token: string;
}): Promise<string> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/user`,
      {
        league,
        team,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};

export const getFantasyTeamAthletes = async ({
  id,
  token,
}: {
  id: number;
  token: string;
}): Promise<IFantasyAthlete[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-athletes/athletes/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getAllAthletes = async ({
  token,
}: {
  token: string;
}): Promise<IFantasyAthlete[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-athletes/leagues`,
      {
        params: { id: 'f14aca9d-f746-431d-8e5e-9db6c311ec69' },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const getGameById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<IPGGame | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/postgres/entities/games-distinct/${id}`,
      {
        params: { id: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data[0];
  } catch (error) {
    return null;
  }
};

export const getSportsActionsByGameId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<Array<ISportActions> | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/sports-actions/games/${id}`,
      {
        params: { match_id: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGamesByDate = async ({
  date,
  token,
  competitionId,
}: {
  date: string;
  token: string;
  competitionId: string;
}): Promise<IPGGame[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/entities/games-date/${date}/${competitionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getUpcomingGames = async ({
  token,
  competitionId,
}: {
  token: string;
  competitionId: string;
}): Promise<IPGGame[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/entities/games-upcoming/${competitionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const updateFantasyTeam = async ({
  team,
  token,
}: {
  team: IFantasyTeam;
  token: string;
}): Promise<IFantasyTeam[]> => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/fantasy-teams`,
      team,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error updating team');
    }
  } catch (error) {
    throw new Error('Error updating team');
  }
};

export const deleteFantasyTeamById = async ({
  id,
  token,
}: {
  id: number;
  token: string;
}): Promise<string> => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/fantasy-teams/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return 'ok';
    } else {
      return 'error';
    }
  } catch (error) {
    return 'error';
  }
};

export const getFantasyTeamByUserId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<IFantasyTeam | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-teams/user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.data[0]) {
      console.log(`User ${id} has no team.`);
    }
    return response.data[0];
  } catch (error) {
    console.log(`Error fetching team for user ${id}:`, error);
    return null;
  }
};

export const getFantasyLeaguesByTeamId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<IFantasyLeagueTeam[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/teams/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getFantasyLeagueTeamsByLeagueId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<IFantasyLeagueTeam[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/teams/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const createFantasyteam = async ({
  team,
  token,
}: {
  team: IFantasyTeam;
  token: string;
}): Promise<IFantasyTeam | null> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fantasy-teams`,
      team,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const removeFromLeague = async ({
  team,
  token,
}: {
  team: IFantasyLeagueTeam;
  token: string;
}): Promise<void> => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/entities/teams`,
      {
        params: { teamid: team },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export const getTransfersStatus = async ({
  token,
}: {
  token: string;
}): Promise<boolean> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fantasy/admin/status`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data[0].transfers_activated;
  } catch (error) {
    return false;
  }
};

export const joinLeagueByEnteredCode = async ({
  team,
  code,
  token,
}: {
  team: IFantasyTeam;
  code: string;
  token: string;
}): Promise<string> => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/fantasy-leagues/user/join-league-code`,
      { team, code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return 'ok';
    } else if (response.status === 404) {
      return 'League not found';
    } else {
      return response.status.toString();
    }
  } catch (error) {
    throw new Error('Error joining league');
  }
};
