/* eslint-disable @typescript-eslint/ban-ts-comment */

// MatchListItem.tsx
import React from 'react';

interface SmallMatchListItemProps {
  homeTeamImage: string;
  homeTeamName: string;
  awayTeamImage: string;
  awayTeamName: string;
  location: string | null;
  targetTime: string;
}

const SmallMatchListItem: React.FC<SmallMatchListItemProps> = ({
  // homeTeamImage,
  homeTeamName,
  // awayTeamImage,
  awayTeamName,
  location,
  //   targetTime,
}) => {
  return (
    <div className="match-list-item-small-item">
      <span className="small-location-match">{location}</span>
      <div className="small-teams-display">
        <div className="modal-team-left">
          {/* <img src={homeTeamImage} alt={homeTeamName} /> */}
          <span className="modal-team-name">{homeTeamName}</span>
        </div>
        <h1 className="modal-score">-</h1>
        <span className="modal-vs">VS</span>
        <h1 className="modal-score">-</h1>

        <div className="modal-team-right">
          <span className="modal-team-name">{awayTeamName}</span>
          {/* <img src={awayTeamImage} alt={awayTeamName} /> */}
        </div>
      </div>
    </div>
  );
};

export default SmallMatchListItem;
