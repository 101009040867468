import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div className="PageTitle">
              <h2>Athstat Privacy Policy</h2>
            </div>
          </Col>
        </Row>
        <h3 className="privacy-policy-paragraph">
          This Privacy Policy describes how Athstat collects, holds, processes
          use, and discloses, your Personal Data (defined below) when you use
          our public websites or access our services. Athstat is a wholly owned
          entity of NU Borders LLC, who own the legal rights to all aspects of
          the Athstat Product and are the legal entity owning the product. For
          the purposes of this agreement, Athstat is the referenced party for
          whom the Privacy Policy described herein applies, operating as a
          legally owned entity of NU Borders LLC. For purposes of applicable
          data protection legislation, NU Borders LLC, and therefore Athstat,
          are located at 1037 Pleasant St. Bridgewater MA 02324. By providing
          your Personal Data to Athstat, you agree that you are authorized to
          provide that information and are accepting this Privacy Policy and any
          supplementary privacy statement that may be relevant to you. If you do
          not agree to our practices, please do not register, subscribe, create
          an account, or otherwise interact with our websites and services.
        </h3>
        <h2 className="privacy-policy-header">What is “Personal Data”?</h2>
        <h3 className="privacy-policy-paragraph">
          The term “Personal Data” refers to information that does or is capable
          of identifying you as an individual. Personal Data may include the
          following: name, address, date of birth, and contact data (i.e. email
          address, telephone number and employer name). No sensitive information
          will be collected regarding the user. We may also collect information
          that is related to you but that does not personally identify you
          (“NonPersonal Data”). Non-Personal Data includes information that
          could personally identify you in its original form, but that we have
          modified (for instance, by aggregating, anonymizing or de-identifying
          such information) to remove or obscure any Personal Data.
        </h3>
        <h2 className="privacy-policy-header">Information Collected</h2>
        <h3 className="privacy-policy-paragraph">
          The type of information we collect or process from or about you will
          depend on how you or your organization interacts with Athstat. When
          you submit Personal Data or we collect it directly from you on our
          websites or through use of our services Athstat is the data controller
          of your Personal Data.
        </h3>
        <h3 className="privacy-policy-paragraph">
          When we receive Personal Data from customers who originate or retain
          ownership of such Personal Data, Athstat is a data processor and our
          Terms and Conditions (including any data protection agreements) with
          our Customers will apply to the processing of such Personal Data.
          Athstat may collect Technical Information about you when you visit our
          websites, which your web browser automatically sends whenever you
          visit a website on the Internet. “Technical Information” is
          information that does not, by itself, identify a specific individual
          but which could be used to indirectly identify you. Our servers
          automatically record Technical Information, which may include your
          Internet Protocol (“IP”) address, browser type, browser language, and
          the date and time of your request.
        </h3>
        <h2 className="privacy-policy-header">How We Collect Information</h2>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Website</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Athstat collects Personal Data about you when you submit information
          through our websites. Examples include requests for product
          information; registering access to the Athstat application, for
          Athstat reports, newsletters or webcasts; or entering promotions.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Email Communication</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Email Communication We use pixel tags and cookies in our marketing
          emails so that we can track your interaction with those messages, such
          as when you open the email or click a URL link that’s embedded within
          them. When recipients click on one of those URLs, they pass through a
          separate web server before arriving at the destination page on a
          Athstat website. We use tools like pixel tags and cookies so that we
          can determine interest in particular topics and measure and improve
          the effectiveness of our communications.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Log Files</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Log files record website activity on our services and enable us to
          gather statistics about our users’ browsing habits. These entries help
          Athstat determine how many and how often users have accessed or used
          our services, which pages they’ve visited, and other similar data.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Cookies and Similar Technologies</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          We may collect information about your use of the websites through
          cookies and similar technologies. A “cookie” is text that we store
          through your computer’s web browser so that we can keep track of your
          interests and/or preferences and recognize you as a return visitor to
          the websites.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Athstat Application Registration</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          We will collect information on all users when they access the
          application. This will be standard information (Name, User Name,
          Password, Team Name, Team position).
        </h3>
        <h3 className="privacy-policy-paragraph">
          Our websites use the following types of cookies for the purposes set
          out below:
        </h3>
        <table className="privacy-policy-table">
          <tr className="privacy-row">
            <th className="privacy-head-1"> Types of Cookies</th>
            <th className="privacy-head-2">Purpose</th>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Essential Cookies</td>
            <td className="privacy-info">
              These cookies are essential to provide you with services available
              through our websites and to enable you to use some of its
              features, e.g., to allow you to log in to secure areas of our
              websites. Without these cookies, the services that you have
              requested cannot be provided.
            </td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Functionality Cookies</td>
            <td className="privacy-info">
              These cookies allow our websites to remember choices you make when
              you use our website, such as remembering your user preferences.
              The purpose of these cookies is to provide you with a more
              personal experience and to avoid you having to re-enter your
              preferences every time you visit our website
            </td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">
              Analytics and Performance Cookies
            </td>
            <td className="privacy-info">
              These cookies are used to collect information about traffic to our
              website. The information gathered does not identify any individual
              visitor. The information is aggregated and anonymous. We use this
              information to help operate our website more efficiently, to
              gather broad demographic information and to monitor the level of
              activity on our website. We may use Google Analytics and other
              third party analytics services for this purpose. Google Analytics
              uses its own cookies. It is only used to improve how our website
              works. You can find out more information about Google Analytics
              cookies here:
              <a>
                https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
              </a>
              . You can find out more about how Google protects your data here:
              <a>www.google.com/analytics/learn/privacy.html</a>. You can
              prevent the use of Google Analytics relating to your use of our
              website by downloading and installing the browser plugin available
              via this link:{' '}
              <a>http://tools.google.com/dlpage/gaoptout?hl=en-GB</a>.
            </td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Social Media Cookies</td>
            <td className="privacy-info">
              These cookies are used when you share information using a social
              media sharing button or “like” button on our website or you link
              your account or engage with our content on or through a social
              networking website such as Facebook, Twitter, or Google+. The
              social network will record that you have done this
            </td>
          </tr>
        </table>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Social Media</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Our websites may contain social media features. These features may
          collect your IP address, the pages visited on our site, and may set a
          cookie to enable the feature to function properly. Social media
          features and widgets are either hosted by a third party or hosted
          directly on our site. Your interactions with these features are
          governed by the privacy policy of the organization providing it.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Links to Other Websites</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Our websites, products or services may link to third party web
          websites, and Athstatis not responsible for any Personal Data
          collected by these third-party websites. Information collected is
          governed through the third party’s privacy policy. Any interactions
          you have with these web websites, or any services or applications they
          offer, are beyond Athstat’ contro
        </h3>
        <h2 className="privacy-policy-header">
          Purposes for Collecting Your Information
        </h2>
        <h3 className="privacy-policy-paragraph">
          We will only process your Personal Data in accordance with applicable
          data protection and privacy laws. We need certain Personal Data in
          order to provide you with access to the website or our services. If
          you registered with us, you will have been asked to check a box
          indicating your agreement to provide this data in order to access our
          services or view our content. This consent provides us with the legal
          basis we require under applicable law to process your data. You
          maintain the right to withdraw such consent at any time. If you do not
          agree to our use of your Personal Data in line with this policy,
          please do not use our website or our services. When fulfilling our
          contractual obligations to our customers (i.e., the data controllers),
          we have a legitimate interest in processing certain Personal Data for
          such contractual purposes
        </h3>
        <h2 className="privacy-policy-header">Use of Information Collected</h2>
        <h3 className="privacy-policy-paragraph">
          We may use your information:
        </h3>
        <ul className="privacy-list">
          <li className="privacy-list-item">
            {' '}
            - To provide you with personalized content;
          </li>
          <li className="privacy-list-item">
            {' '}
            - To process and respond to inquiries;
          </li>
          <li className="privacy-list-item">
            {' '}
            - For the purposes for which you provided the information;
          </li>
          <li className="privacy-list-item">
            {' '}
            - To improve the content and navigability of our website; and
          </li>
          <li className="privacy-list-item">
            {' '}
            - To alert you about new features, special events and important
            announcements
          </li>
        </ul>
        <h3 className="privacy-policy-paragraph">
          We may use Non-Personal Data for any reason and may share such
          information freely with third parties.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Athstat gives you choices about the ways we collect, use, and share
          your Personal Data. You can choose what contact will be stored in your
          account and preferences. However, if you choose not to provide certain
          details, some of your experiences with us may be affected.
        </h3>
        <h3 className="privacy-policy-paragraph">
          We do not use your Personal Data for the purposes of automated
          decision-making. However, we may do so in order to fulfill obligations
          imposed by law, in which case we will inform you of any such
          processing and provide you with an opportunity to object.
        </h3>
        <h3 className="privacy-policy-paragraph">
          If you would like to know what Personal Data, we hold about you, you
          may submit a request to us by using info@athstat.com . We will supply
          Personal Data about you that we hold in our own files within the
          reasonable timeframes stipulated by law. Please note that some
          requests may be subject to a reasonable fee.
        </h3>
        <h2 className="privacy-policy-header">
          Sharing of Information Collected
        </h2>
        <h3 className="privacy-policy-paragraph">
          Athstat will not rent or sell your Personal Data to others but may
          disclose Personal Data with contracted third-party vendors and service
          providers (including cloud service providers) that work with Athstat
          and are contractually bound by confidentiality obligations. We will
          only share Personal Data with these vendors and service providers to
          help us provide a product or service.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Athstat primarily stores data about its customers and users in the
          United States. Customer information, including Personal Data that we
          collect from you, may be transferred to, stored at and processed by us
          and our affiliates and other third parties outside the country in
          which you reside
        </h3>
        <h3 className="privacy-policy-paragraph">
          If Athstat sells any part of its operations, Athstat may transfer
          Personal Data in connection with the sale. If a sale does occur,
          Athstat will attempt to notify you of the disclosure of your Personal
          Data.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Athstat reservesthe right to disclose information by law, litigation,
          or as a matter of national security to comply with valid legal process
          including subpoenas, court orders or search warrants, and as otherwise
          required by applicable law. We may also need to disclose Personal Data
          in the event of an emergency that threatens an individual’s life,
          health, or security.
        </h3>
        <h2 className="privacy-policy-header">Individual Rights </h2>
        <h3 className="privacy-policy-paragraph">
          Opt-out. You may contact us anytime to opt-out of: (i) direct
          marketing communications; (ii) automated decision-making and/or
          profiling; (iii) our collection of sensitive Personal Data; (iv) any
          new processing of your Personal Data that we may carry out beyond the
          original purpose; or (v) the transfer of your Personal Data. Please
          note that your use of the websites may be impacted upon opt-out.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Access. You may access the data we hold about you at any time by
          contacting us directly.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Amend. You can also contact us to update or correct any inaccuracies
          in your Personal Data.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Move. Your data is portable – i.e. you to have the flexibility to move
          your data to other service providers as you wish.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Delete. You can request that we erase your Personal Data.
        </h3>
        <h2 className="privacy-policy-header">Security</h2>
        <h3 className="privacy-policy-paragraph">
          Security of all information is of the utmost importance for Athstat.
          Athstat uses reasonable administrative, technical and physical
          safeguards to protect the security of your Personal Data from
          unauthorized disclosure. We also try to ensure that only necessary
          people and third parties have access to Personal Data. Nevertheless,
          such security measures cannot prevent all loss, misuse or alteration
          of Personal Data and we are not responsible for any damages or
          liabilities relating to any such incidents to the fullest extent
          permitted by law. Where required under law, we will notify you of any
          such loss, misuse or alteration of Personal Data that may affect you
          so that you can take the appropriate actions for the due protection of
          your rights. Athstat also reviews its security procedures periodically
          to consider appropriate new technology and updated methods
        </h3>
        <h3 className="privacy-policy-paragraph">
          We require that our third-party service providers agree to keep all
          confidential information we share with them and to use the information
          only to perform their obligations in the agreements we have in place
          with them. While we provide these third parties with no more
          information than is necessary to perform the function for which we
          engaged them, any information that you provide to these third parties
          independently is subject to their respective privacy policies and
          practices.
        </h3>
        <h2 className="privacy-policy-header">Data Retention and Storage</h2>
        <h3 className="privacy-policy-paragraph">
          Athstat retains your information for business purposes, for as long as
          your account is active, and/or as long as is reasonably necessary to
          provide you with our products and services. Athstat will also retain
          your information as reasonably necessary to comply with our legal
          obligations, resolve disputes and enforce our agreements. We may also
          retain cached or archived copies of your information for a reasonable
          period of time
        </h3>
        <h2 className="privacy-policy-header">
          Location of Data Processing/Storage
        </h2>
        <h3 className="privacy-policy-paragraph">
          Any Personal Data collected about EU (and UK and/or Switzerland)
          visitors via our websites is processed in the United States by Athstat
          or by a third party acting on our behalf. When you provide Personal
          Data to Athstat, you consent to the processing of your information in
          the United States. Our websites are hosted in the United States.
        </h3>
        <h2 className="privacy-policy-header">California Privacy Rights</h2>
        <h3 className="privacy-policy-paragraph">
          If you are a California Resident, the California Consumer Privacy Act
          of 2018 (“CCPA”) may give you certain rights relating to your personal
          information. Please visit the California Attorney General’s privacy
          laws page located here for more information. At points throughout our
          website, you may be able to provide your contact information. If you
          provide your contact information, we will maintain that information
          until you request that we delete it. If you want us to delete your
          contact information, please email us from the email address you
          provided originally. Athstat will not discriminate against you for
          exercising your privacy rights. Athstat does not sell any Personal
          Information (as defined in the CCPA) to third parties, and has not
          done so in the previous 12 months
        </h3>
        <h2 className="privacy-policy-header">Contact</h2>
        <h3 className="privacy-policy-paragraph">
          Our data protection officer is our Chief Privacy Officer. If you have
          any questions, concerns, or suggestions regarding this Privacy Policy,
          or would like to reach our data protection officer, please contact us
          by email at <a>info@athstat.com</a>. Address: 1037 Pleasant St.
          Bridgewater MA 02324
        </h3>
        <h2 className="privacy-policy-header">
          Policy Updates and Modifications
        </h2>
        <h3 className="privacy-policy-paragraph">
          Athstat may review and update this Privacy Policy related to the
          Athstat product periodically without prior notice. Athstat will post a
          notice to its main website at www.athstat.com to inform you of any
          changes to our Privacy Policy and indicate when it was most recently
          updated. Your continued use of our websites after changes have been
          posted to this Privacy Policy will constitute your acceptance of such
          changes. In the case of material changes that may adversely affect
          you, Athstat may notify you directly of changes to this Privacy Policy
        </h3>
      </section>
    </React.Fragment>
  );
};
export default PrivacyPolicy;
