/* eslint-disable no-console */
// import './common/env';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// eslint-disable-next-line no-console
console.log('Keyclock ', process.env.REACT_APP_KEYCLOAK_DEV_CLIENT_SECRET);

console.log(
  'Test variable ',
  process.env.REACT_APP_KEYCLOAK_DEV_CLIENT_SECRETS
);
const redirectUrl = process.env.REACT_APP_APP_URL;
const keycloakUrl = `${process.env.REACT_APP_AUTH_KEYCLOAK_URL}/realms/${process.env.REACT_APP_AUTH_KEYCLOAK_REALM}`;
const client_id = process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID || 'dev-games';
const oidcConfig: AuthProviderProps = {
  authority: keycloakUrl || '',
  redirect_uri: `${redirectUrl}/verify-user`,
  client_id: client_id,
  client_secret:
    process.env.REACT_APP_KEYCLOAK_DEV_CLIENT_SECRET ||
    '9cDDl1tD14Vf4KGpkOk7jjCCnfXk5Qxd',
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

root.render(
  <AuthProvider {...oidcConfig}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
