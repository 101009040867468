import React from 'react';
import { Row } from 'react-bootstrap';
import { IPackage, ITeam } from '../../../../d';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableHead } from '@mui/material';

interface SubscriptionProps {
  teamState: ITeam;
}

const ReceiptSubscriptionsTable: React.FC<SubscriptionProps> = ({
  teamState,
}: SubscriptionProps) => {
  //
  const navigate = useNavigate();

  const linkToPayment = () => {
    if (teamState) {
      navigate(`/auth/payment`);
      // query: {
      //   teamId: teamState._id,
      // },
    } else {
      navigate(`/auth/payment`);
    }
  };

  const packageName = (
    cPackageName: string
  ): { name: string; price: string } => {
    switch (cPackageName) {
      case 'pitchside_package':
        return { name: 'Pitchside', price: '$0/mo' };
      case 'data_sharing_package':
        return { name: 'Data Hub', price: '$10/mo' };
      case 'analysis_package':
        return { name: 'Analytics', price: '$49/mo' };
      case 'bundle_package':
        return { name: 'Bundle (Data Hub & Analytics)', price: '$55/mo' };
      default:
        return { name: 'Package', price: '$0/mo' };
    }
  };

  const packageExpiration = (cPackage: IPackage): string => {
    if (cPackage.id === 'pitchside_package') {
      return 'Promotion';
    } else if (cPackage.expiration) {
      return cPackage.expiration;
    } else {
      return 'N/A';
    }
  };

  const subscriptionsList = (): React.ReactNode => {
    const subscriptionNode: Array<React.ReactNode> = [];
    const bundleNode: React.ReactNode = (
      <tr key={'10000'}>
        <td>{packageName('bundle_package').name}</td>
        <td className="text-left Mo-MW-150">
          {packageName('bundle_package').price}
        </td>
        <td className="text-left Mo-MW-150">{'Inactive'}</td>
        <td className="text-left Mo-MW-150">{'No'}</td>
        <td className="text-left Mo-MW-150">{'N/A'}</td>
      </tr>
    );
    if (teamState) {
      teamState.packages.forEach((subPackage: IPackage, index: number) => {
        subscriptionNode.push(
          <tr key={index}>
            <td>{packageName(subPackage.id).name}</td>
            <td className="text-left Mo-MW-150 ">
              {packageName(subPackage.id).price}
            </td>
            <td
              className="text-left Mo-MW-150 "
              style={{ textTransform: 'capitalize' }}
            >
              {subPackage.bundled && subPackage.id !== 'bundle_package'
                ? 'Bundled'
                : subPackage.status}
            </td>
            <td className="text-left Mo-MW-150">
              {subPackage.status === 'active' ? 'Yes' : 'No'}
            </td>
            <td className="text-left Mo-MW-150">
              {packageExpiration(subPackage)}
            </td>
          </tr>
        );
      });
      const packageIndex = teamState.packages.findIndex(
        (item: IPackage) => item.id === 'bundle_package'
      );
      if (packageIndex === -1) subscriptionNode.push(bundleNode);
    }
    return subscriptionNode;
  };

  return (
    <Row style={{ marginBottom: '20px' }}>
      {/*Subscription Table*/}
      {teamState && (
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <Table className={'subscription-table-striped'}>
            <TableHead>
              <tr>
                <th className="text-left Mo-MW-130">Subscription Name</th>
                <th className="text-left Mo-MW-150">Price</th>
                <th className="text-left Mo-MW-150">Status</th>
                <th className="text-left Mo-MW-150">Reoccurring</th>
                <th className="text-left Mo-MW-150">Term End</th>
              </tr>
            </TableHead>
            <TableBody>{subscriptionsList()}</TableBody>
          </Table>

          <div style={{ float: 'right', marginTop: '3%' }}>
            <Button
              className="Theme-Btn"
              onClick={() => {
                linkToPayment();
              }}
            >
              Go To Payments
            </Button>
          </div>
        </div>
      )}
    </Row>
  );
};

export default ReceiptSubscriptionsTable;
