/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import axios from 'axios';
import React, { useEffect, useState } from 'react';

//import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  kc_id: string;
  // Add other properties as needed
}

const SignUpGames: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const [, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [, setZip] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [club, setClub] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobMonths, setDobMonths] = useState('January');
  const [dobYear, setDobYear] = useState('2024');
  const [dobDay, setDobDay] = useState('01');
  //const [ssnLast4, setSsnLast4] = useState('');
  const [isLoading] = useState(false);
  const [message] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstUser, setFirstUser] = useState<IUser | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;

  //   const [firstName, setFirstName] = useState(kcUser?.given_name || '');
  //   const [lastName, setLastName] = useState(kcUser?.family_name || '');

  const [ageBlock, setAgeBlock] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getUserData('userId', 'accessToken'); // Replace with actual values
      if (userData) {
        setFirstUser(userData);
        // setFirstName(userData.firstName);
        // setLastName(userData.lastName);
        setEmail(userData.email);
      }
    };

    fetchData();
  }, []);

  //   useEffect(() => {
  //     console.log('User: ', isAuthenticated, user);
  //     if (isAuthenticated && user) {
  //       navigate('/auth');
  //     }
  //   }, []);

  const getUserData = async (
    userId: string | undefined,
    accessToken: string
  ): Promise<IUser | null> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATAHUB_URL}/users/${userId}`,
        {
          headers: {
            Authorization: user ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const createClub = async (
    userId: string | undefined,
    accessToken: string | undefined
  ): Promise<IUser | null> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATAHUB_URL}/fantasy-teams/fantasy-clubs`,
        {
          user_id: userId,
          name: club,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBackStep = async () => {
    setStep(step - 1);
    if (step === 1) {
      navigate('/login');
    }
  };

  const handleSubmit = async () => {
    console.log('Submitting...');
    await createClub(userId, user?.access_token);
    // Update user's verified state to 'verified'
    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_DATAHUB_URL}/users/`,
      data: { kc_id: userId, state: 'verified' },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      console.log('Navigating...');
      navigate('/auth');
    });
  };

  const handleNextStep = async () => {
    setStep(step + 1);

    // if (step === 5) {
    //   const idologyData = {
    //     firstName: firstName,
    //     lastName: lastName,
    //     address: address,
    //     zip: zip,
    //     state: state,
    //     city: city,
    //     dobMonth: dobMonth,
    //     dobYear: dobYear,
    //     dobDay: dobDay,
    //     ssnLast4: ssnLast4,
    //   };
    //   try {
    //     const response = await axios({
    //       method: 'post',
    //       url: `${process.env.REACT_APP_DATAHUB_URL}/idology/${userId}`,
    //       data: idologyData,
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     });

    //     await createClub(userId, user?.access_token);

    //     setIsLoading(false);
    //     if (response.data.response['summary-result'].message === 'PASS') {
    //       setMessage('Verified');
    //       navigate('/auth');

    //       // Update user's verified state to 'verified'
    //       await axios({
    //         method: 'put',
    //         url: `${process.env.REACT_APP_DATAHUB_URL}/users/`,
    //         data: { kc_id: userId, state: 'verified' },
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //       });
    //     } else {
    //       setMessage('UH OH');
    //       navigate('/pending');

    //       // Update user's verified state to 'pending'
    //       await axios({
    //         method: 'put',
    //         url: `${process.env.REACT_APP_DATAHUB_URL}/users/`,
    //         data: { kc_id: userId, state: 'pending' },
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('Error sending Idology data:', error);
    //     setIsLoading(false);
    //     setMessage('UH OH');
    //     navigate('/pending');
    //   }
    // }
  };

  const handleYearChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedYear = event.target.value as string;
    if (selectedYear) {
      setDobYear(selectedYear);
    }
  };

  const handleDayChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedDay = event.target.value as string;
    if (selectedDay) {
      setDobDay(selectedDay);
    }
  };

  const handleMonthChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthNumber = monthNames.indexOf(event.target.value as string) + 1;
    if (monthNumber) {
      setDobMonths(event.target.value as string);
      setDobMonth(
        monthNumber.toString() // < 10 ? `0${monthNumber}` : monthNumber.toString()
      );
    }
  };

  const handleSubmitAge = async () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns a zero-based value (where zero indicates the first month)
    const currentDay = new Date().getDate();

    let age = currentYear - parseInt(dobYear);
    if (
      currentMonth < parseInt(dobMonth) ||
      (currentMonth === parseInt(dobMonth) && currentDay < parseInt(dobDay))
    ) {
      age--;
    }

    console.log('Their age: ', age);

    if (age >= 18) {
      setStep(step + 1);
    } else {
      setAgeBlock(true);
      // Block the user
      alert('You must be at least 18 years old to sign up.');
    }
  };

  //   const handleGoToHomepage = async () => {
  //     await auth.removeUser();
  //     await auth.revokeTokens();
  //     await auth.signoutRedirect();
  //     auth.
  //     //navigate('/');
  //   };

  return (
    <div className="sign-up">
      <div className="sign-up-header">
        <img
          src="/icons/Athstat-header.svg"
          alt="Athstat Logo"
          className="athstat-header-logo"
        />
      </div>
      {isLoading ? (
        <div className="centered-content">
          <div className="sign-up-box">
            <div>Validating...</div>
          </div>
        </div>
      ) : message ? (
        <div className="centered-content">
          <div className="sign-up-box">
            <div>{message}</div>
          </div>
        </div>
      ) : (
        <>
          {step === 1 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {ageBlock ? (
                  <>
                    <h1 className="age-block-header ">Not Allowed</h1>
                    <h1
                      className="verification-p"
                      style={{ textAlign: 'center', marginTop: -10 }}
                    >
                      You must be 18 years old or older to sign upz
                    </h1>
                    <div className="verification-buttons">
                      <button
                        className="verification-back"
                        //onClick={handleGoToHomepage}
                      >
                        Back to homepage
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1
                      className="verification-header"
                      style={{ marginLeft: '-35px' }}
                    >
                      Enter your date of birth
                    </h1>

                    <select
                      name="dobMonth"
                      value={dobMonths}
                      style={{ marginTop: '10px', marginLeft: '-20px' }}
                      className="long-verification-select"
                      onChange={(e) => {
                        handleMonthChange(e);
                      }}
                      required
                    >
                      {[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ].map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '100%',
                        marginBottom: '30px',
                        marginLeft: '-20px',
                      }}
                    >
                      <select
                        name="dobDay"
                        className="short-verification-select"
                        value={dobDay}
                        onChange={(e) => handleDayChange(e)}
                        required
                      >
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <option
                              key={day}
                              value={day < 10 ? `0${day}` : day}
                            >
                              {day < 10 ? `0${day}` : day}
                            </option>
                          )
                        )}
                      </select>
                      <select
                        name="dobYear"
                        value={dobYear}
                        className="short-verification-select-2"
                        onChange={(e) => handleYearChange(e)}
                        required
                      >
                        {Array.from({ length: 125 }, (_, i) => 2024 - i).map(
                          (year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className="verification-buttons">
                      <button
                        className="verification-back"
                        onClick={handleBackStep}
                      >
                        Back
                      </button>
                      <button
                        className="verification-next"
                        onClick={handleSubmitAge}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-75px' }}
                >
                  Enter your address
                </h1>
                <input
                  type="text"
                  name="address"
                  placeholder="Street Address"
                  value={address}
                  style={{ marginTop: '15px' }}
                  className="verification-long-input"
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />{' '}
                <input
                  type="text"
                  name="city"
                  className="verification-long-input"
                  placeholder="City"
                  style={{ marginTop: '-40px' }}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <div className="short-input-group">
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    className="verification-short-input"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    maxLength={2}
                    required
                  />
                  <input
                    type="text"
                    name="zip"
                    placeholder="Zip"
                    className="verification-short-input"
                    maxLength={5}
                    onChange={(e) => setZip(e.target.value)}
                    required
                  />{' '}
                </div>
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {step === 3 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-20px' }}
                >
                  Enter last four of your SSN
                </h1>
                <div className="ssn-div">
                  <img
                    data-tip="State law requires us to confirm user identity through the use of certain identification factors."
                    src="/icons/information.png"
                    alt="Help icon"
                    style={{ marginLeft: '5px', width: '20px', height: '20px' }}
                  />
                  <ReactTooltip place="top" type="dark" effect="float" />
                  <h1 className="ssn-description"> Why do you need this?</h1>
                </div>

                <input
                  type="text"
                  name="ssnLast4"
                  value={ssnLast4}
                  className="verification-long-input"
                  placeholder="Last 4 SSN"
                  onChange={(e) => setSsnLast4(e.target.value)}
                  required
                />

                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )} */}

          {step === 3 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-20px' }}
                >
                  Enter Club Name
                </h1>
                <input
                  type="text"
                  name="ssnLast4"
                  value={club}
                  className="verification-long-input"
                  placeholder="Enter Club name"
                  onChange={(e) => setClub(e.target.value)}
                  required
                />

                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button
                    className="verification-next"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-100px' }}
                >
                  {' '}
                  Terms & Conditions
                </h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '310px',
                    marginTop: '10px',
                  }}
                >
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    style={{ marginTop: '5px' }}
                    onChange={handleInputChange}
                  />
                  <h1
                    className="verification-terms"
                    style={{ marginLeft: '10px' }}
                  >
                    By checking this box, you agree to ATHSTAT’s{' '}
                    <Link className="terms-links" to="/games-terms">
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link className="terms-links" to="/privacy-policy">
                      Privacy Policy
                    </Link>{' '}
                    , and also confirm that you are of legal age to participate
                    in ATHSTAT’s games. Read more about ATHSTAT’s responsible
                    gaming policy{' '}
                    <Link className="terms-links" to="/responsible-gaming">
                      here
                    </Link>{' '}
                  </h1>
                </div>
                <div className="verification-buttons">
                  <button
                    className="verification-back"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                  <button className="verification-next" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* {step === 6 && (
            <div className="centered-content">
              <div
                className="sign-up-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1
                  className="verification-header"
                  style={{ marginLeft: '-100px' }}
                >
                  {' '}
                  Verifying user....
                </h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '310px',
                    marginTop: '10px',
                  }}
                >
                  <h1
                    className="verification-terms"
                    style={{ marginLeft: '10px' }}
                  >
                    Wait a moment while we verify your information.
                  </h1>
                </div>
                <div className="loading-spinner">
                  <img
                    src="/img/loading2.gif"
                    alt="Loading"
                    style={{ height: '100px', borderRadius: '50px' }}
                  />
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default SignUpGames;
