// MatchListItem.tsx
import React, { useState, useEffect } from 'react';
import MatchDetailsModal from './MatchDetailsModal';

interface MatchListItemProps {
  homeTeamImage: string;
  homeTeamName: string;
  homeTeamScore: number;
  awayTeamScore: number;
  awayTeamImage: string;
  awayTeamName: string;
  location: string;
  targetTime: string;
}

const MatchListItem: React.FC<MatchListItemProps> = ({
  homeTeamImage,
  homeTeamName,
  homeTeamScore,
  awayTeamScore,
  awayTeamImage,
  awayTeamName,
  location,
  targetTime,
}) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const target = new Date(targetTime);

    if (now > target) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        hasExpired: true,
      };
    }

    //console.log('Scores: ', homeTeamScore, awayTeamScore);

    const difference = target.getTime() - now.getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds,
      hasExpired: false,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  const formatTime = (value: number, unit: string) => {
    return value > 0 ? `${value}${unit} ` : '';
  };

  const getColor = () => {
    if (timeLeft.days >= 1) {
      return '#24D05B';
    } else if (timeLeft.hours >= 0) {
      return '#F97066';
    } else if (timeLeft.minutes >= 0) {
      return 'orange';
    } else if (timeLeft.seconds > 0) {
      return 'red';
    }
    return;
  };

  const getClockIcon = () => {
    if (timeLeft.days >= 1) {
      return '/icons/green-clock.svg';
    } else if (timeLeft.hours >= 0) {
      return '/icons/red-clock.svg';
    } else if (timeLeft.minutes >= 0) {
      return 'orange-clock.svg';
    } else if (timeLeft.seconds > 0) {
      return 'red-clock.svg';
    }
    return '';
  };
  const handleMatchItemClick = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   const kickoffDate = new Date(targetTime);
  //   const dateOptions = {
  //     year: 'numeric' as const,
  //     month: 'long' as const,
  //     day: 'numeric' as const,
  //   };
  //const formattedDate = kickoffDate.toLocaleDateString(undefined, dateOptions);

  return (
    <div
      className="match-list-item"
      onClick={handleMatchItemClick}
      style={{ color: getColor() }}
    >
      <div className="match-list-team-info">
        <div className="left-team">
          <img
            src={homeTeamImage || '/icons/default-player-img.png'}
            onError={(e) => {
              if (e.target instanceof HTMLImageElement) {
                e.target.src = '/icons/default-player-img.png';
              }
            }}
            alt={homeTeamName}
            className="matchlist-team-image"
          />
          <span className="match-list-team-name">{homeTeamName}</span>
        </div>
        <span className="vs">VS</span>
        <div className="right-team" style={{ textAlign: 'right' }}>
          <span className="match-list-team-name">{awayTeamName}</span>
          <img
            src={awayTeamImage || '/icons/default-player-img.png'}
            onError={(e) => {
              if (e.target instanceof HTMLImageElement) {
                e.target.src = '/icons/default-player-img.png';
              }
            }}
            alt={awayTeamName}
            className="matchlist-team-image"
          />
        </div>
      </div>
      <span className="stadium-location">
        <img src="/icons/location.svg" />
        <h1 className="location-name" style={{ textAlign: 'left' }}>
          {location}
        </h1>
      </span>
      {timeLeft.hasExpired ? (
        <div className="past-game-date">
          <img src="/icons/match.date.png" className="match-calendar" />
          <p className="expired-date">
            {new Date(targetTime).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
          </p>
        </div>
      ) : (
        <>
          {/* <p>{formattedDate}</p> */}
          <p className="time-countdown" style={{ width: '200px' }}>
            <img style={{ marginRight: '5px' }} src={getClockIcon()} />
            {timeLeft.days > 0 && `${formatTime(timeLeft.days, 'd')}`}
            {timeLeft.hours > 0 && `${formatTime(timeLeft.hours, 'h')}`}
            {timeLeft.minutes > 0 && `${formatTime(timeLeft.minutes, 'm')}`}
            {timeLeft.seconds > 0 && `${formatTime(timeLeft.seconds, 's')}`}
          </p>
        </>
      )}
      <img
        style={{ justifyContent: 'flex-end' }}
        src="/icons/table-arrow.svg"
      />
      {isModalOpen && (
        <MatchDetailsModal
          matchDetails={{
            homeTeamImage,
            homeTeamName,
            homeTeamScore,
            awayTeamScore,
            awayTeamImage,
            awayTeamName,
            location,
            targetTime,
          }}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default MatchListItem;
