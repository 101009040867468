import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import PriceChangeCharts from './PriceChangeCharts';

export interface Player {
  player_name: string;
  price: number;
  position_class: string;
  image_url: string;
  id?: number;
  team_id: number;
  athlete_id: string;
  purchase_price: number;
  purchase_date: string;
  is_starting: boolean;
  slot: number;
  score: number;
}
export interface PlayerSelected {
  source_id: string;
  tracking_id: string;
  player_name: string;
  nick_name: string;
  birth_country?: null;
  date_of_birth?: null;
  isactive?: null;
  abbr?: null;
  athstat_name: string;
  athstat_firstname: string;
  athstat_lastname: string;
  athstat_middleinitial?: null;
  general_comments?: null;
  team_id: string;
  age?: null;
  height?: null;
  weight?: null;
  best_match_full_name?: null;
  best_match_first_name?: null;
  best_match_last_name?: null;
  best_match_team?: null;
  best_match_gender?: null;
  external_source?: null;
  best_match_iaaid?: null;
  unified_id?: null;
  hidden?: null;
  kc_id?: null;
  kcsynced?: null;
  gender: string;
  price?: number | undefined;
  power_rank_rating: number;
  region?: null;
  position_class: string;
  data_source: string;
  position: string;
  on_dark_image_url?: null;
  on_light_image_url?: null;
  image_url: string;
}

interface Ranking {
  athlete_id: string;
  game_id: string;
  opposition_name: string;
  team_name: string;
  updated_power_ranking: number;
}

interface SmallPlayerCardProps {
  player_name: string;
  position_class: string;
  image_url: string;
  athlete_id: string;
  side: boolean;
  selectPerson: (person: Player) => void;
  selectedPlayer: Player;
  removePlayer: (playerName: string, position: string, side: boolean) => void;
}

const SmallPlayerCardItem: React.FC<SmallPlayerCardProps> = ({
  player_name,
  position_class,
  image_url,
  athlete_id,
  side,
  selectPerson,
  selectedPlayer,
  removePlayer,
}) => {
  const { user } = useAuth();
  const token = user?.access_token;
  const [selectedPlayerForModal, setSelectedPlayerForModal] =
    useState<PlayerSelected | null>(null);
  const [selectedTab, setSelectedTab] = useState('Player Information');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [athletesPowerRankings, setAthletesPowerRankings] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/athletes-power-rankings/${athlete_id}`
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        // eslint-disable-next-line no-console
        setAthletesPowerRankings(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
      });
  }, [athlete_id]);
  //   const [isCheckedd, setIsCheckedd] = useState(false);
  //   const [disabled, setDisabled] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedTab, setSelectedTab] = useState('Player Information');

  // const handleStatsImageClick = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // const switchToUpcoming = () => {
  //   setSelectedTab('Player Information');
  // };

  // const switchToPrevious = () => {
  //   // Disable switching to "Power Rankings" if needed
  //   if (isPowerRankingsDisabled) {
  //     return;
  //   }
  //   setSelectedTab('Power Rankings');
  // };

  // const isPowerRankingsDisabled = true; // Set to true to disable "Power Rankings"

  const openModal = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/fantasy-athletes/${athlete_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;

        setSelectedPlayerForModal(data[0]);
        setIsModalOpen(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="small-player-card-items">
      {/* <h1 className="id-div">{tracking_id}</h1> */}
      <div
        key={selectedPlayer.player_name}
        onClick={() => selectPerson(selectedPlayer)}
        style={{ cursor: 'pointer' }}
      >
        <img src="/icons/checkbox.svg" alt="" />
      </div>
      <img
        className="stat-img"
        src="/icons/eye-regular.svg"
        style={{ height: '35px' }}
        alt="eye-icon"
        onClick={openModal}
      />

      <img
        src={image_url || '/icons/default-player-img.png'}
        onError={(e) => {
          if (e.target instanceof HTMLImageElement) {
            e.target.src = '/icons/default-player-img.png';
          }
        }}
        alt="avatar"
        className="small-card-avatar"
        style={{
          marginLeft: '25px',
        }}
      />

      <div className="small-player-name-position">
        <h1 className="small-player-name">{player_name}</h1>
        <h1 className="player-position">{position_class}</h1>
      </div>

      <div
        key={selectedPlayer.id}
        onClick={() => removePlayer(player_name, position_class, side)}
        style={{ cursor: 'pointer' }}
      >
        <img
          style={{
            marginLeft: '200px',
          }}
          src="/icons/componentX.svg"
        />
      </div>
      {isModalOpen && (
        <div
          className="custom-modal"
          style={{
            marginTop: '30px',
            height: '90vh',
            //overflowY: 'auto',
          }}
        >
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <div
              style={{
                display: 'flex',
                marginLeft: '-20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Added width to ensure it takes the full width
              }}
            >
              <div>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Player Information' ? 'selected-tab' : ''
                  }`}
                  onClick={() => setSelectedTab('Player Information')}
                >
                  Player Information
                </button>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Power Rankings' ? 'selected-tab' : ''
                  }`}
                  onClick={() => setSelectedTab('Power Rankings')}
                  //disabled={isPowerRankingsDisabled}
                >
                  Power Rankings
                </button>
              </div>
              <div
                onClick={closeModal}
                style={{ marginRight: '-20px', cursor: 'pointer' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#E6E6E6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="modal-player-button">
              <h1 className="modal-player-header">Player</h1>
              {/* <button className="add-player" onClick={handleAddToTeamClick}>
                Select Player
              </button> */}
            </div>
            <img
              src={image_url}
              onError={(e) => {
                if (e.target instanceof HTMLImageElement) {
                  e.target.src = '/icons/default-player-img.png';
                }
              }}
              className="stats-card--user-img"
            />
            <div style={{ width: '100%', height: '400px', overflowY: 'auto' }}>
              {selectedTab === 'Player Information' && (
                <>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Name</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.player_name || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Position</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.position_class || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Country</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.birth_country || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Age</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.age || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Sex</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.gender || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Power Ranking</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.power_rank_rating || '-'}
                    </h1>
                  </div>
                  <div className="player-detail-row">
                    <h1 className="detail-desc">Current Value</h1>
                    <h1 className="detail-value">
                      {selectedPlayerForModal?.price &&
                        (selectedPlayerForModal?.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }) ||
                          '-')}
                    </h1>
                  </div>
                  <div className="player-detail-row-graph">
                    <h1 className="detail-desc">Value History</h1>
                    {selectedPlayerForModal?.tracking_id && (
                      <PriceChangeCharts
                        trackingId={selectedPlayerForModal?.tracking_id}
                      />
                    )}
                  </div>
                </>
              )}
              {selectedTab === 'Power Rankings' && (
                <div style={{ height: 500 }}>
                  {athletesPowerRankings.map((ranking: Ranking, index) => (
                    <div className="player-detail-row-rankings" key={index}>
                      <h1 className="detail-desc">
                        {ranking.team_name || '-'} vs{' '}
                        {ranking.opposition_name || '-'}
                      </h1>
                      <h1 className="detail-value">
                        {ranking.updated_power_ranking || '-'}
                      </h1>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmallPlayerCardItem;
