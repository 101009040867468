import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Tab,
  Tabs,
  ModalHeader,
  ModalBody,
  Modal,
} from 'react-bootstrap';
import {
  ILeague,
  IMember,
  IOrganization,
  ITeam,
  IUpdateLeague,
  IUpdateOrganization,
  IUpdateTeam,
} from '../../../../d';
import axios from 'axios';
import {
  Button,
  Accordion,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import MembersTab from './Tabs/MembersTab';
import Message from '../../Message/Message';
import { OrganizationContext } from '../../../context/OrganizationContext';
import { LeagueContext } from '../../../context/LeagueContext';
import { TeamContext } from '../../../context/TeamContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
// import isUrl from 'validator/lib/isURL';

const EntityTable: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const initialIFrameLink = process.env.REACT_APP_OPENSEARCH_LINK
    ? process.env.REACT_APP_OPENSEARCH_LINK
    : 'https://qa-opensearch.athstat-next.com/app/home';
  const { setSelectedOrganization, setUpdatedItem } =
    useContext(OrganizationContext);
  const { setLeague } = useContext(LeagueContext);
  const { setTeam } = useContext(TeamContext);
  const [organizations, setOrganizations] = useState<Array<IOrganization>>([]);
  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [teams, setTeams] = useState<Array<ITeam>>([]);
  const [organization, setOrganization] = useState<IOrganization | undefined>();
  const [leagueState, setLeagueState] = useState<ILeague | undefined>();
  const [teamState, setTeamState] = useState<ITeam | undefined>();
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  // const [selectedEntity, setSelectedEntity] = useState<string>('');
  const [activeItem, setActiveId] = React.useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showIFrameModal, setShowIFrameModal] = useState(false);
  const [iFrameLink, setIFrameLink] = useState(initialIFrameLink);
  const [currentArchivedEntityName, setCurrentArchivedEntityName] =
    useState('');
  const [currentType, setCurrentType] = useState('');

  useEffect(() => {
    if (user) {
      const getOrganizations = async () => {
        setLoadingData(true);
        try {
          const fetchedOrganizations = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/organizations`,
            {
              params: { user_id: userId },
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            }
          );
          if (setOrganizations) {
            setOrganizations(fetchedOrganizations.data);
            setOrganization(fetchedOrganizations.data[0]);
            // setSelectedEntity('Organizations');
          }
          setLoadingData(false);
        } catch (err) {
          setLoadingData(false);
        }
      };

      getOrganizations();
    }
  }, []);

  const fetchLeagues = async (selectedOrg: IOrganization) => {
    if (organization && organization._id && userId) {
      const fetchedLeagues = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/leagues/`,
        {
          params: { organization_id: selectedOrg._id },
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      if (fetchedLeagues.data) {
        setLeagues(fetchedLeagues.data);
      }
    }
  };

  const fetchTeams = async (selectedLeague: ILeague) => {
    if (selectedLeague && selectedLeague._id && userId) {
      const fetchedTeams = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/teams/`,
        {
          params: { league_id: selectedLeague._id },
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      if (fetchedTeams.data) {
        setTeams(fetchedTeams.data);
      }
    }
  };

  const linkToOrganization = (org: IOrganization) => {
    if (setSelectedOrganization && setUpdatedItem) {
      setSelectedOrganization(org);
      setUpdatedItem(org);
    }
    navigate(`/auth/organizations`);
  };

  const linkToLeague = (league: ILeague) => {
    if (setLeague && setUpdatedItem) {
      setLeague(league);
      setUpdatedItem(league);
    }
    navigate(`/auth/leagues`);
  };

  const linkToTeam = (team: ITeam) => {
    if (setTeam && setUpdatedItem) {
      setTeam(team);
      setUpdatedItem(team);
    }
    navigate(`/auth/teams`);
  };

  //Organizations
  const handleOrganizationChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select Team') {
      const selectedOrganization = organizations[event.target.value as number];
      setOrganization(selectedOrganization);
      await fetchLeagues(selectedOrganization);
      setActiveId(2);
      setLeagueState(undefined);
      setTeamState(undefined);
    }
  };

  const organizationsDropDown = () => {
    const organizationItems: Array<React.ReactNode> = [
      <option
        key={'10000'}
        value={'Select Organization'}
        disabled={organization !== undefined}
      >
        Select Organization
      </option>,
    ];
    organizations.forEach((organization, index) => {
      organizationItems.push(
        <option key={index} value={index}>
          {organization.hidden
            ? `${organization.name} (archived)`
            : organization.name}
        </option>
      );
    });
    return organizationItems;
  };

  const joinOrganization = async (selectedOrganization: IOrganization) => {
    if (userId && selectedOrganization) {
      const newMember: IMember = {
        user_id: userId,
        roleLevel: 500,
      };
      const memberIndex = selectedOrganization.members.findIndex(
        (member) => member.user_id === userId
      );
      if (memberIndex === -1) {
        const updatedMembers = [...selectedOrganization.members, newMember];
        const updateOrganization: IUpdateOrganization = {
          _id: selectedOrganization._id,
          members: updatedMembers,
        };

        const fetchedUpdatedOrganization = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/organizations/join`,
          updateOrganization,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );

        if (fetchedUpdatedOrganization.data) {
          setOrganization(fetchedUpdatedOrganization.data);

          //Updating Organization. Necessary Evil
          const organizationsCopy = [...organizations];
          const foundIndex = organizationsCopy.findIndex(
            (x) => x._id === fetchedUpdatedOrganization.data._id
          );
          organizationsCopy[foundIndex] = fetchedUpdatedOrganization.data;
          setOrganizations(organizationsCopy);
          setMessage('Joined Organization');
          setShowMessage(true);
        } else {
          setMessage('Error Joining Organization');
          setIsError(true);
          setShowMessage(true);
        }
      } else {
        const updatedMembers = selectedOrganization.members.filter(
          (member) => member.user_id !== userId
        );
        const updateOrganization: IUpdateOrganization = {
          _id: selectedOrganization._id,
          members: updatedMembers,
        };

        const fetchedUpdatedOrganization = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/organizations/leave`,
          updateOrganization,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedUpdatedOrganization.data) {
          setOrganization(fetchedUpdatedOrganization.data);
          //Updating Organization. Necessary Evil
          const organizationsCopy = [...organizations];
          const foundIndex = organizationsCopy.findIndex(
            (x) => x._id === fetchedUpdatedOrganization.data._id
          );
          organizationsCopy[foundIndex] = fetchedUpdatedOrganization.data;
          setOrganizations(organizationsCopy);
          setMessage('Left Organization');
          setIsError(false);
          setShowMessage(true);
        } else {
          setMessage('Error Leaving Organization');
          setIsError(true);
          setShowMessage(true);
        }
      }
    }
  };

  const organizationList = (): React.ReactNode => {
    const organizationNode: Array<React.ReactNode> = [];
    organizations.forEach((organization: IOrganization, index: number) => {
      organizationNode.push(
        <TableRow key={index}>
          <TableCell>{organization._id}</TableCell>
          <TableCell className="text-left Mo-MW-150">
            {organization.hidden
              ? `${organization.name} (archived)`
              : organization.name}
          </TableCell>
          <TableCell className="text-left Mo-MW-150">
            {organization.organization_admin_email}
          </TableCell>
          <TableCell>
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <Button
                className="Entity-Btn-Go"
                onClick={() => joinOrganization(organization)}
              >
                Join/Leave
              </Button>
              <Button
                className="Entity-Btn-Go"
                onClick={() => linkToOrganization(organization)}
              >
                Go <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
              <Button
                className="Entity-Archive-Btn-Go"
                onClick={() => {
                  if (setOrganization) {
                    setOrganization(organization);
                    setCurrentType('organization');
                    setCurrentArchivedEntityName(organization.name);
                    setShowDeleteModal(true);
                  }
                }}
              >
                {`${!organization.hidden ? 'Archive' : 'Unarchive'}`}
                <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
    return organizationNode;
  };

  const archiveOrganization = async () => {
    setShowMessage(false);
    if (organization) {
      const updatedOrganization: IUpdateOrganization = {
        _id: organization._id,
        hidden: !organization.hidden,
      };

      const retrievedOrganization = await axios
        .put(
          `${process.env.REACT_APP_API_URL}/organizations/archive`,
          updatedOrganization,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        )
        .then((r) => {
          return r.data;
        })
        .catch(() => {
          return 'Error';
        });

      if (
        retrievedOrganization &&
        retrievedOrganization._id &&
        retrievedOrganization.name
      ) {
        const organizationsCopy = [...organizations];
        const foundIndex = organizationsCopy.findIndex(
          (x) => x._id === retrievedOrganization._id
        );
        organizationsCopy[foundIndex] = retrievedOrganization;
        setOrganizations(organizationsCopy);
        setShowDeleteModal(false);
        setIsError(false);
        setMessage(
          `${retrievedOrganization.name} has been ${
            retrievedOrganization.hidden ? 'archived' : 'unarchived'
          }. Please refresh list to see changes if needed.`
        );
        setShowMessage(true);
      } else {
        setIsError(true);
        setMessage(retrievedOrganization);
        setShowMessage(true);
        setShowDeleteModal(false);
      }
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleIFrameModal = () => {
    setShowIFrameModal((prevState) => !prevState);
  };

  // Leagues
  const handleLeagueChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select League') {
      const selectedLeague = leagues[event.target.value as number];
      await fetchTeams(selectedLeague);
      setActiveId(3);
      setLeagueState(selectedLeague);
      setTeamState(undefined);
    }
  };

  const leaguesDropDown = () => {
    const leagueItems: Array<React.ReactNode> = [
      <option
        key={'20000'}
        value={'Select League'}
        disabled={leagueState !== undefined}
      >
        {leagueState ? leagueState.name : 'Select League'}
      </option>,
    ];
    leagues.forEach((league, index) => {
      leagueItems.push(
        <option key={index} value={index}>
          {league.hidden ? `${league.name} (archived)` : league.name}
        </option>
      );
    });
    return leagueItems;
  };

  const joinLeague = async (selectedLeague: ILeague) => {
    if (userId && selectedLeague) {
      const newMember: IMember = {
        user_id: userId,
        roleLevel: 400,
      };
      const memberIndex = selectedLeague.members.findIndex(
        (member) => member.user_id === userId
      );
      if (memberIndex === -1) {
        const updatedMembers = [...selectedLeague.members, newMember];
        const updateLeague: IUpdateLeague = {
          _id: selectedLeague._id,
          members: updatedMembers,
        };

        const fetchedUpdatedLeague = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/leagues/join`,
          updateLeague,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedUpdatedLeague.data) {
          setLeagueState(fetchedUpdatedLeague.data);
          //Updating League. Necessary Evil
          const leaguesCopy = [...leagues];
          const foundIndex = leaguesCopy.findIndex(
            (x) => x._id === fetchedUpdatedLeague.data._id
          );
          leaguesCopy[foundIndex] = fetchedUpdatedLeague.data;
          setLeagues(leaguesCopy);
          setMessage('Joined League');
          setShowMessage(true);
          setIsError(false);
        } else {
          setMessage('Error Joining League');
          setShowMessage(true);
          setIsError(true);
        }
      } else {
        const updatedMembers = selectedLeague.members.filter(
          (member) => member.user_id !== userId
        );
        const updateLeague: IUpdateOrganization = {
          _id: selectedLeague._id,
          members: updatedMembers,
        };
        const fetchedUpdatedLeague = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/leagues/leave`,
          updateLeague,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedUpdatedLeague.data) {
          setLeagueState(fetchedUpdatedLeague.data);
          //Updating League. Necessary Evil
          const leaguesCopy = [...leagues];
          const foundIndex = leaguesCopy.findIndex(
            (x) => x._id === fetchedUpdatedLeague.data._id
          );
          leaguesCopy[foundIndex] = fetchedUpdatedLeague.data;
          setLeagues(leaguesCopy);
          setMessage('Left League');
          setIsError(false);
          setShowMessage(true);
        } else {
          setMessage('Error Leaving League');
          setIsError(true);
          setShowMessage(true);
        }
      }
    }
  };

  const leaguesList = (): React.ReactNode => {
    const leagueNode: Array<React.ReactNode> = [];
    leagues.forEach((league: ILeague, index: number) => {
      leagueNode.push(
        <tr key={index}>
          <td>{league._id}</td>
          <td className="text-left Mo-MW-150">
            {league.hidden ? `${league.name} (archived)` : league.name}
          </td>
          <td className="text-left Mo-MW-150">{league.sport_id}</td>
          <td className="text-left Mo-MW-150">{league.organization_id}</td>
          <td>
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <Button
                className="Entity-Btn-Go"
                onClick={() => joinLeague(league)}
              >
                Join/Leave
              </Button>
              <Button
                className="Entity-Btn-Go"
                onClick={() => linkToLeague(league)}
              >
                Go <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
              <Button
                className="Entity-Archive-Btn-Go"
                onClick={() => {
                  setLeagueState(league);
                  setCurrentType('league');
                  setCurrentArchivedEntityName(league.name);
                  setShowDeleteModal(true);
                }}
              >
                {`${!league.hidden ? 'Archive' : 'Unarchive'}`}
                <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });
    return leagueNode;
  };

  const archiveLeague = async () => {
    setShowMessage(false);
    if (leagueState) {
      const updatedLeague: IUpdateLeague = {
        _id: leagueState._id,
        hidden: !leagueState.hidden,
        organization_id: leagueState.organization_id,
      };

      const retrievedLeague = await axios
        .put(
          `${process.env.REACT_APP_API_URL}/leagues/archive`,
          updatedLeague,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        )
        .then((r) => {
          return r.data;
        })
        .catch(() => {
          return 'Error';
        });

      if (retrievedLeague && retrievedLeague._id) {
        const leaguesCopy = [...leagues];
        const foundIndex = leaguesCopy.findIndex(
          (x) => x._id === retrievedLeague._id
        );
        leaguesCopy[foundIndex] = retrievedLeague;
        setLeagues(leaguesCopy);
        setShowDeleteModal(false);
        setIsError(false);
        setMessage(
          `${retrievedLeague.name} has been ${
            retrievedLeague.hidden ? 'archived' : 'unarchived'
          }. Please refresh list to see changes if needed.`
        );
        setShowMessage(true);
      } else {
        setMessage(retrievedLeague);
        setIsError(true);
        setShowMessage(true);
        setShowDeleteModal(false);
      }
    } else {
      setShowDeleteModal(false);
      setMessage('No League Selected');
      setIsError(true);
      setShowMessage(true);
    }
  };

  // Team
  const handleTeamChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select Team') {
      const selectedTeam = teams[event.target.value as number];
      setTeamState(selectedTeam);
      setActiveId(4);
    }
  };

  const teamsDropDown = () => {
    const teamItems: Array<React.ReactNode> = [
      <option
        key={'30000'}
        value={'Select Team'}
        disabled={teamState !== undefined}
      >
        {teamState ? teamState.name : 'Select Team'}
      </option>,
    ];
    teams.forEach((team, index) => {
      teamItems.push(
        <option key={index} value={index}>
          {team.hidden ? `${team.name} (archived)` : team.name}
        </option>
      );
    });
    return teamItems;
  };

  const joinTeam = async (selectedTeam: ITeam) => {
    if (userId && selectedTeam) {
      const newMember: IMember = {
        user_id: userId,
        roleLevel: 400,
      };
      const memberIndex = selectedTeam.members.findIndex(
        (member) => member.user_id === userId
      );
      if (memberIndex === -1) {
        const updatedMembers = [...selectedTeam.members, newMember];
        const updateTeam: IUpdateTeam = {
          _id: selectedTeam._id,
          members: updatedMembers,
        };
        const fetchedUpdatedTeam = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/teams/join`,
          updateTeam,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );

        if (fetchedUpdatedTeam.data) {
          setTeamState(fetchedUpdatedTeam.data);
          //Updating Team. Necessary Evil
          const teamsCopy = [...teams];
          const foundIndex = teamsCopy.findIndex(
            (x) => x._id === fetchedUpdatedTeam.data._id
          );
          teamsCopy[foundIndex] = fetchedUpdatedTeam.data;
          setTeams(teamsCopy);
          setMessage('Joined Team');
          setShowMessage(true);
          setIsError(false);
        } else {
          setMessage('Error Joining Team');
          setShowMessage(true);
          setIsError(true);
        }
      } else {
        const updatedMembers = selectedTeam.members.filter(
          (member) => member.user_id !== userId
        );
        const updateTeam: IUpdateTeam = {
          _id: selectedTeam._id,
          members: updatedMembers,
        };

        const fetchedUpdatedTeam = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/teams/leave`,
          updateTeam,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedUpdatedTeam.data) {
          setTeamState(fetchedUpdatedTeam.data);
          //Updating Team. Necessary Evil
          const teamsCopy = [...teams];
          const foundIndex = teamsCopy.findIndex(
            (x) => x._id === fetchedUpdatedTeam.data._id
          );
          teamsCopy[foundIndex] = fetchedUpdatedTeam.data;
          setTeams(teamsCopy);
          setMessage('Left Team');
          setIsError(false);
          setShowMessage(true);
        } else {
          setMessage('Error Leaving Team');
          setIsError(true);
          setShowMessage(true);
        }
      }
    }
  };

  const teamsList = (): React.ReactNode => {
    const teamNode: Array<React.ReactNode> = [];
    teams.forEach((team: ITeam, index: number) => {
      teamNode.push(
        <tr key={index}>
          <td>{team._id}</td>
          <td className="text-left Mo-MW-150">
            {' '}
            {team.hidden ? `${team.name} (archived)` : team.name}
          </td>
          <td className="text-left Mo-MW-150">{team.league_id}</td>
          <td>
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <Button
                className="Entity-Archive-Btn-Go"
                onClick={() => {
                  setTeamState(team);
                  setShowIFrameModal(true);
                }}
              >
                View
                <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
            </div>
          </td>
          <td>
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <Button className="Entity-Btn-Go" onClick={() => joinTeam(team)}>
                Join/Leave
              </Button>
              <Button
                className="Entity-Btn-Go"
                onClick={() => linkToTeam(team)}
              >
                Go <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
              <Button
                className="Entity-Archive-Btn-Go"
                onClick={() => {
                  setTeamState(team);
                  setCurrentType('team');
                  setCurrentArchivedEntityName(team.name);
                  setShowDeleteModal(true);
                }}
              >
                {`${!team.hidden ? 'Archive' : 'Unarchive'}`}{' '}
                <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });
    return teamNode;
  };

  const archiveTeam = async () => {
    setShowMessage(false);
    if (teamState) {
      const updatedTeam: IUpdateTeam = {
        _id: teamState._id,
        hidden: !teamState.hidden,
        league_id: teamState.league_id,
      };

      const retrievedTeam = await axios
        .put(`${process.env.REACT_APP_API_URL}/teams/archive`, updatedTeam, {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        })
        .then((r) => {
          return r.data;
        })
        .catch(() => {
          return 'Error';
        });

      if (retrievedTeam && retrievedTeam._id) {
        const teamsCopy = [...teams];
        const foundIndex = teamsCopy.findIndex(
          (x) => x._id === retrievedTeam._id
        );
        teamsCopy[foundIndex] = retrievedTeam;
        setTeams(teamsCopy);
        setShowDeleteModal(false);
        setIsError(false);
        setMessage(
          `${retrievedTeam.name} has been ${
            retrievedTeam.hidden ? 'archived' : 'unarchived'
          }. Please refresh list to see changes if needed.`
        );
        setShowMessage(true);
      } else {
        setMessage(retrievedTeam);
        setShowMessage(true);
        setIsError(true);
        setShowDeleteModal(false);
      }
    }
  };

  const updateOSLink = async () => {
    // if (!isUrl(iFrameLink, { require_protocol: true })) {
    //   setIsError(true);
    //   setMessage('Please format link correctly, i.e https://www.example.com');
    //   setShowMessage(true);
    //   return;
    // } else
    if (teamState && iFrameLink) {
      const updateTeam: IUpdateTeam = {
        _id: teamState._id,
        os_frame_link: iFrameLink,
      };

      const fetchedUpdatedTeam = await axios.put(
        `   ${process.env.REACT_APP_API_URL}/teams`,
        updateTeam,
        {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );

      if (fetchedUpdatedTeam.data) {
        setTeamState(fetchedUpdatedTeam.data);
        setIFrameLink(initialIFrameLink);
        const teamsCopy = [...teams];
        const foundIndex = teamsCopy.findIndex(
          (x) => x._id === fetchedUpdatedTeam.data._id
        );
        teamsCopy[foundIndex] = fetchedUpdatedTeam.data;
        setTeams(teamsCopy);
        setShowIFrameModal(false);
        setIsError(false);
        setShowMessage(false);
      } else {
        setShowIFrameModal(false);
        setIsError(true);
        setShowMessage(true);
        setMessage('Error Updating Team');
      }
    }
  };

  const toggle = (tab: React.SetStateAction<number>) => () => {
    if (activeItem !== tab) {
      setActiveId(tab);
    }
  };

  const returnArchiveFunctionByType = () => {
    switch (currentType) {
      case 'organization':
        return archiveOrganization();
      case 'league':
        return archiveLeague();
      case 'team':
        return archiveTeam();
      default:
        return null;
    }
  };

  return (
    <Row>
      <div className="SectionTitle _with-btn">
        <Row>
          <Col size="12" md="2" lg="2">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Organizations"
                  className="theme-input"
                  defaultValue={organization?.name}
                  onChange={(e) => handleOrganizationChange(e)}
                >
                  {organizationsDropDown()}
                </select>
              </div>
            </div>
          </Col>

          <Col size="12" md="2" lg="2">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Leagues"
                  className="theme-input"
                  defaultValue={leagueState?.name}
                  onChange={(e) => handleLeagueChange(e)}
                >
                  {leaguesDropDown()}
                </select>
              </div>
            </div>
          </Col>

          <Col size="12" md="2" lg="2">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Teams"
                  className="theme-input"
                  defaultValue={teamState?.name}
                  onChange={(e) => handleTeamChange(e)}
                >
                  {teamsDropDown()}
                </select>
              </div>
            </div>
          </Col>
          {showMessage && (
            <Col size="12" md="6" lg="6" className={'adminDashboardMessage'}>
              <Message
                message={message}
                setShowMessage={setShowMessage}
                error={isError}
                noMargin={true}
              />
            </Col>
          )}
        </Row>
      </div>

      <Col size="12">
        <br />
        <Tabs
          defaultActiveKey={1}
          className="ThemeTabContent nav-tabs ThemeTabs"
          id="ThemeTabs"
        >
          <Tab
            className=""
            eventKey={1}
            onClick={toggle(1)}
            role="tab"
            title={'Organizations'}
          >
            {!loadingData && organizations && organizations.length > 0 && (
              <div>
                <Table className="table-striped">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-left Mo-MW-130">
                        Organization ID
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Organization Name
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Admin Email
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Manage
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <tbody>{organizationList()}</tbody>
                </Table>
              </div>
            )}
            {loadingData ||
              (organizations.length === 0 && (
                <div style={{ color: 'white' }}>No Organizations Loaded</div>
              ))}
          </Tab>
          <Tab
            className=""
            eventKey={2}
            onClick={toggle(2)}
            role="tab"
            title={'Leagues'}
          >
            {!loadingData && organization && leagues.length > 0 && (
              <div>
                <Table className="table-striped">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-left Mo-MW-130">
                        League ID
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        League Name
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Sport ID
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Organization ID
                      </TableCell>
                      <TableCell className="text-left Mo-MW-150">
                        Manage
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <tbody>{leaguesList()}</tbody>
                </Table>
              </div>
            )}
            {loadingData ||
              (leagues.length === 0 && (
                <div style={{ color: 'white' }}>No Leagues Loaded</div>
              ))}
          </Tab>
          <Tab
            className=""
            eventKey={3}
            onClick={toggle(3)}
            role="tab"
            title={'Teams'}
          >
            {!loadingData && teams.length > 0 && (
              <div>
                <Accordion
                  className="Accordian-block GameData-Accordian"
                  expanded={true}
                >
                  <AccordionDetails className="Accordian-body p-0">
                    <Table className="table-striped">
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-left Mo-MW-130">
                            Team ID
                          </TableCell>
                          <TableCell className="text-left Mo-MW-150">
                            Team Name
                          </TableCell>
                          <TableCell className="text-left Mo-MW-150">
                            League ID
                          </TableCell>
                          <TableCell className="text-left Mo-MW-150">
                            IFrame Link
                          </TableCell>
                          <TableCell>Manage</TableCell>
                        </TableRow>
                      </TableHead>

                      <tbody>{teamsList()}</tbody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            {loadingData ||
              (teams.length === 0 && (
                <div style={{ color: 'white' }}>No Teams Loaded</div>
              ))}
          </Tab>
          <Tab
            className=""
            eventKey={4}
            onClick={toggle(4)}
            role="tab"
            title={'Members'}
          >
            {!loadingData && teamState && <MembersTab teamProp={teamState} />}

            {loadingData ||
              !teamState ||
              (teamState && teamState.members.length === 0 && (
                <div style={{ color: 'white' }}>No Team Players Loaded</div>
              ))}
          </Tab>
        </Tabs>
      </Col>
      <Modal
        show={showDeleteModal}
        isOpen={showDeleteModal}
        centered
        className="CreateGame-Popup"
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={true}
        toggle={toggleDeleteModal}
      >
        <ModalHeader closeButton={true} onHide={toggleDeleteModal}>
          Archive/Unarchive Entity
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col size="12">
              <div className="form-group mb-md-0">
                <p
                  className={'SportCovered-Data'}
                >{`Are you sure you want to archive/unarchive ${currentArchivedEntityName}? If archiving, the entity will be hidden until unarchived.`}</p>

                <br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <div className="form-group Model-btns ">
                <Button
                  className="Theme-Btn Table-Btn-Cancel"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  className="Theme-Btn Table-Btn-Delete "
                  onClick={() => {
                    returnArchiveFunctionByType();
                  }}
                >
                  Archive/Unarchive
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        show={showIFrameModal}
        isOpen={showIFrameModal}
        centered
        className="CreateGame-Popup"
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={true}
        toggle={toggleIFrameModal}
      >
        <ModalHeader closeButton={true} onHide={toggleIFrameModal}>
          {`${teamState ? teamState.name : "Team's iFrame Link"} Iframe Link`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col size="12">
              <div className="form-group mb-md-0">
                <textarea
                  style={{ width: '100%', height: '50px' }}
                  className="form-control theme-input-dp"
                  defaultValue={
                    teamState?.os_frame_link
                      ? teamState.os_frame_link
                      : iFrameLink
                  }
                  onChange={(e) => {
                    setIFrameLink(e.target.value);
                  }}
                />

                <br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <div className="form-group Model-btns ">
                <Button
                  className="Theme-Btn Table-Btn-Cancel"
                  onClick={() => {
                    setShowIFrameModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  className="Theme-Btn "
                  onClick={() => {
                    updateOSLink();
                  }}
                >
                  Set New Link
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Row>
  );
};

export default EntityTable;
