import React from 'react';
import { IFantasyAthlete } from '../../../d';

interface IProps {
  player?: IFantasyAthlete | null;
  onPress: () => void;
  showPrice?: boolean;
  highlight: boolean;
}

const PlayerCard: React.FC<IProps> = ({ player, onPress, highlight }) => {
  return (
    <div
      className={
        'games-player-card rounded ' + (highlight ? 'card-selected' : '')
      }
      onClick={onPress}
    >
      {player?.player_name ? <h4>{player?.player_name}</h4> : <h4>+</h4>}
      {player?.price ? (
        <p
          className="mx-3 card-price"
          style={{ fontSize: 12 }}
        >{`$${player.price.toFixed(2)}`}</p>
      ) : null}
    </div>
  );
};

export default PlayerCard;
