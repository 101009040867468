import React from 'react';
import { Close } from '@mui/icons-material';

interface MessageProps {
  message: string;
  setShowMessage: (showMessage: boolean) => void;
  error: boolean;
  noMargin?: boolean;
}

type Props = MessageProps;

const Message: React.FC<Props> = ({
  message,
  setShowMessage,
  error,
  noMargin,
}) => {
  return (
    <div>
      <span
        className={error ? 'errorMessage' : 'successMessage'}
        style={{ fontSize: 'medium' }}
      >
        {message}
      </span>

      <Close
        style={{
          color: 'white',
          marginLeft: noMargin ? '0%' : '1%',
        }}
        fontSize={'small'}
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
};

export default Message;
