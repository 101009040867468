//If this does not work, use the redirect that Tafara used on mobile to do the register and then go through the IDOLOGY Signup games form

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

interface DecodedToken {
  sub: string;
  aud: string;
  exp: number;
  iat: number;
}

export interface AccessTokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  'not-before-policy'?: number;
  refresh_expires_in?: number;
  refresh_token?: string;
  scope?: string;
  session_state: string;
  token_type: string;
}

export interface IUser {
  kc_id: string;
  email: string;
  firstName: string;
  lastName: string;
  kcSynced: boolean;
  hidden: boolean;
  middleInitial: string;
  age: string;
  sex: string;
  height: string;
  weight: string;
  nftCustomerAgreement: boolean;
  gamesOnboardingComplete: boolean;
  verified: string;
  //   state: userState;
  athcoin_balance: number;
}

const NewLogin: React.FC = () => {
  //   const [firstName, setFirstName] = useState('');
  //   const [lastName, setLastName] = useState('');
  //   const [email, setEmail] = useState('');
  //   const [password, setPassword] = useState('');
  //   const [confirmPassword, setConfirmPassword] = useState('');
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [, setSignInMessage] = useState('');
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const keycloakTokenUrl = process.env.REACT_APP_KEYCLOAK_TOKEN_URL;
  const grantType = process.env.REACT_APP_GRANT_TYPE;
  const scope = process.env.REACT_APP_SCOPE;
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          //LOGIC FOR GEOFENCE UPDATE HERE if blocked location navigate ('/location-block)
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          console.error('Unable to retrieve your location:', error.message);
          navigate('/location-disclaimer');
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      navigate('/location-disclaimer');
    }
  }, []);

  const getAccessToken = async (): Promise<AccessTokenResponse | null> => {
    if (!clientId || !clientSecret || !grantType || !scope) {
      console.error('Required environment variables are not defined.');
      return null;
    }

    try {
      const params = new URLSearchParams();
      params.append('client_id', clientId);
      params.append('grant_type', grantType);
      params.append('client_secret', clientSecret);
      params.append('scope', scope);
      params.append('username', signInEmail);
      params.append('password', signInPassword);

      const response: AxiosResponse<AccessTokenResponse> = await axios.post(
        'https://keycloak-sandbox.athstat-next.com/auth/realms/athstat/protocol/openid-connect/token',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (response.status === 400) {
        // console.error('error 400 - Bad Request');
      }

      //if response is 401, return the access token -
      if (response.status === 401) {
        // console.error('error 401 - Unauthorized');
      }

      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    const accessTokenResponse = await getAccessToken();

    if (accessTokenResponse) {
      console.log('Access token:', accessTokenResponse.access_token);
      const decodedToken = jwt_decode(
        accessTokenResponse.access_token
      ) as DecodedToken;
      console.log('Sub:', decodedToken.sub);

      // Fetch the user's data
      const user = await getUserData(
        decodedToken.sub,
        accessTokenResponse.access_token
      );

      // Redirect the user based on their verified status
      if (user) {
        switch (user.verified) {
          case 'new':
          case null:
            navigate('/signup-games');
            break;
          case 'pending':
            navigate('/pending');
            break;
          case 'banned':
            navigate('/banned');
            break;
          case 'verified':
            navigate('/auth/games-dash');
            break;
          default:
            break;
        }
      }

      setSignInMessage('Success: Logged in');
    } else {
      console.error('Error signing in');
      setSignInMessage('Failed');
    }
  };

  const getUserData = async (
    userId: string,
    accessToken: string
  ): Promise<IUser | null> => {
    try {
      const response = await axios.get(
        `http://localhost:5005/api/v1/users/${userId}`,
        {
          headers: {
            Authorization: user ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };
  //   const handleSignIn = (e: { preventDefault: () => void }) => {
  //     e.preventDefault();

  //     if (!clientId || !clientSecret) {
  //       console.error('Client ID or secret is not defined');
  //       return;
  //     }
  //     // Prepare the data
  //     const data = new URLSearchParams();
  //     data.append('grant_type', 'password');
  //     data.append('client_id', clientId);
  //     data.append('client_secret', clientSecret);
  //     data.append('username', signInEmail);
  //     data.append('password', signInPassword);

  //     // Send the request
  //     axios({
  //       method: 'post',
  //       url: 'https://keycloak-sandbox.athstat-next.com/auth/realms/athstat/protocol/openid-connect/token',
  //       data: data,
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     })
  //       .then((response) => {
  //         console.log('Access token:', response.data.access_token);
  //         const decodedToken = jwt_decode(
  //           response.data.access_token
  //         ) as DecodedToken;
  //         console.log('Sub:', decodedToken.sub);

  //         setSignInMessage('Success: Logged in');
  //       })
  //       .catch((error) => {
  //         console.error('Error signing in:', error);
  //         setSignInMessage('Failed');
  //       });
  //   };

  return (
    <div className="sign-up">
      <div className="sign-up-header">
        <img
          src="/icons/Athstat-header.svg"
          alt="Athstat Logo"
          className="athstat-header-logo"
        />
      </div>
      <div className="centered-content">
        <div className="sign-up-box">
          <img
            src="/icons/Athstat-header.svg"
            alt="Athstat Logo"
            className="athstat-logo-form"
          />
          <div className="sign-up-form">
            <form onSubmit={handleSignIn} className="login-form">
              <input
                type="email"
                value={signInEmail}
                onChange={(e) => setSignInEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={signInPassword}
                onChange={(e) => setSignInPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <div className="forgot-password">
                <a href="/forgot-password">Forgot password?</a>
              </div>
              <button type="submit" className="login-submit">
                Sign In
              </button>
            </form>
          </div>
        </div>
        <div className="new-user">
          <div className="new-display-name">
            <img
              className="login-line"
              src="/icons/login-line.svg"
              alt="login-line"
            />
            <p className="new-question">New to ATHSTAT?</p>
            <img
              className="login-line"
              src="/icons/login-line.svg"
              alt="login-line"
            />
          </div>
          <button
            className="login-sign-up-button"
            onClick={() => (window.location.href = '/signup')}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
