import React from 'react';
import { Table } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IAppLog } from '../../../../d';

interface AppLogsProps {
  appLogs: Array<IAppLog>;
  loadingData: boolean;
}

const LogsTable: React.FC<AppLogsProps> = ({
  appLogs,
  loadingData,
}: AppLogsProps) => {
  //App Logs
  const appLogList = (): React.ReactNode => {
    const appLogNode: Array<React.ReactNode> = [];
    appLogs.forEach((appLog: IAppLog, index: number) => {
      appLogNode.push(
        <tr key={index}>
          <td className="text-left Mo-MW-150">
            {new Date(Date.parse(appLog.date)).toLocaleTimeString()}
          </td>
          <td className="text-left Mo-MW-150">{appLog.receiver}</td>
          <td className="text-left Mo-MW-150">{appLog.log}</td>
        </tr>
      );
    });
    return appLogNode;
  };

  return (
    <div>
      {!loadingData && (
        <div>
          <Accordion
            className="Accordian-block GameData-Accordian"
            expanded={true}
          >
            <AccordionDetails className="Accordian-body p-0">
              <Table striped responsive className="table-striped">
                <thead>
                  <tr>
                    <th className="text-left Mo-MW-150">Date</th>
                    <th className="text-left Mo-MW-130">Receiver</th>
                    <th className="text-left Mo-MW-150">Log</th>
                  </tr>
                </thead>

                <tbody>{appLogList()}</tbody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {loadingData ||
        (appLogs.length === 0 && (
          <div style={{ color: 'white' }}>No Logs Loaded</div>
        ))}
    </div>
  );
};

export default LogsTable;
