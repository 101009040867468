import React from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { IAthletePriceChange } from '../../../d';

interface GraphProps {
  data: IAthletePriceChange[];
}

export const Graph: React.FunctionComponent<GraphProps> = ({ data }) => {
  return (
    <React.Fragment>
      <h4 style={{ color: 'white' }} className={'profile-table-title'}>
        Price History
      </h4>
      <div style={{ width: '100%', overflowX: 'scroll' }}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line
            type="monotone"
            dataKey="price"
            stroke="#049d2d"
            strokeWidth={3}
            color={'white'}
          />
          <XAxis
            dataKey={'change_time'}
            type="category"
            tick={{ display: 'none' }}
            color={'white'}
            fontStyle={'bold'}
            label={{ value: 'Time', position: 'insideBottomRight', offset: 0 }}
          />
          <YAxis dataKey={'price'} />
          <Tooltip />
        </LineChart>
      </div>
    </React.Fragment>
  );
};
