import React, { useState } from 'react';
import { Row } from 'react-bootstrap';

interface IOption {
  title: string;
  action: () => void;
}

interface FilterComponentProps {
  title: string;
  options: IOption[];
}

const FilterOption: React.FunctionComponent<FilterComponentProps> = ({
  title,
  options,
}) => {
  const [, setSelectedValue] = useState('All Players');

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(
      (option) => option.title === event.target.value
    );
    setSelectedValue(event.target.value);
    if (selectedOption) {
      selectedOption.action();
    }
  };

  return (
    <Row style={{ padding: 4 }}>
      <label htmlFor="standard-select">{title}</label>
      <div className="select">
        <select
          id="standard-select"
          onChange={onChange}
          style={{ width: '100%' }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.title} style={{ lineHeight: 20 }}>
              {option.title}
            </option>
          ))}
        </select>
        <span className="focus"></span>
      </div>
    </Row>
  );
};

export default FilterOption;
