import { createTheme } from "@mui/material/styles";

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
    },
    secondary: {
      main: "#0f0",
    },
  },
  typography: {
    body2: {
      fontSize: "1.1rem",
    },
  },
  // shape: {
  //   borderRadius: 30,
  // },
  spacing: 8,
  components:{
    MuiTextField: {
      defaultProps:{
        variant: "filled",
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          borderColor: "white",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiButton: {
      defaultProps:{disableRipple: true,
        color: "inherit",
      },
      styleOverrides:{
        root: {
          color: "white",
          fontWeight: "bold",
          padding: "20px",
          fontFamily: "Open Sans, sans-serif"
        },
        fullWidth: {
          maxWidth: "300px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps:{
        disableRipple: true,
      },

    },
    MuiPaper: {
      defaultProps:{
        elevation: 12,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 12,
      },
    }
  },
});
