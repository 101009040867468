import React, { useContext, useEffect, useState } from 'react';
/* prettier-ignore-file */
// import { useNavigate } from 'react-router-dom';
import { FantasyGamesContext } from 'context/FantasyGamesContext';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
//import { Modal } from 'react-bootstrap';

export const DeleteAccount: React.FC = () => {
  // const navigator = useNavigate();
  const { fetchFantasyTeam, loading } = useContext(FantasyGamesContext);
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [, setAccess] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, []);

  useEffect(() => {
    if (kcUser && fetchFantasyTeam && token) {
      fetchFantasyTeam(kcUser.sub, token);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <section className="games-home-page">
        <div className="account-details">
          <h1 className="account-header">Account</h1>
          <div>
            <div className="account-tab-description">
              <div className="account-img">
                <img
                  src="/icons/account-profile.png"
                  alt={'Profile'}
                  className="img-general-info"
                />
              </div>
              <h1 className="account-tab-title">Delete Account</h1>
            </div>
            <div className="general-info-table-delete">
              <div className="general-info-row">
                <div className="general-info-label">Are you sure?</div>
                <div className="general-info-value">
                  <h4 style={{ color: 'white' }}>
                    You will still be able to recover your account within 30
                    days
                  </h4>
                </div>
              </div>
              <div className="general-info-buttons">
                <button className="general-info-button-discard">Discard</button>
                <button className="general-info-button-delete">
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default DeleteAccount;
