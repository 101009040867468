/* eslint-disable @typescript-eslint/ban-ts-comment */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export interface Team {
  id?: string;
  name: string;
  club_id: string;
  official_league_id: string;
  created_date?: Date;
  team_value?: number;
  balance?: number;
  round_score?: number;
  transfers_remaining?: number;
  transfer_penalties?: number;
}

export interface League {
  id?: number;
  type: string;
  official_league_id: string;
  title: string;
  created_date?: string;
  entry_code?: string;
  entry_fee?: number;
  is_private: boolean;
  reward_type: string;
  reward_description: string;
  end_round: number;
  is_open: boolean;
  join_deadline?: string | null;
  disclaimer: string;
  has_ended: boolean;
}

interface JoinLeagueModalProps {
  onClose: () => void;
  code: string;
  official_league_id: string | null;
  league: League | null;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const JoinLeagueModal: React.FC<JoinLeagueModalProps> = ({
  code,
  official_league_id,
  league,
  setIsModalOpen,
}) => {
  //const [isModalOpen, setIsModalOpen] = useState(true);
  const [, setTeamName] = useState('');
  const { user } = useAuth();
  const accessToken = user?.access_token;
  const [isLeagueJoined, setIsLeagueJoined] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [, setOfficialLeagueId] = useState<string | null>(null);
  const [teamValues, setTeamValues] = useState('All Teams');
  const [, setCodes] = useState<string | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamFiltered, setTeamFiltered] = useState<Team>();
  const [leagues, setLeagues] = useState<League | null>();
  //const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleChooseLeague = () => {
    handleClose();
    navigate('/auth/games-dash/fantasy/leagues');
  };

  useEffect(() => {
    // This code will run whenever club_id or official_league_id changes

    setCodes(code);
    setOfficialLeagueId(official_league_id);
    setLeagues(league);

    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATAHUB_URL}/fantasy-teams/fantasy-teams-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // const filteredTeams = response.data.filter(
        //   (team: Team) => team.official_league_id === official_league_id
        // );
        setTeams(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [code, official_league_id, league]);

  const handleJoinLeague = async () => {
    ///teamValue =
    //Get club_id
    if (teamValues === 'All Teams') {
      alert('No team selected');
      return;
    }

    setIsLoading(true);
    //Get user's club_id, and create the team
    // console.log('Filtered team ', teamFiltered);
    try {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/fantasy-leagues/join-league`,
          {
            team: teamFiltered,
            league: leagues,
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setIsLeagueJoined(true);
            //console.log('Team created: ', response.data);
          } else {
            // Handle non-successful response
            // eslint-disable-next-line no-console
            console.error('Request failed with status', response.status);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // Handle network error
          // eslint-disable-next-line no-console
          console.error('Network error', error);
          setIsLoading(false);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating team: ', error);
      setIsLoading(false);
    }
  };

  const teamsDropDown = () => {
    const teamsItems: Array<React.ReactNode> = [
      <option key={'10000'} value={'All Teams'}>
        {'All Teams'}
      </option>,
    ];
    teams.forEach((team, index) => {
      teamsItems.push(
        <option key={index} value={team.name}>
          {team.name}
        </option>
      );
    });
    return teamsItems;
  };

  const handleTeamChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'All Teams') {
      const selectedTeam = event.target.value as string;
      if (selectedTeam) {
        setTeamName(selectedTeam);
        setTeamValues(selectedTeam);
        const filteredTeam = teams.filter(
          (team: Team) => team.name === selectedTeam
        );
        const empTeam: Team = {
          id: filteredTeam[0].id,
          name: filteredTeam[0].name,
          club_id: filteredTeam[0].club_id,
          official_league_id: filteredTeam[0].official_league_id,
          created_date: filteredTeam[0].created_date,
          team_value: filteredTeam[0].team_value,
          balance: filteredTeam[0].balance,
          round_score: filteredTeam[0].round_score,
          transfers_remaining: filteredTeam[0].transfers_remaining,
          transfer_penalties: filteredTeam[0].transfers_remaining,
        };
        setTeamFiltered(empTeam);
      }
    } else {
      setTeamValues('All Teams');
    }
  };

  return (
    <div className="match-modal">
      <div className="modal-name-close">
        <div className="modal-img-div">
          <img className="create-team-modal-img" src="/icons/CreateTeam.svg" />
        </div>
        <h1 className="modal-close-x" onClick={handleClose}>
          X
        </h1>
      </div>
      {isLoading ? (
        <>
          <h1 className="modal-name">Joining league...</h1>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <div className="loading-spinner">
              <img
                src="/img/loading2.gif"
                alt="Loading"
                style={{ height: '100px', borderRadius: '50px' }}
              />
            </div>
          </div>
        </>
      ) : isLeagueJoined ? (
        <>
          <h1 className="modal-name">Successfully Joined</h1>
          <h2 className="modal-description">
            Take your team and play them to start winning prizes.
          </h2>
          <div className="modal-buttons">
            <button className="modal-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create" onClick={handleChooseLeague}>
              Goto Leagues
            </button>
          </div>
        </>
      ) : (
        <>
          <h1 className="modal-name">Join League</h1>
          <h2 className="modal-description">
            To join a league, you must choose one of your teams to play.
          </h2>
          <div className="new-team-input">
            <h1 className="modal-name-2">Team Name</h1>
            <select
              name="dobMonth"
              value={teamValues}
              style={{ marginTop: '10px', marginLeft: '-20px' }}
              className="long-verification-select"
              onChange={(e) => {
                handleTeamChange(e);
              }}
              required
            >
              {teamsDropDown()}
            </select>
          </div>
          <div className="modal-buttons">
            <button className="modal-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create" onClick={handleJoinLeague}>
              Join League
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default JoinLeagueModal;
