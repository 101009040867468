import { getAllAthletes } from 'components/Games/games.services';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { IFantasyAthlete } from '../../../d';
import useFantasyTeam from '../Games/useFantasyTeamHook';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
} from '@mui/material';

const Search: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const navigator = useNavigate();
  const token = user?.access_token;
  const [masterList, setmasterList] = useState<IFantasyAthlete[]>([]);
  const [filteredAthletes, setFilteredAthletes] = useState<IFantasyAthlete[]>(
    []
  );
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const { loading, setLoading } = useFantasyTeam();

  React.useEffect(() => {
    const fetchMarketPlayers = async () => {
      if (isAuthenticated && token) {
        try {
          setLoading(true);
          const response = await getAllAthletes({
            token: token,
          });
          setmasterList(response);
          setFilteredAthletes(response);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    fetchMarketPlayers();
  }, []);

  const onPlayerSelect = (selectedPlayer: IFantasyAthlete) => {
    navigator(
      `/auth/games-dash/fantasy/profile?playerId=${selectedPlayer.tracking_id}`
    );
  };

  const handleSearchBar = async (search: string | null) => {
    setIsSearchUsed(true);
    if (search) {
      const filteredPlayers = masterList.filter((player) =>
        player.player_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredAthletes(filteredPlayers);
    } else {
      setFilteredAthletes(masterList);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div
        className="back-breadcrumbs2"
        style={{
          marginTop: '100px',
          marginLeft: '100px',
        }}
      >
        <Button className="backButtonNFT" onClick={() => navigator(-1)}>
          <img
            className="nft-vector"
            src="/icons/back-nft-2.svg"
            alt={'Portfolio'}
          />
          <h1 className="back-word2">Back</h1>
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-60px',
        }}
      >
        <Col sm={'6'} className="search-container">
          <Row
            className="title-autocomplete"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h1 className="search-title">Players</h1>

            <Autocomplete
              style={{
                width: '350px',
                height: '15px',
                color: '#fff',
                background: '#17181c',
                marginBottom: '40px',
                marginTop: '20px',
                cursor: 'pointer',
                marginLeft: '20px',
              }}
              freeSolo
              options={masterList.map((athlete) => athlete.player_name || '')}
              onChange={(_event, selectedValue) =>
                handleSearchBar(selectedValue)
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <div
                  ref={params.InputProps.ref}
                  className="autocomplete-container"
                >
                  <input
                    {...params.inputProps}
                    placeholder={'Search Athletes by Name Here'}
                    type="text"
                    className="form-control theme-input player-input search-text"
                    id="free-solo-demo"
                  />
                </div>
              )}
            />
          </Row>
          <table className="player-table">
            <thead>
              <th
                style={{
                  width: '40%',
                  borderTop: '10px solid #17181c',
                  borderBottom: '10px solid #17181C',
                }}
              >
                Name
              </th>
              <th
                style={{
                  width: '30%',
                  borderTop: '10px solid #17181c',
                  borderBottom: '10px solid #17181C',
                }}
              >
                Team
              </th>
              <th
                style={{
                  width: '30%',
                  borderTop: '10px solid #17181c',
                  borderBottom: '10px solid #17181C',
                }}
              >
                Position
              </th>
            </thead>
            <tbody>
              {isSearchUsed
                ? filteredAthletes.map((player, index) => (
                    <tr
                      key={index}
                      className="player-search-row"
                      onClick={() => onPlayerSelect(player)}
                    >
                      <td>{player.player_name}</td>
                      <td>{player.best_match_team || '-'}</td>
                      <td>{player.position || '-'}</td>
                    </tr>
                  ))
                : masterList.map((player, index) => (
                    <tr
                      key={index}
                      className="player-search-row"
                      onClick={() => onPlayerSelect(player)}
                    >
                      <td>{player.player_name}</td>
                      <td>{player.best_match_team || '-'}</td>
                      <td>{player.position || '-'}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default Search;
