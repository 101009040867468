import React, { useState, useEffect } from 'react';
import MatchListItem from './MatchListItem';
import axios from 'axios';
//import { useAuth } from 'react-oidc-context';

interface Match {
  home_team: string;
  away_team: string;
  team_image_url: string;
  opposition_team_image_url: string;
  opposition_team_name: string;
  game_id: string;
  team_name: string;
  opposition_name: string;
  team_score: number;
  opposition_score: number;
  venue: string;
  kickoff_time: string;
  league_name: string;
  location: string | null;
  round: string | null;
  game_status: string | null;
  game_clock: string | null;
}

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}

type MatchListProps = {
  selectedCompetition: SelectedCompetition | null;
};

const MatchList: React.FC<MatchListProps> = ({ selectedCompetition }) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [showUpcoming, setShowUpcoming] = useState(true);
  //const { user } = useAuth();
  //const token = user?.access_token;
  const [, setCurrentPage] = useState(1);
  const matchesPerPage = 5;

  useEffect(() => {
    //console.log('Fetching matches...: ', selectedCompetition);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/entities/competition-games/${selectedCompetition?.id}`
      )
      .then((response) => {
        const data = response.data.slice(0, 30).reverse();
        // eslint-disable-next-line no-console
        console.log('Match data: ', data);
        setMatches(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching match data: ', error);
      });
  }, [selectedCompetition]);

  const switchToUpcoming = () => {
    setShowUpcoming(true);
    setCurrentPage(1);
  };

  const switchToPrevious = () => {
    setShowUpcoming(false);
    setCurrentPage(1);
  };

  //   const indexOfLastMatch = currentPage * matchesPerPage;
  //   const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;

  const currentMatches = matches
    .filter((match) => {
      const kickoffTime = new Date(match.kickoff_time);
      return showUpcoming
        ? kickoffTime > new Date()
        : kickoffTime <= new Date();
    })
    .sort((a, b) => {
      const dateA = new Date(a.kickoff_time);
      const dateB = new Date(b.kickoff_time);
      return dateB.getTime() - dateA.getTime(); // reversed the subtraction
    })
    .map((match) => {
      const kickoffTime = new Date(match.kickoff_time);
      if (kickoffTime <= new Date()) {
        match.kickoff_time = kickoffTime.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'long',
          year: '2-digit',
        });
      }
      return match;
    });

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(matches.length / matchesPerPage); i++) {
    pageNumbers.push(i);
  }

  const sortedMatches = [...currentMatches].sort((a, b) => {
    const dateA = new Date(a.kickoff_time);
    const dateB = new Date(b.kickoff_time);

    // If Upcoming Matches is selected, sort in ascending order
    // If Previous Matches is selected, sort in descending order
    return showUpcoming
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });

  return (
    <div className="matches-container">
      <h1 className="match-container-title" style={{ marginBottom: '32px' }}>
        Matches
      </h1>
      <div className="div-buttons">
        <div style={{ marginTop: '-15px' }}>
          <button
            className={`match-tabs ${showUpcoming ? 'selected-tab' : ''}`}
            onClick={switchToUpcoming}
            style={{ marginBottom: '32px', marginLeft: '-10px' }}
          >
            Upcoming Matches
          </button>
          <button
            className={`match-tabs ${!showUpcoming ? 'selected-tab' : ''}`}
            onClick={switchToPrevious}
          >
            Previous Matches
          </button>
        </div>
      </div>
      <div className="matches-containers">
        {sortedMatches.map((match) => {
          const kickoffDate = new Date(match.kickoff_time);
          const dateOptions = {
            year: 'numeric' as const,
            month: 'long' as const,
            day: 'numeric' as const,
          };
          const formattedDate = kickoffDate.toLocaleDateString(
            undefined,
            dateOptions
          );

          return (
            <MatchListItem
              key={match.game_id}
              homeTeamImage={match.team_image_url}
              homeTeamName={match.home_team}
              homeTeamScore={match.team_score}
              awayTeamScore={match.opposition_score}
              awayTeamImage={match.opposition_team_image_url}
              awayTeamName={match.away_team}
              location={match.venue || 'N/A'}
              targetTime={
                match.game_status === 'completed'
                  ? formattedDate
                  : match.kickoff_time
              }
            />
          );
        })}
      </div>

      {/* <div className="pagination-matches">
        <button
          className="pagination-button-group"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src="icons/arrow-left.svg" />
          <h1 className="pagination-text">Previous</h1>
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`pagination-button ${
              currentPage === number ? 'active' : ''
            }`}
          >
            <h1 className="pagination-text">{number}</h1>
          </button>
        ))}
        <button
          className="pagination-button-group"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === Math.ceil(matches.length / matchesPerPage)}
        >
          <h1 className="pagination-text">Next</h1>
          <img src="icons/arrow-right.svg" />
        </button>
      </div> */}
    </div>
  );
};

export default MatchList;
