import { createContext } from 'react';
import { ITeam } from '../../d';

import {
  coachObject,
  refereeObject,
  rosterObject,
  substituteObject,
} from './GameContext';

interface ITeamContext {
  team: ITeam | null;
  setTeam?: (team: ITeam) => void;
}

export const sampleTeam: ITeam = {
  name: 'Sample Team',
  abbreviation: 'SAT',
  seasons: [],
  members: [],
  league_id: '',
  organization_id: '',
  sport_id: '',
  os_frame_link: process.env.REACT_APP_OPENSEARCH_LINK
    ? process.env.REACT_APP_OPENSEARCH_LINK
    : 'https://qa-opensearch.athstat-next.com/app/home',
  saved_roster: [rosterObject],
  saved_coaches: [coachObject],
  saved_referees: [refereeObject],
  saved_substitutions: [substituteObject],
  packages: [],
  hidden: false,
};

export const sampleTeamContext: ITeamContext = {
  team: sampleTeam,
};

export const TeamContext = createContext<ITeamContext>(sampleTeamContext);
