import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import { IAthletePriceChange, IFantasyAthlete } from '../../../d';
import PlayerInformation from './playerInfo';
import PowerRankings from './powerRankings';

export const PlayerProfile: React.FC = () => {
  const [activeItem, setActiveId] = React.useState(1);
  const [player, setPlayer] = useState<IFantasyAthlete>();
  const [priceChanges, setPriceChanges] = useState<IAthletePriceChange[]>();
  const { user, isAuthenticated } = useAuth();
  const token = user?.access_token;
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get('playerId');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        setIsLoading(true);
        if (isAuthenticated && token) {
          const response = await axios.get(
            `${process.env.REACT_APP_DATAHUB_URL}/athletes/${playerId}`,
            {
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            }
          );
          setPlayer(response.data[0]);
        }
      } catch (error) {
        return error;
      }
      setIsLoading(false);
    };

    fetchPlayer();
  }, [playerId]);

  useEffect(() => {
    const fetchPriceHistory = async () => {
      try {
        setIsLoading(true);
        if (isAuthenticated && token) {
          const response = await axios.get(
            `${process.env.REACT_APP_DATAHUB_URL}/fantasy-athletes/price-changes/${playerId}`,
            {
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            }
          );
          setPriceChanges(response.data);
        }
      } catch (error) {
        return error;
      }
      setIsLoading(false);
    };

    fetchPriceHistory();
  }, [playerId]);

  const toggle = (tab: React.SetStateAction<number>) => () => {
    if (activeItem !== tab) {
      setActiveId(tab);
    }
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <div className="Tab-container Dashboard-container">
            <div className="back-breadcrumbs2">
              <Button className="backButtonNFT" onClick={() => navigator(-1)}>
                <img
                  className="nft-vector"
                  src="/icons/back-nft-2.svg"
                  alt={'Portfolio'}
                />
                <h1 className="back-word2">Back</h1>
              </Button>
            </div>
            <Row className="">
              <Col size="12">
                <div className="PageTitle">
                  <h2>Loading...</h2>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  } else if (!player) {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <div className="back-breadcrumbs2">
            <Button className="backButtonNFT" onClick={() => navigator(-1)}>
              <img
                className="nft-vector"
                src="/icons/back-nft-2.svg"
                alt={'Portfolio'}
              />
              <h1 className="back-word2">Back</h1>
            </Button>
          </div>

          <div className="Tab-container Dashboard-container">
            <Row className="">
              <Col size="12">
                <div className="PageTitle">
                  <h2>Error: Player loaded Incorrectly.</h2>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <div className="back-breadcrumbs2">
            <Button className="backButtonNFT" onClick={() => navigator(-1)}>
              <img
                className="nft-vector"
                src="/icons/back-nft-2.svg"
                alt={'Portfolio'}
              />
              <h1 className="back-word2">Back</h1>
            </Button>
          </div>
          <div className="Tab-container Dashboard-container player-profile-container">
            <Row>
              <Col size="8">
                <h2 className="player-profile-name">{player?.player_name}</h2>
              </Col>
            </Row>

            <Tabs defaultActiveKey={1} className="nav-tabs2" id="ThemeTabs2">
              <Tab
                className="profile-tab"
                eventKey={1}
                onClick={toggle(1)}
                role="tab"
                title={'Information'}
              >
                <div className="profile-tab-container">
                  <PlayerInformation player={player} data={priceChanges} />
                </div>
              </Tab>
              <Tab
                className="profile-tab"
                eventKey={2}
                onClick={toggle(2)}
                role="tab"
                title={'Power Rankings'}
              >
                <div className="Stat-Tab-container">
                  <PowerRankings player={player} />
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
      </React.Fragment>
    );
  }
};
export default PlayerProfile;
