import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const GamesEULA: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div className="PageTitle">
              <h2>Athstat Games: Paid Fantasy Terms of Service</h2>
            </div>
          </Col>
        </Row>
        <h2 className="privacy-policy-header">1. ACCEPTANCE OF TERMS</h2>
        <h3 className="privacy-policy-paragraph">
          This Athstat Gaming Paid Fantasy Terms of Service (“Paid Fantasy TOS”)
          sets forth terms and conditions, in addition to the{' '}
          <a className="onboarding-link" href="https://www.athstat.io/terms">
            Athstat Terms of Service (“TOS”)
          </a>
          and that{' '}
          <a
            className="onboarding-link"
            href="https://www.athstat.io/privacy-policy"
          >
            Privacy Policy
          </a>
          , that govern your access to and use of the Athstat Gaming Paid
          Fantasy services (“Athstat Gaming Paid Fantasy” or the “Services”) and
          your participation in the contests offered therein (each, a
          “Contest”). Athstat Gaming Paid Fantasy services currently include
          Athstat Gaming Daily Fantasy (DFS). This DFS game includes both a paid
          service as well as a free service. Athstat Gaming is wholly owned by
          Athstat LLC, a Commonwealth of Virginia Limited Liability Company
          (LLC).
        </h3>
        <h3 className="privacy-policy-paragraph">
          This Paid Fantasy TOS and the TOS, together with any additional terms,
          addendums, or documents that may be referenced herein and therein
          (collectively, “Agreement”), form the entire agreement between you and
          Athstat LLC and its affiliates (collectively, “Athstat”) in connection
          with the Services. Your use, or continued use of the Services
          constitutes acceptance of the Agreement. Do not use the Services if
          you do not wish to be bound by the Agreement. Athstat may update and
          change this Agreement at any time without notice to you; your access
          to and/or use of the Services after any such change means you accept
          such change. At any time you can review the most current version of
          the Paid Fantasy TOS at by accessing the app, currently located in the
          Apple App Store and Android Play Store.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Capitalized terms not defined in this Paid Fantasy TOS have the
          meanings ascribed to them in the TOS. Certain defined terms in this
          Paid Fantasy TOS are included in the definitions of capitalized terms
          in the TOS. In particular, Athstat Gaming Paid Fantasy is a “
          Service”; accordingly, all references in the TOS to Service
          incorporate by reference Athstat Gaming Paid Fantasy. If there is a
          conflict between the terms of the Paid Fantasy TOS and the TOS, the
          terms of the Paid Fantasy TOS shall control. The imposition of
          additional enumerated restrictions or liabilities on your actions or
          omissions, by one document over the other, shall not be considered in
          and of itself a conflict.
        </h3>
        <h2 className="privacy-policy-header">2. ELIGIBILITY</h2>
        <h3 className="privacy-policy-paragraph">
          The Contests are void where prohibited. To be eligible to enter any
          paid Contest, you must meet all of the following conditions:
        </h3>
        <h3 className="privacy-policy-paragraph">
          You are at least 18 years of age (19 if located in AL or NE, or 21 if
          located in AZ or MA);
        </h3>
        <h3 className="privacy-policy-paragraph">
          You are physically located in the following regions within the United
          States at the time of contest entry: the District of Columbia, and the
          following States: Alaska, Arkansas, California, District of Columbia,
          Florida, Georgia, Illinois, Kansas, Kentucky, Massachusetts, Maryland,
          Minnesota, North Carolina, North Dakota, Nebraska, New Hampshire, New
          Mexico, New York, Oklahoma, Oregon, Rhode Island, South Carolina,
          South Dakota, Texas, Utah, Wisconsin, West Virginia, Wyoming.
          collectively, the “Eligibility Area”
        </h3>
        <h3 className="privacy-policy-paragraph">
          You are physically located in the Eligibility Area at the time of your
          entry into a Contest; and
        </h3>
        <h3 className="privacy-policy-paragraph">
          You are not listed on any US Government prohibited or watch list.
        </h3>
        <h3 className="privacy-policy-paragraph">
          You may also be required to sign and return an Affidavit of
          Eligibility as proof of your eligibility to participate in the
          Contests. You will be required to provide this information within ten
          (10) days after Athstat first attempts to contact you. If you fail to
          comply with this deadline, Athstat may disqualify you from further
          participation, and withhold or revoke any prizes at its sole
          discretion. If Athstat chooses to disqualify you on this basis, it
          will refund the Contest entry fee for the Contest from which you were
          disqualified.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Athstat may, at its sole discretion, restrict or limit users from
          entering certain contests based on their contest play history and
          results.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Employees and agents of Athstat, its corporate affiliates,
          subsidiaries, advertising and promotional agencies, any other prize
          sponsor and any entity involved in the development, production,
          implementation, administration or fulfillment of the Contests (all the
          foregoing, together with Athstat, collectively referred to as “Contest
          Entities”), and the immediate family members and persons living in the
          same household as any of the above may not participate in any publicly
          available paid Contests or free Contests with cash or cash equivalent
          prizes except for purposes of testing the user experience on a limited
          basis. Participation in free Contests with no prizes and private paid
          Contests is permitted.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Athletes, coaches, and other team management, team support personnel,
          and team owners are not eligible for any Athstat Contests in the sport
          or sports in which their team competes. Team owners, referees, league
          employees, sports commissioners, sports agents and other individuals
          who, through an ownership interest or game-related employment, can
          influence gameplay in the real world game are also ineligible.
        </h3>
        <h2 className="privacy-policy-header">3. HOW TO REGISTER</h2>
        <h3 className="privacy-policy-paragraph">
          A valid Athstat ID (also known as a User ID) is required to
          participate in Athstat Gaming Paid Fantasy. If you do not have an
          Athstat ID, you will be prompted to sign up for one before being
          permitted to enter or participate in a Contest offered on the Service.
          Please ensure that your contact information is accurate, up-to-date,
          and complete, including a valid email address. This information is
          mandatory if you wish to be eligible to win and collect prizes. A user
          who has not provided valid registration information will automatically
          be deemed ineligible and will not be considered for a prize. A valid
          PayPal Bank / ACH account or credit card is also required to add funds
          to your Athstat Fantasy Sports account and to receive and collect a
          prize. A valid PayPal, ACH account or credit card may only be
          associated with a single Athstat ID.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Please note that Contests may only be played under the Athstat ID with
          which they were created or entered, and may not be transferred to
          another Athstat ID or played by another person. Also, you may not
          share an account with another person or allow another user to play
          under your Athstat ID. Likewise, you may not enter a Contest on behalf
          of someone without an Athstat ID, or that is ineligible to participate
          in the Contest. As a rule, each Contest entrant (Entrant) is allowed
          to play under one Athstat ID. It is at Athstat&#34;s sole discretion
          and judgment to determine whether multiple Athstat IDs belong to the
          same Entrant. If you are found to be participating in the Contests
          using more than one Athstat ID, such penalties may include, but are
          not limited to, disqualification from current and future Contests and
          forfeiture of Contest entry fee(s) or potential winnings.
        </h3>
        <h2 className="privacy-policy-header">4. GAME RULES</h2>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>How To Play</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Daily Fantasy In addition to these rules, additional rules,
          instructions and guidelines regarding game play and scoring (which are
          fully incorporated into this Paid Fantasy TOS by reference) are listed
          in the Official Rules section below under “Official Daily Fantasy
          Rules”. In the event of a conflict between the Official Daily Fantasy
          Rules and the Paid Fantasy TOS, the Paid Fantasy TOS will control.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>General Rules</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          1. User must be 18 years of age or older and live in an area where
          fantasy games are legal in order to participate in Athstat games.{' '}
          <br /> 2. A user may only create one team per competition, but may
          sign up for multiple competitions. Within a competition, user may
          enter multiple leagues with their team.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Terms:</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Player = User who logs in to create an account and play Athstat Daily
          <br />
          <br />
          Fantasy games Team = Created by the user by picking 5 athletes playing
          in the real life version of the game <br />
          <br /> Competition = An event created in the Athstat DFS that users
          can join, usually mapped to a real life event such as the 2023 Rugby
          World Cup Championship <br /> <br /> League = a user- or
          Athstat-managed group of teams that compete against each other within
          the overall Competition. The leagues can be paid-to-enter with cash
          prizes or free to enter with no cash prices <br />
          <br />
          In-app-currency = virtual dollars ($) assigned to a player to use in
          the buying and trading of athletes on their team. These are freely
          provided for each competition and a user starts off with $100 for each
          competition they enter <br />
          <br /> AthCoins = unique Athstat Currency that is purchased using
          cash.
          <br />
          <br />1 AthCoin = $1USD. AthCoins are used to enter paid-for leagues
          that have cash prizes.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Creating Teams:</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          3. Each Team Consists of 5 Athletes. <br /> 4. Users may swap players
          in and out of their teams at any moment. <br />
          5. Athlete Prices will be based on current market values.
          <br /> 6. Each player starts with an initial amount of $100 (in-app
          currency) to buy and sell players. <br />
          7. In-app currency is not able to be purchased using real-world
          currency.
          <br /> 8. In-app currency is earned/lost based on a users trading
          decisions.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Joining Leagues</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          9. Once a user has created a team, they can join a league. <br />{' '}
          <br />
          10. There are two types of leagues: <br />
          <span style={{ textIndent: '25px' }}>
            a. Athstat Leagues - <br />
          </span>
          <span style={{ textIndent: '40px' }}>
            i. Cost- Athstat Leagues can be free or paid. For paid leagues,
            users will enter a league with AthCoins (See XX. Cost of Athcoins).
            The amount of AthCoins it will take to join a league will vary from
            league to league, however, the cost (in AthCoins) will be listed
            before a user can register for a league. <br />
            ii. Duration - Athstat leagues will take place over either 2 Weeks,
            1 Month or a Sports Season. At the end of which a prize of
            real-world currency will be awarded (see iv. Prizes)
            <br />
            iii. Prizes - Real-word currency prize that will be announced to all
            users before they are allowed to register. (see 18. Prizes) <br />{' '}
            <br />
          </span>
          b. User Leagues - <br />
          i. Cost- Always Free to enter <br />
          ii. Duration - Season. <br />
          iii. Prizes - None. <br /> <br />
          11. Upon joining Athstat Games, a user automatically receives 1
          AthCoin that can be used to join an Athstat backed paid league for a
          chance to win Prizes (see #19-20).. A User may create leagues for
          other users to join. User created leagues are free and do not cost any
          AthCoins. <br />
          12. A user-based league does not have any prize pool. <br />
          13. When a User joins a league. Their initial score is zero. <br />
          14. The user’s score will be updated as the Teams Value changes, and
          will be calculated as the difference between the initial entry team
          value and the current team value. <br />
          15. The difference between the initial entry team value and the
          current team value, is what will determine whether or not they have
          won the league. See item 17 for how points are awarded. <br /> 16. The
          1st place winner of the league will win the entirety of the cash
          price. If there is a tie for first, the cash prize will be split
          equally.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Points</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          17. A users value will be changed as follows:
        </h3>
        <img
          src="/img/game-points.png"
          alt={'games admin'}
          style={{ height: '500px', marginLeft: '100px' }}
        />

        <h3 className="privacy-policy-paragraph">
          18. An Athstat analysis model automatically creates and updates user’s
          points. This model is proprietary - no user may access the code,
          infrastructure or raw output from the model at any time.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Prizes</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          19. Athstat will announce prizes for each league at least 24 hours
          prior to the league starting <br />
          <br />
          20. For Athstat-created paid leagues, the top user of each league will
          be deemed the winner of the league. In order to receive their prize
          the following must occur: <br />
          a. The winner of the prize shall be notified by email within 5
          business days of the conclusion of the league and, in each case,
          provided details of how to claim their prize. <br />
          b. Winners must be 18+ years of age and live in an area where fantasy
          games are legal. We reserve the right to ask for identification to
          verify this information.
          <br /> c. If a winner does not respond to Athstat within 14 days of
          being notified of their win. Athstat reserves the right to offer the
          prize to the user with the second highest score. This user again will
          be notified by email, and will be given details of how to claim their
          prize.
          <br /> d. If a winner declines the prize, or the winner is invalid or
          in breach of Athstat’s Rules and End User Licensing Agreement, that
          winner’s prize will be forfeited. In this case, Athstat reserves the
          right to offer the prize to the user with the second highest score.{' '}
          <br />
          e. Once the winner of the prize has been selected, proof that a valid
          winner was awarded a prize can be obtained by sending an email to
          <a className="onboarding-link" href="mailto:gaming@athstat.com">
            gaming@athstat.com
          </a>
        </h3>

        <h3 className="privacy-policy-paragraph">
          All prizes offered are established and made known to Contest entrants
          in advance of each Contest and are listed on each respective Contest
          page. Shortly following the conclusion of each Contest, Athstat Gaming
          will display the list of potential Contest winners (subject to
          verification). These Contest results will not be changed due to any
          subsequent official adjustments of statistics by Athstat’s data
          providers unless otherwise determined by Athstat at its sole
          discretion. In the event of a tie, Athstat will combine the winning
          prizes and evenly distribute the prizes to the tied entrants. For
          example, if three participants tie for 1st place, with 1st place
          winning $100, 2nd place winning $50, and 3rd place winning $30, each
          of the tied entrants will receive $60. Winners will be contacted via
          email by Athstat Gaming personnel, using the email address provided in
          the app. Athstat personnel will connect secure banking and/or PayPal
          information and distribute prize money within 3 business days.
        </h3>
        <h3 className="privacy-policy-paragraph">
          All potential winners are subject to verification by Athstat, and must
          meet all eligibility requirements before a prize will be awarded.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Prizes may not be transferred or assigned except by Athstat. Only
          listed prizes will be awarded and no substitutions, equivalents or
          redemptions will be made. Expenses not specifically stated in this
          Paid Fantasy TOS, together with the reporting and payment of all
          applicable taxes, fees, and/or surcharges, if any, arising out of, or
          resulting from, acceptance or use of a prize, are the sole
          responsibility of the winner of that prize. A potential winner may be
          required to provide additional documentation (such as social security
          number) in order to comply with tax and other legal reporting
          requirements. Each winner may receive an IRS Form 1099 for the value
          of the prize awarded. As required by law in certain jurisdictions,
          Athstat may withhold verified prize winnings from entrants with
          delinquent child support obligations. Contest Entities expressly
          disclaim any responsibility or liability for injury or loss to any
          person or property relating to the delivery and/or subsequent use of
          the prizes awarded. Contest Entities make no representations or
          warranties concerning the appearance, safety, or performance of any
          prize awarded. Any unclaimed prizes will not be awarded. Restrictions,
          conditions, and limitations apply. Contest Entities will not replace
          any lost or stolen prize items.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Accessing Funds:</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Cash deposits for entry fees and cash prize winnings are held by
          Athstat in one or more separate, segregated accounts (each, a
          &#34;Segregated Account&#34;). The funds held in the Segregated
          Account(s) do not belong to Athstat or the Contest Entities and it is
          our intention and practice to take all reasonable actions to make sure
          that such funds are not available to creditors of Athstat other than
          to players whose funds are held in the Segregated Account. Athstat and
          the Contest Entities do not and will not commingle the funds in the
          Segregated Account with any other funds belonging to Athstat or the
          Contest Entities. Athstat and the Contest Entities do not and will not
          use the funds in the Segregated Account for its operating expenses or
          any other purpose. If you believe that funds in your Athstat Fantasy
          Sports account have been misallocated, compromised or otherwise
          mishandled, you may contact us online using the &#34;Contact us&#34;
          link in the footers of Athstat Gaming Paid Fantasy websites, the
          “Help” section of the Athstat Fantasy app or the Contact Us links
          below.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Entrants may access cash prize winnings and any cash deposits for
          entry fees (excluding bonuses and promotions) from their Athstat
          Fantasy Sports account once funds have settled. Requests for access to
          funds will be honored by the later of five business days of the
          request or ten business days of the submission of any tax reporting
          documentation required by applicable law, unless Athstat believes in
          good faith that the Entrant engaged in fraudulent conduct or other
          conduct that would cause Athstat to be in violation of applicable law.
          Available funds will be credited directly into the Entrant&#34;s
          PayPal account, or such other means as Athstat determines in its sole
          discretion. Please review the{' '}
          <a
            className="onboarding-link"
            href="https://www.paypal.com/us/legalhub/useragreement-full"
          >
            PayPal User Agreement
          </a>{' '}
          for more information.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Bonuses and promotions may be offered by Athstat Gaming Paid Fantasy
          from time-to-time. Any cash bonuses and promotions an entrant receives
          may not be available to access from your Athstat Fantasy Sports
          account unless certain conditions are met as stated by the terms for
          each applicable bonus, or promotion. These terms may include, but are
          not limited to, using the bonus to enter into at least one Contest
          with an entry fee greater than zero dollars. Any attempted abuse of
          bonuses is prohibited, and may result in the loss of bonuses, and the
          suspension of all playing privileges on Athstat Gaming Paid Fantasy.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Accounts may be deemed dormant or inactive for the amount of time
          specified by applicable state law, usually between thirty-six (36) and
          sixty (60) months. Once an account is classified as dormant, any
          remaining funds held in the account will be handled in accordance with
          applicable state laws to remit abandoned funds to the appropriate
          state agency as unclaimed property.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Cancellation Policy:</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          If any Contest is not capable of running as planned, for any reason
          including infection by computer virus, bugs, tampering, unauthorized
          intervention, fraud, technical failures, or any other causes beyond
          the control of Contest Entities which corrupt or affect the
          administration, security, fairness, integrity or proper conduct of any
          Contest, Athstat reserves the right at its sole discretion to cancel,
          terminate, modify, or suspend a Contest without any further
          obligation.
        </h3>
        <h3 className="privacy-policy-paragraph">
          In the event Athstat is prevented from continuing with a Contest or
          the integrity and/or feasibility of a Contest is severely undermined
          by any event beyond the control of Athstat, including but not limited
          to fire, flood, epidemic, earthquake, explosion, labor dispute or
          strike, act of God or public enemy, satellite or equipment failure,
          riot or civil disturbance, war (declared or undeclared), terrorist
          threat or activity, or any federal, state or local government law,
          order, or regulation, order of any court or jurisdiction, infection by
          computer virus, unauthorized intervention, technical failures or other
          cause not reasonably within the control of Athstat (each a “Force
          Majeure” event or occurrence), Athstat reserves the right at its sole
          discretion to cancel, terminate, modify, or suspend a Contest without
          any further obligation.
        </h3>
        <h5 className="privacy-policy-sub-header">
          {' '}
          <em>Player Identification:</em>
        </h5>
        <h3 className="privacy-policy-paragraph">
          Athstat reserves the right to identify and label players who meet
          certain criteria (including but not limited to frequency of play,
          frequency of wins) in a manner visible to other players. Such criteria
          and labels will be determined by Athstat in its sole discretion and
          may be changed or modified at any time, with or without notice, by
          Athstat.
        </h3>
        <h2 className="privacy-policy-header">5. CONDUCT</h2>
        <h3 className="privacy-policy-paragraph">
          By accessing and using Athstat Gaming Paid Fantasy and entering the
          Contests, entrants agree to be bound by the decisions of the Contest
          Entities, which are final and binding in all respects. Athstat
          reserves the right at its sole discretion to disqualify any individual
          it finds to be (a) tampering or attempting to tamper with the entry
          process or the operation of Athstat Gaming Paid Fantasy, any Contest,
          or any Athstat website; (b) violating the Official Daily Fantasy
          Rules, the Official Best Ball Rules, the Official Public Prize League
          Rules, this Paid Fantasy TOS, or any other guidelines, rules, or
          instructions associated with Athstat Gaming Paid Fantasy or the
          Contests; (c) violating the TOS, conditions of use and/or general
          rules or guidelines of any other Services; or (d) acting in an
          unsportsmanlike or disruptive manner, or with intent to annoy, abuse,
          threaten or harass any other person.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Actions deemed as improper conduct include, but are not limited to,
          those actions outlined in the “Member Conduct” section of the TOS, as
          well as the following:
        </h3>
        <ul className="privacy-list">
          <li className="privacy-list-item">
            {' '}
            - Providing false information about your eligibility to enter
            Contests and collect prizes
          </li>
          <li className="privacy-list-item">
            {' '}
            - Using any fraudulent, or unauthorized credit cards, or payment
            methods
          </li>
          <li className="privacy-list-item">
            {' '}
            - Using any automated services or routines including, but not
            limited to, robots, web crawlers, spiders, ants, and scrapers to
            access and/or collect information on any Athstat site without
            explicit written consent from Athstat; this includes uploading,
            editing or otherwise modifying lineups using any automated scripts
            or tools or otherwise interacting with Athstat Gaming Paid Fantasy
            using methods or technology other than the Athstat-provided
            interface and technology
          </li>
          <li className="privacy-list-item">
            {' '}
            - Attempting to abuse, as determined solely at Athstat&#34;s
            discretion, bonuses and promotions
          </li>
          <li className="privacy-list-item">
            {' '}
            -Attempting to tamper with, or alter a real-life sporting event that
            is directly connected with the Contests in which you have direct
            involvement or interest
          </li>
          <li className="privacy-list-item">
            {' '}
            -Sharing your lineup with other individual(s) prior to a contest
            starting, including for purposes of engaging in syndicate play or
            otherwise attempting to collude with other players
          </li>
          <li className="privacy-list-item">
            {' '}
            -Selling any stakes in your own play, buying stakes in other
            players, or other means of redistributing entry fees or winnings
            based on pre-existing agreements or relationships with other players
          </li>
          <li className="privacy-list-item">
            {' '}
            -Buying or selling lineups to be used on Athstat Gaming Paid
            Fantasy.
          </li>
        </ul>
        <h3 className="privacy-policy-paragraph">
          If you engage in conduct that Athstat determines, in its sole
          discretion, is harmful or detrimental to other Contest entrants or is
          unfair, fraudulent or otherwise negatively impacts Athstat Gaming Paid
          Fantasy or if you create a Athstat ID, nickname, or Contest name that
          Athstat deems, in its sole discretion, to be abusive, defamatory,
          harassing, hateful, indecent, objectionable, offensive, or vulgar, we
          reserve the right to cancel any Contests you created, cancel any of
          your Contest entries, refuse to award prizes, limit your participation
          in Contests, and block your Athstat Fantasy Sports account from any
          future activity.
        </h3>
        <h3 className="privacy-policy-paragraph">
          If you, as an Entrant, notice any improper conduct, please notify our
          Customer Care team at{' '}
          <a className="onboarding-link" href="mailto:gaming@athstat.com">
            gaming@athstat.com
          </a>{' '}
          at once so that we can investigate and take the appropriate action, if
          any is deemed necessary, against the offending entrants and accounts.
        </h3>
        <h3 className="privacy-policy-paragraph">
          Please note that a violation of this Paid Fantasy TOS, DFS rules,
          conditions of use, and/or the general guidelines of any Service may be
          enforced against all other activities associated with the offending
          Athstat ID. If your Athstat ID is found to be in violation of the
          terms of service or official rules of another Service, that violation
          is grounds for Athstat to suspend or terminate your access to all
          products or Services played under your Athstat ID - including Athstat
          Gaming Paid Fantasy. Your Contest entry fee(s) or potential winnings
          may be forfeited if Athstat cancels any Contests you created, cancels
          any of your Contest entries, or blocks your Athstat Fantasy Sports
          account from any future activity as result of your violation of the
          Agreement.
        </h3>
        <h3 className="privacy-policy-paragraph">
          CAUTION: ANY ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO
          DELIBERATELY DAMAGE ANY WEBSITE OR MOBILE APPLICATION OR UNDERMINE THE
          LEGITIMATE OPERATION OF Athstat Gaming PAID FANTASY OR THE CONTESTS
          MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS. SHOULD SUCH AN ATTEMPT
          BE MADE, Athstat RESERVES THE RIGHT TO IMMEDIATELY TERMINATE THE
          ENTRANT&#34;S ACCOUNT, IF ANY, AND SEEK DAMAGES FROM ANY SUCH PERSON
          TO THE FULLEST EXTENT PERMITTED BY LAW.
        </h3>
        <h2 className="privacy-policy-header">6. GENERAL CONDITIONS</h2>
        <h3 className="privacy-policy-paragraph">
          The Contests are governed by the laws of the United States, without
          regard to its conflict of laws principles, and are subject to all
          applicable federal, state, and local laws and regulations, and is void
          where prohibited by law. Athstat Gaming Paid Fantasy is offered for
          entertainment purposes only and may not be used in connection with any
          form of gambling or wagering. By entering, each Entrant: (a) releases
          and holds harmless the Contest Entities, including, without
          limitation, the respective directors, officers, employees and agents
          of each, from any and all liability for any injuries, loss or damage
          of any kind to persons, including death, or property damage resulting
          in whole or in party, directly or indirectly, from the use of Athstat
          Gaming Paid Fantasy, acceptance, possession, misuse or use of any
          prize, participation in the Contests, or while traveling to, preparing
          for, or participating in any prize-related activity, bankruptcy,
          addictive behavior or any other state that causes harm to Entrant; and
          (b) grants to Contest Entities the right to use and publish Athstat ID
          or nickname, city/state of residence, score, and Contest ranking, in
          any and all media now known or hereinafter developed without
          territorial or time limitations, in connection with the Contests.
        </h3>
        <h2 className="privacy-policy-header">7. LIMITATIONS OF LIABILITY</h2>
        <h3 className="privacy-policy-paragraph">
          Contest Entities assume no liability for lost, late, misdirected or
          garbled entries, or for theft, destruction, or unauthorized access to,
          entries. Contest Entities assume no responsibility for any incorrect
          or inaccurate information, whether caused by website users, by any of
          the equipment or programming associated with or utilized in the
          Contest, or by any technical or human error which may occur in the
          processing of submissions or game transactions in connection with the
          Contest. Contest Entities assume no responsibility for any error,
          omission, interruption, deletion, defect, delay in operation or
          transmission, failures, or technical malfunction of any telephone
          network or lines, computer online systems, servers, providers,
          computer equipment, software, email, players, or browsers, whether on
          account of technical problems, traffic congestion on the Internet or
          at any website, or on account of any combination of the foregoing,
          that may occur (including but not limited to any such problems which
          may result in the inability to access the Contest site or to process
          any game transaction(s) in connection with the Contest). Contest
          Entities assume no responsibility for any injury or damage to entrants
          or to any computer related to or resulting from participating in, or
          downloading materials in connection with, Athstat Gaming Paid Fantasy
          or any Contests. If Athstat Gaming Paid Fantasy or any Contest is not
          capable of running as planned, for any reason including infection by
          computer virus, bugs, tampering, unauthorized intervention, fraud,
          technical failures, or any other causes beyond the control of Contest
          Entities which corrupt or affect the administration, security,
          fairness, integrity or proper conduct of Athstat Gaming Paid Fantasy
          or the Contests, Athstat reserves the right at its sole discretion to
          cancel, terminate, modify, or suspend Athstat Gaming Paid Fantasy or
          any Contest.
        </h3>
        <h2 className="privacy-policy-header">8. PRIVACY</h2>
        <h3 className="privacy-policy-paragraph">
          By using Athstat Gaming Paid Fantasy and by entering the Contests, you
          agree to Athstat’s use of your personal information, as described in
          the Athstat Privacy Policy.. You also agree to the use of your
          personal information as stated in the section entitled “Publicity”
          below and you acknowledge and agree that your account activity and
          winnings may be disclosed by Athstat to applicable state or federal
          entities (e.g. Secretary of State, Department of Revenue, etc.).
        </h3>
        <h2 className="privacy-policy-header">9. WINNER&#39;S LIST</h2>
        <h3 className="privacy-policy-paragraph">
          While potential winners may be posted on the Contest site(s) at the
          conclusion of each Contest, all potential winners are still subject to
          verification and must meet all eligibility requirements before a prize
          will be awarded. Lists of verified prize winners may be obtained after
          winner confirmation is complete and within 90 days after conclusion of
          any Contest on Athstat Gaming Paid Fantasy by sending a
          self-addressed, stamped envelope to: Athstat Gaming Paid Fantasy,
          Winners List Requests, 40736 Chevington Lane, Leesburg VA 20175.
        </h3>
        <h2 className="privacy-policy-header">10. PUBLICITY</h2>
        <h3 className="privacy-policy-paragraph">
          Participation in Athstat Gaming Paid Fantasy and acceptance of prizes
          constitutes each winner’s permission for the Contest Entities to use
          his/her name, address (city and state), likeness, photograph, picture,
          portrait, voice, biographical information, and/or any statements made
          by each winner regarding Athstat Gaming Paid Fantasy or Athstat for
          advertising and promotional purposes without notice or additional
          compensation, except where prohibited by law. Additionally, Athstat
          may request that you sign and return a publicity release to confirm
          your grant of rights to Athstat hereunder.
        </h3>
        <h2 className="privacy-policy-header">11. INTELLECTUAL PROPERTY</h2>
        <h3 className="privacy-policy-paragraph">
          Athstat Gaming Paid Fantasy and the Contests and all of the related
          pages, content and code are generally copyrighted by Athstat LLC
          and/or the Contest Entities. Copying or unauthorized use of any
          copyrighted materials, trademarks, or any other intellectual property
          without the express written consent of its owner is strictly
          prohibited. Athstat Gaming Paid Fantasy and Contests are not
          affiliated with, nor endorsed by, the National Football League, the
          National Basketball Association, the National Hockey League, Major
          League Baseball, UEFA Champions League, English Premier League, or any
          of their respective member teams, the Professional Golfers&#39;
          Association, PayPal or any other sports organization, league or
          entity.
        </h3>
        <h2 className="privacy-policy-header">12. ASSIGNMENT</h2>
        <h3 className="privacy-policy-paragraph">
          Athstat may freely assign the Paid Fantasy TOS and all of the policies
          and other documents incorporated or referenced in it (including all
          rights, licenses, and obligations under it or them), in whole or in
          part and without notice, for any reason, including for the purpose of
          internal restructuring (for example, mergers or liquidations).
        </h3>
        <h2 className="privacy-policy-header">13. OFFICIAL RULES</h2>
        <h3 className="privacy-policy-paragraph">
          If you can&#39;t find the answer to your question in reviewing this
          TOS, we recommend reviewing the Help section in the app for additional
          information.
        </h3>
        <h2 className="privacy-policy-header">
          14. OFFICIAL DAILY FANTASY RULES
        </h2>
        <h3 className="privacy-policy-paragraph">Rugby</h3>
        <h2 className="privacy-policy-header">15. CONTACT US</h2>
        <h3 className="privacy-policy-paragraph">
          For further questions regarding our paid fantasy contests, please
          contact us at:{' '}
          <a className="onboarding-link" href="mailto:gaming@athstat.com">
            gaming@athstat.com
          </a>
        </h3>
      </section>
    </React.Fragment>
  );
};
export default GamesEULA;
