import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'react-bootstrap';
import { Table, TableBody, TableHead } from '@mui/material';
import { IOrganization, IPackage, ISessionEvent, ITeam } from '../../../../d';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { Button } from '@mui/material';
import Message from '../../Message/Message';

interface SubscriptionProps {
  organizationState: IOrganization;
  teamState: ITeam;
}

const SubscriptionsTable: React.FC<SubscriptionProps> = ({
  organizationState,
  teamState,
}: SubscriptionProps) => {
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [selectedEntity, setSelectedEntity] = useState<string>('');
  const { user } = useAuth();
  const kcUser = user?.profile;
  const token = user?.access_token;
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [chargeBeeLink, setChargeBeeLink] = React.useState<string>('');

  // Team
  const togglePaymentModal = () => {
    setShowPaymentModal(true);
  };

  const createPortalSession = async (
    customerId: string,
    selectedTeam: ITeam,
    org: IOrganization
  ) => {
    if (customerId && org && org._id && kcUser) {
      const payCheck: ISessionEvent = {
        customer_id: customerId ? customerId : org._id,
        organizationInfo: {
          organization_id: org._id,
          organization_admin_email: org.organization_admin_email,
          organizationName: org.name,
          teamName: selectedTeam ? selectedTeam.name : org.name,
          child_id: customerId ? customerId : undefined,
          first_name: kcUser.given_name,
          last_name: kcUser.family_name,
        },
      };

      if (process.env.REACT_APP_APP_URL)
        payCheck.redirect_url = `${process.env.REACT_APP_APP_URL}/auth/payment/subscriptions?team_id=${selectedTeam._id}`;
      try {
        const fetchedSession = await axios.post(
          `${process.env.REACT_APP_API_URL}/payments/create-session`,
          payCheck,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        const link: string = fetchedSession.data;
        return link;
      } catch (e) {
        return null;
      }
    }
  };

  const createSubscriptionSession = async (
    customerId: string,
    selectedTeam: ITeam,
    org: IOrganization,
    package_name: string
  ) => {
    if (customerId && org && org._id && kcUser) {
      const payCheck: ISessionEvent = {
        customer_id: customerId ? customerId : org._id,
        organizationInfo: {
          organization_id: org._id,
          organization_admin_email: org.organization_admin_email,
          organizationName: org.name,
          teamName: selectedTeam ? selectedTeam.name : org.name,
          child_id: customerId ? customerId : undefined,
          first_name: kcUser.given_name,
          last_name: kcUser.family_name,
        },
        package_name: package_name,
      };

      if (process.env.REACT_APP_APP_URL)
        payCheck.redirect_url = `${process.env.REACT_APP_APP_URL}/auth/payment/subscriptions?team_id=${selectedTeam._id}&package_name=${package_name}`;
      try {
        const fetchedSession = await axios.post(
          `${process.env.REACT_APP_API_URL}/payments/subscriptions/create-subscription`,
          payCheck,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        const link: string = fetchedSession.data;
        return link;
      } catch (e) {
        return null;
      }
    }
  };

  const handlePaymentIntent = async () => {
    if (teamState && teamState._id) {
      const link = await createPortalSession(
        teamState._id,
        teamState,
        organizationState
      );
      if (link) {
        setChargeBeeLink(link);
        setShowPaymentModal(true);
      } else {
        setMessage('Error creating Payment Link. Contact Support.');
        setIsError(true);
        setShowMessage(true);
      }
    } else {
      setMessage('Error creating Payment Link. Contact Support.');
      setIsError(true);
      setShowMessage(true);
    }
  };

  // const payCheck = (team: ITeam) => {
  //   let result = false;
  //   team.packages.forEach((appPackage) => {
  //     if (
  //       appPackage.id !== 'pitchside_package' &&
  //       (appPackage.status === 'active' ||
  //         appPackage.status === 'in_trial' ||
  //         appPackage.status === 'non_renewing')
  //     ) {
  //       result = true;
  //     }
  //   });
  //   return result;
  // };

  const handleSubscriptionIntent = async (
    team: ITeam,
    package_name: string
  ) => {
    if (team && team._id) {
      // if (payCheck(team)) {
      //   setMessage('Please cancel existing subscription or purchase bundle');
      //   setIsError(true);
      //   setShowMessage(true);
      // } else {
      const link = await createSubscriptionSession(
        team._id,
        team,
        organizationState,
        package_name
      );
      if (link) {
        setChargeBeeLink(link);
        setShowPaymentModal(true);
      } else {
        setMessage('Error creating Payment Link. Contact Support.');
        setIsError(true);
        setShowMessage(true);
      }
    } else {
      setMessage('Error creating Payment Link. Contact Support.');
      setIsError(true);
      setShowMessage(true);
    }
  };

  const chargeBeeButton = (cPackage: IPackage): React.ReactNode => {
    if (
      cPackage.status === 'active' ||
      cPackage.status === 'in_trial' ||
      cPackage.status === 'non_renewing'
    ) {
      if (cPackage.id === 'pitchside_package') {
        return <span>Free</span>;
      } else {
        return (
          <Button
            className="Table-Btn-Go"
            onClick={() => handlePaymentIntent()}
          >
            {`Change >`}
          </Button>
        );
      }
    } else {
      if (cPackage.id === 'pitchside_package') {
        return <span>Free</span>;
      } else {
        return (
          <Button
            className="Table-Btn-Go"
            disabled={cPackage.id === 'pitchside_package'}
            onClick={() => handleSubscriptionIntent(teamState, cPackage.id)}
          >
            Pay
          </Button>
        );
      }
    }
  };

  const chargeBeeBundleButton = (
    cPackages: Array<IPackage>
  ): React.ReactNode => {
    if (
      (cPackages[1].status === 'active' ||
        cPackages[1].status === 'in_trial' ||
        cPackages[1].status === 'non_renewing') &&
      (cPackages[2].status === 'active' ||
        cPackages[2].status === 'in_trial' ||
        cPackages[2].status === 'non_renewing')
    ) {
      return (
        <Button className="Table-Btn-Go" onClick={() => handlePaymentIntent()}>
          {`Change >`}
        </Button>
      );
    } else {
      return (
        <Button
          className="Table-Btn-Go"
          onClick={() => handleSubscriptionIntent(teamState, 'bundle_package')}
        >
          Pay
        </Button>
      );
    }
  };

  const packageName = (
    cPackageName: string
  ): { name: string; price: string } => {
    switch (cPackageName) {
      case 'pitchside_package':
        return { name: 'Pitchside', price: '$0/mo' };
      case 'data_sharing_package':
        return { name: 'Data Hub', price: '$10/mo' };
      case 'analysis_package':
        return { name: 'Analytics', price: '$49/mo' };
      case 'bundle_package':
        return { name: 'Bundle (Data Hub & Analytics)', price: '$55/mo' };
      default:
        return { name: 'Package', price: '$0/mo' };
    }
  };

  const packageExpiration = (cPackage: IPackage): string => {
    if (cPackage.id === 'pitchside_package') {
      return 'Promotion';
    } else if (cPackage.expiration) {
      return cPackage.expiration;
    } else {
      return 'N/A';
    }
  };

  const subscriptionsList = (): React.ReactNode => {
    const subscriptionNode: Array<React.ReactNode> = [];
    const bundleNode: React.ReactNode = (
      <tr key={'10000'}>
        <td>{packageName('bundle_package').name}</td>
        <td className="text-left Mo-MW-150">
          {packageName('bundle_package').price}
        </td>
        <td className="text-left Mo-MW-150">{'Inactive'}</td>
        <td className="text-left Mo-MW-150">{'No'}</td>
        <td className="text-left Mo-MW-150">{'N/A'}</td>
        <td className="text-left Mo-MW-150">
          {chargeBeeBundleButton(teamState.packages)}
        </td>
      </tr>
    );
    if (teamState) {
      teamState.packages.forEach((subPackage: IPackage, index: number) => {
        subscriptionNode.push(
          <tr key={index}>
            <td>{packageName(subPackage.id).name}</td>
            <td className="text-left Mo-MW-150">
              {packageName(subPackage.id).price}
            </td>
            <td
              className="text-left Mo-MW-150"
              style={{ textTransform: 'capitalize' }}
            >
              {subPackage.bundled && subPackage.id !== 'bundle_package'
                ? 'Bundled'
                : subPackage.status}
            </td>
            <td className="text-left Mo-MW-150">
              {subPackage.status === 'active' ? 'Yes' : 'No'}
            </td>
            <td className="text-left Mo-MW-150">
              {packageExpiration(subPackage)}
            </td>
            <td className="text-left Mo-MW-150">
              {chargeBeeButton(subPackage)}
            </td>
          </tr>
        );
      });

      const packageIndex = teamState.packages.findIndex(
        (item: IPackage) => item.id === 'bundle_package'
      );
      if (packageIndex === -1) subscriptionNode.push(bundleNode);
    }
    return subscriptionNode;
  };

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Row>
        {showMessage && (
          <Col size="12" md="6" lg="6">
            <Message
              message={message}
              setShowMessage={setShowMessage}
              error={isError}
              noMargin={true}
            />
          </Col>
        )}
      </Row>

      {/*Subscription Table*/}
      {teamState && (
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <Table className={'subscription-table-striped'}>
            <TableHead>
              <tr>
                <th className="text-left Mo-MW-130">Subscription Name</th>
                <th className="text-left Mo-MW-150">Price</th>
                <th className="text-left Mo-MW-150">Status</th>
                <th className="text-left Mo-MW-150">Reoccurring</th>
                <th className="text-left Mo-MW-150">Term End</th>
                <th className="text-left Mo-MW-150">Manage</th>
              </tr>
            </TableHead>
            <TableBody>{subscriptionsList()}</TableBody>
          </Table>
        </div>
      )}

      {teamState && (
        <Modal
          show={showPaymentModal}
          isOpen={showPaymentModal}
          centered
          className="CreateGame-Popup"
          inline={false}
          overflowScroll
          noClickableBodyWithoutBackdrop={true}
          toggle={togglePaymentModal}
        >
          <ModalHeader closeButton={true} onHide={togglePaymentModal}>
            Go to Payment Portal
          </ModalHeader>
          {!chargeBeeLink && (
            <ModalBody>
              <Row>
                <Col size="12">
                  <div className="form-group mb-md-0">
                    <p
                      className={'SportCovered-Data'}
                    >{`Payments are currently not activated. Please try again later.`}</p>

                    <br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size="12">
                  <div className="form-group Model-btns ">
                    <Button
                      className="Theme-Btn Table-Btn-Cancel"
                      onClick={() => {
                        setShowPaymentModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          )}

          {chargeBeeLink && (
            <ModalBody>
              <Row>
                <Col size="12">
                  <div className="form-group mb-md-0">
                    <p
                      className={'SportCovered-Data'}
                    >{`You will now be redirected to the secure Athstat Payment Portal.`}</p>

                    <br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size="12">
                  <div className="form-group Model-btns ">
                    <Button
                      className="Theme-Btn Table-Btn-Cancel"
                      onClick={() => {
                        setShowPaymentModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    {chargeBeeLink && (
                      <Button className="Theme-Btn">
                        <a
                          target="_blank"
                          href={chargeBeeLink}
                          className="chargebee-hover-box"
                          rel="noreferrer"
                        >
                          Continue
                        </a>
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          )}
        </Modal>
      )}
    </Row>
  );
};

export default SubscriptionsTable;
