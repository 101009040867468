import React, { useState, useEffect } from 'react';
import SmallMatchListItem from 'components/Matches/SmallMatchListItem';
import SmallPlayerCard from 'components/Players/SmallPlayerCard';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import SmallPlayerCardItem from 'components/Players/SmallPlayerCardItem';
import PlayerDisplayItems from 'components/Players/PlayerDisplayItems';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export interface Players {
  player_name: string;
  price: number;
  position_class: string;
  image_url: string;
  id?: number;
  team_id: number;
  athlete_id: string;
  purchase_price: number;
  purchase_date: string;
  is_starting: boolean;
  slot: number;
  score: number;
}
export interface Match {
  game_id: string;
  team_score: number;
  opposition_score: number;
  venue: string;
  kickoff_time: string;
  competition_name: string;
  team_id: string;
  opposition_team_id: string;
  is_knockout?: boolean | null;
  is_league_managed?: boolean | null;
  location?: string | null;
  extra_info?: string | null;
  hidden: boolean;
  league_id: string;
  round: string;
  network?: string | null;
  game_status: string;
  game_clock?: string | null;
  result?: string | null;
  home_team: string;
  away_team: string;
}

const ManageTeam: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [activeItem, setActiveItem] = useState<number>(1);
  const [totalPlayerValue, setTotalPlayerValue] = useState<number>(0); // Step 1
  const { user } = useAuth();
  const token = user?.access_token;
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [, setCurrentPage] = useState(1);

  const [players, setPlayers] = useState<Players[]>([]);
  const [subPlayers, setSubPlayers] = useState<Players[]>([]);
  const [currentPlayers, setCurrentPlayers] = useState<Players[]>([]);
  const [benchedPlayers, setBenchedPlayers] = useState<Players[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);

  const [selectedLeftPerson, setSelectedLeftPerson] = useState<Players>();
  const [selectedRightPerson, setSelectedRightPerson] = useState<Players>();
  const [, setChangedMade] = useState(false);
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [, setIsLoading] = useState(false);
  const [selectedCurrentBacksCount, setSelectedCurrentBacksCount] = useState(0);
  const [selectedBenchedBacksCount, setSelectedBenchedBacksCount] = useState(0);
  const [selectedCurrentForwardsCount, setSelectedCurrentForwardsCount] =
    useState(0);
  const [selectedBenchedForwardsCount, setSelectedBenchedForwardsCount] =
    useState(0);

  const [removedCurrPlF, setRemovedCurrPlF] = useState<Players[]>([]);
  const [removedCurrPlB, setRemovedCurrPlB] = useState<Players[]>([]);
  const [removedBencPlF, setRemovedBencPlF] = useState<Players[]>([]);
  const [removedBencPlB, setRemovedBencPlB] = useState<Players[]>([]);
  // const matchesPerPage = 5;
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [officialID, setOfficialID] = useState<string | null>(null);
  const [transerLimit, setTransferLimit] = useState<number>(0);
  const [isTransferActive, setIsTransferActive] = useState(false);

  useEffect(() => {
    if (id) {
      setSelectedId(id);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/fantasy-athletes/fantasy-team-athletes/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.slice(0, 30);

          setPlayers(data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data: ', error);
        });
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/entities/competition-games/${officialID}`
      )
      .then((response) => {
        const data = response.data.slice(0, 30);
        setMatches(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching match data: ', error);
      });
  }, [officialID]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/fantasy-league-config/${
          officialID ?? ''
        }`
      )
      .then((response) => {
        setTransferLimit(response.data.transfers_allowed);
        setIsTransferActive(response.data.transfers_activated);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
      });
  }, [officialID]);

  useEffect(() => {
    if (selectedId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/fantasy-teams/team/${selectedId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setTeamName(data[0].name);
          setOfficialID(data[0].official_league_id);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data: ', error);
        });
    }
  }, [selectedId]);

  useEffect(() => {
    // Step 2: Calculate total player value
    const totalValue = players?.reduce(
      (total, player) => total + player.price,
      0
    );
    const filteredPlayerA = players.filter(
      (players: Players) => players.is_starting === true
    );
    const filteredPlayerB = players.filter(
      (players: Players) => players.is_starting === false
    );
    const countOfCBacks = filteredPlayerA.filter(
      (players: Players) => players.position_class === 'back'
    ).length;

    const countOfCforwards = filteredPlayerA.filter(
      (players: Players) => players.position_class === 'forward'
    ).length;

    const countOfBBacks = filteredPlayerB.filter(
      (players: Players) => players.position_class === 'back'
    ).length;

    const countOfBforwards = filteredPlayerB.filter(
      (players: Players) => players.position_class === 'forward'
    ).length;

    setSelectedCurrentBacksCount(countOfCBacks);
    setSelectedCurrentForwardsCount(countOfCforwards);
    setSelectedBenchedBacksCount(countOfBBacks);
    setSelectedBenchedForwardsCount(countOfBforwards);
    setCurrentPlayers(filteredPlayerA);
    setBenchedPlayers(filteredPlayerB);
    setTotalPlayerValue(totalValue);
  }, [players]);

  const showToast = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000, // Auto close after 3 seconds
    });
  };
  const showToastSuccess = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000, // Auto close after 3 seconds
    });
  };

  const toggle = (tab: number) => () => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  const switchToUpcoming = () => {
    setCurrentPage(1);
    setShowUpcoming(true);
  };

  const switchToPrevious = () => {
    setCurrentPage(1);
    setShowUpcoming(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const selectPerson = (person: Players) => {
    // console.log('Selecting a player ', person);
    const empPlayer: Players = {
      player_name: '',
      price: 0,
      position_class: '',
      image_url: '',
      id: 0,
      team_id: 0,
      athlete_id: '',
      purchase_price: 0,
      purchase_date: '',
      is_starting: false,
      slot: 0,
      score: 0,
    };

    if (person.is_starting === true) {
      setSelectedLeftPerson(selectedLeftPerson === person ? empPlayer : person);
      if (selectedLeftPerson === person) {
        setSelectedLeftPerson(empPlayer);
      }
    } else {
      setSelectedRightPerson(
        selectedRightPerson === person ? empPlayer : person
      );
      if (selectedRightPerson === person) {
        setSelectedRightPerson(empPlayer);
      }
    }
  };

  // Function to handle swapping two people
  const swapPeople = () => {
    setChangedMade(true);
    if (selectedLeftPerson && selectedRightPerson) {
      if (
        selectedLeftPerson.position_class == selectedRightPerson.position_class
      ) {
        const updatedPeople = players.map((person) => {
          if (person.id === selectedLeftPerson.id) {
            return {
              ...person,
              id: selectedRightPerson.id,
              is_starting: false,
              slot: selectedRightPerson.slot,
            };
          } else if (person.id === selectedRightPerson.id) {
            return {
              ...person,
              id: selectedLeftPerson.id,
              is_starting: true,
              slot: selectedLeftPerson.slot,
            };
          }
          return person;
        });
        setPlayers(updatedPeople);
        setSaveButtonVisible(true);
      } else {
        showToast(
          `Make sure you have select players with the same position. eg. Back and Back)`
        );
        return;
      }
    }
  };
  const removePlayerSubs = (player_name: string) => {
    const updatedList = subPlayers.filter(
      (player) => player.player_name !== player_name
    );
    setSubPlayers(updatedList);
  };

  const removePlayer = (
    playerName: string,
    position: string,
    side: boolean
  ) => {
    const remCount =
      removedBencPlB.length +
      removedBencPlF.length +
      removedCurrPlB.length +
      removedCurrPlF.length;

    if (remCount < transerLimit && isTransferActive === true) {
      if (position === 'back' && side === true) {
        const updatedList = players.filter(
          (player) => player.player_name !== playerName
        );
        const updatedPlayer = players.filter(
          (player) => player.player_name === playerName
        );
        const updatedPlayers = [...removedCurrPlB, ...updatedPlayer];
        setRemovedCurrPlB(updatedPlayers);
        setPlayers(updatedList);
        setSelectedCurrentBacksCount(selectedCurrentBacksCount - 1);
      } else if (position === 'forward' && side === true) {
        const updatedList = players.filter(
          (player) => player.player_name !== playerName
        );
        const updatedPlayer = players.filter(
          (player) => player.player_name === playerName
        );
        const updatedPlayers = [...removedCurrPlF, ...updatedPlayer];
        setRemovedCurrPlF(updatedPlayers);
        setPlayers(updatedList);
        setSelectedCurrentForwardsCount(selectedCurrentForwardsCount - 1);
      } else if (position === 'back' && side === false) {
        const updatedList = players.filter(
          (player) => player.player_name !== playerName
        );
        const updatedPlayer = players.filter(
          (player) => player.player_name === playerName
        );
        const updatedPlayers = [...removedBencPlB, ...updatedPlayer];
        setRemovedBencPlB(updatedPlayers);
        setPlayers(updatedList);
        setSelectedCurrentBacksCount(selectedCurrentBacksCount - 1);
      } else if (position === 'forward' && side === false) {
        const updatedList = players.filter(
          (player) => player.player_name !== playerName
        );
        const updatedPlayer = players.filter(
          (player) => player.player_name === playerName
        );
        const updatedPlayers = [...removedBencPlF, ...updatedPlayer];
        setRemovedBencPlF(updatedPlayers);
        setPlayers(updatedList);
        setSelectedCurrentForwardsCount(selectedCurrentForwardsCount - 1);
      }
    } else {
      showToast(`Allowed to make ${transerLimit} transfers`);
    }
  };

  const addPlayerToCurrent = () => {
    const countOfSubforwards = subPlayers.filter(
      (players: Players) => players.position_class === 'forward'
    ).length;
    const countOfSubBacks = subPlayers.filter(
      (players: Players) => players.position_class === 'back'
    ).length;
    const Subforwards = subPlayers.filter(
      (players: Players) => players.position_class === 'forward'
    );
    const SubBacks = subPlayers.filter(
      (players: Players) => players.position_class === 'back'
    );

    const subForwardCount = 5 - selectedCurrentForwardsCount;
    const subBackCount = 10 - selectedCurrentBacksCount;
    if (
      countOfSubforwards <= subForwardCount &&
      countOfSubBacks <= subBackCount
    ) {
      const beforeUpdate = [...Subforwards, ...SubBacks];

      const remPlayers = [...removedCurrPlF, ...removedCurrPlB];

      const updatedArray = beforeUpdate.map((before, index) => {
        const item = remPlayers[index];
        if (item) {
          return {
            ...before,
            id: item.id,
            slot: item.slot,
            team_id: item.team_id,
          };
        }
        // Return the original item if `item` is undefined
        return before;
      });

      const updatedPlayers = [...currentPlayers, ...updatedArray];
      const updatedList = [...players, ...updatedArray];
      setCurrentPlayers(updatedPlayers);
      setPlayers(updatedList);
      setSaveButtonVisible(true);
      scrollToTop();
    } else {
      showToast(
        `Make sure you have the correct number of backs and forwards you want to put in the current lineup`
      );
    }
  };

  const addPlayerToBenchLineUp = () => {
    const countOfSubforwards = subPlayers.filter(
      (players: Players) => players.position_class === 'forward'
    ).length;
    const countOfSubBacks = subPlayers.filter(
      (players: Players) => players.position_class === 'back'
    ).length;
    const Subforwards = subPlayers.filter(
      (players: Players) => players.position_class === 'forward'
    );
    const SubBacks = subPlayers.filter(
      (players: Players) => players.position_class === 'back'
    );

    const subForwardCount = 8 - selectedBenchedForwardsCount;
    const subBackCount = 1 - selectedBenchedBacksCount;
    if (
      countOfSubforwards <= subForwardCount &&
      countOfSubBacks <= subBackCount
    ) {
      const beforeUpdate = [...Subforwards, ...SubBacks];

      const remPlayers = [...removedBencPlF, ...removedBencPlB];

      const updatedArray = beforeUpdate.map((before, index) => {
        const item = remPlayers[index];
        if (item) {
          return {
            ...before,
            id: item.id,
            slot: item.slot,
            team_id: item.team_id,
            is_starting: false,
          };
        }
        // Return the original item if `item` is undefined
        return before;
      });
      const updatedPlayers = [...benchedPlayers, ...updatedArray];
      const updatedList = [...players, ...updatedArray];
      setBenchedPlayers(updatedPlayers);
      setPlayers(updatedList);
      setSaveButtonVisible(true);
      scrollToTop();
    } else {
      showToast(
        `Make sure you have the correct number of backs and forwards you want to put in the current lineup`
      );
    }
  };

  const addPlayerToSub = (person: Players) => {
    // eslint-disable-next-line no-console
    console.log('Selected player', person);
  };
  const addPlayerToBench = (person: Players) => {
    // console.log('Selected player', person);

    const playerExists = subPlayers.some(
      (p) => p.player_name === person.player_name
    );
    if (!playerExists) {
      const updatedPlayers = [...subPlayers, person];
      setSubPlayers(updatedPlayers);
    }
  };

  const saveChanges = () => {
    // console.log('Save Players changed ', players);
    // console.log('Removed Player Current ', removedCurrPlF.length);
    if (
      removedCurrPlB.length > 0 ||
      removedCurrPlF.length > 0 ||
      removedBencPlB.length > 0 ||
      removedBencPlF.length > 0
    ) {
      try {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/fantasy-athletes/fantasy-team-athletes/update-ext`,
            {
              team: players,
              teamId: selectedId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              setRemovedBencPlB([]);
              setRemovedBencPlF([]);
              setRemovedCurrPlB([]);
              setRemovedCurrPlF([]);
              setSelectedBenchedBacksCount(0);
              setSelectedBenchedForwardsCount(0);
              setSelectedCurrentBacksCount(0);
              setSelectedCurrentForwardsCount(0);
              showToastSuccess('Your changes has been saved successfully!');
            } else {
              // Handle non-successful response
              // eslint-disable-next-line no-console
              console.error('Request failed with status', response.status);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            // Handle network error
            // eslint-disable-next-line no-console
            console.error('Network error', error);
            setIsLoading(false);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating team: ', error);
        setIsLoading(false);
      }
    } else {
      try {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/fantasy-athletes/fantasy-team-athletes/update-int`,
            {
              team: players,
              teamId: selectedId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              const empPlayer: Players = {
                player_name: '',
                price: 0,
                position_class: '',
                image_url: '',
                id: 0,
                team_id: 0,
                athlete_id: '',
                purchase_price: 0,
                purchase_date: '',
                is_starting: false,
                slot: 0,
                score: 0,
              };
              setIsLoading(false);
              showToastSuccess('Your changes has been saved successfully!');
              setSelectedLeftPerson(empPlayer);
              setSelectedRightPerson(empPlayer);
            } else {
              // Handle non-successful response
              // eslint-disable-next-line no-console
              console.error('Request failed with status', response.status);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            // Handle network error
            // eslint-disable-next-line no-console
            console.error('Network error', error);
            setIsLoading(false);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating team: ', error);
        setIsLoading(false);
      }
    }

    setChangedMade(false);
    setSaveButtonVisible(false);
  };

  return (
    <section className="games-home-page" style={{ marginLeft: '-100px' }}>
      <h1
        className="match-container-title"
        style={{
          marginTop: '-50px',
          marginLeft: '-1200px',
        }}
      >
        {teamName}
      </h1>

      <div
        style={{
          marginTop: '-15px',
          marginLeft: '-370px',
          marginBottom: '-80px',
        }}
      >
        <button
          className={`match-tabs ${showUpcoming ? 'selected-tab' : ''}`}
          onClick={switchToUpcoming}
          style={{ marginBottom: '32px', marginLeft: '-450px' }}
        >
          Team Performance
        </button>
        <button
          style={{ width: '200px' }}
          className={`match-tabs ${!showUpcoming ? 'selected-tab' : ''}`}
          onClick={switchToPrevious}
        >
          Player Management
        </button>
        {saveButtonVisible && (
          <button
            className=""
            onClick={saveChanges}
            style={{
              marginRight: '-500px',
              borderRadius: '5px',
              background: '#26b670',
              border: 'none',
            }}
          >
            {'Save Changes +'}
          </button>
        )}
      </div>
      {showUpcoming && (
        <div className="team-performance">
          <div className="upcoming-match-display-small">
            <h1 className="small-upcoming-match-header">Upcoming Matches</h1>{' '}
            {matches.map((match) => (
              <SmallMatchListItem
                key={match.game_id}
                homeTeamImage={''}
                homeTeamName={match.home_team}
                awayTeamImage={''}
                awayTeamName={match.away_team}
                location={match.venue}
                targetTime={match.kickoff_time}
              />
            ))}
          </div>

          {/* <div className="field-display">
            <img src="/icons/soccer-field.jpg" />
          </div> */}

          <div className="player-small-display">
            <div className="player-tabs-container">
              <div
                className={`player-management-tab ${
                  activeItem === 1 ? 'active-tab' : ''
                }`}
                onClick={toggle(1)}
              >
                Current Lineup
              </div>
              <div
                className={`player-management-tab ${
                  activeItem === 2 ? 'active-tab' : ''
                }`}
                onClick={toggle(2)}
              >
                Benched
              </div>
            </div>

            {activeItem === 1 && (
              <div className="small-player-card-lists-items">
                <div className="small-player-card-lists-items">
                  {currentPlayers.map((currentPlayer) => (
                    <SmallPlayerCard
                      key={currentPlayer.id}
                      tracking_id={currentPlayer.slot}
                      player_name={currentPlayer.player_name}
                      position_class={currentPlayer.position_class}
                      price={currentPlayer.price.toString()}
                      image_url={currentPlayer.image_url}
                    />
                  ))}
                </div>
              </div>
            )}

            {activeItem === 2 && (
              <div className="small-player-card-lists-items">
                <div className="small-player-card-lists-items">
                  {benchedPlayers.map((benchedPlayer) => (
                    <SmallPlayerCard
                      key={benchedPlayer.id}
                      tracking_id={benchedPlayer.slot}
                      player_name={benchedPlayer.player_name}
                      position_class={benchedPlayer.position_class}
                      price={benchedPlayer.price.toString()}
                      image_url={benchedPlayer.image_url}
                    />
                  ))}
                </div>
              </div>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="378"
              height="2"
              viewBox="0 0 378 2"
              fill="none"
            >
              <path
                d="M1.5 1H377"
                stroke="#323232"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <div className="small-list-current-value">
              <h1 className="small-current-value">Current Value:</h1>
              <h1 className="total-small-current-value">
                ${totalPlayerValue.toLocaleString()}
              </h1>
            </div>
          </div>
        </div>
      )}
      <ToastContainer position="top-center" pauseOnHover />
      {!showUpcoming && (
        <>
          <div className="team-performance">
            <div className="player-small-display">
              <div className="player-tabs-container">
                <div className="manage-team-head">
                  <h6>Current Lineup</h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="378"
                    height="2"
                    viewBox="0 0 378 2"
                    fill="none"
                  >
                    <path
                      d="M1.5 1H377"
                      stroke="#323232"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p> These are active players that earn you points.</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="378"
                    height="2"
                    viewBox="0 0 378 2"
                    fill="none"
                  >
                    <path
                      d="M1.5 1H377"
                      stroke="#323232"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="small-player-card-lists-item">
                <div className="small-player-card-lists-item">
                  {currentPlayers.map((currentPlayer) => (
                    <div
                      key={currentPlayer.player_name}
                      // onClick={() => selectPerson(currentPlayer, true)}
                      style={{
                        border:
                          selectedLeftPerson &&
                          selectedLeftPerson.id === currentPlayer.id
                            ? '2px solid green'
                            : 'none',
                        background:
                          selectedLeftPerson &&
                          selectedLeftPerson.id === currentPlayer.id
                            ? 'rgba(38, 182, 112, 0.15)'
                            : '#323232',
                        borderRadius: '5px',
                      }}
                    >
                      <SmallPlayerCardItem
                        key={currentPlayer.player_name}
                        player_name={currentPlayer.player_name}
                        position_class={currentPlayer.position_class}
                        image_url={currentPlayer.image_url}
                        side={currentPlayer.is_starting}
                        selectPerson={selectPerson}
                        selectedPlayer={currentPlayer}
                        removePlayer={removePlayer}
                        athlete_id={currentPlayer.athlete_id}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="378"
                height="2"
                viewBox="0 0 378 2"
                fill="none"
              >
                <path
                  d="M1.5 1H377"
                  stroke="#323232"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            <div className="manage-team-arrows">
              <img src="/icons/compLeft.svg" onClick={swapPeople} />
              <img src="/icons/compRight.svg" onClick={swapPeople} />
            </div>

            <div className="player-small-display">
              <div className="player-tabs-container">
                <div className="manage-team-head">
                  <h6>Benched Lineup</h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="378"
                    height="2"
                    viewBox="0 0 378 2"
                    fill="none"
                  >
                    <path
                      d="M1.5 1H377"
                      stroke="#323232"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p> Benched players do not score points.</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="378"
                    height="2"
                    viewBox="0 0 378 2"
                    fill="none"
                  >
                    <path
                      d="M1.5 1H377"
                      stroke="#323232"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="small-player-card-lists-item">
                <div className="small-player-card-lists-item">
                  {benchedPlayers.map((benchedPlayer) => (
                    <div
                      key={benchedPlayer.player_name}
                      // onClick={() => selectPerson(currentPlayer, true)}
                      style={{
                        border:
                          selectedRightPerson &&
                          selectedRightPerson.id === benchedPlayer.id
                            ? '2px solid green'
                            : 'none',
                        background:
                          selectedRightPerson &&
                          selectedRightPerson.id === benchedPlayer.id
                            ? 'rgba(38, 182, 112, 0.15)'
                            : '#323232',
                        borderRadius: '5px',
                      }}
                    >
                      <SmallPlayerCardItem
                        key={benchedPlayer.player_name}
                        player_name={benchedPlayer.player_name}
                        position_class={benchedPlayer.position_class}
                        image_url={benchedPlayer.image_url}
                        side={benchedPlayer.is_starting}
                        selectPerson={selectPerson}
                        selectedPlayer={benchedPlayer}
                        removePlayer={removePlayer}
                        athlete_id={benchedPlayer.athlete_id}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="500"
                height="2"
                viewBox="0 0 378 2"
                fill="none"
              >
                <path
                  d="M1.5 1H377"
                  stroke="#323232"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          {/* <div>
            <SportsSelection
              onCompetitionSelect={handleCompetitionSelect}
              selectedCompetition={selectedCompetition}
            />
          </div> */}
          <PlayerDisplayItems
            competion_id={officialID ? officialID : ''}
            onAddPlayerToSub={() => {
              addPlayerToSub;
            }}
            removePlayerSubs={removePlayerSubs}
            addPlayerToCurrent={addPlayerToCurrent}
            onAddPlayerToBench={addPlayerToBench}
            addPlayerToBenchLineUp={addPlayerToBenchLineUp}
            remBacCount={
              removedCurrPlB.length
                ? removedCurrPlB.length
                : removedBencPlB.length
            }
            remForCount={
              removedCurrPlF.length
                ? removedCurrPlF.length
                : removedBencPlF.length
            }
          />
        </>
      )}
    </section>
  );
};

export default ManageTeam;
