import { createContext, useState } from 'react';
import { IFantasyTeam } from '../../d';
import React from 'react';
import { getFantasyTeamByUserId } from 'components/Games/games.services';

interface IFantasyContext {
  fantasyTeam: IFantasyTeam | null;
  fetchFantasyTeam?: (id: string, token: string) => void;
  loading: boolean;
}

interface IProps {
  children: React.ReactNode;
}

export const sampleFantasyTeam: IFantasyTeam = {
  id: -1,
  team_name: '',
  user_id: '',
  team_value: 0,
  balance: 0,
  created_date: '',
  selected_avatar_index: 0,
};

export const sampleGameContext: IFantasyContext = {
  fantasyTeam: sampleFantasyTeam,
  loading: false,
};

export const FantasyGamesContext =
  createContext<IFantasyContext>(sampleGameContext);

export const FantasyContextProvider: React.FC<IProps> = ({ children }) => {
  const [fantasyTeam, setFantasyTeam] = useState<IFantasyTeam | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFantasyTeam = async (id: string, token: string) => {
    try {
      setLoading(true);
      await getFantasyTeamByUserId({
        id: id,
        token: token,
      })
        .then((res) => {
          setFantasyTeam(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      // console.error('Error fetching fantasy team:', error);
      setLoading(false);
    }
  };

  const contextValue: IFantasyContext = {
    fantasyTeam,
    fetchFantasyTeam,
    loading,
  };

  return (
    <FantasyGamesContext.Provider value={contextValue}>
      {children}
    </FantasyGamesContext.Provider>
  );
};
