/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import ComingSoonModal from 'components/UserSignUp/ComingSoon';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

interface imgObject {
  id?: number;
  title?: string;
  description?: string;
  image_url?: string;
  created_timestamp?: string;
  expiry_timestamp?: string;
  created_by?: string;
  type?: string;
  navigate_to?: string;
}

const BannerCarousel: React.FC = () => {
  const [currentDiv, setCurrentDiv] = useState(1);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [imagesUrl, setImageUrl] = useState<imgObject[]>([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/unauth/fantasy-announcements`)
      .then((response) => {
        // eslint-disable-next-line no-console
        // console.log('Fantasy club: ', response);
        setImageUrl(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching club data: ', error);
      });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDiv((prevDiv) => (prevDiv % 3) + 1);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLeagueNavigation = () => {
    if (isAuthenticated) {
      navigate('games-dash/fantasy/leagues');
    } else {
      navigate('newlogin');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTeamsNavigation = () => {
    if (isAuthenticated) {
      navigate('games-dash/fantasy/myteams');
    } else {
      navigate('newlogin');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHomeNavigation = () => {
    if (isAuthenticated) {
      navigate('/auth');
    } else {
      navigate('/newlogin');
    }
  };

  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isModalFadingOut] = useState(false);
  const [, setIsModalVisible] = useState(true);
  const handleAnimationEnd = () => {
    if (isModalFadingOut) {
      setIsModalVisible(false);
    }
  };
  const openModal = () => {
    setIsModalOpens(true);
  };
  const closeModal = () => {
    setIsModalOpens(false);
  };

  return (
    <div className="cycle-divs-container">
      {imagesUrl.map((image, index) => (
        <div
          key={index}
          className={`banner-content ${
            currentDiv === index + 1 ? 'active' : ''
          }`}
        >
          <img
            className="banner-image"
            src={image.image_url}
            style={{ cursor: 'pointer' }}
            onClick={openModal}
          ></img>
        </div>
      ))}

      {/* <div className={`banner-content ${currentDiv === 1 ? 'active' : ''}`}>
        <img
          className="banner-image"
          src="img/Banner1.png"
          style={{ cursor: 'pointer' }}
          onClick={openModal}
        ></img>
      </div>
      <div className={`banner-content ${currentDiv === 2 ? 'active' : ''}`}>
        <img
          className="banner-image"
          src="img/Banner2.png"
          style={{ cursor: 'pointer' }}
          onClick={openModal}
        ></img>
      </div>
      <div className={`banner-content ${currentDiv === 3 ? 'active' : ''}`}>
        <img
          className="banner-image"
          src="img/banner-3.png"
          style={{ cursor: 'pointer' }}
          onClick={openModal}
        ></img>
      </div> */}
      <div className="line">
        {imagesUrl.map((_image, index) => (
          <div
            key={index}
            className={`line-segment ${
              currentDiv === index + 1 ? 'active' : ''
            }`}
          ></div>
        ))}

        {/* <div
          className={`line-segment ${currentDiv === 2 ? 'active' : ''}`}
        ></div>
        <div
          className={`line-segment ${currentDiv === 3 ? 'active' : ''}`}
        ></div> */}
      </div>
      <ComingSoonModal
        isOpen={isModalOpens}
        setIsOpen={closeModal}
        isModalFadingOut={isModalFadingOut}
        handleAnimationEnd={handleAnimationEnd}
      />
    </div>
  );
};

export default BannerCarousel;
