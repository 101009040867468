import { Col as Col, Row as Row } from 'react-bootstrap';
import { Table, TableHead } from '@mui/material';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { IMember, User, ITeam, IUpdateTeam } from '../../../../../d';
import { TeamContext } from '../../../../context/TeamContext';
import { useAuth } from 'react-oidc-context';

interface TeamManagementProp {
  teamProp: ITeam;
}

interface IRoleLevel {
  title: string;
  level: number;
}

const membersTab: React.FC<TeamManagementProp> = ({
  teamProp,
}: TeamManagementProp) => {
  const [teamState, setTeamState] = useState<ITeam>(teamProp);
  const [users, setUsers] = useState<Array<User> | undefined>();
  const { user } = useAuth();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const { setTeam } = useContext(TeamContext);
  const [userRoleLevel, setUserRoleLevel] = useState<number>(0);

  const roleLevels: IRoleLevel[] = [
    {
      title: 'Team Admin',
      level: 300,
    },
    {
      title: 'Manager',
      level: 250,
    },
    {
      title: 'Analyst',
      level: 200,
    },
    {
      title: 'Player',
      level: 100,
    },
  ];

  React.useEffect(() => {
    const getUsers = async () => {
      const fetchedUsers = await axios.get(
        `   ${process.env.REACT_APP_API_URL}/admin/teams/users/${teamProp._id}`,
        {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      setUsers(fetchedUsers.data);

      const member: IMember | undefined = teamProp.members.find(
        (iMember: IMember) => iMember.user_id === userId
      );
      if (member) {
        setUserRoleLevel(member.roleLevel);
      }
    };
    getUsers();
  }, [teamProp]);

  React.useEffect(() => {
    const refreshMembers = () => {
      setTeamState(teamProp);
    };
    refreshMembers();
  }, [teamProp]);

  const updateMember = async (updatedMember: IMember) => {
    if (updatedMember && setTeam) {
      const members = teamState.members;
      const memberIndex = members.findIndex(
        (x) => x.user_id === updatedMember.user_id
      );
      members[memberIndex] = updatedMember;
      const updateTeam: IUpdateTeam = {
        _id: teamState._id,
        members: members,
      };

      const fetchedUpdatedTeam = await axios.put(
        `   ${process.env.REACT_APP_API_URL}/teams`,
        updateTeam,
        {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );

      setTeamState(fetchedUpdatedTeam.data);
    }
  };

  const handleRoleLevelChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
    currentMember: IMember
  ) => {
    currentMember.roleLevel = roleLevels[event.target.value as number].level;
    await updateMember(currentMember);
  };

  const roleLevelDropDown = () => {
    const roleItems: Array<React.ReactNode> = [
      <option key={'10000'} disabled>
        Select Role
      </option>,
    ];
    roleLevels.forEach((role, index) => {
      roleItems.push(
        <option key={index} value={index}>
          {role.title}
        </option>
      );
    });
    return roleItems;
  };

  const roleText = (level: number) => {
    return roleLevels.findIndex((item) => item.level === level);
  };

  const membersList = (): React.ReactNode => {
    const membersNode: Array<React.ReactNode> = [];

    teamState.members.forEach((IMember: IMember, index: number) => {
      if (users) {
        const currentMember = users.find(
          (item) => item.kc_id === IMember.user_id
        );
        if (currentMember) {
          membersNode.push(
            <tr key={index}>
              <td>{currentMember.firstName}</td>
              <td>{currentMember.lastName}</td>
              <td>{currentMember.email}</td>
              <td>
                <div className="form-group mb-md-0">
                  <select
                    disabled={userId === IMember.user_id || userRoleLevel < 300}
                    className="form-control theme-input"
                    value={roleText(IMember.roleLevel)}
                    onChange={(e) => handleRoleLevelChange(e, IMember)}
                  >
                    {roleLevelDropDown()}
                  </select>
                </div>
              </td>
            </tr>
          );
        }
      } else {
        membersNode.push(
          <tr key={index}>
            <td>{IMember.user_id}</td>
            <td>
              <div className="form-group mb-md-0">
                <select
                  className="form-control theme-input"
                  value={roleText(IMember.roleLevel)}
                  onChange={(e) => handleRoleLevelChange(e, IMember)}
                >
                  <option key={'10000'} disabled>
                    Select Role
                  </option>
                  {roleLevelDropDown()}
                </select>
              </div>
            </td>
          </tr>
        );
      }
    });

    return membersNode;
  };

  return (
    <div>
      <Row>
        <Col size="12">
          <Table className="table-striped">
            <TableHead>
              {users && (
                <tr>
                  <th className="Mo-MW-120">First Name</th>
                  <th className="Mo-MW-120">Last Name</th>
                  <th>Email</th>
                  <th>Role Level</th>
                </tr>
              )}
              {!users && (
                <tr>
                  <th className="Mo-MW-120">User ID</th>
                  <th>Role Level</th>
                </tr>
              )}
            </TableHead>

            <tbody>{membersList()}</tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default membersTab;
