import { RouteObject } from 'react-router-dom';
import React from 'react';
// import PublicHome from '../components/PublicPages/PublicHome';
import HowToUseAppDash from '../components/PublicPages/HowToUseAppDash';
import PrivacyPolicy from '../components/PublicPages/PrivacyPolicy';
import StaticEula from '../components/EndUserLicenseAgreement/StaticEula';
import UserGuide from '../components/PublicPages/UserGuide';
import QuickStartGuide from '../components/PublicPages/QuickStartGuide';
import GamesExternal from '../components/PublicPages/GamesExternal';
import GamesEULA from 'components/PublicPages/GamesEULA';
import AllowedAreas from 'components/PublicPages/AllowedAreasDFS';
import ManageTeam from 'components/Teams/ManageTeam';
import ResponsibleGaming from 'components/PublicPages/ResponsibleGaming';
import HomePage from 'components/HomePage/index';

const PublicRoutes: Array<RouteObject> = [
  { index: true, element: <HomePage /> },
  { path: '/app-how-to', element: <HowToUseAppDash /> },
  {
    path: '/changelog',
    element: <h2 style={{ color: 'white' }}>Changelog: To Be Announced</h2>,
  },

  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/responsible-gaming', element: <ResponsibleGaming /> },
  { path: '/quick-start', element: <QuickStartGuide /> },
  { path: '/terms', element: <StaticEula /> },
  { path: '/user-guide', element: <UserGuide /> },
  { path: '/games', element: <GamesExternal /> },
  { path: '/games-terms', element: <GamesEULA /> },
  { path: '/allowed-locations', element: <AllowedAreas /> },
  { path: '/manageteam', element: <ManageTeam /> },
];

export default PublicRoutes;
