import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'react-bootstrap';
import { Table, TableHead, TableBody } from '@mui/material';
import { ILeague, IOrganization, ISessionEvent, ITeam } from '../../../d';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { Button } from '@mui/material';
import Message from '../Message/Message';
import SubscriptionsTable from './Subscriptions/SubscriptionsTable';
import { useSearchParams } from 'react-router-dom';

const PaymentTable: React.FC = () => {
  const [organizations, setOrganizations] = useState<Array<IOrganization>>([]);
  const [leagues, setLeagues] = useState<Array<ILeague>>([]);
  const [teams, setTeams] = useState<Array<ITeam>>([]);
  const [organization, setOrganization] = useState<IOrganization | undefined>();
  const [leagueState, setLeagueState] = useState<ILeague | undefined>();
  const [teamState, setTeamState] = useState<ITeam | undefined>();
  const { user, isAuthenticated } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [chargeBeeLink, setChargeBeeLink] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId');

  useEffect(() => {
    if (isAuthenticated && token) {
      const getOrganizations = async () => {
        setLoadingData(true);
        try {
          const fetchedOrganizations = await axios.get(
            `${process.env.REACT_APP_API_URL}/organizations`,
            {
              headers: {
                Authorization: user ? `Bearer ${token}` : undefined,
              },
            }
          );
          if (setOrganizations) {
            setOrganizations(fetchedOrganizations.data);
            if (teamId) {
              const fetchedTeam = await axios.get(
                `${process.env.REACT_APP_API_URL}/teams/team/${teamId}`,
                {
                  headers: {
                    Authorization: user ? `Bearer ${token}` : undefined,
                  },
                }
              );
              if (fetchedTeam.data) {
                setTeamState(fetchedTeam.data);
                const organizationId = fetchedTeam.data.organization_id;
                const fetchedOrg = await axios.get(
                  `${process.env.REACT_APP_API_URL}/organizations/organization/${organizationId}`,
                  {
                    headers: {
                      Authorization: user ? `Bearer ${token}` : undefined,
                    },
                  }
                );
                if (fetchedOrg.data) {
                  setOrganization(fetchedOrg.data);
                  const fetchedLeagues: Array<ILeague> = await fetchLeagues(
                    fetchedOrg.data
                  );
                  const leagueIndex: number = fetchedLeagues.findIndex(
                    (item: ILeague) => item._id === fetchedTeam.data.league_id
                  );
                  if (leagueIndex !== -1) {
                    setLeagueState(fetchedLeagues[leagueIndex]);
                  }
                  await fetchTeamsByOrganization(fetchedOrg.data._id);
                }
              }
            }
          }
          setLoadingData(false);
        } catch (err) {
          setLoadingData(false);
        }
      };

      getOrganizations();
    }
  }, []);

  const fetchLeagues = async (selectedOrg: IOrganization) => {
    if (selectedOrg && selectedOrg._id && userId) {
      const fetchedLeagues = await axios.get(
        `${process.env.REACT_APP_API_URL}/leagues/`,
        {
          params: { user_id: userId, organization_id: selectedOrg._id },
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      if (fetchedLeagues.data) {
        setLeagues(fetchedLeagues.data);
      }
      return fetchedLeagues.data;
    }
  };

  const fetchTeamsByLeague = async (selectedLeague?: ILeague | undefined) => {
    if (selectedLeague && selectedLeague._id && userId) {
      const fetchedTeams = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/`,
        {
          params: { user_id: userId, league_id: selectedLeague._id },
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      if (fetchedTeams.data) {
        setTeams(fetchedTeams.data);
        setTeamState(undefined);
      }
    }
  };

  const fetchTeamsByOrganization = async (
    selectedOrganizationId: string | undefined
  ) => {
    if (selectedOrganizationId && userId) {
      const fetchedTeams = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/`,
        {
          params: { user_id: userId, organization_id: selectedOrganizationId },
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        }
      );
      if (fetchedTeams.data) {
        setTeams(fetchedTeams.data);
      }
    }
  };

  const fetchTeam = async (sTeam: ITeam): Promise<ITeam | undefined> => {
    if (sTeam) {
      return await axios
        .get(`${process.env.REACT_APP_API_URL}/teams/team/${sTeam._id}`, {
          headers: {
            Authorization: user ? `Bearer ${token}` : undefined,
          },
        })
        .then((r) => {
          return r.data;
        })
        .catch(() => {
          return null;
        });
    }
  };

  //Organizations
  const handleOrganizationChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select Team') {
      const selectedOrganization = organizations[event.target.value as number];
      setOrganization(selectedOrganization);
      await fetchLeagues(selectedOrganization);
      await fetchTeamsByOrganization(selectedOrganization._id);
      setLeagueState(undefined);
      setTeamState(undefined);
    }
  };

  const organizationsDropDown = () => {
    const organizationItems: Array<React.ReactNode> = [
      <option
        key={'10000'}
        value={'Select Organization'}
        disabled={organization !== undefined}
      >
        Select Organization
      </option>,
    ];
    organizations.forEach((organization, index) => {
      organizationItems.push(
        <option key={index} value={index}>
          {organization.hidden
            ? `${organization.name} (hidden)`
            : organization.name}
        </option>
      );
    });
    return organizationItems;
  };

  // Leagues
  const handleLeagueChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select League') {
      const selectedLeague = leagues[event.target.value as number];
      setLeagueState(selectedLeague);
      await fetchTeamsByLeague(selectedLeague);
    }
  };

  const leaguesDropDown = () => {
    const leagueItems: Array<React.ReactNode> = [
      <option
        key={'20000'}
        value={'Select League'}
        disabled={leagueState !== undefined}
      >
        {'Select League'}
      </option>,
    ];
    leagues.forEach((league, index) => {
      leagueItems.push(
        <option key={index} value={index}>
          {league.hidden ? `${league.name} (hidden)` : league.name}
        </option>
      );
    });
    return leagueItems;
  };

  const handleTeamChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value !== 'Select Team') {
      const selectedTeam = teams[event.target.value as number];
      const fTeam = await fetchTeam(selectedTeam);
      if (fTeam && fTeam._id) {
        setTeamState(fTeam);
      }
    }
  };

  const teamsDropDown = () => {
    const teamItems: Array<React.ReactNode> = [
      <option
        key={'30000'}
        value={'Select Team'}
        disabled={teamState !== undefined}
      >
        {'Select Team'}
      </option>,
    ];
    teams.forEach((team, index) => {
      teamItems.push(
        <option key={index} value={index}>
          {team.hidden ? `${team.name} (hidden)` : team.name}
        </option>
      );
    });
    return teamItems;
  };

  const handleSubscriptionsReview = async (teamId: string) => {
    if (teamId) {
      const selectedTeamIndex = teams.findIndex((item) => item._id === teamId);
      const selectedTeam = teams[selectedTeamIndex];
      const fTeam = await fetchTeam(selectedTeam);
      if (fTeam && fTeam._id) {
        const selectedLeagueIndex = leagues.findIndex(
          (item) => item._id === fTeam.league_id
        );
        const selectedLeague = leagues[selectedLeagueIndex];
        setLeagueState(selectedLeague);
        setTeamState(fTeam);
      }
    }
  };

  const togglePaymentModal = () => {
    setShowPaymentModal(true);
  };

  const createPortalSession = async (
    customerId: string,
    selectedTeam: ITeam,
    org: IOrganization
  ) => {
    if (customerId && org && org._id && kcUser) {
      const payCheck: ISessionEvent = {
        customer_id: customerId ? customerId : org._id,
        organizationInfo: {
          organization_id: org._id,
          organization_admin_email: org.organization_admin_email,
          organizationName: org.name,
          teamName: selectedTeam ? selectedTeam.name : org.name,
          child_id: customerId ? customerId : undefined,
          first_name: kcUser.given_name,
          last_name: kcUser.family_name,
        },
      };

      if (process.env.REACT_APP_APP_URL)
        payCheck.redirect_url = `${process.env.REACT_APP_APP_URL}/auth/payment/subscriptions?team_id=${selectedTeam._id}`;
      try {
        const fetchedSession = await axios.post(
          `${process.env.REACT_APP_API_URL}/payments/create-session`,
          payCheck,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        const link: string = fetchedSession.data;
        return link;
      } catch (e) {
        return null;
      }
    }
  };

  const handlePaymentIntent = async (team: ITeam) => {
    setTeamState(team);
    if (team && team._id && organization) {
      const link = await createPortalSession(team._id, team, organization);
      if (link) {
        setChargeBeeLink(link);
        setShowPaymentModal(true);
      } else {
        setMessage('Error creating Payment Link. Contact Support.');
        setIsError(true);
        setShowMessage(true);
      }
    } else {
      setMessage('Error creating Payment Link. Contact Support.');
      setIsError(true);
      setShowMessage(true);
    }
  };

  const teamsList = (): React.ReactNode => {
    const teamNode: Array<React.ReactNode> = [];
    teams.forEach((team: ITeam, index: number) => {
      teamNode.push(
        <tr key={index}>
          <td className="text-left Mo-MW-150">
            {organization ? organization.name : team.organization_id}
          </td>
          <td className="text-left Mo-MW-150">
            {leagueState ? leagueState.name : team.league_id}
          </td>
          <td className="text-left Mo-MW-150">
            {' '}
            {team.hidden ? `${team.name} (hidden)` : team.name}
          </td>
          <td className="text-left">
            <div className="btn-block">
              <Button
                className="Table-Btn-Go"
                onClick={() =>
                  team._id
                    ? handleSubscriptionsReview(team._id)
                    : setTeamState(team)
                }
              >
                Review
              </Button>
            </div>
          </td>
          <td className="text-left">
            <div className="btn-block">
              <Button
                className="Table-Btn-Go"
                onClick={() => handlePaymentIntent(team)}
              >
                Manage <i className="fa fa-angle-right" aria-hidden="true" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });
    return teamNode;
  };

  return (
    <Row>
      <div className="SectionTitle _with-btn form-group">
        <label style={{ paddingBottom: '10px' }}>
          *Select your team based on the dropdowns below to manage payments
        </label>
        <br />
        <Row>
          <Col size="12" md="2" lg="3">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Organizations"
                  className="theme-input"
                  value={
                    organization
                      ? organizations.findIndex(
                          (item) => item._id === organization._id
                        )
                      : 50000
                  }
                  onChange={(e) => handleOrganizationChange(e)}
                >
                  {organizationsDropDown()}
                </select>
              </div>
            </div>
          </Col>

          <Col size="12" md="2" lg="3">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Leagues"
                  className="theme-input"
                  value={
                    leagueState
                      ? leagues.findIndex(
                          (item) => item._id === leagueState._id
                        )
                      : 40000
                  }
                  onChange={(e) => handleLeagueChange(e)}
                >
                  {leaguesDropDown()}
                </select>
              </div>
            </div>
          </Col>

          <Col size="12" md="2" lg="3">
            <div className="form-group mb-md-0">
              <div className="select">
                <select
                  id="Teams"
                  className="theme-input"
                  value={
                    teamState
                      ? teams.findIndex((item) => item._id === teamState._id)
                      : 30000
                  }
                  onChange={(e) => handleTeamChange(e)}
                >
                  {teamsDropDown()}
                </select>
              </div>
            </div>
          </Col>
          {showMessage && (
            <Col size="12" md="6" lg="6" className={'adminDashboardMessage'}>
              <Message
                message={message}
                setShowMessage={setShowMessage}
                error={isError}
                noMargin={true}
              />
            </Col>
          )}
        </Row>
      </div>

      {/*Subscription Table*/}
      {!loadingData && organization && teamState && (
        <div className="SectionTitle">
          <Row>
            <Col size="12">
              <div className="PageTitle-Payments">
                <h3>
                  Payments for: {teamState ? teamState.name : 'Selected Team'}
                </h3>
              </div>
            </Col>
          </Row>{' '}
          <SubscriptionsTable
            organizationState={organization}
            teamState={teamState}
          />
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <Row>
          <Col size="12">
            <div className="PageTitle-Payments">
              <h3>Summary List of all Teams</h3>
            </div>
          </Col>
        </Row>
        <Col size="12">
          <Table className="table-striped">
            <TableHead>
              <tr>
                <th className="text-left Mo-MW-130">Organization</th>
                <th className="text-left Mo-MW-150">League</th>
                <th className="text-left Mo-MW-150">Team Name</th>
                <th className="text-left Mo-MW-150">Subscriptions</th>
                <th className="text-left Mo-MW-150">Account</th>
              </tr>
            </TableHead>
            {!organization && (
              <TableBody>
                <tr>
                  <td className="text-left Mo-MW-150">Select Organization.</td>
                </tr>
              </TableBody>
            )}
            {teams.length > 0 && <TableBody> {teamsList()} </TableBody>}

            {organization && teams.length === 0 && (
              <TableBody>
                {' '}
                <tr>
                  <td className="text-left Mo-MW-150">
                    Organization has no teams.
                  </td>
                </tr>{' '}
              </TableBody>
            )}
          </Table>
        </Col>
      </div>

      {teamState && (
        <Modal
          show={showPaymentModal}
          isOpen={showPaymentModal}
          centered
          className="CreateGame-Popup"
          inline={false}
          overflowScroll
          noClickableBodyWithoutBackdrop={true}
          toggle={togglePaymentModal}
        >
          <ModalHeader closeButton={true} onHide={togglePaymentModal}>
            Go to Payment Portal
          </ModalHeader>
          {!chargeBeeLink && (
            <ModalBody>
              <Row>
                <Col size="12">
                  <div className="form-group mb-md-0">
                    <p
                      className={'SportCovered-Data'}
                    >{`Payments are currently not activated. Please try again later.`}</p>

                    <br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size="12">
                  <div className="form-group Model-btns ">
                    <Button
                      className="Theme-Btn Table-Btn-Cancel"
                      onClick={() => {
                        setShowPaymentModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          )}

          {chargeBeeLink && (
            <ModalBody>
              <Row>
                <Col size="12">
                  <div className="form-group mb-md-0">
                    <p
                      className={'SportCovered-Data'}
                    >{`You will now be redirected to the secure Athstat Payment Portal.`}</p>

                    <br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size="12">
                  <div className="form-group Model-btns ">
                    <Button
                      className="Theme-Btn Table-Btn-Cancel"
                      onClick={() => {
                        setShowPaymentModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    {chargeBeeLink && (
                      <Button className="Theme-Btn">
                        <a
                          target="_blank"
                          href={chargeBeeLink}
                          className="chargebee-hover-box"
                          rel="noreferrer"
                        >
                          Continue
                        </a>
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          )}
        </Modal>
      )}
    </Row>
  );
};

export default PaymentTable;
