import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { createFantasyteam } from './games.services';
import { FantasyGamesContext } from 'context/FantasyGamesContext';

interface GamesOnboardProps {
  onboardingCallback: (accepted: boolean) => void;
  setOnboardingStatus: (accepted: boolean) => void;
}

const GamesOnboard: React.FC<GamesOnboardProps> = ({
  onboardingCallback,
  setOnboardingStatus,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [teamName, setTeamName] = useState('');
  const [, setCompletedOnboarding] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const { fetchFantasyTeam } = useContext(FantasyGamesContext);
  const navigate = useNavigate();
  const token = user?.access_token;

  const handleNext = () => {
    if (activeStep === 4 && teamName.length < 5) {
      alert('Team name must be at least 5 characters');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSkip = () => {
    setActiveStep(3);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/auth/');
    } else if (activeStep === 3) {
      navigate('/auth/');
      alert(
        'You must accept the Terms and Conditions in order to play Athstat Games'
      );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTeamName(event.target.value);
  };

  const handleSubmit = () => {
    if (teamName.length < 5) {
      alert('Team name must be at least 5 characters');
      return;
    }

    if (teamName === '') {
      alert('Please enter a team name');
      return;
    }

    createUserTeam()
      .then((response) => {
        if (response === 'success') {
          onboardingCallback(true);
          setOnboardingStatus(true);
          setCompletedOnboarding(true);
          navigate('/auth/games-dash/fantasy/pickteam');
          alert('Team created successfully');
        } else {
          alert('Error creating team');
          return;
        }
      })
      .catch(() => {
        alert('Error creating team');
        return;
      });
  };

  const createUserTeam = async (): Promise<string> => {
    if (token && isAuthenticated && fetchFantasyTeam) {
      try {
        const response = await createFantasyteam({
          token: user.access_token,
          team: {
            team_name: teamName,
            user_id: user.profile.sub,
            selected_avatar_index: 1,
          },
        });
        if (response != null) {
          fetchFantasyTeam(user.profile.sub, token);
          return 'success';
        } else return 'error';
      } catch (error) {
        return 'error';
      }
    } else {
      return 'error';
    }
  };

  return (
    <Box className="onboarding-styling">
      <Box className="onboard-wizard">
        {activeStep === 0 && (
          <Box>
            <Button className="onboardingSkip" onClick={handleSkip}>
              Skip
            </Button>
            <h2 className="onboarding-title">Welcome to Athstat Games!</h2>
            <img
              className="onboardingImg"
              src="/img/couch.png"
              alt={'onboarding'}
            />
            <Box className="onboardingButton-container">
              <Button className="onboarding-button" onClick={handleBack}>
                Close
              </Button>
              <Button className="onboarding-button" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Button className="onboardingSkip" onClick={handleSkip}>
              Skip
            </Button>
            <h2 className="onboarding-title">Compete To Be The Best!</h2>
            <h3 className="onboarding-description">
              Test your skills against the best from all over the world.
            </h3>
            <img className="onboardingImg" src="/img/fans.png" alt={'fans'} />

            <Box className="onboardingButton-container">
              <Button className="onboarding-button" onClick={handleBack}>
                Back
              </Button>
              <Button className="onboarding-button" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <Button className="onboardingSkip" onClick={handleSkip}>
              Skip
            </Button>
            <h2 className="onboarding-title">Earn Prizes!</h2>
            <h3 className="onboarding-description">
              Earn exclusive prizes and rewards by strategizing, managing your
              team, and competing against friends in head to head leagues!{' '}
            </h3>
            <img className="onboardingImg" src="/img/prizes.png" alt={'fans'} />

            <Box className="onboardingButton-container">
              <Button className="onboarding-button" onClick={handleBack}>
                Back
              </Button>
              <Button className="onboarding-button" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 3 && (
          <Box>
            <h2 className="onboarding-title">Terms and Conditions</h2>
            <h3 className="onboarding-description2">
              By clicking ACCEPT, you are confirming that you are 18, you live
              in an area where Fantasy Games are{' '}
              <a
                className="onboarding-link"
                href="https://www.athstat.io//allowed-locations"
              >
                allowed
              </a>{' '}
              , and you accept the terms listed in our{' '}
              <a
                className="onboarding-link"
                href="https://www.athstat.io/privacy-policy"
              >
                Privacy Policy
              </a>{' '}
              <a
                className="onboarding-link"
                href="https://www.athstat.io/games-terms"
              >
                and Athstat Games Terms And Conditions
              </a>{' '}
            </h3>

            <Box className="onboardingButton-container">
              <Button className="onboarding-button2" onClick={handleBack}>
                Close
              </Button>
              <Button className="onboarding-button" onClick={handleNext}>
                ACCEPT
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 4 && (
          <Box>
            <h2 className="onboarding-title">Name Your Team</h2>
            <Box>
              <input
                className="team-onboarding"
                style={{ color: 'white' }}
                placeholder="Team Name"
                value={teamName}
                onChange={handleNameChange}
              />
            </Box>
            <img
              className="onboardingImg3"
              src="/img/pickname.png"
              alt={'fans'}
            />

            <Box className="onboardingButton-container">
              <Button className="onboarding-button2" onClick={handleBack}>
                Back
              </Button>
              <Button className="onboarding-button3" onClick={handleSubmit}>
                Get Started
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GamesOnboard;
