import { RouteObject } from 'react-router-dom';
import React from 'react';
import ReactRouterEB from '../components/ErrorFallBack/ReactRouterEB';
import Layout from '../components/Layout/Layout';
import Login from '../components/Login/Login';
import PublicLayout from '../components/Layout/PublicLayout';
import PublicRoutes from './public';

import AdminRoutes from './AdminRoutes';

import PaymentRoutes from './PaymentRoutes';

import GamesRoutes from './GamesRoutes';
import GamesDash from 'components/HomePage';
// import SignUpForm from 'components/UserSignUp/UserSignUpFlow';
import SignUpWizard from '../components/UserSignUp/SignUpWizard';
import NewLogin from 'components/UserSignUp/NewLogin';
import Pending from '../components/UserSignUp/Pending';
import Banned from '../components/UserSignUp/Banned';
import SignUpGames from 'components/UserSignUp/SignUpGames';
import LocationBlocked from '../components/UserSignUp/LocationBlocked';
import LocationDisclaimer from '../components/UserSignUp/LocationDisclaimer';
import VerifyUser from 'components/UserSignUp/VerifyUser';

const authRoutes: Array<RouteObject> = [
  { index: true, element: <GamesDash /> },
  {
    path: 'admin',
    children: AdminRoutes,
    errorElement: <ReactRouterEB />,
  },

  {
    path: 'games-dash',
    children: GamesRoutes,
    errorElement: <ReactRouterEB />,
  },

  {
    path: 'payment',
    children: PaymentRoutes,
    errorElement: <ReactRouterEB />,
  },
];

const Routes: Array<RouteObject> = [
  {
    path: '/',
    element: <PublicLayout />,
    children: PublicRoutes,
    errorElement: <ReactRouterEB />,
  },

  { path: '/login', element: <Login /> },
  { path: '/signup', element: <SignUpWizard /> },
  { path: '/newlogin', element: <NewLogin /> },
  { path: '/pending', element: <Pending /> },
  { path: '/banned', element: <Banned /> },
  { path: '/signup-games', element: <SignUpGames /> },
  { path: '/location-blocked', element: <LocationBlocked /> },
  { path: '/location-disclaimer', element: <LocationDisclaimer /> },
  { path: '/verify-user', element: <VerifyUser /> },

  {
    path: '/auth',
    element: <Layout />,
    children: authRoutes,
    errorElement: <ReactRouterEB />,
  },
];

export default Routes;
