import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="footer-styling-games">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1280"
        height="2"
        viewBox="0 0 1280 2"
        fill="none"
      >
        <path d="M0 1H1280" stroke="#323232" strokeWidth="2" />
      </svg>
      <div className="footer-div">
        <div className="name-links">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="127"
            height="14"
            viewBox="0 0 127 14"
            fill="none"
          >
            <path
              d="M50.4719 13.4971V7.89431H40.4321V13.4971H37.3789V0.692871H40.4321V5.56787H50.4719V0.692871H53.5437V13.4971H50.4719Z"
              fill="white"
            />
            <path
              d="M58.9062 13.3422V10.8521C58.9062 10.6358 59.0922 10.4604 59.3185 10.4604L61.4294 9.98112C61.5193 9.98112 61.5937 10.0483 61.5937 10.1331L61.6061 10.9807C61.6061 11.0654 61.6805 11.1326 61.7704 11.1326H70.4092C71.1841 11.1326 71.4878 10.9836 71.4878 10.3581V8.49348C71.4878 7.9031 71.1686 7.69852 70.4092 7.69852H63.3109C60.5305 7.69852 59.4673 6.42424 59.4673 4.54204V3.65647C59.4673 1.77428 60.5305 0.5 63.3109 0.5H72.9881C73.6266 0.5 74.1443 0.988085 74.1443 1.59015V3.32329C74.1443 3.37882 74.0978 3.42266 74.0389 3.42266L71.5343 3.76461C71.4754 3.76461 71.429 3.72077 71.429 3.66524V2.94335C71.4259 2.88781 71.3794 2.84397 71.3205 2.84397H63.314C62.5887 2.84397 62.2198 3.04856 62.2198 3.63894V4.5625C62.2198 5.11781 62.5391 5.35746 63.314 5.35746H70.4123C73.1927 5.35746 74.2559 6.63174 74.2559 8.51686V10.326C74.2559 12.2082 73.1927 13.5029 70.4123 13.5029H59.0705C58.9806 13.5029 58.9062 13.4328 58.9062 13.348V13.3422Z"
              fill="white"
            />
            <path
              d="M107.494 13.4971H110.637L102.68 0.49707H100.631L92.625 13.4971H95.734L97.2435 10.9222H106.025L107.497 13.4971H107.494ZM98.7779 8.42041L101.673 3.30575L104.509 8.42041H98.7779Z"
              fill="white"
            />
            <path
              d="M14.8723 13.4971H18.0153L10.0554 0.49707H8.00647L0 13.4971H3.10898L4.61852 10.9222H13.3999L14.8723 13.4971ZM6.15596 8.42041L9.04796 3.30867L11.8842 8.42333H6.15596V8.42041Z"
              fill="white"
            />
            <path
              d="M117.772 13.4971V2.75335H111.188V0.692871H126.997V2.75335H120.447V13.4971H117.769H117.772Z"
              fill="white"
            />
            <path
              d="M83.1491 13.4971V2.75335H76.5654V0.692871H92.3738V2.75335H85.8242V13.4971H83.146H83.1491Z"
              fill="white"
            />
            <path
              d="M25.197 13.4971V2.75335H18.6133V0.692871H34.4216V2.75335H27.872V13.4971H25.1939H25.197Z"
              fill="white"
            />
          </svg>
          <h1 className="footer-desc">
            {' '}
            PlayerRankings is daily fantasy made easy. It’s just you against the
            numbers.
          </h1>
          <div className="footer-links">
            <a
              href="https://www.example.com"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Contact Us
            </a>
            <a
              href="https://www.example.com"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Help
            </a>
            <a
              href="https://www.example.com"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Responsible Gaming
            </a>
            <a
              href="https://www.example.com"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Terms
            </a>
            <a
              href="https://www.example.com"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Privacy
            </a>
          </div>
        </div>
        <div className="app-store-links">
          <h1 className="app-store-text">Get the app</h1>
          <div className="store-img-pics">
            <img
              className="app-store-img"
              src="/img/apple-appstore.png"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://apps.apple.com/app/id6473277507',
                  '_blank'
                );
              }}
            />
            <img
              className="app-store-img"
              src="/img/google-appstore.png"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=io.athstatgaming.app',
                  '_blank'
                );
              }}
            />
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1280"
        height="2"
        viewBox="0 0 1280 2"
        fill="none"
      >
        <path d="M0 1H1280" stroke="#323232" strokeWidth="2" />
      </svg>
      <div className="cr-social-links">
        <h1 className="copy-right-text">
          © 2024 ATHSTAT. All rights reserved.
        </h1>
        <div className="social-links">
          <img src={'/icons/discord.svg'} />
          <img src={'/icons/unkown-social.svg'} />
          <img src={'/icons/youtube.svg'} />
          <img src={'/icons/instagram.svg'} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
