import React, { useEffect, ReactElement, useState } from 'react';
import Header from '../Header/Header';
import styles from '../../styles/Home.module.css';
import Footer from '../Footer/Footer';
import axios, { AxiosResponse } from 'axios';
import { User } from '../../../d';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const Layout = (): ReactElement => {
  const navigate = useNavigate();
  const auth = useAuth();
  const kcUser = auth.user?.profile;
  //const token = auth.user?.access_token;
  const userId = kcUser?.sub;
  //const email = kcUser?.email ? kcUser?.email : 'example@example.com';
  //const given_name = kcUser?.given_name;
  //const family_name = kcUser?.family_name;
  const [loadingUser] = useState(false);
  const [athUser] = useState<User>({
    kc_id: '',
    email: '',
    kcSynced: true,
    hidden: false,
    middleInitial: '',
    age: '',
    sex: '',
    height: '',
    weight: '',
    nftCustomerAgreement: false,
    gamesOnboardingComplete: false,
    state: 'new',
    verified: '',
    athcoin_balance: 0,
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            //LOGIC FOR GEOFENCE UPDATE HERE if blocked location navigate ('/location-block)
            // eslint-disable-next-line no-console
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            const runGeo = async () => {
              const result = await postLocation(latitude, longitude);
              if (result === 'Success') {
                setTimeout(async () => {
                  const res = await fetchGeolocation();
                  if (res) {
                    if (res === 'ENTER') {
                      //getUser();
                      navigate('/auth');
                    } else if (res === 'EXIT') {
                      navigate('/location-blocked');
                      return;
                    } else {
                      navigate('/location-disclaimer');
                    }
                  } else {
                    // eslint-disable-next-line no-console
                    console.log('Failed to get the location status');
                  }
                }, 1500);
              }
            };
            runGeo();
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.error('Unable to retrieve your location:', error.message);
          }
        );
      } else {
        // eslint-disable-next-line no-console
        console.log('Geolocation is not supported by this browser.');
        navigate('/location-disclaimer');
      }
    }
    // const getUser = async () => {
    //   try {
    //     const fetchedUser = await axios.get(
    //       `${process.env.REACT_APP_API_URL}/users/${userId}`,
    //       {
    //         headers: {
    //           Authorization: token ? `Bearer ${token}` : undefined,
    //         },
    //       }
    //     );

    //     if (fetchedUser.data) {
    //       // eslint-disable-next-line no-console
    //       console.log(`Success: User ${fetchedUser.data.state} fetched.`);
    //       switch (fetchedUser.data.state) {
    //         case 'new':
    //         case null:
    //         case undefined:
    //           navigate('/signup-games');
    //           break;
    //         case 'pending':
    //           navigate('/pending');
    //           break;
    //         case 'banned':
    //           navigate('/banned');
    //           break;
    //         default:
    //           break;
    //       }
    //     }
    //   } catch (err) {
    //     // eslint-disable-next-line no-console
    //     console.log(err);
    //   }
    // };
  }, [auth]);

  //   useEffect(() => {
  //     if (auth.isAuthenticated) {
  //       const getUser = async () => {
  //         setLoadingUser(true);
  //         try {
  //           const fetchedUser = await axios.get(
  //             `${process.env.REACT_APP_API_URL}/users/${userId}`,
  //             {
  //               headers: {
  //                 Authorization: token ? `Bearer ${token}` : undefined,
  //               },
  //             }
  //           );

  //           if (fetchedUser.data) {
  //             // eslint-disable-next-line no-console
  //             console.log(`Success: User ${userId} fetched.`);
  //             setAthUser(fetchedUser.data);
  //             setLoadingUser(false);
  //           } else {
  //             if (userId) {
  //               const newUser: User = {
  //                 kc_id: userId,
  //                 email: email,
  //                 firstName: given_name,
  //                 lastName: family_name,
  //                 kcSynced: true,
  //                 hidden: false,
  //                 middleInitial: '',
  //                 age: '',
  //                 sex: '',
  //                 height: '',
  //                 weight: '',
  //                 nftCustomerAgreement: false,
  //                 gamesOnboardingComplete: false,
  //                 state: 'new',
  //                 verified: '',
  //                 athcoin_balance: 0,
  //               };
  //               const createdUser = await axios.post(
  //                 `${process.env.REACT_APP_API_URL}/users/`,
  //                 newUser,
  //                 {
  //                   headers: {
  //                     Authorization: token ? `Bearer ${token}` : undefined,
  //                   },
  //                 }
  //               );
  //               if (createdUser) {
  //                 setAthUser(createdUser.data);
  //               }
  //               setLoadingUser(false);
  //             }
  //             setLoadingUser(false);
  //           }
  //         } catch (err) {
  //           setLoadingUser(false);
  //         }
  //       };
  //       getUser();
  //     }
  //   }, [auth.isLoading]);

  const fetchGeolocation = async (): Promise<string> => {
    let response = 'none';
    try {
      await axios
        .get(
          `https://qa-games-app.athstat-next.com/api/v1/geolocation/${userId}`
        )
        .then((r: AxiosResponse) => {
          response = r.data.event_type;
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Error fetching location');
        });
      return response;
    } catch (e) {
      if (e instanceof Error)
        // eslint-disable-next-line no-console
        console.log('Unable to fetch location for user');
      return 'none';
    }
  };

  const postLocation = async (
    latitude: number,
    longitude: number
  ): Promise<string> => {
    try {
      const data = JSON.stringify({
        id: userId,
        location: {
          latitude: latitude,
          longitude: longitude,
        },
      });
      const response: AxiosResponse = await axios.post(
        `https://qa-games-app.athstat-next.com/api/v1/geolocation/checkLocation`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data.message;
    } catch (e) {
      if (e instanceof Error)
        // eslint-disable-next-line no-console
        console.log('Error posting location');
      return 'none';
    }
  };
  // const pathname = useCurrentPath();
  // useEffect(() => {
  //   if (session) {
  //     const getUser = async () => {
  //       setLoadingUser(true);
  //       try {
  //         const fetchedUser = await axios.get(
  //           `${process.env.REACT_APP_API_URL}/users/${userId}`,
  //           {
  //             headers: {
  //               Authorization: session ? `Bearer ${session.token}` : undefined,
  //             },
  //           }
  //         );
  //
  //         if (fetchedUser.data) {
  //           setUser(fetchedUser.data);
  //           setLoadingUser(false);
  //         } else {
  //           if (userId) {
  //             const newUser: User = {
  //               kc_id: userId,
  //               email: email,
  //               firstName: given_name,
  //               lastName: family_name,
  //               kcSynced: true,
  //               hidden: false,
  //               middleInitial: '',
  //               age: '',
  //               sex: '',
  //               height: '',
  //               weight: '',
  //               nftCustomerAgreement: false,
  //             };
  //             const createdUser = await axios.post(
  //               `${process.env.REACT_APP_API_URL}/users/`,
  //               newUser,
  //               {
  //                 headers: {
  //                   Authorization: session
  //                     ? `Bearer ${session.token}`
  //                     : undefined,
  //                 },
  //               }
  //             );
  //             if (createdUser) {
  //               setUser(createdUser.data);
  //             }
  //             setLoadingUser(false);
  //           }
  //           setLoadingUser(false);
  //         }
  //       } catch (err) {
  //         setLoadingUser(false);
  //       }
  //     };
  //     getUser();
  //   }
  // }, []);

  if (!auth.isLoading && !auth.isAuthenticated) {
    navigate('/login');
  }
  if (loadingUser && !athUser) {
    return <div>Initializing User</div>;
  }

  return (
    <div className={styles.container}>
      <head>
        <title>{'Athstat'}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="application-name" content="Athstat Web App" />
        <meta
          name="description"
          content="Athstat Sports Analytics Application"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
      </head>
      <div id="MainApp" className={'MainApp'}>
        <Header competitionId={''} club_id={''} />

        {/*<Login />*/}

        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
