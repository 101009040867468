import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const ResponsibleGaming: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div>
              <h2 className="Gaming-PageTitle">
                {' '}
                Athstat Gaming Responsible Gambling / Gaming Plan{' '}
              </h2>
            </div>
          </Col>
        </Row>
        <h2 className="gaming-policy-header">
          Responsible Daily Fantasy Sports Gaming
        </h2>
        <h3 className="gaming-policy-paragraph">
          Responsible gaming is a vital aspect of the daily fantasy sports
          industry. Athstat looks at responsible gaming as a shared initiative
          of the company and our members. We provide members with tools and
          opportunities to maximize the playing experience while minimizing the
          risks associated with problem gaming. It includes tools to encourage
          and facilitate adopting healthy and balanced habits when engaging in
          DFS activities, while also recognizing the warning signs of problem
          play. Understanding responsible gaming is crucial for both individuals
          and the industry as a whole, as it promotes a safe and enjoyable
          environment for everyone involved.
        </h3>
        <h2 className="gaming-policy-header">
          The Importance of Responsible Gaming
        </h2>
        <h3 className="gaming-policy-paragraph">
          Responsible gaming plays a significant role in safeguarding the DFS
          experience for individuals. It helps ensure that play remains a form
          of entertainment rather than a disruptive behavior. By encouraging
          responsible gaming and providing useful tools, operators can help
          players maintain control over their play and make informed decisions
          about when and how much to play. This protects their well-being and
          encourages healthy playing and entertainment for all of our Athstat
          community and members. Athstat takes seriously our priority to help
          ensure player safety through responsible gaming.
        </h3>

        <h2 className="gaming-policy-header">
          Tips for Gaming Responsibly in Daily Fantasy Sports
        </h2>
        <h3 className="gaming-policy-paragraph">
          To ensure a safe and enjoyable experience, Athstats recommends that
          members follow these tips for gaming responsibly:
        </h3>
        <ul className="gaming-list">
          <li className="gaming-list-item">
            {' '}
            Set a budget: Determine how much money you are comfortable spending
            on DFS and stick to that budget. Avoid chasing losses or increasing
            your budget once it is depleted.
          </li>
          <li className="gaming-list-item">
            {' '}
            Set time limits: Allocate a specific amount of time for DFS and
            stick to it. This prevents excessive play and allows you to engage
            in other activities.
          </li>
          <li className="gaming-list-item">
            {' '}
            Educate yourself: Understand the games you are playing and the
            rules. This knowledge will help you make informed decisions.
          </li>
          <li className="gaming-list-item">
            {' '}
            Take breaks: Regularly take breaks during your sessions to reassess
            your emotions and mindset. This helps prevent impulsive decisions
            and allows you to maintain control over your playing habits.
          </li>
        </ul>
        <h3 className="gaming-policy-paragraph">
          Most importantly, Athstat is working to ensure that all players have
          the support and resources that they need to minimize any problems that
          may arise from their play, including the following features that will
          be rolled out in 2024:
        </h3>
        <ul className="gaming-list">
          <li className="gaming-list-item"> Alerts on entries</li>
          <li className="gaming-list-item">
            {' '}
            These alerts notify you if you exceed your set limit for amount
            played in a day, week, or month.
          </li>
          <li className="gaming-list-item"> Limits on entries and deposits</li>
          <li className="gaming-list-item">
            {' '}
            These self-imposed limits restrict you from playing or depositing
            more than your set amount for daily, weekly, or monthly periods.
            They can’t be removed for 90 days.
          </li>
          <li className="gaming-list-item">
            {' '}
            Timeouts (1-30 days of self-imposed restricted access) by emailing
            us at <a>responsiblegambling@athstat.com</a>
          </li>
          <li className="gaming-list-item">
            {' '}
            Timeouts disable you from depositing funds or placing entries for a
            time period of your choice. You will, however, still be able to
            withdraw funds.
          </li>
          <li className="gaming-list-item">
            {' '}
            Self-exclusion by emailing us directly at
            <a>responsiblegambling@athstat.com</a>
          </li>
          <li className="gaming-list-item">
            {' '}
            This self-imposed feature lasts for 30+ days and blocks you from
            accessing your account and receiving marketing notifications. Before
            your self-exclusion goes into effect, you will be able to withdraw
            your finds.
          </li>
        </ul>
        <h3 className="gaming-policy-paragraph">
          Remember, these tools are beneficial for all members, and are not
          designed just for those who suspect they may have a problem. These
          tools encourage prevention of problem gaming and are designed to help
          minimize the number of issues that arise.
        </h3>

        <h2 className="gaming-policy-header">
          Setting Daily Fantasy Sports Limits and Sticking to Them
        </h2>
        <h3 className="gaming-policy-paragraph">
          Setting limits is a crucial aspect of responsible gaming. By
          establishing both financial and time limits, you can safeguard your
          experience and prevent excessive play. Financial limits involve
          determining the maximum amount of money you are willing and able to
          spend. Time limits, on the other hand, refer to the duration of your
          sessions. It is important to set realistic limits and stick to them to
          ensure that play remains a form of entertainment and does not become
          harmful
        </h3>

        <h2 className="gaming-policy-header">Signs of Problem Gaming</h2>
        <h3 className="gaming-policy-paragraph">
          It is essential to be aware of the signs of problem play to promote
          responsible gaming and intervene when necessary. Remember, you are not
          alone, help and hope exist. Some common signs include:
        </h3>
        <ul className="gaming-list">
          <li className="gaming-list-item">
            {' '}
            Increasing time and money spent on gaming: When play starts to take
            up a significant portion of your time and financial resources, it
            may be a sign of a problem.
          </li>
          <li className="gaming-list-item">
            {' '}
            Neglecting responsibilities: If play begins to interfere with your
            daily life and responsibilities, such as work, relationships, or
            personal hygiene, it is a cause for concern.
          </li>
          <li className="gaming-list-item">
            {' '}
            Chasing losses: Continuously trying to recoup losses by playing more
            can indicate a problem. It is crucial to recognize that play should
            not be seen as a way to make money but rather as a form of
            entertainment.
          </li>
          <li className="gaming-list-item">
            {' '}
            Borrowing money to play: If you find yourself borrowing money or
            taking out loans to fund your playing activities, it is a clear
            warning sign that your playing habits are becoming problematic.
          </li>
          <li className="gaming-list-item">
            {' '}
            Hiding gaming activities: Feeling the need to hide your playing
            habits from loved ones or being dishonest about the extent of your
            play may indicate a problem.
          </li>
        </ul>
        <h2 className="gaming-policy-header">
          Resources for Responsible Gaming
        </h2>
        <h3 className="gaming-policy-paragraph">
          Fortunately, help exists. There are outside resources available to
          support responsible gaming. These resources provide valuable
          information, tools, and support to help individuals maintain control
          over their playing habits. The National Council for Problem Gambling
          (NCPG) can help with many different consumer protection issues such as
          problem play, and are available to discuss tips to keep it fun and
          provide basic facts about problem gaming. NCPG also offers support and
          counseling referrals that may help you through gaming-related
          problems. The NCPG has a toll-free confidential helpline that is
          available 24/7. Contact them by phone or text at 1-800-GAMBLER or chat
          at ncpgambling.org/chat.
        </h3>
        <h3 className="gaming-policy-paragraph">
          For New York Users: call the toll-free confidential helpline at{' '}
          <a>OASAS HOPE line – 1-877-8-HOPENY (1-877-846-7369)</a> or text
          HOPENY to <a>(467369)</a>,
        </h3>
        <h2 className="gaming-policy-header">
          Responsible Gaming & Athstat Support
        </h2>
        <h3 className="gaming-policy-paragraph">
          Athstat plays an active role in promoting responsible gaming by
          implementing measures to create a safe environment through
          collaboration with support organizations. Together, we can ensure that
          DFS remains an enjoyable and responsible activity for all. Responsible
          gaming is of utmost importance in safeguarding the experience for our
          Athstat members and community. By understanding responsible daily
          fantasy sports, recognizing signs of problem play, and following the
          tips and guidelines provided, Athstat members can enjoy daily fantasy
          sports as a form of entertainment while minimizing the risks
          associated with excessive playing.
        </h3>

        <h3 className="gaming-policy-paragraph">
          Contact us at <a>responsiblegambling@athstat.com</a> with any
          questions or concerns. Find out why so many daily fantasy sports users
          are joining the Athstat community and become a member today.
        </h3>
      </section>
    </React.Fragment>
  );
};
export default ResponsibleGaming;
