import React, { ReactElement, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const GamesExternal = (): ReactElement => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();

  useEffect(() => {
    const checkAuth = () => {
      if (!isLoading && isAuthenticated) {
        navigate('/auth/games-dash');
      } else {
        signinRedirect({
          redirect_uri: `${process.env.REACT_APP_APP_URL}/auth/games-dash`,
        });
      }
    };

    checkAuth();
  }, []);

  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <div></div>
        <Outlet />
      </section>
    </React.Fragment>
  );
};
export default GamesExternal;
