/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import PlayerCard from './PlayerCard';
import CreateTeamModal from './CreateTeamModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { useAuth } from 'react-oidc-context';
import PriceChangeChart from './PriceChangeChart';
import ComingSoonModal from 'components/UserSignUp/ComingSoon';
//import { useNavigate } from 'react-router-dom';

interface Player {
  position: string;
  team_name: string;
  tracking_id: number;
  player_name: string;
  position_class: string;
  price: string;
  image_url: string;
  power_rank_rating: string;
  sex: string;
  age: number;
  country: string;
  isChecked?: boolean;
  selectedPlayers: Player[];
  team_id: string;
  athlete_id: string;
}

// interface Team {
//   id: number;
//   name: string;
// }

interface TeamOption {
  name: string;
  id: number;
}

interface SelectedCompetition {
  competition_id: string;
  data_source: string;
  end_date: string;
  games_supported: boolean;
  hidden: boolean;
  false: string;
  id: string;
  name: string;
  start_date: string;
}

type PlayerDisplayProps = {
  selectedCompetition: SelectedCompetition | null;
};

const PlayerDisplay: React.FC<PlayerDisplayProps> = ({
  selectedCompetition,
}) => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [filteredAthletes, setFilteredAthletes] = useState<Player[]>([]);
  const [masterList, setMasterList] = useState<Player[]>([]);

  const [, setIsSearchUsed] = useState(false);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [selectedBacksCount, setSelectedBacksCount] = useState(0);
  const [selectedForwardsCount, setSelectedForwardsCount] = useState(0);
  const [club_id, setClub_id] = useState('');

  const default_league_id = 'fbb7a8f3-f1c0-58df-86f4-c495276188e6'; //French Top 14
  const [official_league_id, setOfficialLeagueId] = useState(default_league_id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [isModalFadingOut] = useState(false);
  //const [, setIsModalVisible] = useState(true);
  const { user } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  //const navigate = useNavigate();
  const [, setTeamOptions] = useState<TeamOption[]>([]);
  const [totalValue, setTotalValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [playersPerPage] = useState(9);

  const [buttonPressed, setButtonPressed] = useState(false);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [allowedBacks, setAllowedBack] = useState<number>(0);
  const [allowedForwards, setAllowedForwards] = useState<number>(0);
  const [budgetLimit, setBudgetLimit] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/fantasy-teams/fantasy-clubs/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data[0];
        setClub_id(data.id);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching club data: ', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCompetition) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/unauth/athletes-by-competition/${
            selectedCompetition?.id ?? ''
          }`
        )
        .then((response) => {
          //const data = response.data.rows;
          const data = response.data;
          setMasterList(data);
          //console.log('Data length: ', data);
          const totalItems = data.length;
          const itemsPerPage = 9; // Change this to the number of items you want to show per page
          const totalPages = Math.ceil(totalItems / itemsPerPage);

          setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data: ', error);
        });
    } else {
      setMasterList([]);
      const totalItems = 0;
      const itemsPerPage = 9; // Change this to the number of items you want to show per page
      const totalPages = Math.ceil(totalItems / itemsPerPage);

      setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
    }
  }, [selectedCompetition]);

  useEffect(() => {
    if (official_league_id) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/unauth/fantasy-league-config/${
            official_league_id ?? ''
          }`
        )
        .then((response) => {
          const data = response.data;
          setAllowedBack(data.allowed_positions.back);
          setAllowedForwards(data.allowed_positions.forward);
          setBudgetLimit(data.team_budget);
          setDeadline(data.deadline);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data: ', error);
        });
    } else {
      setAllowedBack(0);
      setAllowedForwards(0);
      setBudgetLimit(0);
      setDeadline('');
    }
  }, [official_league_id]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedCompetition) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/unauth/athletes-by-competition-pagination/${
            selectedCompetition?.id ?? ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage,
              perPage: playersPerPage,
            },
          }
        )
        .then((response) => {
          //const data = response.data.rows;
          const data = response.data;
          // eslint-disable-next-line no-console
          console.log('Fantasy athletes: ', data);
          setPlayers(data);
          setFilteredAthletes(data);

          setOfficialLeagueId(selectedCompetition?.id ?? '');
          setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data: ', error);
          setIsLoading(false);
        });
    } else {
      setPlayers([]);
      setFilteredAthletes([]);

      setOfficialLeagueId('');
      setIsLoading(false);
    }
  }, [selectedCompetition, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setSelectedPlayers([]);
    setSelectedBacksCount(0);
    setSelectedForwardsCount(0);
  }, [selectedCompetition]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fantasy-teams-all/${userId}`,
          {
            params: {
              userId: userId,
            },
          }
        );
        setTeamOptions(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch teams', error);
      }
    };

    fetchTeams();
  }, []);

  const handleSearchBar = async (search: string | null) => {
    setIsSearchUsed(true);
    setInputValue(search || '');
    if (search) {
      const filteredPlayers = masterList.filter((player) =>
        player.player_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredAthletes(filteredPlayers);
      setPlayers(filteredPlayers);
    } else {
      setFilteredAthletes(masterList);
    }
  };

  useEffect(() => {
    setPlayers(filteredAthletes);
  }, [filteredAthletes]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // Calculate pagination
  //   const indexOfLastPlayer = currentPage * playersPerPage;
  //   const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   //    const currentPlayers = players.slice(indexOfFirstPlayer, indexOfLastPlayer);

  const targetDate = new Date('April 3, 2023 23:59:59').getTime();

  const countdownInterval = setInterval(updateCountdown, 1000);

  function updateCountdown() {
    const currentDate = new Date().getTime();
    const timeDifference = targetDate - currentDate;

    const lockInValueElement = document.querySelector('.lock-in-values');

    if (lockInValueElement) {
      if (timeDifference <= 0) {
        clearInterval(countdownInterval);
        lockInValueElement.innerHTML = 'Picks Locked';
      } else {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        let countdownText = '';
        if (days > 0) countdownText += `${days}d `;
        if (hours > 0 || days > 0) countdownText += `${hours}h `;
        if (minutes > 0 || hours > 0 || days > 0)
          countdownText += `${minutes}m `;
        countdownText += `${seconds}s`;

        lockInValueElement.innerHTML = countdownText;
      }
    }
  }

  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [selectedPlayerForModal, setSelectedPlayerForModal] =
    useState<Player | null>(null);

  const showToast = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000, // Auto close after 3 seconds
    });
  };

  const handlePlayerSelect = (player: Player, isSelected: boolean) => {
    if (
      isSelected &&
      ((player.position_class === 'back' &&
        selectedBacksCount === allowedBacks) ||
        (player.position_class === 'forward' &&
          selectedForwardsCount === allowedForwards))
    ) {
      showToast(`You cannot select more ${player.position_class}s.`);
      return;
    }

    if (isSelected) {
      setSelectedPlayers((prevSelected) => {
        const isPlayerAlreadySelected = prevSelected.some(
          (selectedPlayer) => selectedPlayer.tracking_id === player.tracking_id
        );

        if (!isPlayerAlreadySelected) {
          if (player.position_class === 'back') {
            setSelectedBacksCount((prevCount) => prevCount + 1);
          } else if (player.position_class === 'forward') {
            setSelectedForwardsCount((prevCount) => prevCount + 1);
          }
          return [...prevSelected, player];
        }

        // Update isChecked property of the player in the players array
        const updatedPlayers = players.map((p) =>
          p.tracking_id === player.tracking_id ? { ...p, isChecked: true } : p
        );
        setPlayers(updatedPlayers);

        return prevSelected;
      });
    } else {
      setSelectedPlayers((prevSelected) => {
        const newSelectedPlayers = prevSelected.filter(
          (selectedPlayer) => selectedPlayer.tracking_id !== player.tracking_id
        );

        if (player.position_class === 'back') {
          setSelectedBacksCount((prevCount) => prevCount - 1);
        } else if (player.position_class === 'forward') {
          setSelectedForwardsCount((prevCount) => prevCount - 1);
        }

        // Update isChecked property of the player in the players array
        const updatedPlayers = players.map((p) =>
          p.tracking_id === player.tracking_id ? { ...p, isChecked: true } : p
        );
        setPlayers(updatedPlayers);

        return newSelectedPlayers;
      });
    }
  };

  // Calculate total value whenever selectedPlayers changes
  useEffect(() => {
    const total = selectedPlayers.reduce(
      (sum, player) => sum + parseFloat(player.price),
      0
    );
    setTotalValue(total);
  }, [selectedPlayers]);

  const handleAddPlayerToSelectedPlayers = (player: Player) => {
    if (
      ((player.position_class === 'back' &&
        selectedBacksCount === allowedBacks) ||
        (player.position_class === 'forward' &&
          selectedForwardsCount === allowedForwards)) &&
      !player.isChecked
    ) {
      alert(`You cannot select more ${player.position_class}s.`);
      return;
    }

    if (
      player.position_class === 'back' &&
      selectedBacksCount === allowedBacks
    ) {
      alert(`You cannot select more ${player.position_class}s.`);
      return;
    }

    if (
      player.position_class === 'forward' &&
      selectedForwardsCount === allowedForwards
    ) {
      alert(`You cannot select more ${player.position_class}s.`);
      return;
    }

    //if (isSelected) {
    setSelectedPlayers((prevSelected) => {
      const isPlayerAlreadySelected = prevSelected.some(
        (selectedPlayer) => selectedPlayer.tracking_id === player.tracking_id
      );

      if (!isPlayerAlreadySelected) {
        if (player.position_class === 'back') {
          setSelectedBacksCount((prevCount) => prevCount + 1);
        } else if (player.position_class === 'forward') {
          setSelectedForwardsCount((prevCount) => prevCount + 1);
        }
        return [...prevSelected, player];
      }

      return prevSelected;
    });
    //}
  };

  const handleRemovePlayer = (player: Player) => {
    // Remove player from selectedPlayers
    const newSelectedPlayers = selectedPlayers.filter(
      (selectedPlayer) => selectedPlayer.tracking_id !== player.tracking_id
    );
    setSelectedPlayers(newSelectedPlayers);

    if (player.position_class === 'back') {
      setSelectedBacksCount((prevCount) => prevCount - 1);
    } else if (player.position_class === 'forward') {
      setSelectedForwardsCount((prevCount) => prevCount - 1);
    }

    const updatedPlayers = players.map((p) =>
      p.tracking_id === player.tracking_id ? { ...p, isChecked: false } : p
    );
    setPlayers(updatedPlayers);
    //console.log('updatedPlayers: ', updatedPlayers);
  };

  //   const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  //   const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNewTeamClick = () => {
    if (totalValue <= budgetLimit) {
      setIsModalOpen(true);
    } else {
      showToast('Amount of selected players is more than the budget');
    }
  };

  const isDisabled = totalValue > 10000000;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    if (buttonPressed) {
      const element = document.getElementById('athlete-list');
      if (element) {
        // Get the position of the element
        const position = element.getBoundingClientRect().top + window.scrollY;
        // Scroll to the position of the element
        window.scrollTo({ top: position, behavior: 'smooth' });
        // Set buttonPressed back to false
        setButtonPressed(false);
      }
    }
  }, [buttonPressed]); // Add buttonPressed to the dependency array

  const scrollToTop = () => {
    const element = document.getElementById('athlete-list');
    if (element) {
      // Get the position of the element
      const position =
        element.getBoundingClientRect().top + window.scrollY - 30;
      // Scroll to the position of the element
      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  };

  const handleNextPage = () => {
    scrollToTop();
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    scrollToTop();
    setCurrentPage(currentPage - 1);
  };

  const handleSelectPage = (pageNumber: number) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };

  const returnIsChecked = (player: Player) => {
    const playerInArray = players.find(
      (p) => p.tracking_id === player.tracking_id
    );

    if (!playerInArray) {
      return;
    }
    // Use the isChecked property of the player in the players array as the isSelected variable
    return playerInArray.isChecked;
  };

  const pageLimit = 5; // Change this to the number of pages you want to show
  const startPage = Math.max(2, currentPage - Math.floor(pageLimit / 2));
  const endPage = Math.min(pageNumbers.length - 1, startPage + pageLimit - 1);

  const pagesToShow = [
    1,
    ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i),
    pageNumbers.length,
  ].filter((page, i, arr) => arr.indexOf(page) === i); // Remove duplicates

  const handleIsCheckedToggle = (player: Player) => {
    const updatedPlayers = players.map((p) =>
      p.tracking_id === player.tracking_id
        ? { ...p, isChecked: !p.isChecked }
        : p
    );
    setPlayers(updatedPlayers);

    // Find the player in the players array
    const playerInArray = updatedPlayers.find(
      (p) => p.tracking_id === player.tracking_id
    );

    // If the player is not found, return to prevent errors
    if (!playerInArray) {
      return;
    }

    // Use the isChecked property of the player in the players array as the isSelected variable
    const isSelected = playerInArray.isChecked;

    if (isSelected) {
      setSelectedPlayers((prevSelected) => {
        const isPlayerAlreadySelected = prevSelected.some(
          (selectedPlayer) => selectedPlayer.tracking_id === player.tracking_id
        );

        if (!isPlayerAlreadySelected) {
          if (player.position_class === 'back') {
            setSelectedBacksCount((prevCount) => prevCount + 1);
          } else if (player.position_class === 'forward') {
            setSelectedForwardsCount((prevCount) => prevCount + 1);
          }
          return [...prevSelected, player];
        }

        return prevSelected;
      });
    } else {
      setSelectedPlayers((prevSelected) => {
        const newSelectedPlayers = prevSelected.filter(
          (selectedPlayer) => selectedPlayer.tracking_id !== player.tracking_id
        );

        if (player.position_class === 'back') {
          setSelectedBacksCount((prevCount) => prevCount - 1);
        } else if (player.position_class === 'forward') {
          setSelectedForwardsCount((prevCount) => prevCount - 1);
        }

        return newSelectedPlayers;
      });
    }
  };

  const [isSelectedPlayerModalOpen, setIsSelectedPlayerModalOpen] =
    useState(false);

  const [selectedTab] = useState('Player Information');

  const handleClear = () => {
    setInputValue('');
    setIsLoading(true);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/unauth/athletes-by-competition-pagination/${
          selectedCompetition?.id ?? ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            perPage: playersPerPage,
          },
        }
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        // eslint-disable-next-line no-console
        //console.log('Fantasy athletes: ', data);
        setPlayers(data);
        setFilteredAthletes(data);

        setOfficialLeagueId(selectedCompetition?.id ?? '');
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
    // Rest of the function...
  };

  const [inputValue, setInputValue] = useState('');

  const handleSorting = (sortCriteria: string | null) => {
    setIsLoading(true);
    setIsDropdownOpen(false);
    setCurrentPage(1);
    let paginationURL = '';

    switch (sortCriteria) {
      case 'alphabetical':
        setSortBy('Alphabetical');
        paginationURL = 'athletes-by-competition-pagination-asc';
        break;
      case 'valueLowToHigh':
        paginationURL = 'athletes-by-competition-pagination-price-asc';
        setSortBy('Value Low to High');
        break;
      case 'valueHighToLow':
        paginationURL = 'athletes-by-competition-pagination-price-desc';
        setSortBy('Value High to Low');
        break;
      case 'prLowToHigh':
        paginationURL = 'athletes-by-competition-pagination-pr-asc';
        setSortBy('PR Low to High');
        break;
      case 'prHighToLow':
        paginationURL = 'athletes-by-competition-pagination-pr-desc';
        setSortBy('PR High to Low');
        break;
      default:
        // handle other cases
        break;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/${paginationURL}/${
          selectedCompetition?.id ?? ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            perPage: playersPerPage,
          },
        }
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        // eslint-disable-next-line no-console
        //console.log('Fantasy athletes: ', data);
        setPlayers(data);
        setFilteredAthletes(data);

        setOfficialLeagueId(selectedCompetition?.id ?? '');
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const autoPickTeam = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/unauth/athletes-by-competition-pagination-price-asc/${
          selectedCompetition?.id ?? ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            perPage: 100,
          },
        }
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        const backsPlayers = data
          .filter((players: Player) => players.position_class === 'back')
          .slice(0, allowedBacks);

        const forwardsPlayer = data
          .filter((players: Player) => players.position_class === 'forward')
          .slice(0, allowedForwards);

        const mergePlayer = [...backsPlayers, ...forwardsPlayer];

        const updatedPlayers = mergePlayer.map((p) =>
          p.age !== 0 ? { ...p, isChecked: true } : p
        );

        const updatedArray = players.map((before) => {
          const item = mergePlayer.filter(
            (p) => p.player_name === before.player_name
          );
          if (item) {
            return {
              ...before,
              isChecked: true,
            };
          }
          // Return the original item if `item` is undefined
          return before;
        });
        setSelectedForwardsCount(allowedForwards);
        setSelectedBacksCount(allowedBacks);
        setSelectedPlayers(updatedPlayers);
        setPlayers(updatedArray);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
      });
  };

  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isModalFadingOuts] = useState(false);
  const [, setIsModalVisibles] = useState(true);
  const handleAnimationEnds = () => {
    if (isModalFadingOuts) {
      setIsModalVisibles(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openModals = () => {
    setIsModalOpens(true);
  };
  const closeModals = () => {
    setIsModalOpens(false);
  };

  const calculateTimeLeft = () => {
    const dateString = deadline; // Assuming the date string is in ISO 8601 format

    const date = new Date(dateString);

    date.setHours(date.getHours() - 3);

    const modifiedDateString = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}+00`;

    const kickoffDate = new Date(modifiedDateString);

    const dateOptions = {
      year: 'numeric' as const,
      month: 'long' as const,
      day: 'numeric' as const,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      second: '2-digit' as const,
    };
    const targetTime = kickoffDate.toLocaleDateString(undefined, dateOptions);
    const now = new Date();
    const target = new Date(targetTime);

    if (now > kickoffDate) {
      setIsButtonDisabled(true);
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        hasExpired: true,
      };
    }

    //console.log('Scores: ', homeTeamScore, awayTeamScore);

    const difference = kickoffDate.getTime() - now.getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds,
      hasExpired: false,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedCompetition]);

  const formatTime = (value: number, unit: string) => {
    return value > 0 ? `${value}${unit} ` : '';
  };

  const getColor = () => {
    if (timeLeft.days >= 1) {
      return '#24D05B';
    } else if (timeLeft.hours >= 0) {
      return '#24D05B';
    } else if (timeLeft.minutes >= 0) {
      return 'orange';
    } else if (timeLeft.seconds > 0) {
      return 'red';
    }
    return;
  };

  const getClockIcon = () => {
    if (timeLeft.days >= 1) {
      return '/icons/green-clock.svg';
    } else if (timeLeft.hours >= 0) {
      return '/icons/green-clock.svg';
    } else if (timeLeft.minutes >= 0) {
      return '/icons/orange-clock.svg';
    } else if (timeLeft.seconds > 0) {
      return '/icons/red-clock.svg';
    }
    return '';
  };

  return (
    <div className="players-search-container">
      <div className="player-grid-search" id="athlete-list">
        <h1 className="match-container-title" style={{ marginBottom: '-30px' }}>
          Create Team
        </h1>
        <div className="player-search-sort">
          <div
            style={{
              display: 'flex',
              width: '430px',
            }}
          >
            <Autocomplete
              style={{
                height: '15px',
                color: '#fff',
                background: '#212121',
                marginBottom: '40px',
                marginTop: '20px',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              freeSolo
              options={masterList.map((athlete) => athlete.player_name || '')}
              onChange={(_event, selectedValue) =>
                handleSearchBar(selectedValue)
              }
              onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <div
                  ref={params.InputProps.ref}
                  className="autocomplete-container"
                >
                  <input
                    {...params.inputProps}
                    value={inputValue}
                    placeholder={'Search Players'}
                    type="text"
                    className=" player-input search-text player-auto"
                    id="free-solo-demo"
                  />
                </div>
              )}
            />
            <button
              type="submit"
              className="search-button"
              onClick={() => handleClear()}
              style={{
                marginTop: '20px',
                display: players.length == 1 ? '' : 'none',
              }}
            >
              Clear
            </button>
          </div>

          <div className="dropdown">
            <button
              className="player-dropdown"
              type="button"
              id="sortDropdown"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <h1 className="sort-text">{sortBy ? sortBy : 'Sort By'}</h1>
              <img src={'/icons/games-down-arrow.svg'} />
            </button>
            {isDropdownOpen && (
              <div
                className="dropdown-menu show"
                aria-labelledby="sortDropdown"
              >
                <div
                  className="dropdown-item"
                  onClick={() => handleSorting('alphabetical')}
                >
                  Alphabetical
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => handleSorting('prLowToHigh')}
                >
                  PR Low to High
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => handleSorting('prHighToLow')}
                >
                  PR High to Low
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => handleSorting('valueLowToHigh')}
                >
                  Value Low to High
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => handleSorting('valueHighToLow')}
                >
                  Value High to Low
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="player-card-grid">
          {isLoading ? (
            <div
              style={{
                position: 'absolute',
                left: '35%',
              }}
            >
              <div className="loading-spinner">
                <img
                  src="/img/loading2.gif"
                  alt="Loading"
                  style={{ height: '100px', borderRadius: '50px' }}
                />
              </div>
            </div>
          ) : players.length === 0 ? (
            <div
              className="no-players-found"
              style={{
                position: 'absolute',
                left: '33%',
              }}
            >
              No players found
            </div>
          ) : (
            players.map((player) => (
              <PlayerCard
                key={player.tracking_id}
                tracking_id={player.tracking_id}
                player_name={player.player_name}
                position_class={player.position_class.toUpperCase()}
                position={player.position}
                team_name={player.team_name}
                price={player.price}
                image_url={player.image_url}
                power_rank_rating={player.power_rank_rating}
                onPlayerSelect={(isSelected: boolean) =>
                  handlePlayerSelect(player, isSelected)
                }
                onAddToTeam={(player) =>
                  handleAddPlayerToSelectedPlayers(player)
                }
                disabled={isDisabled}
                sex={player.sex}
                age={player.age}
                country={player.country}
                team_id={player.team_id}
                athlete_id={player.athlete_id}
                selectedPlayers={selectedPlayers}
                handleIsCheckedToggle={() => {
                  handleIsCheckedToggle(player);
                }}
                isChecked={returnIsChecked(player) || false}
              />
            ))
          )}
        </div>

        {/* Pagination */}
        <div
          className="pagination-matches"
          style={{
            marginRight: '58px',
            display: players.length == 1 ? 'none' : '',
          }}
        >
          <button
            onClick={() => handlePrevPage()}
            className={
              currentPage === 1
                ? `pagination-button-arrows-disabled`
                : `pagination-button-arrows`
            }
            disabled={currentPage === 1}
          >
            <img src="/icons/arrow-left.svg" alt="Previous" />
            <h1 className="pagination-text">Previous</h1>
          </button>
          {pagesToShow.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handleSelectPage(pageNumber)}
              className={`pagination-button ${
                currentPage === pageNumber ? 'active' : ''
              }`}
            >
              <h1 className="pagination-text">{pageNumber}</h1>
            </button>
          ))}
          <button
            onClick={() => handleNextPage()}
            className={
              currentPage === pagesToShow[pagesToShow.length - 1]
                ? `pagination-button-arrows-disabled`
                : `pagination-button-arrows`
            }
            disabled={currentPage === pagesToShow[pagesToShow.length - 1]}
          >
            <h1 className="pagination-text">Next</h1>
            <img src="/icons/arrow-right.svg" alt="Next" />
          </button>
          {/* <button
            className="pagination-button-group"
            onClick={() => handleNextPage()}
            //disabled={currentPage === pagesToShow.length}
          >
            <h1 className="pagination-text">Next</h1>
            <img src="/icons/arrow-right.svg" alt="Next" />
          </button> */}
        </div>
      </div>
      <div className="choose-team-box">
        <div className="choose-team-header">
          <h1 className="selected-players-title">Selected Players</h1>
          <svg
            width="261"
            height="2"
            viewBox="0 0 261 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H260"
              stroke="#323232"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <div className="team-lockin">
            <h1 className="lock-in-desc" style={{ marginLeft: '5px' }}>
              Team Lock-In:
            </h1>
            {timeLeft.hasExpired ? (
              <>
                <h1 className="lock-in-value"> Picks Locked</h1>
              </>
            ) : (
              <>
                <p
                  className="time-countdown"
                  style={{ width: '200px', color: getColor() }}
                >
                  <img style={{ marginRight: '5px' }} src={getClockIcon()} />
                  {timeLeft.days > 0 && `${formatTime(timeLeft.days, 'd')}`}
                  {timeLeft.hours > 0 && `${formatTime(timeLeft.hours, 'h')}`}
                  {timeLeft.minutes > 0 &&
                    `${formatTime(timeLeft.minutes, 'm')}`}
                  {timeLeft.seconds > 0 &&
                    `${formatTime(timeLeft.seconds, 's')}`}
                </p>
              </>
            )}
            {/* <img
              style={{
                marginBottom: '6px',
                marginRight: '-5px',
                marginLeft: '15px',
              }}
              src="/icons/orange-clock.svg"
            ></img>
            <h1 className="lock-in-value"></h1> */}
            <img
              data-tip="This is the time when you can no longer make changes to your team."
              src="/icons/information.png"
              alt="Help icon"
              style={{ marginLeft: '5px', width: '20px', height: '20px' }}
            />
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
          <svg
            width="261"
            height="2"
            viewBox="0 0 261 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H260"
              stroke="#323232"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className={'small-card-list with-scroll'}>
          <ToastContainer />
          <div className="backs-section">
            <div
              className="position-available"
              style={{ marginBottom: '-6px' }}
            >
              <h3 className="selected-position">Selected Backs</h3>
              <h3 className="selected-positon-value">
                {' '}
                {selectedBacksCount}/{allowedBacks}
              </h3>
            </div>
            {selectedBacksCount === 0 ? (
              <>
                <div className="add-player-box">+ Add Player</div>
                <div className="add-player-box">+ Add Player</div>
                <div className="add-player-box">+ Add Player</div>
              </>
            ) : (
              selectedPlayers
                .filter((player) => player.position_class === 'back')
                .map((selectedPlayer) => (
                  <div
                    key={selectedPlayer.tracking_id}
                    className="selected-player"
                  >
                    <div></div>
                    <div className="selected-player-card">
                      <img
                        className="small-card--user-img"
                        src={selectedPlayer.image_url}
                        onError={(e) => {
                          if (e.target instanceof HTMLImageElement) {
                            e.target.src = '/icons/default-player-img.png';
                          }
                        }}
                        onClick={() => {
                          setSelectedPlayerForModal(selectedPlayer);
                          setIsSelectedPlayerModalOpen(true);
                        }}
                      />
                      <div className="name-pos-title">
                        <h3 className="small-name">
                          {selectedPlayer.player_name}
                        </h3>
                        <p className="small-position-price">
                          {parseFloat(selectedPlayer.price).toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: 'USD',
                            }
                          )}
                        </p>

                        <p className="small-position">
                          {selectedPlayer.position_class.toUpperCase()}
                        </p>
                        {/* <div className="small-value-ranking">
                          <div className="small-pr-row">
                            <p className="small-pr-desc"> Power Ranking</p>
                            <p className="small-pr-value">
                              {selectedPlayer.power_rank_rating}
                            </p>
                          </div>
                          <div className="small-pr-row">
                            <p className="small-pr-desc"> Dollar Value</p>
                            <p className="small-pr-value">
                              ${selectedPlayer.price}
                            </p>
                          </div>
                        </div> */}
                      </div>
                      <img
                        onClick={() => handleRemovePlayer(selectedPlayer)}
                        className="remove-player-button"
                        src={'/icons/trashcan.png'}
                      />
                    </div>
                  </div>
                ))
            )}
          </div>
          <div className="forwards-section" style={{ overflowY: 'auto' }}>
            <div
              className="position-available"
              style={{ marginBottom: '-6px', marginTop: '8px' }}
            >
              <h3 className="selected-position">Forwards</h3>
              <h3 className="selected-positon-value">
                {selectedForwardsCount}/{allowedForwards}
              </h3>
            </div>
            {/* Display "Add Player" boxes if no player is selected */}
            {selectedForwardsCount === 0 ? (
              <>
                <div className="add-player-box">+ Add Player</div>
                <div className="add-player-box">+ Add Player</div>
                <div className="add-player-box">+ Add Player</div>
              </>
            ) : (
              selectedPlayers
                .filter((player) => player.position_class === 'forward')
                .map((selectedPlayer) => (
                  <div
                    key={selectedPlayer.tracking_id}
                    className="selected-player"
                  >
                    <div></div>
                    <div className="selected-player-card">
                      <img
                        className="small-card--user-img"
                        src={selectedPlayer.image_url}
                        onError={(e) => {
                          if (e.target instanceof HTMLImageElement) {
                            e.target.src = '/icons/default-player-img.png';
                          }
                        }}
                        onClick={() => {
                          setSelectedPlayerForModal(selectedPlayer);
                          setIsSelectedPlayerModalOpen(true);
                        }}
                      />
                      <div className="name-pos-title">
                        <h3 className="small-name">
                          {selectedPlayer.player_name}
                        </h3>
                        <p className="small-position-price">
                          {parseFloat(selectedPlayer.price).toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: 'USD',
                            }
                          )}
                        </p>
                        <p className="small-position">
                          {selectedPlayer.position_class.toUpperCase()}
                        </p>
                        {/* <div className="small-value-ranking">
                          <div className="small-pr-row">
                            <p className="small-pr-desc"> Power Ranking</p>
                            <p className="small-pr-value">
                              {selectedPlayer.power_rank_rating}
                            </p>
                          </div>

                          <div className="small-pr-row">
                            <p className="small-pr-desc"> Dollar Value</p>
                            <p className="small-pr-value">
                              ${selectedPlayer.price}
                            </p>
                          </div>
                        </div> */}
                      </div>
                      <img
                        onClick={() => handleRemovePlayer(selectedPlayer)}
                        className="remove-player-button"
                        src={'/icons/trashcan.png'}
                      />
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <div className="create-team-section">
          <svg
            width="261"
            height="2"
            viewBox="0 0 261 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H260"
              stroke="#323232"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <div className="list-total-value">
            <h1 className="value-total-desc"> Total Value: </h1>
            <h1 className="calc-total" style={{ marginRight: '20px' }}>
              {totalValue.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              /
              {budgetLimit.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </h1>
          </div>
          <div className="autopick-add-button" style={{ marginTop: '8px' }}>
            <div>
              <div>
                <button
                  className="auto-pick-button"
                  onClick={openModals}
                  // disabled={isButtonDisabled}
                >
                  Auto Pick
                </button>
              </div>
            </div>
            <div className="team-dropdown-container">
              <button
                className="team-dropdown-toggle"
                onClick={openModals}
                // disabled={isButtonDisabled}
              >
                {'Create Team +'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ComingSoonModal
        isOpen={isModalOpens}
        setIsOpen={closeModals}
        isModalFadingOut={isModalFadingOuts}
        handleAnimationEnd={handleAnimationEnds}
      />
      {isModalOpen && (
        <CreateTeamModal
          onClose={() => setIsModalOpen(false)}
          club_id={club_id}
          official_league_id={official_league_id}
          totalValue={totalValue}
          setIsModalOpen={setIsModalOpen}
          team_athletes={selectedPlayers}
        />
      )}
      {isSelectedPlayerModalOpen && (
        <div
          className="custom-modal"
          style={{
            marginTop: '30px',
            height: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <div
              style={{
                display: 'flex',
                marginLeft: '-20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Added width to ensure it takes the full width
              }}
            >
              <div>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Player Information' ? 'selected-tab' : ''
                  }`}
                >
                  Player Information
                </button>
                <button
                  className={`match-tabs ${
                    selectedTab === 'Power Rankings' ? 'selected-tab' : ''
                  }`}

                  //disabled={isPowerRankingsDisabled}
                >
                  Power Rankings
                </button>
              </div>
              <div
                onClick={() => setIsSelectedPlayerModalOpen(false)}
                style={{
                  marginRight: '-20px',
                  cursor: 'pointer',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#E6E6E6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <img
              src={selectedPlayerForModal?.image_url}
              onError={(e) => {
                if (e.target instanceof HTMLImageElement) {
                  e.target.src = '/icons/default-player-img.png';
                }
              }}
              className="stats-card--user-img"
            />
            <div className="player-detail-row">
              <h1 className="detail-desc">Name</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.player_name || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Position</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.position_class || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Country</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.country || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Age</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.age || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Sex</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.sex || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Power Ranking</h1>
              <h1 className="detail-value">
                {selectedPlayerForModal?.power_rank_rating || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Current Value</h1>
              <h1 className="detail-value">
                {parseFloat(selectedPlayerForModal?.price ?? '').toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  }
                ) || '-'}
              </h1>
            </div>
            <div className="player-detail-row">
              <h1 className="detail-desc">Value History</h1>
              {selectedPlayerForModal?.tracking_id && (
                <PriceChangeChart
                  trackingId={selectedPlayerForModal?.tracking_id}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerDisplay;
