import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { AthstatLeague, IUserLeague } from '../../../d';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import AccessDenied from '../AccessDenied';
import {
  getAthstatLeagues,
  getUserLeagues,
  //   getFantasyLeagueById,
} from '../Games/games.services';
import { LeagueRow } from './LeagueRow';
import axios from 'axios';

export const AdminViewLeagues: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [, setLoading] = useState<boolean>(false);
  const [AthstatLeagues, setAthstatLeagues] = useState<AthstatLeague[]>([]);
  const [, setUserLeagues] = useState<IUserLeague[]>([]);
  //   const [selectedLeague, setSelectedLeague] = useState<AthstatLeague | null>(
  //     null
  //   );
  const { user } = useAuth();
  const accessToken = user?.access_token;
  const navigator = useNavigate();
  const [access, setAccess] = useState<boolean>(false);
  const kcUser = user?.profile;
  const userId = kcUser?.sub;

  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [, setTeamBudget] = useState<string>();
  //const [budget, setBudget] = useState(selectedLeague?.team_budget);

  //const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    const fetchLeagues = async () => {
      setLoading(true);
      try {
        if (accessToken) {
          const athLeagues = await getAthstatLeagues({
            token: accessToken,
          });
          setAthstatLeagues(athLeagues);
          //console.log('athLeagues', athLeagues);
          const userLeagues = await getUserLeagues({
            token: accessToken,
          });
          setUserLeagues(userLeagues);
          await checkAdmin();
        }
      } catch (error) {
        // TODO: handle error
      }
      setLoading(false);
    };
    fetchLeagues();
  }, [accessToken]);

  const checkAdmin = async () => {
    const fetchedAccess = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/check-access`,
      {
        params: { user_id: userId },
        headers: {
          Authorization: user ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
    if (fetchedAccess.data) {
      setAccess(fetchedAccess.data);
    }
  };
  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  //   const onLeagueSelect = (selectedLeague: AthstatLeague) => {
  //     setModalOpen(true);
  //     //setBudget(selectedLeague.team_budget);
  //     //setSelectedLeague(selectedLeague);
  //     // navigator(
  //     //   `/auth/games-dash/fantasy/viewleague?leagueId=${selectedLeague.id}`
  //     // );
  //   };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Save the new budget to the database here
    // const params = {
    //   team_budget: teamBudget,
    // };
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/fantasy-athletes/fantasy-team-athletes`, ///fantasy-athletes/fantasy-team-athletes
    //     params,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.status === 200) {
    //       // setIsLoading(false);
    //       // setIsTeamCreated(true);
    //       //console.log('Team created: ', response.data);
    //     } else {
    //       // Handle non-successful response
    //       // eslint-disable-next-line no-console
    //       console.error('Request failed with status', response.status);
    //       //setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     // Handle network error
    //     // eslint-disable-next-line no-console
    //     console.error('Network error', error);
    //     //setIsLoading(false);
    //   });

    setIsEditing(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamBudget(event.target.value);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  if (!access) {
    return <AccessDenied message={'Admin Access Denied'} />;
  } else {
    return (
      <React.Fragment>
        <div
          className="back-breadcrumbs2"
          style={{
            marginTop: '100px',
          }}
        >
          <Button className="backButtonNFT" onClick={() => navigator(-1)}>
            <img
              className="nft-vector"
              src="/icons/back-nft-2.svg"
              alt={'Portfolio'}
            />
            <h1 className="back-word2">Back</h1>
          </Button>
          <Button
            className="backButtonNFT"
            onClick={() => navigator(`/auth/games-dash/fantasy/search`)}
            title="Search Players"
            style={{ marginTop: '2px' }}
          >
            <img
              className="nft-vector"
              src="/icons/search.svg"
              alt={'Portfolio'}
            />
          </Button>
        </div>
        {/* <Row
          className="dashboard-PageTitle-row"
          style={{
            marginTop: '30px',
            marginLeft: '140px',
            marginBottom: '-70px',
          }}
        >
          <Col size="12">
            <div className="PageTitle">
              <h2>View All Leagues</h2>
            </div>
          </Col>
        </Row> */}

        <div
          className={'container HeaderDown'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '30px',
          }}
        >
          <ul className="league-tab-container">
            <li className="league-tab">
              <a
                className={`nav-link ${
                  activeTab === 0 ? 'active' : ''
                } custom-nav-link`}
                href="#"
                onClick={() => handleTabClick(0)}
              >
                <span className="nav-button-text">Athstat Leagues</span>
              </a>
            </li>
            <li className="league-tab">
              <a
                className={`nav-link ${
                  activeTab === 1 ? 'active' : ''
                } custom-nav-link`}
                href="#"
                onClick={() => handleTabClick(1)}
              >
                <span className="nav-button-text">User Leagues</span>
              </a>
            </li>
          </ul>

          <input type="text" placeholder="Search" />
          <div className={'leagues-container'}>
            {activeTab === 0 &&
              AthstatLeagues.map((league) => (
                <LeagueRow
                  league={league}
                  key={league.id}
                  onClick={() => {
                    //setSelectedLeague(league);
                    //onLeagueSelect(league);
                  }}
                />
              ))}
            {/* {activeTab === 1 &&
              userLeagues.map((league) => (
                <LeagueRow
                  league={league}
                  key={league.id}
                  onClick={() => {
                    setSelectedLeague(league);
                    onLeagueSelect(league);
                  }}
                />
              ))} */}
          </div>

          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>{/* <h6> League: {selectedLeague?.title}</h6> */}</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 20,
                }}
              >
                <h6 style={{ marginRight: 20 }}> Team Budget: </h6>
                {isEditing ? (
                  <input
                    type="number"
                    // value={budget}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(event)
                    }
                  />
                ) : (
                  <></>
                  //   <p style={{ marginTop: 10 }}>{selectedLeague?.team_budget}</p>
                )}
              </div>
              {isEditing ? (
                <div>
                  <button className="reset-button3" onClick={handleSave}>
                    Save
                  </button>
                </div>
              ) : (
                <button className="reset-button3" onClick={handleEdit}>
                  Edit
                </button>
              )}
            </Box>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
};

export default AdminViewLeagues;
