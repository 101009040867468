import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const HowToUseAppDash: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left"></i>
        </Button>
        <Row className="">
          <Col size="12">
            <div className="PageTitle">
              <h2>User Tutorials</h2>
            </div>
          </Col>
        </Row>

        <div className="Tab-container Dashboard-container">
          <Row className="justify-content-center">
            <Col size="12" sm="12" md="6" lg="4">
              <Link to="/user-guide" style={{ textDecoration: 'none' }}>
                <div className="home-dashboard-info home-link">
                  <div className="icon-name">
                    <div className="home-dashboard-icon-box">
                      <img
                        className="home-vector"
                        src="../icons/fileupload.svg"
                        alt={'Detailed User Guide'}
                      />
                    </div>
                    <h2>Detailed User Guide</h2>
                  </div>
                  <p>
                    A detailed review on how to navigate and utilize Athstat.
                  </p>
                </div>
              </Link>
            </Col>

            <Col size="12" sm="12" md="6" lg="4">
              <Link to="/quick-start" style={{ textDecoration: 'none' }}>
                <div className="home-dashboard-info home-link">
                  <div className="icon-name">
                    <div className="home-dashboard-icon-box">
                      <img
                        className="home-vector"
                        src="../icons/list.svg"
                        alt={'Detailed User Guide'}
                      />
                    </div>
                    <h2>Quick Start Guide</h2>
                  </div>
                  <p>A brief overview on how to get started using the app</p>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};
export default HowToUseAppDash;
