import React from 'react';

interface SmallPlayerCardProps {
  tracking_id: number;
  player_name: string;
  position_class: string;
  price: string;
  image_url: string;
}

const SmallPlayerCard: React.FC<SmallPlayerCardProps> = ({
  tracking_id,
  player_name,
  position_class,
  price,
  image_url,
}) => {
  // const [isChecked, setIsChecked] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedTab, setSelectedTab] = useState('Player Information');

  // const handleStatsImageClick = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // const switchToUpcoming = () => {
  //   setSelectedTab('Player Information');
  // };

  // const switchToPrevious = () => {
  //   // Disable switching to "Power Rankings" if needed
  //   if (isPowerRankingsDisabled) {
  //     return;
  //   }
  //   setSelectedTab('Power Rankings');
  // };

  // const isPowerRankingsDisabled = true; // Set to true to disable "Power Rankings"

  return (
    <div className="small-player-card">
      <h1 className="id-div">{tracking_id}</h1>

      <img
        src={image_url || '/icons/default-player-img.png'}
        onError={(e) => {
          if (e.target instanceof HTMLImageElement) {
            e.target.src = '/icons/default-player-img.png';
          }
        }}
        alt="avatar"
        className="small-card-avatar"
      />

      <div className="small-player-name-position">
        <h1 className="small-player-name">{player_name}</h1>
        <h1 className="player-position">{position_class}</h1>
      </div>
      <div className="player-card-value">
        <h1 className="small-value-amount">${price}</h1>
      </div>
      <img src="/icons/positive.svg" />
    </div>
  );
};

export default SmallPlayerCard;
