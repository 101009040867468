import { RouteObject } from 'react-router-dom';
import React from 'react';
import GamesDashboard from 'components/Games/GameDashboard';
import PickTeam from 'components/FantasyTab/PickTeam/PickTeam';
import { TeamHistory } from 'components/FantasyTab/TeamHistory/TeamHistory';
import JoinLeague from 'components/FantasyTab/JoinLeague/joinLeague';
import { Leaderboard } from 'components/FantasyTab/Leaderboard/Leaderboard';
import GamesDash from 'components/HomePage';
// import { Match } from 'components/Matches/Match';
import Search from 'components/Search/Search';
import PlayerProfile from 'components/PlayerProfile';
import UpcomingMatches from 'components/Matches/UpcomingMatches';
import EditTeam from 'components/FantasyTab/EditTeam/EditTeam';
import AdminGamesDashboard from 'components/AdminGamesDash';
import AdminViewLeagues from 'components/AdminGamesDash/adminViewLeagues';
import ViewLeague from 'components/AdminGamesDash/viewLeague';
import CreateTeam from 'components/Teams/CreateTeam';
import LeagueDisplay from 'components/Leagues/LeagueDisplay';
import TeamDisplay from 'components/Teams/TeamsDisplay';

import ManageTeam from 'components/Teams/ManageTeam';
import AccountWallet from 'components/AccountWallet/index';
import DeleteAccount from 'components/DeleteAccount/index';
import LeagueDetails from 'components/Leagues/LeagueDetails';

const GamesRoutes: Array<RouteObject> = [
  { index: true, element: <GamesDash /> },

  { path: 'fantasy', element: <GamesDashboard /> },
  { path: 'fantasy/createteam', element: <CreateTeam /> },
  { path: 'fantasy/pickteam', element: <PickTeam /> },
  { path: 'fantasy/history', element: <TeamHistory /> },
  { path: 'fantasy/joinfantasyleague', element: <JoinLeague /> },
  { path: 'fantasy/leaderboard', element: <Leaderboard /> },
  {
    path: 'fantasy/matches',
    element: <UpcomingMatches selectedCompetition={null} />,
  },
  { path: 'fantasy/leagues', element: <LeagueDisplay /> },
  { path: 'fantasy/account', element: <AccountWallet /> },
  { path: 'fantasy/account/delete-account', element: <DeleteAccount /> },
  // { path: 'fantasy/matchselect', element: <Match /> },
  { path: 'fantasy/match', element: <Leaderboard /> },
  { path: 'fantasy/search', element: <Search /> },
  { path: 'fantasy/profile', element: <PlayerProfile /> },
  { path: 'fantasy/editTeam', element: <EditTeam /> },
  { path: 'fantasy/admin', element: <AdminGamesDashboard /> },
  { path: 'fantasy/admin/leagues', element: <AdminViewLeagues /> },
  { path: 'fantasy/admin/viewleague', element: <ViewLeague /> },
  { path: 'fantasy/myteams', element: <TeamDisplay /> },
  { path: 'fantasy/manageteam/', element: <ManageTeam /> },
  { path: 'fantasy/leagueinfo/', element: <LeagueDetails /> },

  // { path: 'onboard', element: <GamesOnboard /> },
];

export default GamesRoutes;
