import { RouteObject } from 'react-router-dom';
import React from 'react';
import AdminDashboard from '../components/AdminDashboard';
import EntityDashboard from '../components/AdminDashboard/EntityManagement/EntityDashboard';
import LogsDashboard from '../components/AdminDashboard/Logs/LogsDashboard';
import AdminPlayerSummaryDashboard from '../components/AdminDashboard/PlayerSummariesManagement/AdminPlayerSummaryDashboard';
import SportsDashboard from '../components/AdminDashboard/SportsManagement/SportsDashboard';

const AdminRoutes: Array<RouteObject> = [
  { index: true, element: <AdminDashboard /> },
  {
    path: 'entities',
    element: <EntityDashboard />,
  },
  {
    path: 'logs',
    element: <LogsDashboard />,
  },
  {
    path: 'playerSummaries',
    element: <AdminPlayerSummaryDashboard />,
  },
  // {
  //   path: 'nfts',
  //   element: <AdminNFTDashboard />,
  // },
  {
    path: 'sports',
    element: <SportsDashboard />,
  },
];

export default AdminRoutes;
