import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { IKeycloakUser } from '../../../d';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Row from 'react-bootstrap/Row';

const LoginComponent: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [newUser, setNewUser] = useState<IKeycloakUser>({
    username: 'test@email.com',
    email: 'test@email.com',
    emailVerified: true,
    enabled: false,
    given_name: 'Kevin',
    preferred_name: 'Kevin',
  });
  const [showRegistration] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/verify-user');
    }
  }, [auth]);

  const loginAuth = () => {
    auth.signinRedirect();
  };

  // const getUserData = async (
  //   userId: string | undefined
  // ): Promise<IUser | null> => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_DATAHUB_URL}/users/${userId}`
  //     );
  //     console.log('User infor ', response);
  //     return response.data;
  //   } catch (error) {
  //     return null;
  //   }
  // };

  // const registerAuth = () => {
  //   auth.removeUser();
  // };

  // const registerAuth = async () => {
  //   const creationBody: UserRepresentation = {
  //     username: 'test@email.com',
  //     email: 'test@email.com',
  //     emailVerified: true,
  //     enabled: false,
  //     firstName: 'Kevin',
  //     lastName: 'Kevin',
  //   };
  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/unauth/create-keycloak-user`,
  //       creationBody,
  //       {
  //         headers: {
  //           Authorization:
  //             session && session.token.accessToken
  //               ? `Bearer ${session.token.accessToken}`
  //               : undefined,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch(() => {
  //       return null;
  //     });
  // };

  // if (session) {
  //   navigate('/auth');
  // }

  switch (auth.activeNavigator) {
    case 'signinRedirect':
      return <div>Heading to Keycloak...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <React.Fragment>
      <section className="login_bg">
        <Container>
          <Row className="align-items-center justify-content-center">
            {!showRegistration && (
              <Col size="12" md="12" sm="12">
                <div className="mian_login">
                  <div className="login_donthave">
                    <div style={{ marginBottom: '10%' }}>
                      <img src="/icons/Athstat-logo.svg" alt={'Logo'} />
                    </div>
                    <Button
                      onClick={() => loginAuth()}
                      className="text-dark rount_btns"
                      color={'primary'}
                    >
                      Log In or Register
                    </Button>
                    {/* <div style={{ marginTop: 10 }}>
                      <p>
                        Not Registered? Please click{' '}
                        <a
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                          onClick={() => registerAuth()}
                          href={'#'}
                        >
                          here
                        </a>
                      </p>
                    </div> */}
                    {/*<div style={{ marginTop: 10 }}>*/}
                    {/*  <p>*/}
                    {/*    Not Registered? Please click{' '}*/}
                    {/*    <a*/}
                    {/*      style={{*/}
                    {/*        gridor: 'white',*/}
                    {/*        textDecoration: 'underline',*/}
                    {/*      }}*/}
                    {/*      onClick={() => setShowRegistration(true)}*/}
                    {/*      href={'#'}*/}
                    {/*    >*/}
                    {/*      here*/}
                    {/*    </a>*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                    {/*<div style={{ marginTop: 10 }}>*/}
                    {/*  <p>*/}
                    {/*    Forgot Password?*/}
                    {/*    <a*/}
                    {/*      style={{ gridor: 'white', textDecoration: 'underline' }}*/}
                    {/*      onClick={() => registerAuth()}*/}
                    {/*      href={'#'}*/}
                    {/*    >*/}
                    {/*      here*/}
                    {/*    </a>*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </Col>
            )}
            {showRegistration && (
              <Col size="12" md="12" sm="12">
                <div className="signup_right">
                  <Row className="align-items-center justify-content-end h-100">
                    <Col size="12" className="justify-content-end d-flex">
                      <div className="inner-form-details">
                        <div className="sign_upform">
                          <div className="BrandLogo">
                            <img src="icons/BrandLogo.svg" alt={'Brand Logo'} />
                          </div>
                          <h2 className="text-left loginTitle">
                            <span className="bold_b">Sign</span> {''} in to {''}
                            <span className="bold_g">Athstat</span>
                          </h2>
                          <div className="input_forms">
                            <div className="form-group">
                              <input
                                type="email"
                                id="loginEmail"
                                className="custom_ins form-control form-control-lg"
                                onChange={(e) =>
                                  setNewUser({
                                    ...newUser,
                                    email: e.target.value,
                                  })
                                }
                                placeholder="Email"
                              />
                            </div>

                            <div className="form-group">
                              <input
                                id="firstName"
                                className="custom_ins form-control form-control-lg"
                                onChange={(e) =>
                                  setNewUser({
                                    ...newUser,
                                    preferred_name: e.target.value,
                                  })
                                }
                                placeholder="First Name"
                              />
                            </div>

                            <div className="form-group">
                              <input
                                id="lastName"
                                className="custom_ins form-control form-control-lg"
                                onChange={(e) =>
                                  setNewUser({
                                    ...newUser,
                                    given_name: e.target.value,
                                  })
                                }
                                placeholder="Last Name"
                              />
                            </div>

                            <Button
                              className="text-dark rount_btns"
                              color="primary"
                            >
                              Create Account
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>{' '}
        </Container>
      </section>
      <div id="MainApp" className={'MainApp'}>
        <div className="right-body-login">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginComponent;
