import { createContext } from 'react';
import { IOrganization } from '../../d';

interface IOrganizationContext {
  selectedOrganization?: IOrganization;
  setSelectedOrganization?: (organization: IOrganization) => void;
  organizations?: Array<IOrganization>;
  setOrganizations?: (organizations: Array<IOrganization> | []) => void;
  updatedItem?: unknown;
  setUpdatedItem?: (item: unknown) => void;
}

export const sampleOrganization: IOrganizationContext = {
  selectedOrganization: {
    name: 'Loading',
    organization_admin_email: '',
    members: [],
    sports: [],
    tier: '',
    hidden: false,
  },
  organizations: [],
};

export const OrganizationContext =
  createContext<IOrganizationContext>(sampleOrganization);
