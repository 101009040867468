import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import { useAuth } from 'react-oidc-context';
import { IAthletePriceChange, IFantasyAthlete } from '../../../d';
import { Graph } from './Graph';

interface PlayerInformationProps {
  player: IFantasyAthlete;
  data?: IAthletePriceChange[];
}

export const PlayerInformation: React.FC<PlayerInformationProps> = ({
  player,
  data,
}) => {
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'left', color: 'red' }}>
        <Row>
          <Col sm={'12'} className="profile-container">
            <div style={{ flex: 1 }}>
              <div>
                <h2 className="profile-table-title">Basic Information</h2>
                <table className="profile-table">
                  <tbody>
                    <tr>
                      <th className="left-aligned">Name</th>
                      <td>{player?.player_name || '-'}</td>
                    </tr>
                    <tr>
                      <th className="left-aligned">Date of Birth</th>
                      <td>{player?.date_of_birth || '-'}</td>
                    </tr>
                    <tr>
                      <th className="left-aligned">Position</th>
                      <td>{player.position || '-'}</td>
                    </tr>
                    <tr>
                      <th className="left-aligned">Country</th>
                      <td>{player?.birth_country || '-'}</td>
                    </tr>
                    <tr>
                      <th className="left-aligned">Sex</th>
                      <td>{player?.sex || '-'}</td>
                    </tr>
                    <tr>
                      <th className="left-aligned">Value</th>
                      <td>${player?.price?.toFixed(2) || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          {data && data?.length > 0 ? (
            <Col sm={'12'} className="profile-container">
              <Graph data={data} />
            </Col>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PlayerInformation;
