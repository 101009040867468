/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import ComingSoonModal from 'components/UserSignUp/ComingSoon';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

interface IFantasyClubTeam {
  id?: string;
  name: string;
  club_id: string;
  official_league_id: string;
  created_date?: Date;
  team_value?: number;
  balance?: number;
  round_score?: number;
  transfers_remaining?: number;
  transfer_penalties?: number;
}

interface IFantasyLeague {
  id: string;
  type: string;
  official_league_id: string;
  title: string;
  created_date: Date;
  entry_code: string | null;
  entry_fee: number | null;
  is_private: boolean;
  reward_type: string | null;
  reward_description: string | null;
  end_round: number | null;
  is_open: boolean;
  join_deadline: Date | null;
  disclaimer: string | null;
  has_ended: boolean;
}

interface IFantasyClubReward {
  id: number;
  club_id: number;
  league_id: number;
  reward_description: string;
  is_claimed: boolean;
  time_claimed: Date;
  reward_amount: number;
}

interface HeaderProps {
  competitionId: string;
  club_id: string;
}
const Header: React.FC<HeaderProps> = ({ competitionId, club_id }) => {
  const [activeButton, setActiveButton] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeModalTab, setActiveModalTab] = useState('my-teams');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user, isAuthenticated } = useAuth();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleButtonClick = (buttonName: string) => {
    //@ts-ignore
    setActiveButton(buttonName === activeButton ? null : buttonName);
  };

  const [selectedTab, setSelectedTab] = useState('My Teams');

  const [teams, setTeams] = useState<IFantasyClubTeam[]>([]);
  const [leagues, setLeagues] = useState<IFantasyLeague[]>([]);
  const [prizes, setPrizes] = useState<IFantasyClubReward[]>([]);
  const [access, setAccess] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );

        if (fetchedAccess.data) {
          // eslint-disable-next-line no-console
          console.log(`Success: User ${userId} is an admin.`);
          setAccess(fetchedAccess.data);
        }
      };
      checkAdmin();
    }
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fantasy-teams-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTeams(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (isAuthenticated) {
      fetchTeams();
    }
  }, [isAuthenticated, token, userId]);

  const handleLeagueNavigation = () => {
    if (isAuthenticated) {
      navigate('games-dash/fantasy/leagues');
    } else {
      navigate('newlogin');
    }
  };

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getUserLeaguesByCompetitionId/${competitionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLeagues(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (isAuthenticated) {
      fetchLeagues();
    }
  }, [isAuthenticated, token, competitionId]);

  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAwardsByClubId/${club_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPrizes(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (isAuthenticated) {
      fetchPrizes();
    }
  }, [isAuthenticated, token, club_id]);

  const handleTeamsNavigation = () => {
    if (isAuthenticated) {
      navigate('games-dash/fantasy/myteams');
    } else {
      navigate('newlogin');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHomeNavigation = () => {
    if (isAuthenticated) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  };
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isModalFadingOut] = useState(false);
  const [, setIsModalVisible] = useState(true);
  const handleAnimationEnd = () => {
    if (isModalFadingOut) {
      setIsModalVisible(false);
    }
  };
  const openModal = () => {
    setIsModalOpens(true);
  };
  const closeModal = () => {
    setIsModalOpens(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleModalTabChange = (tab: string) => {
    setActiveModalTab(tab);
  };
  return (
    <>
      <header className="games-header">
        <div className="games-header-nav">
          <div className="icon-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="127"
              height="14"
              viewBox="0 0 127 14"
              fill="none"
            >
              <path
                d="M50.4721 13.4971V7.89434H40.4322V13.4971H37.379V0.692902H40.4322V5.5679H50.4721V0.692902H53.5439V13.4971H50.4721Z"
                fill="white"
              />
              <path
                d="M58.9062 13.3422V10.8521C58.9062 10.6358 59.0922 10.4604 59.3185 10.4604L61.4294 9.98112C61.5193 9.98112 61.5937 10.0483 61.5937 10.1331L61.6061 10.9807C61.6061 11.0654 61.6805 11.1326 61.7704 11.1326H70.4092C71.1841 11.1326 71.4878 10.9836 71.4878 10.3581V8.49348C71.4878 7.9031 71.1686 7.69852 70.4092 7.69852H63.3109C60.5305 7.69852 59.4673 6.42424 59.4673 4.54204V3.65647C59.4673 1.77428 60.5305 0.5 63.3109 0.5H72.9881C73.6266 0.5 74.1443 0.988085 74.1443 1.59015V3.32329C74.1443 3.37882 74.0978 3.42266 74.0389 3.42266L71.5343 3.76461C71.4754 3.76461 71.429 3.72077 71.429 3.66524V2.94335C71.4259 2.88781 71.3794 2.84397 71.3205 2.84397H63.314C62.5887 2.84397 62.2198 3.04856 62.2198 3.63894V4.5625C62.2198 5.11781 62.5391 5.35746 63.314 5.35746H70.4123C73.1927 5.35746 74.2559 6.63174 74.2559 8.51686V10.326C74.2559 12.2082 73.1927 13.5029 70.4123 13.5029H59.0705C58.9806 13.5029 58.9062 13.4328 58.9062 13.348V13.3422Z"
                fill="white"
              />
              <path
                d="M107.494 13.4971H110.637L102.68 0.49707H100.631L92.6246 13.4971H95.7335L97.2431 10.9222H106.024L107.497 13.4971H107.494ZM98.7774 8.42041L101.673 3.30575L104.509 8.42041H98.7774Z"
                fill="white"
              />
              <path
                d="M14.8723 13.4971H18.0153L10.0554 0.49707H8.00647L0 13.4971H3.10898L4.61852 10.9222H13.3999L14.8723 13.4971ZM6.15596 8.42041L9.04796 3.30867L11.8842 8.42333H6.15596V8.42041Z"
                fill="white"
              />
              <path
                d="M117.772 13.4971V2.75338H111.189V0.692902H126.997V2.75338H120.447V13.4971H117.769H117.772Z"
                fill="white"
              />
              <path
                d="M83.1488 13.4971V2.75338H76.5651V0.692902H92.3735V2.75338H85.8239V13.4971H83.1457H83.1488Z"
                fill="white"
              />
              <path
                d="M25.1973 13.4971V2.75338H18.6136V0.692902H34.4219V2.75338H27.8723V13.4971H25.1942H25.1973Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="nav-collection">
            <div className={'title-home-state'}>
              <button
                className={`button-base ${
                  activeButton === 'Home' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('Home')}
              >
                <div className="title-text" onClick={openModal}>
                  {' '}
                  Home
                </div>
              </button>
            </div>

            <div className={'title-home-state'}>
              <button
                className={`button-base ${
                  activeButton === 'Leagues' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('Leagues')}
              >
                <div className="title-text" onClick={openModal}>
                  {' '}
                  Leagues
                </div>
              </button>
            </div>

            <div className={'title-home-state'}>
              <button
                className={`button-base ${
                  activeButton === 'Teams' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('Teams')}
              >
                <div className="title-text" onClick={openModal}>
                  {' '}
                  Teams
                </div>
              </button>
            </div>

            <div className={'title-home-state'}>
              <button
                className={`button-base ${
                  activeButton === 'Resources' ? 'active' : ''
                }`}
                onClick={openModal}
                style={{ display: 'flex', gap: '6px' }}
              >
                <div className="title-text"> Resources</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style={{ marginTop: '2px' }}
                  fill="none"
                >
                  <path
                    d="M4.99683 7.5L9.99683 12.5L14.9968 7.5"
                    stroke="#C2C2C2"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="auth-button-collection">
            {!isAuthenticated ? (
              <div className="auth-button-collection">
                <div>
                  <button className="login-button" onClick={openModal}>
                    <div className="login-button-text"> Login </div>
                  </button>
                </div>
                <div>
                  <button className="sign-up-button" onClick={openModal}>
                    <div className="sign-up-button-text"> Sign Up</div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="auth-button-collection">
                <div>
                  <button
                    className="manage-button"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.8334 9.16658H10.8334V4.16659C10.8334 3.94557 10.7456 3.73361 10.5893 3.57733C10.433 3.42105 10.2211 3.33325 10 3.33325C9.77903 3.33325 9.56707 3.42105 9.41079 3.57733C9.2545 3.73361 9.16671 3.94557 9.16671 4.16659V9.16658H4.16671C3.94569 9.16658 3.73373 9.25438 3.57745 9.41066C3.42117 9.56694 3.33337 9.7789 3.33337 9.99992C3.33337 10.2209 3.42117 10.4329 3.57745 10.5892C3.73373 10.7455 3.94569 10.8333 4.16671 10.8333H9.16671V15.8333C9.16671 16.0543 9.2545 16.2662 9.41079 16.4225C9.56707 16.5788 9.77903 16.6666 10 16.6666C10.2211 16.6666 10.433 16.5788 10.5893 16.4225C10.7456 16.2662 10.8334 16.0543 10.8334 15.8333V10.8333H15.8334C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.2209 16.6667 9.99992C16.6667 9.7789 16.5789 9.56694 16.4226 9.41066C16.2663 9.25438 16.0544 9.16658 15.8334 9.16658Z"
                        fill="white"
                      />
                    </svg>
                    <div className="login-button-text"> Manage</div>
                  </button>
                </div>

                <div>
                  <button
                    className="manage-button"
                    onClick={() => navigate('games-dash/fantasy/account')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.0917 10.5917C13.9087 9.94891 14.505 9.06746 14.7977 8.06997C15.0904 7.07249 15.0649 6.00858 14.7248 5.02625C14.3847 4.04391 13.7469 3.19202 12.9001 2.58907C12.0532 1.98612 11.0395 1.66211 10 1.66211C8.96045 1.66211 7.94676 1.98612 7.09994 2.58907C6.25312 3.19202 5.61528 4.04391 5.27517 5.02625C4.93506 6.00858 4.90959 7.07249 5.2023 8.06997C5.49501 9.06746 6.09134 9.94891 6.90833 10.5917C5.5084 11.1526 4.2869 12.0828 3.37407 13.2833C2.46125 14.4838 1.8913 15.9094 1.725 17.4084C1.71296 17.5178 1.72259 17.6285 1.75335 17.7342C1.78411 17.8399 1.83539 17.9386 1.90426 18.0245C2.04335 18.1979 2.24565 18.309 2.46666 18.3334C2.68768 18.3577 2.9093 18.2932 3.08277 18.1541C3.25624 18.015 3.36735 17.8127 3.39166 17.5917C3.57465 15.9627 4.3514 14.4582 5.57351 13.3657C6.79562 12.2732 8.37741 11.6692 10.0167 11.6692C11.6559 11.6692 13.2377 12.2732 14.4598 13.3657C15.6819 14.4582 16.4587 15.9627 16.6417 17.5917C16.6643 17.7965 16.762 17.9856 16.9159 18.1225C17.0698 18.2595 17.269 18.3346 17.475 18.3334H17.5667C17.7851 18.3082 17.9848 18.1978 18.1221 18.0261C18.2595 17.8544 18.3234 17.6353 18.3 17.4167C18.1329 15.9135 17.5599 14.4842 16.6424 13.2819C15.7249 12.0795 14.4974 11.1496 13.0917 10.5917ZM10 10C9.34073 10 8.69626 9.80453 8.1481 9.43825C7.59993 9.07198 7.17269 8.55139 6.9204 7.9423C6.66811 7.33321 6.6021 6.66299 6.73071 6.01639C6.85933 5.36979 7.1768 4.77584 7.64297 4.30967C8.10915 3.84349 8.70309 3.52602 9.3497 3.39741C9.9963 3.26879 10.6665 3.3348 11.2756 3.58709C11.8847 3.83938 12.4053 4.26662 12.7716 4.81479C13.1378 5.36295 13.3333 6.00742 13.3333 6.66669C13.3333 7.55074 12.9821 8.39859 12.357 9.02371C11.7319 9.64883 10.8841 10 10 10Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 9.16994C16.8126 8.98369 16.5592 8.87915 16.295 8.87915C16.0308 8.87915 15.7774 8.98369 15.59 9.16994L12 12.7099L8.46001 9.16994C8.27265 8.98369 8.0192 8.87915 7.75501 8.87915C7.49082 8.87915 7.23737 8.98369 7.05001 9.16994C6.95628 9.26291 6.88189 9.37351 6.83112 9.49537C6.78035 9.61723 6.75421 9.74793 6.75421 9.87994C6.75421 10.012 6.78035 10.1427 6.83112 10.2645C6.88189 10.3864 6.95628 10.497 7.05001 10.5899L11.29 14.8299C11.383 14.9237 11.4936 14.9981 11.6154 15.0488C11.7373 15.0996 11.868 15.1257 12 15.1257C12.132 15.1257 12.2627 15.0996 12.3846 15.0488C12.5064 14.9981 12.617 14.9237 12.71 14.8299L17 10.5899C17.0937 10.497 17.1681 10.3864 17.2189 10.2645C17.2697 10.1427 17.2958 10.012 17.2958 9.87994C17.2958 9.74793 17.2697 9.61723 17.2189 9.49537C17.1681 9.37351 17.0937 9.26291 17 9.16994Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
                {access && (
                  <div>
                    <button className="manage-button" onClick={openModal}>
                      <div className="login-button-text"> Admin </div>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="menu-button" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M3 12H21M3 6H21M3 18H21" stroke="#FCFCFC" />
            </svg>
          </div>
        </div>
      </header>
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        openModal={openModal}
      />
      <ComingSoonModal
        isOpen={isModalOpens}
        setIsOpen={closeModal}
        isModalFadingOut={isModalFadingOut}
        handleAnimationEnd={handleAnimationEnd}
      />
      {isModalOpen && (
        <div className="manage-modal">
          <div className="manage-modal-header">
            <div className="manage-title">
              <h1 className="manage">Manage</h1>
            </div>
            <div
              className="athcoin-manage"
              onClick={() => {
                navigate('games-dash/fantasy/account');
                setIsModalOpen(false);
              }}
            >
              <img
                className="manage-athcoin-img"
                src="/icons/athcoin.png"
              ></img>
              <h1 className="athcoin-balance-manage">5 </h1>
            </div>
          </div>
          <div className="manage-tab-container">
            <div
              className="manage-tab-names"
              style={{
                borderBottom: '2px solid #323232',
                marginBottom: '10px',
              }}
            >
              <div
                className="manage-tab-label"
                onClick={() => setSelectedTab('My Teams')}
                style={{
                  color: selectedTab === 'My Teams' ? '#26B670' : '#C2C2C2',
                  borderBottom:
                    selectedTab === 'My Teams' ? '2px solid #26B670' : 'none',
                }}
              >
                My Teams
              </div>
              <div
                onClick={() => setSelectedTab('Leagues')}
                style={{
                  color: selectedTab === 'Leagues' ? '#26B670' : '#C2C2C2',
                  borderBottom:
                    selectedTab === 'Leagues' ? '2px solid #26B670' : 'none',
                }}
              >
                Leagues
              </div>
              <div
                className="manage-tab-label"
                onClick={() => setSelectedTab('Prizes')}
                style={{
                  color: selectedTab === 'Prizes' ? '#26B670' : '#C2C2C2',
                  borderBottom:
                    selectedTab === 'Prizes' ? '2px solid #26B670' : 'none',
                }}
              >
                Prizes
              </div>
            </div>
            {selectedTab === 'My Teams' &&
              teams.map((team) => (
                <div
                  className="manage-team-list"
                  key={team.id}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div className="team-name" style={{ marginRight: '10px' }}>
                    {team.name}
                  </div>
                  <img src="path-to-your-arrow-image.png" alt="arrow" />
                </div>
              ))}

            {selectedTab === 'Leagues' &&
              leagues.map((league) => (
                <div
                  className="manage-team-list"
                  key={league.id}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    className="team-manage-div"
                    style={{ marginRight: '10px' }}
                  >
                    <h1 className="team-name-manage">{league.title}</h1>{' '}
                  </div>
                  <div>
                    <img
                      className="go-arrow"
                      src="/icons/manage-arrow.png"
                      alt="arrow"
                    />
                  </div>
                </div>
              ))}

            {selectedTab === 'Prizes' &&
              prizes.map((prize) => (
                <div
                  className="manage-team-list"
                  key={prize.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    className="team-name-manage"
                    style={{ marginRight: '10px' }}
                  >
                    {prize.reward_description}
                  </div>
                  <div>
                    <h1 className="prize-amount-manage">
                      ${prize.reward_amount}
                    </h1>
                  </div>
                  <div className="manage-claim-button">
                    <h1 className="manage-claim">Claim</h1>
                  </div>
                </div>
              ))}

            <div
              style={{
                position: 'absolute',
                bottom: 10,
                right: 5,
              }}
            >
              {selectedTab === 'My Teams' && (
                <button
                  className="manage-button-modal"
                  onClick={handleTeamsNavigation}
                >
                  Create New
                </button>
              )}
              {selectedTab === 'Leagues' && (
                <div className="manage-league-button-container">
                  <button
                    className="manage-button-leagues"
                    onClick={handleLeagueNavigation}
                  >
                    Join League
                  </button>
                  <button
                    className="manage-button-modal"
                    onClick={handleLeagueNavigation}
                  >
                    Create New
                  </button>
                </div>
              )}
              {selectedTab === 'Prizes' && (
                <button
                  className="manage-button-modal"
                  onClick={() => navigate('games-dash/fantasy/account')}
                >
                  Edit Payments
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
