import React from 'react';

interface HowToPlayModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isModalFadingOut: boolean;
  handleAnimationEnd: () => void;
}

const ComingSoonModal: React.FC<HowToPlayModalProps> = ({
  isOpen,
  setIsOpen,
  isModalFadingOut,
  handleAnimationEnd,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div
        className={`modal-how-to ${isModalFadingOut ? 'fade-out' : ''}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <span
          onClick={() => setIsOpen(false)}
          style={{
            color: 'white',
            fontSize: '25px',
            marginLeft: '600px',
            cursor: 'pointer',
          }}
        >
          &times;
        </span>
        <div className="modal-content-2">
          <div className="display-image">
            <img src={'/img/Help.svg'} />
          </div>
          <p
            className="how-to-header"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            {' '}
            Ready to start playing?
          </p>
          <p
            className="how-to-desc"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Download the app to continue.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '32px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '4px',
                  alignItems: 'flex-start',
                  gap: '8px',
                  borderRadius: '8px',
                  background: '#FFF',
                }}
              >
                <img
                  src={'/img/ios-qr-code.png'}
                  style={{ width: '100px', height: '100px' }}
                />
              </div>
              <img
                src={'/icons/appstorebadge.svg'}
                style={{ width: '166px', height: '56px', cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/app/id6473277507',
                    '_blank'
                  );
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '4px',
                  alignItems: 'flex-start',
                  gap: '8px',
                  borderRadius: '8px',
                  background: '#FFF',
                }}
              >
                <img
                  src={'/img/android-qr-code.png'}
                  style={{ width: '100px', height: '100px' }}
                />
              </div>
              <img
                src={'/icons/googlestorebadge.png'}
                style={{ width: '166px', height: '56px', cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=io.athstatgaming.app',
                    '_blank'
                  );
                }}
              />
            </div>
          </div>

          <div
            className="how-to-modal-buttons"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              marginLeft: '350px',
            }}
          >
            <button className="cancel-button" onClick={() => setIsOpen(false)}>
              {' '}
              Cancel{' '}
            </button>
            <button className="got-it-button" onClick={() => setIsOpen(false)}>
              {' '}
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonModal;
