import React, { createContext, useContext, useState } from 'react';
import { isUserInBlockedRegion } from 'utils/location';

type LocationStore = {
  permissionGranted: boolean;
  setPermissionGranted: (permission: boolean) => void;
  inBlockedLocation: boolean;
  validateLocation: (lat: number, long: number) => boolean;
};

const LocationContext = createContext<LocationStore | undefined>(undefined);

export const useLocation = (): LocationStore => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};

interface LocationProviderProps {
  children: React.ReactNode;
}

export const LocationProvider: React.FC<LocationProviderProps> = ({
  children,
}) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [inBlockedLocation, setInBlockedLocation] = useState(false);

  const validateLocation = (lat: number, long: number): boolean => {
    if (lat && long) {
      const isBlocked = isUserInBlockedRegion(lat, long);
      setInBlockedLocation(isBlocked);
    }
    return false;
  };

  const locationStore: LocationStore = {
    permissionGranted,
    setPermissionGranted,
    inBlockedLocation,
    validateLocation,
  };

  return (
    <LocationContext.Provider value={locationStore}>
      {children}
    </LocationContext.Provider>
  );
};
