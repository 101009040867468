import { createContext } from 'react';
import { ILeague } from '../../d';

interface ILeagueContext {
  league: ILeague | null;
  setLeague?: (league: ILeague) => void;
}

export const sampleLeague: ILeague = {
  name: 'Sample League',
  sport_id: '',
  members: [],
  organization_id: '',
  hidden: false,
};

export const sampleLeagueContext: ILeagueContext = {
  league: sampleLeague,
};

export const LeagueContext = createContext<ILeagueContext>(sampleLeagueContext);
