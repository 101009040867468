import React, { FunctionComponent } from 'react';

interface AccessDeniedProps {
  message: string;
}

type Props = AccessDeniedProps;

const AccessDenied: FunctionComponent<Props> = (props) => {
  return (
    <h1
      className={'access_denied'}
      style={{ color: 'white', marginTop: '9vh', marginLeft: '144px' }}
    >
      {props.message}
    </h1>
  );
};

export default AccessDenied;
