import React, { useContext, useEffect, useState } from 'react';
/* prettier-ignore-file */
// import { useNavigate } from 'react-router-dom';
import { FantasyGamesContext } from 'context/FantasyGamesContext';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';

export const AccountWallet: React.FC = () => {
  // const navigator = useNavigate();
  const { fetchFantasyTeam, loading } = useContext(FantasyGamesContext);
  const { user, isAuthenticated, removeUser, signoutRedirect } = useAuth();
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const token = user?.access_token;
  const [, setAccess] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState('Account');
  const [isPurchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);

  const [coinAmount, setCoinAmount] = useState(() => {
    const savedCoinAmount = localStorage.getItem('coinAmount');
    return savedCoinAmount ? parseInt(savedCoinAmount) : 0;
  });

  const [selectedCoins, setSelectedCoins] = useState(1);
  type Transaction = { type: string; amount: number };

  const [transactions, setTransactions] = useState<Transaction[]>(() => {
    const savedTransactions = localStorage.getItem('transactions');
    return savedTransactions ? JSON.parse(savedTransactions) : [];
  });

  const openPurchaseModal = () => {
    setPurchaseModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setWithdrawModalOpen(false);
  };

  const openWithdrawModal = () => {
    setWithdrawModalOpen(true);
  };

  const location = useLocation();

  async function fetchUser(userId: string | undefined) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${userId}`
    );

    if (!response.ok) {
      const responseBody = await response.text();
      // eslint-disable-next-line no-console
      console.error(
        `Failed to fetch user ${userId}. Status: ${response.status}, Body: ${responseBody}`
      );
      throw new Error(`Failed to fetch user ${userId}`);
    }

    const user = await response.json();
    return user;
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const payment = query.get('payment');
    //const coins = query.get('coins');

    if (payment === 'success') {
      // Update the user's coins by the amount of coins
      fetchUser(userId)
        .then(async (user) => {
          setCoinAmount(user.athcoin_balance);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch user:', error);
        });
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && token) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
        }
      };

      checkAdmin();
    }
  }, []);

  useEffect(() => {
    if (kcUser && fetchFantasyTeam && token) {
      fetchFantasyTeam(kcUser.sub, token);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleWithdraw = () => {
    if (selectedCoins <= coinAmount) {
      const newCoinAmount = coinAmount - selectedCoins;
      setCoinAmount(newCoinAmount);
      const newTransactions = [
        ...transactions,
        { type: 'Withdraw', amount: selectedCoins },
      ];
      setTransactions(newTransactions);
      localStorage.setItem('transactions', JSON.stringify(newTransactions));
      closeWithdrawModal();
    } else {
      alert("You don't have enough coins to withdraw this amount.");
    }
  };

  const handlePurchase = async (
    bundle_name: string,
    amount: number,
    coins: number
  ) => {
    const token = user?.access_token;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/buy-athcoins`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
        },
        body: JSON.stringify({
          userId: userId,
          amount: amount,
          name: bundle_name,
          coins: coins,
        }),
      }
    );

    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error('Server API call failed:', response);
    } else {
      const data = await response.json();
      window.location.href = data.url;
    }

    setPurchaseModalOpen(false);
  };

  const onLogout = async () => {
    if (isAuthenticated) {
      removeUser();
      if (
        process.env.REACT_APP_APP_URL &&
        process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID
      ) {
        signoutRedirect({
          post_logout_redirect_uri: process.env.REACT_APP_APP_URL,
          extraQueryParams: {
            client_id: process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID,
          },
        });
      }
    } else {
      if (
        process.env.REACT_APP_APP_URL &&
        process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID
      ) {
        signoutRedirect({
          post_logout_redirect_uri: process.env.REACT_APP_APP_URL,
          extraQueryParams: {
            client_id: process.env.REACT_APP_AUTH_KEYCLOAK_CLIENT_ID,
          },
        });
      }
    }
  };

  return (
    <React.Fragment>
      <section className="games-home-page">
        <div className="account-details">
          <h1 className="account-header">Account</h1>
          <div className="account-tabs">
            <div
              className={
                selectedTab === 'Account'
                  ? 'selectedAccount'
                  : 'unselectedAccount'
              }
              onClick={() => setSelectedTab('Account')}
            >
              Profile
            </div>
            <div
              className={
                selectedTab === 'Wallet'
                  ? 'selectedAccount'
                  : 'unselectedAccount'
              }
              onClick={() => setSelectedTab('Wallet')}
            >
              Wallet
            </div>
            <div
              className={
                selectedTab === 'Prizes'
                  ? 'selectedAccount'
                  : 'unselectedAccount'
              }
              onClick={() => setSelectedTab('Prizes')}
            >
              Prizes
            </div>
          </div>
          {selectedTab === 'Account' && (
            <div>
              <div className="account-tab-description">
                <div className="account-img">
                  <img
                    src="/icons/account-profile.png"
                    alt={'Profile'}
                    className="img-general-info"
                  />
                </div>
                <h1 className="account-tab-title">General Info</h1>
              </div>
              <div className="general-info-table">
                <div className="general-input">
                  <div>
                    <label className="general-input-title" htmlFor="firstName">
                      First Name
                    </label>
                  </div>
                  <div>
                    <input
                      id="input1"
                      type="text"
                      placeholder="First Name"
                      className="general-input-styling"
                    />
                  </div>
                </div>
                <div className="general-input">
                  <div>
                    <label className="general-input-title" htmlFor="lasttName">
                      Last Name
                    </label>
                  </div>
                  <div>
                    <input
                      id="input2"
                      type="text"
                      placeholder="Last Name"
                      className="general-input-styling"
                    />
                  </div>
                </div>
                <div className="general-input">
                  <div>
                    <label className="general-input-title" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div>
                    <input
                      id="input3"
                      type="text"
                      placeholder="Email"
                      className="general-input-styling"
                    />
                  </div>
                </div>
                <div className="general-input">
                  <div>
                    <label className="general-input-title" htmlFor="location">
                      Location
                    </label>
                  </div>
                  <div>
                    <input
                      id="input4"
                      type="text"
                      placeholder="Location"
                      className="general-input-styling"
                    />
                  </div>
                  <div>
                    <h1 className="location-disclaimer">
                      Location cannot be changed
                    </h1>
                  </div>
                </div>
              </div>
              <div className="general-info-buttons">
                <button className="general-info-button-discard">Discard</button>
                <button className="general-info-button-save">
                  Save Changes
                </button>
              </div>
              <div
                className="general-info-buttons-delete"
                style={{
                  textAlign: 'right',
                  paddingTop: 20,
                }}
              >
                <button
                  onClick={onLogout}
                  className="general-info-button-delete"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
          {selectedTab === 'Wallet' && (
            <div>
              <div className="wallet-divs">
                <div className="wallet-div-left">
                  <div className="account-tab-description">
                    <div className="account-img">
                      <img
                        src="/icons/money.png"
                        alt={'Profile'}
                        className="img-general-info"
                      />
                    </div>
                    <h1 className="account-tab-title">Wallet</h1>
                  </div>
                  <div className="wallet-details">
                    <div className="athcoins-img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M9.99513 9.99414C12.201 7.78824 12.201 4.21176 9.99513 2.00586C7.78922 -0.200049 4.21274 -0.200049 2.00684 2.00586C-0.199066 4.21176 -0.199066 7.78824 2.00684 9.99414C4.21274 12.2 7.78922 12.2 9.99513 9.99414Z"
                          fill="#FEB724"
                        />
                      </svg>
                      <h1 className="athcoins-title">Athcoins</h1>
                    </div>
                    <div className="athcoin-info">
                      <h1 className="athcoin-desc">What is ATHCOIN?</h1>
                      <img
                        src="/icons/help.png"
                        alt="help"
                        className="help-icon"
                        data-tip="ATHCOIN is the ATHSTAT platform currency. It can be purchased, or won by winning a league. The value of 1 ATHCOIN is equal to $1 USD, and can be withdrawn for real funds at any given time in your wallet. "
                        style={{ cursor: 'pointer' }}
                      />
                      <ReactTooltip multiline={true} place="top" />
                    </div>
                  </div>
                  <div className="athcoin-amount">
                    <h1 className="athcoin-number">
                      {coinAmount ? coinAmount : 0}
                    </h1>
                  </div>
                  <div className="athcoin-buttons">
                    <button
                      className="athcoin-button"
                      onClick={openPurchaseModal}
                    >
                      Purchase
                    </button>
                    <button
                      className="athcoin-button"
                      onClick={openWithdrawModal}
                    >
                      Withdraw
                    </button>
                  </div>

                  <Modal
                    show={isPurchaseModalOpen}
                    onHide={() => setPurchaseModalOpen(false)}
                    backdrop={false}
                    dialogClassName="custom-modal"
                    style={{
                      backgroundColor: 'transparent',

                      width: '100%', // Set width to 100%
                      boxShadow:
                        ' 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
                    }}
                  >
                    <Modal.Header
                      style={{
                        backgroundColor: '#111',
                        borderBottom: 'none',
                        width: '100%', // Set width to 100%
                      }}
                      className="custom-header"
                    >
                      <Modal.Title>
                        {' '}
                        <h1 className="purchase-modal-header">Purchase</h1>
                      </Modal.Title>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={() => setPurchaseModalOpen(false)}
                        style={{ cursor: 'pointer' }}
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#E6E6E6"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        backgroundColor: '#111',
                        borderBottom: 'none',
                        borderTop: 'none',
                        width: '100%', // Set width to 100%
                      }}
                    >
                      <h1 className="purchase-modal-description">
                        Use these coins to join paid leagues and win big prizes.
                      </h1>
                      <div className="purchase-grid">
                        <img
                          src="/img/beginner-bundle.png"
                          alt="beginner"
                          className="purchase-bundle-img"
                          onClick={() => {
                            handlePurchase('beginnersBundle', 1.99, 4);
                          }}
                        />
                        <img
                          src="/img/single.png"
                          alt="single"
                          className="purchase-bundle-img"
                          onClick={() => {
                            handlePurchase('single', 0.99, 1);
                          }}
                        />
                        <img
                          src="/img/bundle.png"
                          alt="bundle"
                          className="purchase-bundle-img"
                          onClick={() => {
                            handlePurchase('bundle', 3.99, 5);
                          }}
                        />
                        <img
                          src="/img/super-bundle.png"
                          alt="super"
                          className="purchase-bundle-img"
                          onClick={() => {
                            handlePurchase('super', 8.99, 10);
                          }}
                        />
                        <img
                          src="/img/mega-bundle.png"
                          alt="mega"
                          className="purchase-bundle-img"
                          onClick={() => {
                            handlePurchase('mega', 15.99, 20);
                          }}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={isWithdrawModalOpen}
                    onHide={() => setWithdrawModalOpen(false)}
                    backdrop={false}
                    dialogClassName="custom-modal"
                    style={{
                      backgroundColor: 'transparent',

                      width: '100%',
                      boxShadow:
                        ' 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
                    }}
                  >
                    <Modal.Header
                      style={{
                        backgroundColor: '#111',
                        borderBottom: 'none',
                        width: '100%',
                      }}
                      className="custom-header"
                    >
                      <Modal.Title>
                        <div className="withdrawl-header-img">
                          <img
                            src="/img/withdrawal.png"
                            alt="withdrawl"
                            className="withdrawl-img"
                          />
                        </div>
                      </Modal.Title>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={() => setWithdrawModalOpen(false)}
                        style={{ cursor: 'pointer' }}
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#E6E6E6"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        backgroundColor: '#111',
                        borderBottom: 'none',
                        borderTop: 'none',
                      }}
                    >
                      <div className="withdraw-modal-info">
                        <div>
                          <h1 className="purchase-modal-header">Withdraw</h1>
                        </div>
                        <div>
                          <h1 className="purchase-modal-description">
                            How much would you like to withdraw? .
                          </h1>
                        </div>
                      </div>
                      <div className="withdraw-athcoin-amount">
                        <img
                          src="/img/athcoin.png"
                          alt="athcoin"
                          className="ath-img"
                        />
                        <h1 className="coin-total-amount">{coinAmount}</h1>
                      </div>
                      <h1 className="withdraw-amt">Enter Amount</h1>
                      <div className="withdraw-boxes">
                        <div>
                          <select
                            id="coin-select"
                            value={selectedCoins}
                            className="coin-select"
                            onChange={(e) =>
                              setSelectedCoins(Number(e.target.value))
                            }
                          >
                            {Array.from(
                              { length: coinAmount },
                              (_, i) => i + 1
                            ).map((value) => (
                              <option key={value} value={value}>
                                {value} Athcoins
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="coin-value">
                          <p> $ {selectedCoins}</p>
                        </div>
                      </div>
                      <div className="withdraw-buttons">
                        <button
                          onClick={closeWithdrawModal}
                          className="withdraw-button-cancel"
                        >
                          Cancel
                        </button>

                        <button
                          className="withdraw-button-confirm"
                          onClick={handleWithdraw}
                        >
                          Withdraw
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div className="wallet-div-right">
                  <div className="account-history-img">
                    <div className="account-img">
                      <img
                        src="/icons/history.png"
                        alt={'Profile'}
                        className="img-general-info"
                      />
                    </div>
                    <div>
                      <h1
                        className="account-tab-title"
                        style={{ marginLeft: '20px' }}
                      >
                        History
                      </h1>
                    </div>
                  </div>
                  <div className="transaction-history">
                    <table>
                      <tbody>
                        {transactions.map((transaction, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {transaction.type === 'Purchase' ? (
                                  <img
                                    src="/img/athcoin.png"
                                    alt="Purchase"
                                    className="table-icon"
                                  />
                                ) : (
                                  <img
                                    src="/icons/money.png"
                                    alt="Withdraw"
                                    className="money-icon"
                                  />
                                )}
                                <span className="transaction-type">
                                  {transaction.type}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span className="transaction-amount">
                                  {transaction.type === 'Purchase' ? '+' : '-'}$
                                  {transaction.amount}
                                </span>
                                {transaction.type === 'Purchase' ? (
                                  <img
                                    src="/icons/increase.png"
                                    alt="Increase"
                                    className="coin-decrease"
                                  />
                                ) : (
                                  <img
                                    src="/icons/decrease.png"
                                    alt="Decrease"
                                    className="coin-decrease"
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 'Prizes' && (
            <div className="prize-display">
              <div
                className="account-tab-description"
                style={{ marginTop: '32px' }}
              >
                <div className="account-img">
                  <img
                    src="/icons/prize.png"
                    alt={'Profile'}
                    className="img-general-info"
                  />
                </div>
                <h1 className="account-tab-title">Prizes</h1>
              </div>
              <div className="prize-grid">
                <img
                  src="/img/prize-example.png"
                  alt="Prize"
                  className="prize-notification"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};
export default AccountWallet;
