import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const AllowedAreas: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div className="PageTitle">
              <h2>Allowed Areas for Daily Fantasy Sports</h2>
            </div>
          </Col>
        </Row>
        <h3 className="privacy-policy-paragraph">
          Users who live in the following areas are allowed to access our
          Fantasy Games:
        </h3>

        <table
          className="privacy-policy-table"
          style={{ width: '600px', marginLeft: '200px' }}
        >
          <tr className="privacy-row">
            <th className="privacy-head-1"> State</th>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Massachusetts</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Arkansas</td>
          </tr>

          <tr className="privacy-row">
            <td className="privacy-info-1">Maryland</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Alaska</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">California</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Florida</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Georgia</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Illinois</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Kansas</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Kentucky</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Minnessota</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Nebraska</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">New Mexico</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">North Carolina</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">North Dakota</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Oklahoma</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Oregon</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Rhode Island</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">South Carolina</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">South Dakota</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Texas</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Utah</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">West Virginia</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Washington D.C.</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Wisconsin</td>
          </tr>
          <tr className="privacy-row">
            <td className="privacy-info-1">Wyoming</td>
          </tr>
        </table>

        <h2 className="privacy-policy-header">CONTACT US</h2>
        <h3 className="privacy-policy-paragraph">
          For further questions regarding our paid fantasy contests, please
          contact us at:{' '}
          <a className="onboarding-link" href="mailto:gaming@athstat.com">
            gaming@athstat.com
          </a>
        </h3>
      </section>
    </React.Fragment>
  );
};
export default AllowedAreas;
