import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IPlayerSummary } from '../../../../d';

import axios from 'axios';
import { Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import AccessDenied from '../../AccessDenied';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const PlayerSummaryDashboard: React.FC = () => {
  const [playerSummaries, setPlayerSummaries] = useState<Array<IPlayerSummary>>(
    []
  );
  const { isAuthenticated, user } = useAuth();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const userId = kcUser?.sub;
  const navigate = useNavigate();
  const [access, setAccess] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      const checkAdmin = async () => {
        const fetchedAccess = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/check-access`,
          {
            params: { user_id: userId },
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        if (fetchedAccess.data) {
          setAccess(fetchedAccess.data);
          const getSummaries = async () => {
            const fetchedSummaries: Array<IPlayerSummary> = await axios
              .get(`${process.env.REACT_APP_API_URL}/admin/playerSummaries`, {
                headers: {
                  Authorization: user ? `Bearer ${token}` : undefined,
                },
              })
              .then((r) => {
                return r.data;
              })
              .catch(() => {
                return [];
              });
            if (fetchedSummaries) {
              setPlayerSummaries(fetchedSummaries);
            }
          };

          getSummaries();
        }
      };

      checkAdmin();
    }
  }, []);

  const linkToPlayerSummary = (selectedSummaryId: string | null) => {
    if (selectedSummaryId) {
      navigate({
        pathname: `/auth/playercards/player-data-summary`,
        search: `?summaryId=${selectedSummaryId}`,
      });
    } else {
      navigate(`/auth/playercards/player-data-summary/`);
    }
  };

  const playerBoxes = () => {
    const playerSummaryCards: Array<React.ReactNode> = [
      <Col size="12" md="6" lg="3" key="NewplayerCard">
        <div className="DataSharing-Info">
          <a onClick={() => linkToPlayerSummary(null)}>
            <div
              className="Inner-Data"
              style={{ height: '150px', paddingLeft: '5%' }}
            >
              <Row>
                <IconButton disabled>
                  <Add color="secondary" style={{ fontSize: '60px' }} />
                </IconButton>
              </Row>
              <Row center>
                <Col>
                  <h5>Create</h5>
                </Col>
              </Row>
            </div>
          </a>
        </div>
      </Col>,
    ];
    if (playerSummaries) {
      playerSummaries.forEach((summary: IPlayerSummary, index: number) => {
        if (!summary.hidden) {
          playerSummaryCards.push(
            <Col size="12" md="6" lg="3" key={`${summary.player_id}-${index}`}>
              <div className="DataSharing-Info-PS">
                <a onClick={() => linkToPlayerSummary(summary._id)}>
                  {' '}
                  <div className="Inner-Data">
                    <div className="SectionTitle">
                      <Row>
                        <Col>
                          <h2>{`Summary Name: ${summary.playerCardName}`}</h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="list-item">
                      <h6>{`Player: ${summary.firstName} ${summary.middleInitial} ${summary.lastName}`}</h6>
                      <h6>{`Sport: ${
                        summary.sport ? summary.sport : 'N/A'
                      }`}</h6>
                      <h6>{`Date Created: ${
                        summary.date_created
                          ? summary.date_created
                          : 'Unavailable'
                      }`}</h6>
                      {/* <h6>{`Weight: ${summary.weight} kg/lbs`}</h6> */}
                    </div>
                  </div>
                </a>
              </div>
            </Col>
          );
        }
      });
    }
    return playerSummaryCards;
  };

  if (!access) {
    return <AccessDenied message={'Admin Access Denied'} />;
  } else {
    return (
      <React.Fragment>
        <section className="content-body HeaderDown">
          <Button className="backButton" onClick={() => navigate(-1)}>
            <i className="backarrow left" />
          </Button>
          <Row>
            <Col size="12">
              <div className="PageTitle">
                <h2>Admin Player Summary Dashboard</h2>
              </div>
            </Col>
          </Row>
          <div style={{ marginTop: '2%', paddingLeft: '1%' }}>
            <Row>{playerBoxes()}</Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
};
export default PlayerSummaryDashboard;
