/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';

const PlayerLeaderboard: React.FC = () => {
  const [, setCurrentDiv] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDiv((prevDiv) => (prevDiv % 3) + 1);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="player-leaderboard">
      <img
        src={'/img/updated_qr_codes.png'}
        // style={{ width: '166px', height: '56px' }}
      />
      {/* <h1 className="player-leaderboard-title">Top Performing Users</h1>

      <ul className="player-leaderboard-list">
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league"></div>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league"></div>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league"></div>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league"></div>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league"></div>
        </li>
      </ul> */}

      {/* Will need to map over the top performing users. keep the classNames BELOW in order to style the cards. */}
      {/* <ul className="player-leaderboard-list">
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league">
            <h1 className="leaderboard-name">Olivia</h1>
            <h1 className="leaderboard-league">Athstat Test</h1>
          </div>
          <h1 className="leaderboard-player-money"> $100,000</h1>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league">
            <h1 className="leaderboard-name">Tafara</h1>
            <h1 className="leaderboard-league">Athstat Test</h1>
          </div>
          <h1 className="leaderboard-player-money"> $90,000</h1>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league">
            <h1 className="leaderboard-name">Thariq</h1>
            <h1 className="leaderboard-league">Athstat Test</h1>
          </div>
          <h1 className="leaderboard-player-money"> $80,000</h1>
        </li>
        <li className="player-leaderboard-list-item">
          <img
            style={{ backgroundColor: '#323232' }}
            src="icons/leaderavatar.svg"
          />
          <div className="leaderboard-name-league">
            <h1 className="leaderboard-name">Nyasha</h1>
            <h1 className="leaderboard-league">Athstat Test</h1>
          </div>
          <h1 className="leaderboard-player-money"> $70,000</h1>
        </li>
      </ul> */}
    </div>
  );
};

export default PlayerLeaderboard;
