import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

const RRErrorBoundary = (): React.JSX.Element => {
  const error = useRouteError();
  let errorMessage: string;
  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    // errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorMessage = 'Unknown error';
  }
  return (
    <div>
      <h1>We are not sure what we should show you.</h1>
      <p>Sorry, The browser bot is having issues...</p>
      <p>{/* <i>{errorMessage}</i> */}</p>
    </div>
  );
};

export default RRErrorBoundary;
