import React, { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
// import { useAuth } from 'react-oidc-context';
import {
  IAthletePriceChange,
  IFantasyAthlete,
  AthletePowerRank,
} from '../../../d';
import { Graph } from './Graph';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { getDisplayDate } from '../utils/timeParser';

interface PlayerInformationProps {
  player: IFantasyAthlete;
  data?: IAthletePriceChange[];
}

export const PlayerInformation: React.FC<PlayerInformationProps> = ({
  player,
  data,
}) => {
  // const { user } = useAuth();
  // const token = user?.access_token;

  const [powerRankings, setPowerRankings] = useState<AthletePowerRank[]>([]);
  const { user } = useAuth();
  const token = user?.access_token;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchPowerRankings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATAHUB_URL}/athletes/powerrankings/${player.tracking_id}`,
          {
            headers: {
              Authorization: user ? `Bearer ${token}` : undefined,
            },
          }
        );
        setPowerRankings(response.data);
      } catch (error) {
        // console.error('Error fetching power rankings:', error);
      }
    };

    fetchPowerRankings();
  }, [player.tracking_id]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <Col sm={'6'} className="profile-container">
          <div style={{ flex: 1 }}>
            <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
              <h2
                className="profile-table-title"
                style={{ color: '#049d2d' }}
                onClick={toggleModal}
              >
                Power Rankings
                <i
                  className="fa fa-info-circle"
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={toggleModal}
                ></i>
              </h2>
              <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header
                  closeButton
                  style={{
                    backgroundColor: '#049d2d',
                    borderBottom: '1px solid #17181c',
                  }}
                >
                  <Modal.Title
                    style={{ fontFamily: 'DM Sans', color: '#17181c' }}
                  >
                    What is a Power Ranking?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#049d2d' }}>
                  <p style={{ fontFamily: 'DM Sans', color: 'white' }}>
                    It’s a cumulative score of how a players actions affect
                    their teams expected score. Athstat sums up the impact of
                    each action then give a final score. The higher the score
                    the more impactful the player is believed to be.
                  </p>
                </Modal.Body>
                <Modal.Footer
                  style={{ backgroundColor: '#049d2d', borderTop: 'none' }}
                >
                  <Button
                    variant="secondary"
                    onClick={toggleModal}
                    style={{ backgroundColor: '#17181c' }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {powerRankings.length === 0 ? (
                <p className="power-rankings-none">
                  No rankings to display for {player.player_name}
                </p>
              ) : (
                <table className="profile-table-rankings">
                  <tbody>
                    {powerRankings.map((ranking) => (
                      <tr key={ranking.id}>
                        <td className="rankings-opposition">
                          {ranking.opposition_name}
                        </td>
                        <tr style={{ borderBottom: '1px solid lightgrey' }}>
                          <td className="rankings-date">
                            {ranking.game_date
                              ? getDisplayDate(ranking.game_date.toString())
                              : ''}
                          </td>
                          <td className="ranking-value">
                            <span className="value-circle">
                              {ranking.updated_power_ranking}
                            </span>
                          </td>
                        </tr>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Col>
        {data && data?.length > 0 ? (
          <Col sm={'6'} className="profile-container">
            <Graph data={data} />
          </Col>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default PlayerInformation;
