/* eslint-disable no-console */
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const LocationBlocked: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, removeUser } = useAuth();

  const onLogout = async () => {
    if (isAuthenticated) {
      removeUser();
      navigate('/');
    } else {
      navigate('/');
    }
  };
  return (
    <div className="sign-up">
      <div className="sign-up-header">
        <img
          src="/icons/Athstat-header.svg"
          alt="Athstat Logo"
          className="athstat-header-logo"
        />
      </div>
      <div className="centered-content">
        <div
          className="sign-up-box"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 className="verification-header" style={{ marginLeft: '10px' }}>
            Uh Oh
          </h1>
          <h1
            className="verification-p"
            style={{ width: '330px', marginTop: '-20px', textAlign: 'center' }}
          >
            Athstat Games is not allowed in your state yet. Please keep checking
            back for updates.
          </h1>

          <div className="verification-buttons">
            <button className="verification-back" onClick={onLogout}>
              Back To Home
            </button>
            <button
              className="verification-next"
              style={{ marginLeft: '-40px' }}
            >
              <a
                href="mailto:gaming@athstat.com"
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                Contact Us
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationBlocked;
