import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

export interface IAthletePriceChange {
  id?: number;
  athlete_id?: string;
  price_change: number;
  game_id?: string;
  updated_price: number;
}

interface PriceChangeChartProps {
  //data: IAthletePriceChange[];
  trackingId: string;
}

const PriceChangeCharts: React.FunctionComponent<PriceChangeChartProps> = ({
  trackingId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [dataPoints, setDataPoints] = useState<number[]>([]);
  const [, setAthletePriceChanges] = useState<IAthletePriceChange[]>([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unauth/athletes-price-changes/${trackingId}`
      )
      .then((response) => {
        //const data = response.data.rows;
        const data = response.data;
        // eslint-disable-next-line no-console
        //console.log('Athlete price changes: ', data);
        setAthletePriceChanges(data);

        setLabels(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          data.map((_change: IAthletePriceChange, _index: number) => ' ')
        );
        setDataPoints(
          data.map((change: IAthletePriceChange) => change.updated_price)
        );

        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  }, [trackingId]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      y: {
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)', // Change this to the color you want
        },
        ticks: {
          color: 'white',
          callback: function (value: string | number) {
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(Number(value));
          },
        },
      },
      x: {
        grid: {
          display: false, // This will remove the vertical grid lines
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataPoints,
        borderColor: '#26B670',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointBorderColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.4, // This makes the line smooth
        pointRadius: 0, // This removes the points
      },
    ],
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          left: '35%',
        }}
      >
        <div className="loading-spinner">
          <img
            src="/img/loading2.gif"
            alt="Loading"
            style={{ height: '100px', borderRadius: '50px' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', paddingTop: 40 }}>
      <Line options={options} data={data} />;
      {/* <Line
        data={{
          labels: athletePriceChange?.map(() => ' '),
          datasets: [
            {
              data: athletePriceChange?.map((d) => d?.new_price || 0),
              backgroundColor: main_color,
              borderColor: main_color,
              pointBackgroundColor: main_color,
              pointBorderColor: main_color,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    return '$' + value;
                  },
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        }}
      /> */}
    </div>
  );
};

export default PriceChangeCharts;
