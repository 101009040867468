import blockedRegions from './regions.json';

export interface Region {
  name: string;
  latitude: number;
  longitude: number;
  radius: number; //KMs
}

function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371; // Earth's radius in kilometers
  const latDistance = toRadians(lat2 - lat1);
  const lonDistance = toRadians(lon2 - lon1);
  const a =
    Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(lonDistance / 2) *
      Math.sin(lonDistance / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function toRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export function isUserInBlockedRegion(
  userLatitude: number,
  userLongitude: number
): boolean {
  for (const region of blockedRegions) {
    const distance = calculateDistance(
      userLatitude,
      userLongitude,
      region.latitude,
      region.longitude
    );
    if (distance <= region.radius) {
      return true;
    }
  }
  return false;
}
