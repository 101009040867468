import { RouteObject } from 'react-router-dom';
import React from 'react';
import PaymentDashboard from '../components/Payment/PaymentDashboard';
import Subscriptions from '../components/Payment/Subscriptions';

const PaymentRoutes: Array<RouteObject> = [
  { index: true, element: <PaymentDashboard /> },

  {
    path: 'subscriptions',
    element: <Subscriptions />,
  },
];

export default PaymentRoutes;
