import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const UserGuide: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="content-body HeaderDown">
        <Button className="backButton" onClick={() => navigate(-1)}>
          {' '}
          <i className="backarrow left" />
        </Button>
        <Row>
          <Col size="12">
            <div className="PageTitle">
              <h2>Detailed User Guide</h2>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col size="12"> */}
        <iframe
          src={`https://s3.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/Athstat+User+Guide.pdf`}
          width="100%"
          height="750vh"
        />
        {/* </Col>
        </Row> */}
      </section>
    </React.Fragment>
  );
};
export default UserGuide;
