import React from 'react';
import { Button } from 'react-bootstrap';
import { AthstatLeague, IUserLeague } from '../../../d';

interface ILeagueRowProps {
  league: AthstatLeague | IUserLeague;
  onClick: () => void;
}

export const LeagueRow: React.FunctionComponent<ILeagueRowProps> = ({
  league,
  onClick,
}) => {
  return (
    <div className="league-card">
      <h4 className="league-type">{league.league_type} League</h4>
      <h4 className="league-title">{league.title}</h4>
      <Button className="reset-button3" onClick={onClick}>
        <h4>Edit</h4>
      </Button>
    </div>
  );
};
