import {
  getAllAthletes,
  getFantasyTeamAthletes,
  getFantasyTeamByUserId,
  getTransfersStatus,
  updateFantasyTeamAthletes,
} from 'components/Games/games.services';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import {
  IFantasyAthlete,
  IFantasyTeam,
  IFantasyTeamAthlete,
} from '../../../../d';
import PlayerCard from '../../Components/PlayerCard';
// import useFantasyTeam from '../../useFantasyTeamHook';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
} from '@mui/material';
import FilterOption from './FilterComponent';
import { postFantasyTeam } from 'components/Games/games.services';
interface Slot {
  player: IFantasyAthlete | null;
  position?: string;
}

interface ActionResponse {
  type: 'success' | 'error';
  message: string;
}

// interface IReturn {
//   slots: Slot[];
//   addPlayers: (players: IFantasyAthlete[]) => void;
//   addPlayerToIndex: (
//     slotIndex: number,
//     player: IFantasyAthlete
//   ) => ActionResponse;
//   removeByIndex: (index: number) => ActionResponse;
//   getPlayerByIndex: (slotIndex: number) => IFantasyAthlete | null;
//   balance: number;
//   setBalance: React.Dispatch<React.SetStateAction<number>>;
//   saveTeam: (teamId: number | undefined) => Promise<ActionResponse>;
//   loading: boolean;
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
// }

const PickTeam: React.FC = () => {
  const [team, setTeam] = useState<IFantasyTeam | null>(null);
  const { user } = useAuth();
  const navigator = useNavigate();
  const token = user?.access_token;
  const kcUser = user?.profile;
  const [selectedPlayer, setSelectedPlayer] = useState<IFantasyAthlete | null>(
    null
  );
  const [playerIndex, setPlayerIndex] = useState<number | undefined>(undefined);
  const [masterList, setmasterList] = useState<IFantasyAthlete[]>([]);
  const [filteredAthletes, setFilteredAthletes] = useState<IFantasyAthlete[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [originalSlots, setOriginalSlots] = useState<Slot[]>();
  const [slots, setSlots] = useState<Slot[]>([
    { player: null },
    { player: null },
    { player: null },
    { player: null },
    { player: null },
  ]);
  const [balance, setBalance] = useState<number>(0);

  const [, setIsSearchUsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [, setPlayerCardClicked] = useState(false);

  useEffect(() => {
    const fetchTeamPlayers = async (id: number) => {
      try {
        if (token) {
          getFantasyTeamAthletes({
            id: id ? id : -1,
            token: token,
          })
            .then((res) => {
              addPlayers(res);
            })
            .catch(() => {
              setLoading(false);
              alert('Something went wrong');
            });
        }
      } catch (error) {
        return [];
      }
    };

    const fetchFantasyTeam = async () => {
      try {
        setLoading(true);
        await getFantasyTeamByUserId({
          id: kcUser?.sub ? kcUser.sub : '',
          token: token ? token : '',
        })
          .then((res) => {
            setTeam(res);
            setBalance(res?.balance ?? 0);
            fetchTeamPlayers(res?.id ?? -1);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFantasyTeam();
  }, [token]);

  useEffect(() => {
    const fetchMarketPlayers = async () => {
      if (token) {
        try {
          setLoading(true);
          await getAllAthletes({
            token: token,
          })
            .then((res) => {
              setmasterList(res);
              setFilteredAthletes(res);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
        }
      }
    };

    fetchMarketPlayers();
  }, [token]);

  const addPlayers = (players: IFantasyAthlete[]) => {
    const newSlots = [...slots];
    players.forEach((player, index) => {
      newSlots[index].player = player;
    });
    const newOriginalSlots = JSON.parse(JSON.stringify(newSlots));
    setOriginalSlots(newOriginalSlots);
    setSlots(newSlots);
  };

  const addPlayerToIndex = (
    slotIndex: number,
    player: IFantasyAthlete
  ): ActionResponse => {
    if (slotIndex >= 1 && slotIndex <= 5) {
      const newSlots = [...slots];
      const oldPlayer = newSlots[slotIndex - 1].player;
      const updatedBalance = balance + (oldPlayer?.price || 0) - player.price;
      newSlots[slotIndex - 1].player = player;
      setSlots(newSlots);
      setBalance(updatedBalance);
      return { type: 'success', message: 'Player added to slot.' };
    } else {
      return { type: 'error', message: `Invalid slot index. ${slotIndex}` };
    }
  };

  // const removeByIndex = (index: number): ActionResponse => {
  //   const newSlots = [...slots];
  //   if (index >= 1 && index <= 5) {
  //     const oldPlayer = newSlots[index - 1].player;
  //     if (oldPlayer) {
  //       setBalance(balance + oldPlayer?.price);
  //     }
  //     newSlots[index - 1].player = null;
  //     setSlots(newSlots);
  //     return { type: 'success', message: 'Player removed from slot.' };
  //   } else {
  //     return { type: 'error', message: 'Invalid slot index.' + index };
  //   }
  // };

  const getPlayerByIndex = (slotIndex: number): IFantasyAthlete | null => {
    if (slotIndex >= 1 && slotIndex <= 5) {
      return slots[slotIndex - 1].player;
    } else {
      return null;
    }
  };

  const saveTeam = async (
    teamid: number | undefined
  ): Promise<ActionResponse> => {
    try {
      if (slots.filter((slot) => slot.player).length < 5) {
        throw new Error('Team must have 5 players.');
      }

      const playerIds = slots.map((slot) => slot.player?.tracking_id);
      if (new Set(playerIds).size !== playerIds.length) {
        throw new Error('Team cannot have duplicate players.');
      }

      if (originalSlots) {
        const originalPlayerIds = originalSlots.map(
          (slot) => slot.player?.tracking_id
        );
        if (JSON.stringify(originalPlayerIds) === JSON.stringify(playerIds)) {
          throw new Error('No changes made to team.');
        }
      }

      if (token && teamid) {
        if (originalSlots && originalSlots[0].player === null) {
          const athletes: [number, string, number, string, string, number][] =
            slots.map((slot, index) => {
              const player: IFantasyAthlete | null = slot.player;
              return [
                teamid,
                player?.tracking_id || '',
                player?.price || 0,
                new Date().toISOString(),
                player?.position || '',
                index + 1,
              ];
            });

          await postFantasyTeam({
            athletes: athletes,
            token: user.access_token,
          });
        } else {
          const athletes: IFantasyTeamAthlete[] = slots.map((slot, index) => {
            const player: IFantasyAthlete | null = slot.player;
            return {
              team_id: teamid,
              athlete_id: player?.tracking_id || '',
              purchase_date: new Date().toISOString(),
              purchase_price: player?.price || 0,
              position: player?.position || '',
              slot: index + 1,
            };
          });

          await updateFantasyTeamAthletes({
            athletes: athletes,
            token: user.access_token,
          });
        }

        return { type: 'success', message: 'Team saved successfully.' };
      } else {
        throw new Error('Error saving team.');
      }
    } catch (error) {
      return {
        type: 'error',
        message: 'Sorry, something went wrong.',
      };
    }
  };

  const fetchTransferStatus = async () => {
    try {
      if (token) {
        const response = await getTransfersStatus({ token });
        return response;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const replacePlayer = (slotIndex: number, player: IFantasyAthlete) => {
    const response = addPlayerToIndex(slotIndex, player);
    if (response.type !== 'success') {
      alert(response.message);
    }
    resetState();
  };

  const resetState = () => {
    setSelectedPlayer(null);
    setPlayerIndex(undefined);
    setFilteredAthletes(masterList);
  };

  const save = async () => {
    try {
      if (balance < 0) {
        alert('You have insufficient funds');
        return;
      }
      const transferStatus = await fetchTransferStatus();
      if (!transferStatus) {
        alert('Unable to save team. Transfers are closed');
        return;
      }

      const response = await saveTeam(team?.id);

      if (response.type === 'success') {
        alert("Team's changes saved");
        setTimeout(() => {
          alert('Redirecting to dashboard');
          navigator('/auth/games-dash/fantasy');
        }, 1000);
      } else {
        alert(response.message);
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const handleSearchBar = (search: string | null) => {
    setIsSearchUsed(true);
    if (search) {
      const filteredPlayers = masterList.filter((player: IFantasyAthlete) =>
        player.player_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredAthletes(filteredPlayers);
    } else {
      setFilteredAthletes(masterList);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="pick-team-styling">
        <div
          className="back-breadcrumbs2"
          style={{
            marginTop: '100px',
            marginLeft: '100px',
          }}
        >
          <Button className="backButtonNFT" onClick={() => navigator(-1)}>
            <img
              className="nft-vector"
              src="/icons/back-nft-2.svg"
              alt={'Portfolio'}
            />
            <h1 className="back-word2">Back</h1>
          </Button>
          <Button
            className="backButtonNFT"
            onClick={() => navigator(`/auth/games-dash/fantasy/search`)}
            title="Search Players"
            style={{ marginTop: '2px' }}
          >
            <img
              className="nft-vector"
              src="/icons/search.svg"
              alt={'Portfolio'}
            />
          </Button>
        </div>
        <div className="games-title-header">
          <Row
            className="dashboard-PageTitle-row "
            style={{
              marginTop: '30px',
              marginLeft: '140px',
              marginBottom: '-70px',
            }}
          >
            <Col size="12">
              <div className="PageTitle">
                <h2>Pick Your Team</h2>
              </div>
            </Col>
          </Row>
        </div>
        <div className="centered-container">
          <Container className="pick-team-container" fluid>
            <Row className="centered-container">
              <Col sm="9">
                <div className="fantasy-name-header">
                  <Row className="games-header HeaderDown games-header-styling">
                    {/* <Col sm={4} /> */}
                    <Col sm={4} className="pick-team-header">
                      {' '}
                      {team?.team_name ? team.team_name : 'Team'}
                    </Col>
                    <Col
                      sm={4}
                      className="pick-team-header"
                    >{`Balance: ${balance.toFixed(2)}`}</Col>
                    <Col>
                      <button
                        onClick={() => {
                          navigator('/auth/games-dash/fantasy');
                        }}
                        className="fantasy-button2"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          save();
                        }}
                        className="fantasy-button2"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                  <Col className="background-container">
                    <div className="top-row-container">
                      <PlayerCard
                        player={getPlayerByIndex(1)}
                        onPress={() => {
                          setPlayerIndex(1);
                          setSelectedPlayer(getPlayerByIndex(1));
                          setPlayerCardClicked(true);
                          setSidebarOpen(true);
                        }}
                        highlight={playerIndex === 1}
                      />
                      <PlayerCard
                        player={getPlayerByIndex(2)}
                        onPress={() => {
                          setPlayerIndex(2);
                          setSelectedPlayer(getPlayerByIndex(2));
                          setPlayerCardClicked(true);
                          setSidebarOpen(true);
                        }}
                        highlight={playerIndex === 2}
                      />
                      <PlayerCard
                        player={getPlayerByIndex(3)}
                        onPress={() => {
                          setPlayerIndex(3);
                          setSelectedPlayer(getPlayerByIndex(3));
                          setPlayerCardClicked(true);
                          setSidebarOpen(true);
                        }}
                        highlight={playerIndex === 3}
                      />
                    </div>
                    <div className="mid-row-container">
                      <PlayerCard
                        player={getPlayerByIndex(4)}
                        onPress={() => {
                          setPlayerIndex(4);
                          setSelectedPlayer(getPlayerByIndex(4));
                          setPlayerCardClicked(true);
                          setSidebarOpen(true);
                        }}
                        highlight={playerIndex === 4}
                      />
                      <PlayerCard
                        player={getPlayerByIndex(5)}
                        onPress={() => {
                          setPlayerIndex(5);
                          setSelectedPlayer(getPlayerByIndex(5));
                          setPlayerCardClicked(true);
                          setSidebarOpen(true);
                        }}
                        highlight={playerIndex === 5}
                      />
                    </div>
                  </Col>
                </div>
              </Col>
              <Col className={`market-side-bar ${sidebarOpen ? 'open' : ''}`}>
                <div className="sticky-header">
                  <button
                    className="fantasy-button"
                    style={{ marginBottom: '20px', color: 'white' }}
                    onClick={() => setSidebarOpen(false)}
                  >
                    x
                  </button>
                </div>
                {playerIndex !== undefined ? (
                  <Row className="sel-player-container fantasy-title-row">
                    {`Selected Slot: ${playerIndex}`}
                  </Row>
                ) : selectedPlayer === null ? (
                  <Row className="sel-player-container fantasy-title-row">
                    Pick a field spot and select a Player
                  </Row>
                ) : (
                  <Row className="sel-player-container">{`Selected: ${selectedPlayer.player_name}`}</Row>
                )}
                <Row>
                  <Button className="fantasy-button" onClick={resetState}>
                    Clear Filters
                  </Button>
                  <Col>
                    <FilterOption
                      title="Price"
                      options={[
                        {
                          title: 'All',
                          action: () => {
                            setFilteredAthletes(masterList);
                          },
                        },
                        {
                          title: 'Affordable',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) => player.price <= balance
                              )
                            );
                          },
                        },
                        {
                          title: '0 - 10',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 0 && player.price <= 10
                              )
                            );
                          },
                        },
                        {
                          title: '11 - 20',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 11 && player.price <= 20
                              )
                            );
                          },
                        },
                        {
                          title: '21 - 40',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 21 && player.price <= 40
                              )
                            );
                          },
                        },
                        {
                          title: '41 - 60',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 41 && player.price <= 60
                              )
                            );
                          },
                        },
                        {
                          title: '61 - 80',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 61 && player.price <= 80
                              )
                            );
                          },
                        },
                        {
                          title: '81 - 100',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 81 && player.price <= 100
                              )
                            );
                          },
                        },
                        {
                          title: '101 - 150',
                          action: () => {
                            setFilteredAthletes(
                              masterList.filter(
                                (player) =>
                                  player.price >= 101 && player.price <= 150
                              )
                            );
                          },
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Autocomplete
                  style={{
                    width: '350px',
                    height: '15px',
                    color: '#fff',
                    background: '#17181c',
                    marginBottom: '40px',
                    marginTop: '20px',
                    cursor: 'pointer',
                    marginLeft: '20px',
                  }}
                  freeSolo
                  options={filteredAthletes.map(
                    (athlete) => athlete.player_name || ''
                  )}
                  onChange={(_event, selectedValue) =>
                    handleSearchBar(selectedValue)
                  }
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                  }
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <div
                      ref={params.InputProps.ref}
                      className="autocomplete-container"
                    >
                      <input
                        {...params.inputProps}
                        placeholder={'Search Athletes by Name Here'}
                        type="text"
                        className="form-control theme-input player-input search-text"
                        id="free-solo-demo"
                      />
                    </div>
                  )}
                />

                <ul className="player-list">
                  {filteredAthletes.map((player, index) => (
                    <li
                      className="player-row"
                      key={index}
                      onClick={() => {
                        if (playerIndex) {
                          replacePlayer(playerIndex, player);
                        }
                      }}
                    >
                      <h5>${player.price.toFixed(2)}</h5>
                      <h5>{player.player_name}</h5>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PickTeam;
